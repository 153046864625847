import AnimatedView from "@/common/components/AnimatedView";
import { globalStyles } from "@/common/styles";
import ProgressBar from "@/modules/Forms/Register/components/ProgressBar";
import { IntroParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import { CountSlide, NameSlide } from "./components/Slides";
import Age from "./components/Slides/Age";
import Final from "./components/Slides/Final";
import Weight from "./components/Slides/Weight";
import styles from "./styles";

const RegisterForm = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<IntroParamList>>();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const [slides] = useState([
    { component: <CountSlide />, name: "start", icon: "ray-start" },
    { component: <NameSlide />, name: "name", icon: "rename-box" },
    { component: <Age />, name: "birthday", icon: "calendar" },
    { component: <Weight />, name: "weight", icon: "weight-kilogram" },
    { component: <Final />, name: "end", icon: "ray-end" },
  ]);
  return (
    <Card style={[globalStyles.card, styles.card]}>
      {starting_form.slide === 0 && (
        <AnimatedView>
          <Card.Title
            title={t("TextGetStartedTitle")}
            titleStyle={styles.title}
            titleNumberOfLines={0}
            subtitle={
              <Text>
                {t("TextGetStartedTitleSubtitle")}{" "}
                <Text
                  style={globalStyles.link}
                  onPress={async () => navigation.navigate("Login")}
                >
                  {t("TextSignIn")}
                </Text>
                .
              </Text>
            }
            subtitleStyle={styles.subtitle}
            subtitleNumberOfLines={0}
          />
        </AnimatedView>
      )}
      <Card.Content>
        <ProgressBar
          style={styles.progressBar}
          data={slides.map((slide) => {
            return { icon: slide.icon, name: slide.name };
          })}
          current={starting_form.slide}
        />
        <AnimatedView>
          {slides[starting_form.slide || 0].component}
        </AnimatedView>
      </Card.Content>
    </Card>
  );
};

export default memo(RegisterForm);
