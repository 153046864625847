import { moderateScale } from "@/theme/dimensions";
import { memo } from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";

const Header = ({ toggle }: { toggle: () => void }) => {
  const boxes = useSelector((state: RootState) => state.boxes);

  return (
    <View style={[styles.container]}>
      {!boxes.some((b) => b.products.some((bp) => bp.product.hybrid)) && (
        <IconButton
          style={styles.backButton}
          icon={"arrow-left"}
          size={moderateScale(20)}
          onPress={() => {
            toggle();
          }}
        />
      )}
    </View>
  );
};

export default memo(Header);
