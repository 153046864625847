import { isBusinessDay, validateNIF } from "@/common/utils";
import { islandCodes } from "@/config";
import moment from "moment";
import validate from "validate.js";

validate.extend(validate.validators.datetime, {
  // The value is guaranteed not to be null or undefined but otherwise it
  // could be anything.
  parse: function (value, options) {
    return +moment.utc(value);
  },
  // Input is a unix timestamp
  format: function (value, options) {
    var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
    return moment.utc(value).format(format);
  },
});
validate.validators.validatePortugueseVAT = function (
  value,
  options,
  key,
  attributes
) {
  if (value == "" || validateNIF(value)) return null;
  return options.message;
};
validate.validators.businessDay = function (
  value: string,
  options: { message: any }
) {
  if (isBusinessDay(value)) return null;
  return options.message;
};
validate.validators.isAnIsland = function (
  value: string,
  options: { message: any }
) {
  if (
    value[0] !== "9" &&
    !(
      value.length > 4 &&
      value[4] !== "-" &&
      islandCodes.includes(value.slice(0, 2))
    )
  )
    return null;
  return options.message;
};

export const sortBoxesByProducts = (boxes: Box[]): CartProduct[] => {
  let result: CartProduct[] = [];
  boxes.forEach((box) => {
    box.products.forEach((bp) => {
      result.push({
        ...bp.product,
        c_quantity: bp.quantity,
        animals: [box.animal],
      });
    });
  });

  return result;
};
export const getAnimalNamesById = (animals: Animal[]): Object => {
  let result: Object = {};
  animals.forEach((animal) => {
    result[animal._id] = animal.name;
  });
  return result;
};

export const validateForm = (state: OrderForm): OrderFormErrors => {
  const schema = {
    name: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    address: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    zip_code: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      isAnIsland: {
        message: "NotificationIslandMustBeManual",
      },
    },
    country: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    city: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      email: true,
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    nif: {
      presence: {
        allowEmpty: true,
      },
      length: { maximum: 16 },
    },
    payment_type: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    payment_phone: {
      presence: {
        allowEmpty: state.slide !== 2 || state.values.payment_type !== "mbw",
        message: "NotificationCannotBeEmpty",
      },
    },
    payment_iban: {
      presence: {
        allowEmpty:
          state.slide !== 2 || !["dd_f"].includes(state.values.payment_type),
        message: "NotificationCannotBeEmpty",
      },
      length: { maximum: 34 },
    },
    payment_acc_holder: {
      presence: {
        allowEmpty:
          state.slide !== 2 || !["dd_f"].includes(state.values.payment_type),
        message: "NotificationCannotBeEmpty",
      },
      length: { maximum: 100 },
    },
  };
  return validate(state.values, schema, { fullMessages: false });
};
