import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import {
  getFinalPrice,
  getShippingCost,
  isObjectEmpty,
  roundNumber,
} from "@/common/utils";
import {
  boxesToGoogleProducts,
  googleAddPaymentInfo,
  googleAddShippingInfo,
  googlePurchase,
} from "@/common/utils/analytics.utils";
import { shippingBoxValue } from "@/config";
import { push, update } from "@/redux/reducers/Deliveries";
import { changeValues, incrementSlide, toggle } from "@/redux/reducers/Order";
import { update as updateReduxSubscription } from "@/redux/reducers/Subscription";
import { update as updateUser } from "@/redux/reducers/User";
import { getDeliveries } from "@/services/deliveries.service";
import {
  createSubscription,
  updateSubscription,
} from "@/services/subscription.service";
import { updateShippingInfo } from "@/services/user.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import * as Linking from "expo-linking";
import moment from "moment";
import React, { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, View } from "react-native";
import { Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, config, useSpring } from "react-spring";
import { validateForm } from "../../helpers";
import styles from "./styles";
const AnimatedText = Animated(Text);
const Footer = ({ toast }: { toast: ToastType }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const shippings = useSelector((state: RootState) => state.shippings);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const countries = useSelector((state: RootState) => state.countries);
  const state = useSelector((state: RootState) => state.orderForm);
  const [loading, setLoading] = useState(false);
  const [loadingDeliveries, setLoadingDeliveries] = useState(false);

  const [price, setPrice] = useState(0);
  const animated_price = useSpring({
    immediate: false,
    config: config.gentle,
    number: price,
    from: { number: 0 },
  });

  const updateDeliveries = () => {
    setLoadingDeliveries(true);
    Promise.all([getDeliveries(user.accessToken, subscription._id)])
      .then((response2) => {
        dispatch(update(response2[0].data));
        setLoadingDeliveries(false);
      })
      .catch((error) => {
        console.error(error);
        setLoadingDeliveries(false);
        toast.show(t(`BackendNotification${error.response.data.message}`), {
          type: "danger",
        });
      });
  };

  const getShippingValue = () => {
    return getShippingCost(shippings, user, boxes, subscription);
  };
  useEffect(() => {
    setPrice(
      parseFloat(
        getFinalPrice(
          user,
          boxes,
          isObjectEmpty(subscription) || subscription.tier === 1
            ? parseFloat(getShippingValue())
            : 0
        )
      )
    );
  }, [user, boxes]);

  const deliverOrderForm = () => {
    setLoading(true);
    let promise: any;
    if (isObjectEmpty(subscription)) {
      promise = createSubscription(user.accessToken, boxes, state.values);
    } else {
      promise = updateSubscription(
        user.accessToken,
        subscription._id,
        boxes,
        state.values
      );
    }
    promise
      .then(async (response: any) => {
        if (["cc", "cc_f"].includes(state.values.payment_type)) {
          if (Platform.OS == "web") {
            window.open(response.data.payment.method.url, "_blank");
          } else {
            Linking.openURL(response.data.payment.method.url);
          }
        }
        await googlePurchase({
          currency: "EUR",
          transaction_id: response.data.payment._id,
          value: parseFloat(response.data.payment.value),
          affiliation: "Purrmi Store",
          coupon: user.promocode?.code || "",
          shipping: isObjectEmpty(subscription)
            ? shippingBoxValue
            : roundNumber(parseFloat(getShippingValue()), 2),
          tax: roundNumber(
            parseFloat(response.data.payment.value) -
              parseFloat(response.data.payment.value) / 1.23,
            2
          ),
          items: boxesToGoogleProducts(boxes),
        });
        let updated_user = { ...user };
        updated_user.promocode = undefined;
        updated_user.friend_discount = undefined;
        updated_user.cash = 0;
        dispatch(updateUser(user));
        dispatch(push(response.data.delivery));
        dispatch(updateReduxSubscription(response.data.subscription));
        dispatch(incrementSlide());
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
        toast.show(t(`BackendNotification${error.response.data.message}`), {
          type: "danger",
        });
      });
  };

  const continueOrderForm = async () => {
    if (state.slide === 0) {
      dispatch(incrementSlide());
    } else if (state.slide === 3 || state.slide === 4) {
      dispatch(toggle());
    } else {
      let errors = validateForm(state);
      if (errors && Object.keys(errors).length > 0) {
        dispatch(
          changeValues({
            ...state,
            values: { ...state.values },
            errors: errors,
          })
        );
        toast.show(t("ErrorErrorsInForm"), { type: "danger" });
      } else {
        if (state.slide === 1) {
          await googleAddShippingInfo({
            currency: "EUR",
            value: parseFloat(
              getFinalPrice(user, boxes, parseFloat(getShippingValue()))
            ),
            coupon: user.promocode?.code,
            shipping_tier: "Next-day",
            items: boxesToGoogleProducts(boxes),
          });
          updateShippingInfo(user.accessToken, state.values);
          dispatch(incrementSlide());
        } else {
          await googleAddPaymentInfo({
            currency: "EUR",
            value: parseFloat(
              getFinalPrice(user, boxes, parseFloat(getShippingValue()))
            ),
            coupon: user.promocode?.code,
            items: boxesToGoogleProducts(boxes),
          });
          deliverOrderForm();
        }
      }
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.mobileContainer}>
        <View style={styles.productPriceContainer}>
          <Text style={styles.productFinalPriceTitle} variant={"bodySmall"}>
            {state.slide === 3
              ? t("CheckoutFooterMessageShipping")
              : t("TextFirstTimeCtaText")}
          </Text>
          {state.slide === 3 ? (
            <Fragment>
              <Text style={styles.productDeliveryDate} variant={"titleMedium"}>
                {moment(subscription.delivery_date)
                  .locale(i18n.language)
                  .format("LL")}
              </Text>
            </Fragment>
          ) : (
            <Fragment>
              <Text style={styles.productFinalPrice} variant={"displayLarge"}>
                <AnimatedText
                  style={styles.productFinalPrice}
                  variant={"displayLarge"}
                >
                  {animated_price.number.to((n) => n.toFixed(2))}
                </AnimatedText>
                €
              </Text>
              <Text style={styles.productInfoSubtitle} variant={"labelLarge"}>
                {t("BoxesVATIncluded")}
              </Text>
            </Fragment>
          )}
        </View>
        <View style={styles.productActions}>
          {state.slide === 3 && (
            <Button
              mode="contained"
              style={styles.actionButton}
              loading={loadingDeliveries}
              disabled={loadingDeliveries}
              onPress={updateDeliveries}
            >
              {t("TextRefresh")}
            </Button>
          )}
          {state.slide !== 3 && (
            <Button
              style={styles.actionButton}
              mode={state.slide === 3 ? "outlined" : "contained"}
              loading={loading}
              onPress={continueOrderForm}
            >
              {state.slide === 2
                ? t("TextPay")
                : state.slide === 3 || state.slide === 4
                ? t("TextClose")
                : t("TextContinue")}
            </Button>
          )}
          {state.slide === 2 && (
            <View style={styles.paymentSecurityInfo}>
              <Icon
                name={"lock"}
                size={moderateScale(20)}
                color={theme.colors.onSurfaceVariant}
              />
              <Text
                style={styles.paymentSecurityInfoText}
                variant="labelMedium"
              >
                {t("TextBillingSafePaymentText")}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
};

export default memo(Footer);
