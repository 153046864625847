import React, { memo } from "react";
import Svg, { Path } from "react-native-svg";
const Box = ({ ...props }) => {
  return (
    <Svg
      style={props.style}
      width="134"
      height="152"
      viewBox={props.viewBox || "0 0 134 152"}
    >
      <Path d="M63.8735 1.13492L43.4155 12.9558L109.742 51.2805V79.6785C109.742 80.8134 109.181 81.7875 108.199 82.355L92.8235 91.2396C92.3333 91.5225 91.7705 91.5225 91.2804 91.2396C90.7887 90.9551 90.5073 90.468 90.5073 89.9005V62.3948L24.1804 24.0701L3.72233 35.8909C1.75863 37.0259 0.634521 38.9741 0.634521 41.244V110.756C0.634521 113.026 1.75863 114.974 3.72233 116.109L63.8735 150.865C65.8372 152 68.0855 152 70.0492 150.865L130.2 116.109C132.164 114.974 133.288 113.026 133.288 110.756V41.244C133.288 38.9741 132.164 37.0259 130.2 35.8909L70.0492 1.13492C68.0855 -1.01924e-05 65.8372 -1.01924e-05 63.8735 1.13492ZM10.8891 63.3457C9.71086 62.6638 9.30884 61.1547 9.99227 59.9765C10.6742 58.7967 12.1817 58.3947 13.3599 59.0781L42.8325 76.1082C44.0107 76.7901 44.4128 78.2992 43.7293 79.479C43.0474 80.6572 41.5399 81.0592 40.3617 80.3758L10.8891 63.3457ZM10.8891 76.9262C9.71086 76.2443 9.30884 74.7352 9.99227 73.5554C10.6742 72.3772 12.1817 71.9752 13.3599 72.6571L28.097 81.1721C29.2737 81.8555 29.6757 83.3647 28.9938 84.5429C28.3119 85.7226 26.8044 86.1247 25.6261 85.4412L10.8891 76.9262Z" />
    </Svg>
  );
};

export default memo(Box);
