import { decrementSlide, goToSlide, toggle } from "@/redux/reducers/Order";
import { moderateScale } from "@/theme/dimensions";
import { memo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";
import { IconButton, Text, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, useSpring } from "react-spring";
import styles from "./styles";
const AnimatedView = Animated(View);
const AnimatedPressable = Animated(Pressable);
const Header = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const boxes = useSelector((state: RootState) => state.boxes);
  const dispatch = useDispatch();
  const stage1Ref = useRef(null);
  const stage2Ref = useRef(null);
  const current = useSelector((state: RootState) => state.orderForm.slide);
  const [stage1, stage1_api] = useSpring(() => ({
    from: { width: 0 },
  }));
  const [stage1_b, stage1_b_api] = useSpring(() => ({
    from: {
      backgroundColor: theme.colors.surfaceDisabled,
    },
  }));
  const [stage2_b, stage2_b_api] = useSpring(() => ({
    from: {
      backgroundColor: theme.colors.surfaceDisabled,
    },
  }));
  const [stage2, stage2_api] = useSpring(() => ({
    from: { width: 0 },
  }));
  useEffect(() => {
    if (current && current == 1) {
      stage1_api.start({
        width: stage1Ref.current?.offsetWidth,
      });
      stage1_b_api.start({
        backgroundColor: theme.colors.secondary,
      });
    } else if (current && current == 2) {
      stage1_api.start({
        width: stage1Ref.current?.offsetWidth,
      });
      stage1_b_api.start({
        backgroundColor: theme.colors.secondary,
      });
      stage2_api.start({ width: stage2Ref.current?.offsetWidth });
      stage2_b_api.start({
        backgroundColor: theme.colors.secondary,
      });
    }
  }, [current]);

  const moveTo = (slide: number) => {
    dispatch(goToSlide(slide));
  };
  const goBack = () => {
    if (current === 0) {
      dispatch(toggle());
    } else {
      dispatch(decrementSlide());
    }
  };

  return (
    <View style={[styles.container]}>
      {!(
        boxes.some((b) => b.products.some((bp) => bp.product.hybrid)) &&
        current === 0
      ) ? (
        <IconButton
          style={styles.backButton}
          icon={"arrow-left"}
          size={moderateScale(20)}
          onPress={() => {
            if (current < 3) {
              goBack();
            } else {
              dispatch(toggle());
            }
          }}
        />
      ) : (
        <View style={{ minHeight: 50 }} />
      )}
      {current === 3 && (
        <IconButton
          style={styles.backButton}
          icon={"bell-ring-outline"}
          size={moderateScale(20)}
          onPress={() => {
            if (current < 3) {
              goBack();
            } else {
              toggle();
            }
          }}
        />
      )}
      {current < 3 && (
        <View style={styles.trackerContainer}>
          <View style={styles.stageButtonContainer}>
            <Pressable
              style={styles.stageButton}
              disabled={current < 0}
              onPress={() => moveTo(0)}
            >
              <Text
                style={[
                  styles.stageButtonNumber,
                  current < 0 ? styles.stageButtonNumberDisabled : {},
                ]}
                variant="labelMedium"
              >
                1
              </Text>
            </Pressable>
            <Text style={styles.stageButtonText} variant="labelMedium">
              {t("TextSummary")}
            </Text>
          </View>
          <View ref={stage1Ref} style={styles.connectionContainer}>
            <View style={styles.connectionDotted}></View>
            <AnimatedView
              style={{ ...stage1, ...styles.connectionAnimated }}
            ></AnimatedView>
          </View>
          <View style={styles.stageButtonContainer}>
            <AnimatedPressable
              style={{ ...styles.stageButton, ...stage1_b }}
              disabled={current < 1}
              onPress={() => moveTo(1)}
            >
              <Text
                style={[
                  styles.stageButtonNumber,
                  current < 1 ? styles.stageButtonNumberDisabled : {},
                ]}
                variant="labelMedium"
              >
                2
              </Text>
            </AnimatedPressable>
            <Text style={styles.stageButtonText} variant="labelMedium">
              {t("TextShipping")}
            </Text>
          </View>
          <View ref={stage2Ref} style={styles.connectionContainer}>
            <View style={styles.connectionDotted}></View>
            <AnimatedView
              style={{ ...stage2, ...styles.connectionAnimated }}
            ></AnimatedView>
          </View>
          <View style={styles.stageButtonContainer}>
            <AnimatedPressable
              style={{ ...styles.stageButton, ...stage2_b }}
              disabled={current < 2}
              onPress={() => moveTo(2)}
            >
              <Text
                style={[
                  styles.stageButtonNumber,
                  current < 2 ? styles.stageButtonNumberDisabled : {},
                ]}
                variant="labelMedium"
              >
                3
              </Text>
            </AnimatedPressable>
            <Text style={styles.stageButtonText} variant="labelMedium">
              {t("TextPayment")}
            </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default memo(Header);
