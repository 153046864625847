export const light_colors = {
  primary: "rgb(0, 107, 89)",
  onPrimary: "rgb(255, 255, 255)",
  primaryContainer: "rgb(122, 248, 218)",
  onPrimaryContainer: "rgb(0, 32, 26)",
  secondary: "rgb(75, 99, 92)",
  onSecondary: "rgb(255, 255, 255)",
  secondaryContainer: "rgb(205, 232, 223)",
  onSecondaryContainer: "rgb(7, 32, 26)",
  tertiary: "rgb(66, 98, 119)",
  onTertiary: "rgb(255, 255, 255)",
  tertiaryContainer: "rgb(199, 231, 255)",
  onTertiaryContainer: "rgb(0, 30, 46)",
  error: "rgb(186, 26, 26)",
  onError: "rgb(255, 255, 255)",
  errorContainer: "rgb(255, 218, 214)",
  onErrorContainer: "rgb(65, 0, 2)",
  success: "rgb(0, 110, 41)",
  onSuccess: "rgb(255, 255, 255)",
  successContainer: "rgb(106, 255, 134)",
  onSuccessContainer: "rgb(0, 33, 7)",
  warning: "rgb(125, 87, 0)",
  onWarning: "rgb(255, 255, 255)",
  warningContainer: "rgb(255, 222, 170)",
  onWarningContainer: "rgb(39, 25, 0)",
  background: "rgb(250, 253, 250)",
  onBackground: "rgb(25, 28, 27)",
  surface: "rgb(250, 253, 250)",
  onSurface: "rgb(25, 28, 27)",
  surfaceVariant: "rgb(219, 229, 224)",
  onSurfaceVariant: "rgb(63, 73, 69)",
  outline: "rgb(111, 121, 117)",
  outlineVariant: "rgb(191, 201, 196)",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "rgb(46, 49, 48)",
  inverseOnSurface: "rgb(239, 241, 239)",
  inversePrimary: "rgb(91, 219, 190)",
  elevation: {
    level0: "transparent",
    level1: "rgb(238, 246, 242)",
    level2: "rgb(230, 241, 237)",
    level3: "rgb(223, 237, 232)",
    level4: "rgb(220, 236, 231)",
    level5: "rgb(215, 233, 228)",
  },
  surfaceDisabled: "rgba(25, 28, 27, 0.12)",
  onSurfaceDisabled: "rgba(25, 28, 27, 0.38)",
  backdrop: "rgba(41, 50, 47, 0.4)",
  backdrop2: "rgba(41, 50, 47, 0.7)",
};
export const light_colors_2 = {
  primary: "#5056A9",
  onPrimary: "#FFFFFF",
  primaryContainer: "#E0E0FF",
  onPrimaryContainer: "#040865",
  secondary: "#006B59",
  onSecondary: "#FFFFFF",
  secondaryContainer: "#CDE8DF",
  onSecondaryContainer: "#00201A",
  tertiary: "#9C4144",
  onTertiary: "#FFFFFF",
  tertiaryContainer: "#FFDAD9",
  onTertiaryContainer: "#410008",
  error: "rgb(186, 26, 26)",
  onError: "rgb(255, 255, 255)",
  errorContainer: "rgb(255, 218, 214)",
  onErrorContainer: "rgb(65, 0, 2)",
  success: "rgb(0, 110, 41)",
  onSuccess: "rgb(255, 255, 255)",
  successContainer: "rgb(106, 255, 134)",
  onSuccessContainer: "rgb(0, 33, 7)",
  warning: "rgb(125, 87, 0)",
  onWarning: "rgb(255, 255, 255)",
  warningContainer: "rgb(255, 222, 170)",
  onWarningContainer: "rgb(39, 25, 0)",
  background: "#FFFBFF",
  onBackground: "#1B1B1F",
  surface: "#FFFBFF",
  onSurface: "#1B1B1F",
  surfaceVariant: "#E3E1EC",
  onSurfaceVariant: "#46464F",
  outline: "#777680",
  outlineVariant: "#C7C5D0",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "#303034",
  inverseOnSurface: "#F3EFF4",
  inversePrimary: "#BEC2FF",
  elevation: {
    level0: "transparent",
    level1: "rgb(246, 243, 251)",
    level2: "rgb(241, 238, 248)",
    level3: "rgb(236, 233, 246)",
    level4: "rgb(234, 231, 245)",
    level5: "rgb(231, 228, 243)",
  },
  surfaceDisabled: "rgba(25, 28, 27, 0.12)",
  onSurfaceDisabled: "rgba(25, 28, 27, 0.38)",
  backdrop: "rgba(41, 50, 47, 0.4)",
  backdrop2: "rgba(41, 50, 47, 0.7)",
};
export const light_colors_3 = {
  primary: "rgb(80, 86, 169)",
  onPrimary: "rgb(255, 255, 255)",
  primaryContainer: "rgb(224, 224, 255)",
  onPrimaryContainer: "rgb(4, 8, 101)",
  secondary: "rgb(92, 93, 114)",
  onSecondary: "rgb(255, 255, 255)",
  secondaryContainer: "rgb(225, 224, 249)",
  onSecondaryContainer: "rgb(25, 26, 44)",
  tertiary: "rgb(120, 83, 107)",
  onTertiary: "rgb(255, 255, 255)",
  tertiaryContainer: "rgb(255, 216, 238)",
  onTertiaryContainer: "rgb(46, 17, 38)",
  error: "rgb(186, 26, 26)",
  onError: "rgb(255, 255, 255)",
  errorContainer: "rgb(255, 218, 214)",
  onErrorContainer: "rgb(65, 0, 2)",
  success: "rgb(0, 110, 41)",
  onSuccess: "rgb(255, 255, 255)",
  successContainer: "rgb(106, 255, 134)",
  onSuccessContainer: "rgb(0, 33, 7)",
  warning: "rgb(125, 87, 0)",
  onWarning: "rgb(255, 255, 255)",
  warningContainer: "rgb(255, 222, 170)",
  onWarningContainer: "rgb(39, 25, 0)",
  background: "rgb(255, 251, 255)",
  onBackground: "rgb(27, 27, 31)",
  surface: "rgb(255, 251, 255)",
  onSurface: "rgb(27, 27, 31)",
  surfaceVariant: "rgb(227, 225, 236)",
  onSurfaceVariant: "rgb(70, 70, 79)",
  outline: "rgb(119, 118, 128)",
  outlineVariant: "rgb(199, 197, 208)",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "rgb(48, 48, 52)",
  inverseOnSurface: "rgb(243, 239, 244)",
  inversePrimary: "rgb(190, 194, 255)",
  elevation: {
    level0: "transparent",
    level1: "rgb(246, 243, 251)",
    level2: "rgb(241, 238, 248)",
    level3: "rgb(236, 233, 246)",
    level4: "rgb(234, 231, 245)",
    level5: "rgb(231, 228, 243)",
  },
  surfaceDisabled: "rgba(27, 27, 31, 0.12)",
  onSurfaceDisabled: "rgba(27, 27, 31, 0.38)",
  backdrop: "rgba(48, 48, 56, 0.4)",
};
export const light_colors_4 = {
  primary: "rgb(156, 65, 68)",
  onPrimary: "rgb(255, 255, 255)",
  primaryContainer: "rgb(255, 218, 217)",
  onPrimaryContainer: "rgb(65, 0, 8)",
  secondary: "rgb(119, 86, 86)",
  onSecondary: "rgb(255, 255, 255)",
  secondaryContainer: "rgb(255, 218, 217)",
  onSecondaryContainer: "rgb(44, 21, 21)",
  tertiary: "rgb(116, 90, 47)",
  onTertiary: "rgb(255, 255, 255)",
  tertiaryContainer: "rgb(255, 222, 173)",
  onTertiaryContainer: "rgb(40, 25, 0)",
  error: "rgb(186, 26, 26)",
  onError: "rgb(255, 255, 255)",
  errorContainer: "rgb(255, 218, 214)",
  onErrorContainer: "rgb(65, 0, 2)",
  success: "rgb(0, 110, 41)",
  onSuccess: "rgb(255, 255, 255)",
  successContainer: "rgb(106, 255, 134)",
  onSuccessContainer: "rgb(0, 33, 7)",
  warning: "rgb(125, 87, 0)",
  onWarning: "rgb(255, 255, 255)",
  warningContainer: "rgb(255, 222, 170)",
  onWarningContainer: "rgb(39, 25, 0)",
  background: "rgb(255, 251, 255)",
  onBackground: "rgb(32, 26, 26)",
  surface: "rgb(255, 251, 255)",
  onSurface: "rgb(32, 26, 26)",
  surfaceVariant: "rgb(244, 221, 220)",
  onSurfaceVariant: "rgb(82, 67, 67)",
  outline: "rgb(133, 115, 114)",
  outlineVariant: "rgb(215, 193, 193)",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "rgb(54, 47, 46)",
  inverseOnSurface: "rgb(251, 238, 237)",
  inversePrimary: "rgb(255, 179, 178)",
  elevation: {
    level0: "transparent",
    level1: "rgb(250, 242, 246)",
    level2: "rgb(247, 236, 240)",
    level3: "rgb(244, 231, 234)",
    level4: "rgb(243, 229, 233)",
    level5: "rgb(241, 225, 229)",
  },
  surfaceDisabled: "rgba(32, 26, 26, 0.12)",
  onSurfaceDisabled: "rgba(32, 26, 26, 0.38)",
  backdrop: "rgba(59, 45, 45, 0.4)",
};

export const dark_colors = {
  primary: "rgb(91, 219, 190)",
  onPrimary: "rgb(0, 56, 45)",
  primaryContainer: "rgb(0, 81, 67)",
  onPrimaryContainer: "rgb(122, 248, 218)",
  secondary: "rgb(177, 204, 195)",
  onSecondary: "rgb(29, 53, 46)",
  secondaryContainer: "rgb(51, 75, 69)",
  onSecondaryContainer: "rgb(205, 232, 223)",
  tertiary: "rgb(170, 203, 227)",
  onTertiary: "rgb(16, 52, 71)",
  tertiaryContainer: "rgb(42, 74, 95)",
  onTertiaryContainer: "rgb(199, 231, 255)",
  error: "rgb(255, 180, 171)",
  onError: "rgb(105, 0, 5)",
  errorContainer: "rgb(147, 0, 10)",
  onErrorContainer: "rgb(255, 180, 171)",
  success: "rgb(60, 227, 104)",
  onSuccess: "rgb(0, 57, 17)",
  successContainer: "rgb(0, 83, 29)",
  onSuccessContainer: "rgb(106, 255, 134)",
  warning: "rgb(254, 186, 50)",
  onWarning: "rgb(66, 44, 0)",
  warningContainer: "rgb(95, 65, 0)",
  onWarningContainer: "rgb(255, 222, 170)",
  background: "rgb(25, 28, 27)",
  onBackground: "rgb(225, 227, 224)",
  surface: "rgb(25, 28, 27)",
  onSurface: "rgb(225, 227, 224)",
  surfaceVariant: "rgb(63, 73, 69)",
  onSurfaceVariant: "rgb(191, 201, 196)",
  outline: "rgb(137, 147, 143)",
  outlineVariant: "rgb(63, 73, 69)",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "rgb(225, 227, 224)",
  inverseOnSurface: "rgb(46, 49, 48)",
  inversePrimary: "rgb(0, 107, 89)",
  elevation: {
    level0: "transparent",
    level1: "rgb(28, 38, 35)",
    level2: "rgb(30, 43, 40)",
    level3: "rgb(32, 49, 45)",
    level4: "rgb(33, 51, 47)",
    level5: "rgb(34, 55, 50)",
  },
  surfaceDisabled: "rgba(225, 227, 224, 0.12)",
  onSurfaceDisabled: "rgba(225, 227, 224, 0.38)",
  backdrop: "rgba(41, 50, 47, 0.4)",
  backdrop2: "rgba(41, 50, 47, 0.7)",
};
export const dark_colors_2 = {
  primary: "#BEC2FF",
  onPrimary: "#1F2578",
  primaryContainer: "#373E90",
  onPrimaryContainer: "#E0E0FF",
  secondary: "#5BDBBE",
  onSecondary: "#00382D",
  secondaryContainer: "#005143",
  onSecondaryContainer: "#7AF8DA",
  tertiary: "#FFB3B2",
  onTertiary: "#5F131A",
  tertiaryContainer: "#7E2A2E",
  onTertiaryContainer: "#FFDAD9",
  error: "rgb(255, 180, 171)",
  onError: "rgb(105, 0, 5)",
  errorContainer: "rgb(147, 0, 10)",
  onErrorContainer: "rgb(255, 180, 171)",
  success: "rgb(60, 227, 104)",
  onSuccess: "rgb(0, 57, 17)",
  successContainer: "rgb(0, 83, 29)",
  onSuccessContainer: "rgb(106, 255, 134)",
  warning: "rgb(254, 186, 50)",
  onWarning: "rgb(66, 44, 0)",
  warningContainer: "rgb(95, 65, 0)",
  onWarningContainer: "rgb(255, 222, 170)",
  background: "#1B1B1F",
  onBackground: "#E5E1E6",
  surface: "#1B1B1F",
  onSurface: "#E5E1E6",
  surfaceVariant: "#46464F",
  onSurfaceVariant: "#C7C5D0",
  outline: "#91909A",
  outlineVariant: "#46464F",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "#E5E1E6",
  inverseOnSurface: "#1B1B1F",
  inversePrimary: "#5056A9",
  elevation: {
    level0: "transparent",
    level1: "rgb(35, 35, 42)",
    level2: "rgb(40, 40, 49)",
    level3: "rgb(45, 45, 56)",
    level4: "rgb(47, 47, 58)",
    level5: "rgb(50, 50, 62)",
  },
  surfaceDisabled: "rgba(225, 227, 224, 0.12)",
  onSurfaceDisabled: "rgba(225, 227, 224, 0.38)",
  backdrop: "rgba(41, 50, 47, 0.4)",
  backdrop2: "rgba(41, 50, 47, 0.7)",
};
export const dark_colors_3 = {
  primary: "rgb(190, 194, 255)",
  onPrimary: "rgb(31, 37, 120)",
  primaryContainer: "rgb(55, 62, 144)",
  onPrimaryContainer: "rgb(224, 224, 255)",
  secondary: "rgb(197, 196, 221)",
  onSecondary: "rgb(46, 47, 66)",
  secondaryContainer: "rgb(68, 69, 89)",
  onSecondaryContainer: "rgb(225, 224, 249)",
  tertiary: "rgb(232, 185, 213)",
  onTertiary: "rgb(69, 38, 60)",
  tertiaryContainer: "rgb(94, 60, 83)",
  onTertiaryContainer: "rgb(255, 216, 238)",
  error: "rgb(255, 180, 171)",
  onError: "rgb(105, 0, 5)",
  errorContainer: "rgb(147, 0, 10)",
  onErrorContainer: "rgb(255, 180, 171)",
  success: "rgb(60, 227, 104)",
  onSuccess: "rgb(0, 57, 17)",
  successContainer: "rgb(0, 83, 29)",
  onSuccessContainer: "rgb(106, 255, 134)",
  warning: "rgb(254, 186, 50)",
  onWarning: "rgb(66, 44, 0)",
  warningContainer: "rgb(95, 65, 0)",
  onWarningContainer: "rgb(255, 222, 170)",
  background: "rgb(27, 27, 31)",
  onBackground: "rgb(229, 225, 230)",
  surface: "rgb(27, 27, 31)",
  onSurface: "rgb(229, 225, 230)",
  surfaceVariant: "rgb(70, 70, 79)",
  onSurfaceVariant: "rgb(199, 197, 208)",
  outline: "rgb(145, 144, 154)",
  outlineVariant: "rgb(70, 70, 79)",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "rgb(229, 225, 230)",
  inverseOnSurface: "rgb(48, 48, 52)",
  inversePrimary: "rgb(80, 86, 169)",
  elevation: {
    level0: "transparent",
    level1: "rgb(35, 35, 42)",
    level2: "rgb(40, 40, 49)",
    level3: "rgb(45, 45, 56)",
    level4: "rgb(47, 47, 58)",
    level5: "rgb(50, 50, 62)",
  },
  surfaceDisabled: "rgba(229, 225, 230, 0.12)",
  onSurfaceDisabled: "rgba(229, 225, 230, 0.38)",
  backdrop: "rgba(48, 48, 56, 0.4)",
};
export const dark_colors_4 = {
  primary: "rgb(255, 179, 178)",
  onPrimary: "rgb(95, 19, 26)",
  primaryContainer: "rgb(126, 42, 46)",
  onPrimaryContainer: "rgb(255, 218, 217)",
  secondary: "rgb(230, 189, 187)",
  onSecondary: "rgb(68, 41, 41)",
  secondaryContainer: "rgb(93, 63, 63)",
  onSecondaryContainer: "rgb(255, 218, 217)",
  tertiary: "rgb(228, 193, 141)",
  onTertiary: "rgb(66, 44, 5)",
  tertiaryContainer: "rgb(91, 67, 26)",
  onTertiaryContainer: "rgb(255, 222, 173)",
  error: "rgb(255, 180, 171)",
  onError: "rgb(105, 0, 5)",
  errorContainer: "rgb(147, 0, 10)",
  onErrorContainer: "rgb(255, 180, 171)",
  success: "rgb(60, 227, 104)",
  onSuccess: "rgb(0, 57, 17)",
  successContainer: "rgb(0, 83, 29)",
  onSuccessContainer: "rgb(106, 255, 134)",
  warning: "rgb(254, 186, 50)",
  onWarning: "rgb(66, 44, 0)",
  warningContainer: "rgb(95, 65, 0)",
  onWarningContainer: "rgb(255, 222, 170)",
  background: "rgb(32, 26, 26)",
  onBackground: "rgb(236, 224, 223)",
  surface: "rgb(32, 26, 26)",
  onSurface: "rgb(236, 224, 223)",
  surfaceVariant: "rgb(82, 67, 67)",
  onSurfaceVariant: "rgb(215, 193, 193)",
  outline: "rgb(160, 140, 139)",
  outlineVariant: "rgb(82, 67, 67)",
  shadow: "rgb(0, 0, 0)",
  scrim: "rgb(0, 0, 0)",
  inverseSurface: "rgb(236, 224, 223)",
  inverseOnSurface: "rgb(54, 47, 46)",
  inversePrimary: "rgb(156, 65, 68)",
  elevation: {
    level0: "transparent",
    level1: "rgb(43, 34, 34)",
    level2: "rgb(50, 38, 38)",
    level3: "rgb(57, 43, 43)",
    level4: "rgb(59, 44, 44)",
    level5: "rgb(63, 47, 47)",
  },
  surfaceDisabled: "rgba(236, 224, 223, 0.12)",
  onSurfaceDisabled: "rgba(236, 224, 223, 0.38)",
  backdrop: "rgba(59, 45, 45, 0.4)",
};
