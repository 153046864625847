import { update as updateLanguages } from "@/redux/reducers/Languages";
import { getLanguages } from "@/services/languages.service";
import ForgotPasswordScreen from "@/views/ForgotPassword";
import IntroScreen from "@/views/Intro";
import RegisterScreen from "@/views/Register";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { View } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { IntroParamList } from "../../types";

const Stack = createNativeStackNavigator<IntroParamList>();

export default function IntroNavigator() {
  const dispatch = useDispatch();
  const [ready, setReady] = React.useState(false);
  const user = useSelector((state: RootState) => state.user);
  React.useEffect(() => {
    Promise.all([getLanguages(user.accessToken)]).then((response) => {
      dispatch(updateLanguages(response[0].data));
      setReady(true);
    });
  }, []);
  if (!ready) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator animating={true} size={"large"} />
      </View>
    );
  }
  return (
    <Stack.Navigator screenOptions={{ headerShown: false, title: "Purrmi" }}>
      <Stack.Screen name="Login" component={IntroScreen} />
      <Stack.Screen name="Register" component={RegisterScreen} />
      <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
    </Stack.Navigator>
  );
}
