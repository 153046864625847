import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
  },
  checkpointInfoIcon: { marginRight: 10, color: theme.colors.primary },
  infoAddOn: {
    marginTop: 5,
    marginLeft: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  infoAddOnContainer: {
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    marginBottom: 20,
    paddingHorizontal: 20,
  },
  image: {
    width: moderateScale(120),
    height: moderateScale(120),
    alignSelf: "center",
    borderRadius: BORDER_RADIUS,
    marginBottom: 5,
  },
  sectionVetOpinion: {
    alignItems: "center",
    backgroundColor: theme.colors.tertiary,
    borderRadius: BORDER_RADIUS,
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    minHeight: 100,
    padding: 10,
    marginVertical: 15,
  },
  sandInput: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  sectionVetOpinionIcon: {
    color: theme.colors.onTertiary,
    marginBottom: 5,
  },
  sectionVetOpinionText: {
    color: theme.colors.onTertiary,
    textAlign: "center",
  },
  sandInputCheckbox: {},
  sandInputContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    paddingHorizontal: 20,
    width: "100%",
  },
  sandInputText: {
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "80%",
  },
  animalName: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
});
