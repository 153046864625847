import { theme } from "@/theme";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    padding: 20,
  },
  title: { marginBottom: 10 },
  checkpointInfoIcon: { marginRight: 10 },
  card: {
    width: "100%",
    padding: 25,
  },
  infoContainer: { width: "100%", marginBottom: 15, flexDirection: "row" },
  infoContainerFinal: { marginBottom: 0 },
  divider: { marginBottom: 15 },
  infoTitle: { alignSelf: "flex-start" },
  infoDots: {
    top: -2,
    width: "auto",
    marginHorizontal: 5,
    flex: 1,
    borderBottomStyle: "dotted",
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.surfaceDisabled,
  },
  infoValue: { alignSelf: "flex-end" },
  infoAddOn: { marginLeft: 10 },
  infoAddOnGreen: { color: theme.colors.success },
  infoValueSuccess: { color: theme.colors.success, fontWeight: "bold" },
});
