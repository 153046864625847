import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  connectionAnimated: {
    borderEndColor: theme.colors.secondary,
    borderEndWidth: 3,
    borderRadius: BORDER_RADIUS,
    position: "absolute",
    width: 2,
  },
  connectionContainer: {
    alignItems: "center",
    height: 30,
  },
  connectionDotted: {
    borderEndColor: theme.colors.surfaceDisabled,
    borderEndStyle: "dotted",
    borderEndWidth: 2,
    height: "100%",
    width: 2,
  },
  container: {
    flex: 1,
    flexDirection: "row",
  },
  icon: {
    alignItems: "center",
    backgroundColor: theme.colors.surfaceDisabled,
    borderRadius: 100,
    color: theme.colors.onSurfaceDisabled,
    justifyContent: "center",
    padding: 10,
  },
  infoBoxIcon: {
    fill: theme.colors.surfaceDisabled,
    height: moderateScale(164),
    width: moderateScale(145),
  },
  infoBoxIconContainer: { alignItems: "center" },
  infoBoxIconWrapper: {
    position: "absolute",
    width: "100%",
    overflow: "hidden",
  },
  infoBoxIconProgress: {
    fill: theme.colors.secondary,
    height: moderateScale(164),
    top: 0,
    position: "absolute",
    width: moderateScale(145),
  },
  infoContainer: {
    flex: 1,
    paddingHorizontal: 30,
    height: "100%",
  },
  infoDescription: {
    textAlign: "center",
    paddingBottom: 20,
  },
  infoPaymentMethod: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  infoPaymentMethodContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  infoPaymentMethodImage: {
    height: 75,
    width: 75,
  },
  infoSubtitle: {
    marginBottom: 15,
    textAlign: "center",
  },
  infoTitle: {
    textAlign: "center",
  },
  mainIconContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: 100,
    padding: 2,
  },
  mainIconRim: {
    backgroundColor: theme.colors.background,
    borderRadius: 100,
    padding: 3,
  },
  progressContainer: {
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
    paddingBottom: 50,
    width: 70,
  },
});
