import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const languagesSlice = createSlice({
  name: "languages",
  initialState: [] as Language[],
  reducers: {
    update(state, action: PayloadAction<Language[]>) {
      return [...action.payload];
    },
  },
});

export const { update } = languagesSlice.actions;
export default languagesSlice.reducer;
