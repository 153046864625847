import { MaterialCommunityIcons } from "@expo/vector-icons";
import React, { memo } from "react";

const Icon = ({ ...props }) => {
  return (
    <MaterialCommunityIcons {...props} style={[props.icon, props.style]} />
  );
};

export default memo(Icon);
