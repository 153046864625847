import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  dataTableTitleMobile: {
    marginBottom: 10,
    textAlign: "center",
  },
  dataTableTextMobile: {
    ...theme.fonts.labelLarge,
  },
  dataTableTextTitleMobile: {
    ...theme.fonts.bodyMedium,
    marginBottom: 5,
  },
  dataTableContainerMobile: {
    width: "100%",
    borderRadius: BORDER_RADIUS,
    padding: 20,
    marginVertical: 10,
    backgroundColor: theme.colors.background,
  },
  listContainer: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
    marginBottom: 50,
  },
  dataTableText: {
    color: theme.colors.primary,
  },
});
