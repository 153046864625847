import React, { memo, useEffect, useState } from "react";
import { View } from "react-native";
import { animated as Animated, config, useTransition } from "react-spring";
import styles from "./styles";

const RealAnimatedView = Animated(View);

const AnimatedView = ({ ...props }) => {
  const [items, setItems] = useState([]);
  const transition = useTransition([props.children], {
    config: config.stiff,
    from: { opacity: 0 },
    enter: { opacity: 1 },
  });
  useEffect(() => {
    setItems([props.children]);
  }, [props.children]);
  return (
    <View style={props.customStyle ? props.customStyle : styles.container}>
      {transition((style, child) =>
        child ? (
          <RealAnimatedView style={style}>{child}</RealAnimatedView>
        ) : (
          <></>
        )
      )}
    </View>
  );
};

export default memo(AnimatedView);
