import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export const globalStyles = StyleSheet.create({
  link: { color: theme.colors.primary, textDecorationLine: "underline" },
  error: { color: theme.colors.error },
  row: { flexDirection: "row" },
  bold: { fontWeight: "bold" },
  center: { textAlign: "center" },
  normal: { fontWeight: "normal" },
  wFullCentered: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileContainer: {
    zIndex: 0,
    width: "100%",
    maxWidth: 1200,
    alignSelf: "center",
  },
  label: {
    color: theme.colors.onSurfaceVariant,
  },
  highlighted: {
    color: theme.colors.primary,
  },
  card: { padding: 20, width: moderateScale(300), borderRadius: BORDER_RADIUS },
});
