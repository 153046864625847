export const sortBoxesByProducts = (boxes: Box[]): CartProduct[] => {
  let result: CartProduct[] = [];
  boxes.forEach((box) => {
    box.products.forEach((bp) => {
      result.push({
        ...bp.product,
        c_quantity: bp.quantity,
        animals: [box.animal],
      });
    });
  });

  return result;
};
export const getAnimalNamesById = (animals: Animal[]): Object => {
  let result: Object = {};
  animals.forEach((animal) => {
    result[animal._id] = animal.name;
  });
  return result;
};
