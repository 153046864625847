import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  billingFormContainer: {
    alignItems: "center",
    marginTop: 115,
    width: "70%",
  },
  billingFormContainerRight: {
    alignItems: "center",
    width: "50%",
  },
  billingInfoList: {
    marginLeft: 15,
  },
  billingInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 5,
  },
  billingInfoListItemFreeText: {
    ...theme.fonts.labelLarge,
    color: theme.colors.success,
  },
  billingInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
    marginRight: 5,
    marginTop: 2,
  },
  billingInfoListItemText: { ...theme.fonts.labelLarge },
  billingOption: {
    borderRadius: BORDER_RADIUS,
    marginTop: 15,
  },
  billingOptionActive: {
    borderColor: theme.colors.secondary,
    borderRadius: BORDER_RADIUS,
    borderWidth: 2,
    marginTop: 15,
  },
  billingOptionPrice: { ...theme.fonts.bodyLarge },
  billingOptionPriceBefore: {
    ...theme.fonts.labelSmall,
    color: theme.colors.backdrop,
    marginRight: 5,
    textDecorationLine: "line-through",
  },
  billingOptionPriceLineThrough: {
    ...theme.fonts.labelSmall,
    textDecorationLine: "line-through",
  },
  billingOptionPromo: {
    backgroundColor: theme.colors.success,
    borderRadius: BORDER_RADIUS,
    color: theme.colors.background,
    height: 22,
    paddingHorizontal: 5,
    paddingVertical: 1,
  },
  billingOptionSubtitle: { ...theme.fonts.labelLarge, maxWidth: "80%" },
  billingOptionSubtitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  billingOptionSubtitlePriceContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  billingOptionTitle: { ...theme.fonts.bodyMedium },
  billingOptionTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    paddingHorizontal: 30,
    width: "100%",
  },
  headerInfoTitle: {
    marginTop: 10,
    ...theme.fonts.bodyMedium,
  },
  paymentChoiceContainer: {
    width: "100%",
  },
  paymentDescription: {
    textAlign: "justify",
  },
  paymentDescriptionContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 15,
    paddingBottom: 50,
    width: "100%",
  },
  paymentDescriptionInput: {
    marginBottom: 5,
  },
  paymentInfoTitle: {
    marginVertical: 10,
    ...theme.fonts.bodyMedium,
  },
  paymentOption: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    marginBottom: 3,
    paddingHorizontal: 15,
    paddingVertical: 1,
  },
  paymentOptionActive: {
    borderWidth: 2,
    borderColor: theme.colors.secondary,
  },
  paymentOptionContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  paymentOptionImage: {
    height: moderateScale(50),
    width: moderateScale(50),
  },
  paymentOptionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
});
