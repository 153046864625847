import AnimatedView from "@/common/components/AnimatedView";
import { globalStyles } from "@/common/styles";
import { IntroParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Card, Text } from "react-native-paper";
import ManualLogin from "./components/ManualLogin";
import SocialLogin from "./components/SocialLogin";
import styles from "./styles";

const LoginForm = () => {
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<IntroParamList>>();
  const [manual_login, setManualLogin] = useState<boolean>(false);
  const toggleManualLogin = () => {
    setManualLogin((state) => !state);
  };
  return (
    <Card style={globalStyles.card}>
      <Card.Title
        title={t("welcomeAgain")}
        titleStyle={styles.title}
        subtitle={t("welcomeSubheading")}
        subtitleStyle={styles.subtitle}
        titleNumberOfLines={0}
        subtitleNumberOfLines={0}
      />
      <Card.Content>
        <AnimatedView>
          {manual_login ? (
            <ManualLogin toggleManualLogin={toggleManualLogin} />
          ) : (
            <SocialLogin toggleManualLogin={toggleManualLogin} />
          )}
        </AnimatedView>
        <View style={[globalStyles.row]}>
          <Text variant="labelMedium" style={globalStyles.label}>
            {t("TextDontHaveAccount")}
          </Text>
          <Text
            variant="labelMedium"
            onPress={() => navigation.navigate("Register")}
          >
            <Text style={[globalStyles.link, styles.registerLink]}>
              {t("TextSignUp")}
            </Text>
          </Text>
        </View>
      </Card.Content>
    </Card>
  );
};

export default memo(LoginForm);
