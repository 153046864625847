import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";
import styles from "./styles";
const Header = ({ toggle }: { toggle: () => void }) => {
  return (
    <View style={[styles.container]}>
      <IconButton
        style={styles.backButton}
        icon={"arrow-left"}
        size={moderateScale(20)}
        onPress={() => {
          toggle();
        }}
      />
    </View>
  );
};

export default memo(Header);
