import { Button } from "@/common/components/Button";
import { getFinalPrice, getShippingCost } from "@/common/utils";
import {
  boxesToGoogleProducts,
  googleBeginCheckout,
} from "@/common/utils/analytics.utils";
import { toggle as toggleCheckoutForm } from "@/redux/reducers/Order";
import { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, config, useSpring } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const FirstModule = ({
  toast,
  toggle,
}: {
  toast: ToastType;
  toggle: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const shippings = useSelector((state: RootState) => state.shippings);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const state = useSelector((state: RootState) => state.orderForm);
  const [loading, setLoading] = useState(false);

  const [price, setPrice] = useState(0);
  const animated_price = useSpring({
    immediate: false,
    config: config.gentle,
    number: price,
    from: { number: 0 },
  });

  const getShippingValue = () => {
    return getShippingCost(shippings, user, boxes, subscription);
  };
  useEffect(() => {
    setPrice(
      parseFloat(getFinalPrice(user, boxes, parseFloat(getShippingValue()))),
    );
  }, [boxes]);

  return (
    <View style={styles.container}>
      <View style={styles.mobileContainer}>
        <View style={styles.productPriceContainer}>
          <Text style={styles.productFinalPriceTitle} variant={"bodySmall"}>
            {t("TextFirstTimeCtaText")}
          </Text>
          <Fragment>
            <Text style={styles.productFinalPrice} variant={"displayLarge"}>
              <AnimatedText
                style={styles.productFinalPrice}
                variant={"displayLarge"}
              >
                {animated_price.number.to((n) => n.toFixed(2))}
              </AnimatedText>
              €
            </Text>
            <Text style={styles.productInfoSubtitle} variant={"labelLarge"}>
              {t("BoxesVATIncluded")}
            </Text>
          </Fragment>
        </View>
        <View style={styles.productActions}>
          <Button
            style={styles.actionButton}
            mode={"contained"}
            loading={loading}
            onPress={async () => {
              await googleBeginCheckout({
                currency: "EUR",
                value: parseFloat(
                  getFinalPrice(user, boxes, parseFloat(getShippingValue())),
                ),
                items: boxesToGoogleProducts(boxes),
              });
              if (!state.open) {
                dispatch(toggleCheckoutForm());
              }
              toggle();
            }}
          >
            {t("TextContinue")}
          </Button>
          {!boxes.some((b) => b.products.some((bp) => bp.product.hybrid)) && (
            <Button
              style={[styles.actionButton, { backgroundColor: "transparent" }]}
              mode={"outlined"}
              loading={loading}
              onPress={toggle}
            >
              {t("TextClose")}
            </Button>
          )}
        </View>
      </View>
    </View>
  );
};

export default memo(FirstModule);
