import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { discountProducts, getTranslationLanguage } from "@/common/utils";
import {
  googleAddToCart,
  googleRemoveFromCart,
  productToGoogleProduct,
} from "@/common/utils/analytics.utils";
import { experimentalPackageID, free_products, testFoods } from "@/config";
import {
  updateProductQuantity as reduxProductUpdate,
  remove,
} from "@/redux/reducers/Boxes";
import { updateBox } from "@/services/boxes.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import { RootTabParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { Card, IconButton, Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, useTransition } from "react-spring";
import {
  getAnimalNamesById,
  sortBoxesByProducts,
} from "../../../../../helpers";
import styles from "./styles";

const AnimatedView = Animated(View);

const Products = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigation = useNavigation<StackNavigationProp<RootTabParamList>>();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const animals = useSelector((state: RootState) => state.animals);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [animal_names] = useState(getAnimalNamesById(animals));
  const refMap = useMemo(() => new WeakMap(), []);
  const transitions = useTransition(products, {
    from: { opacity: 0, height: 0 },
    keys: (item: CartProduct) => `Cart_Product_${item._id}_${item.animals[0]}`,
    enter: (item: CartProduct) => async (next) => {
      await next({
        opacity: 1,
        height:
          refMap.get(item).offsetHeight === 0
            ? 100
            : refMap.get(item).offsetHeight,
      });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    trail: 100,
    config: { tension: 125, friction: 20, precision: 0.1 },
  });

  useEffect(() => {
    setProducts(sortBoxesByProducts(boxes));
  }, [boxes]);

  const updateProductQuantity = (
    productId: string,
    animalId: string,
    quantity: number,
  ) => {
    setLoading(true);
    let box = { ...boxes.find((b) => b.animal === animalId) };
    let new_box = {
      ...box,
      products: box.products.map((a) => {
        return { ...a };
      }),
    };
    let box_product = new_box.products.find(
      (bp) => bp.product._id === productId,
    );

    if (quantity > 0 && quantity <= box_product.product.quantity) {
      box_product.quantity = quantity;
      updateBox(user.accessToken, animalId, new_box, productId)
        .then((response) => {
          dispatch(reduxProductUpdate({ productId, animalId, quantity }));
          setLoading(false);
        })
        .catch((error) => {
          toast.show(t("BackendNotification" + error.response.data.message), {
            type: "danger",
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  const removeProduct = (productId: string, animalId: string) => {
    setLoading(true);
    let box = { ...boxes.find((b) => b.animal === animalId) };
    let new_box = {
      ...box,
      products: box.products.map((a) => {
        return { ...a };
      }),
    };
    new_box.products = new_box.products.filter(
      (item) => item.product._id !== productId,
    );
    updateBox(user.accessToken, animalId, new_box, productId)
      .then((response) => {
        dispatch(remove({ productId, animalId }));
        setLoading(false);
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setLoading(false);
      });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.infoGreetings} variant={"titleSmall"}>
        {t("DashboardProducts")}
      </Text>
      {products.length == 0 && (
        <View style={styles.noProductsContainer}>
          <Text style={styles.noProductsTitle} variant={"labelLarge"}>
            {t("TextWithoutProductsInCart")}
          </Text>
          <Button
            mode={"contained"}
            onPress={() => navigation.navigate("Explorer")}
          >
            {t("TextGoToProducts")}
          </Button>
        </View>
      )}
      <View>
        {transitions((style, product: CartProduct) => (
          <AnimatedView
            style={{ ...style, width: "100%", position: "relative" }}
          >
            <View
              ref={(ref) => ref && refMap.set(product, ref)}
              style={styles.cardContainer}
            >
              <Card style={styles.card}>
                <View style={{ flexDirection: "row" }}>
                  <View style={styles.imageContainer}>
                    <Image
                      style={styles.image}
                      source={{ uri: product.images[0] }}
                    />
                  </View>
                  <View style={styles.animalsNamesContainer}>
                    <Text
                      style={{ height: 25, textAlign: "center" }}
                      variant={"labelSmall"}
                      numberOfLines={0}
                    >
                      {product.animals.map(
                        (a, i, arr) =>
                          `${animal_names[a]} ${
                            i === arr.length - 1
                              ? ""
                              : i === arr.length - 2
                                ? t("TextAnd")
                                : ", "
                          }
                        `,
                      )}
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text style={styles.productTitle} variant={"labelMedium"}>
                      {product.title[getTranslationLanguage(i18n.language)]}
                    </Text>
                    <View style={styles.priceContainer}>
                      <Text style={styles.productPrice} variant={"titleMedium"}>
                        {(
                          product.price *
                          (1 - product.discount / 100) *
                          product.c_quantity
                        ).toFixed(2)}
                        €
                      </Text>
                      {(discountProducts().includes(product._id) ||
                        testFoods.includes(product.food)) && (
                        <Text
                          style={styles.productPriceStrike}
                          variant={"labelMedium"}
                        >
                          {(
                            (product.price +
                              product.price *
                                (product._id === experimentalPackageID ||
                                testFoods.includes(product.food)
                                  ? 0.15
                                  : 0.1)) *
                            (1 - product.discount / 100)
                          ).toFixed(2)}
                          €
                        </Text>
                      )}
                    </View>
                    {product._id === experimentalPackageID && (
                      <View>
                        <View
                          key={"box_product_free_1"}
                          style={styles.headerInfoListItem}
                        >
                          <View
                            style={{
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <Image
                              source={{ uri: free_products[0].images[0] }}
                              style={styles.headerInfoListItemImage}
                            />
                            <View
                              style={{
                                alignItems: "flex-start",
                                justifyContent: "center",
                                maxWidth: "80%",
                              }}
                            >
                              <Text style={[styles.headerInfoListItemTitle]}>
                                {
                                  free_products[0].title[
                                    getTranslationLanguage(i18n.language)
                                  ]
                                }
                                {" - "}x{1}{" "}
                                <Text
                                  style={{
                                    fontSize: moderateScale(12),
                                    fontWeight: "bold",
                                    color: theme.colors.success,
                                  }}
                                >
                                  {t("TextFree")}
                                </Text>{" "}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {/* <View
                      key={"box_product_free_2"}
                      style={styles.headerInfoListItem}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <Image
                          source={{ uri: free_products[1].images[0] }}
                          style={styles.headerInfoListItemImage}
                        />
                        <View
                          style={{
                            alignItems: "flex-start",
                            justifyContent: "center",
                            maxWidth: "80%",
                          }}
                        >
                          <Text style={[styles.headerInfoListItemTitle]}>
                            {
                              free_products[1].title[
                                getTranslationLanguage(i18n.language)
                              ]
                            }
                            {" - "}x{1}{" "}
                            <Text
                              style={{
                                fontSize: 20,
                                fontWeight: "bold",
                                color: theme.colors.success,
                              }}
                            >
                              {t("TextFree")}
                            </Text>{" "}
                          </Text>
                        </View>
                      </View>
                    </View> */}
                      </View>
                    )}
                    <View style={styles.tagContainer}>
                      {discountProducts().includes(product._id) ||
                      testFoods.includes(product.food) ? (
                        <Text style={styles.tagDiscount} variant={"labelSmall"}>
                          <Icon
                            style={styles.tagIcon}
                            name={"tag"}
                            size={moderateScale(11)}
                          />
                          {product._id === experimentalPackageID ||
                          testFoods.includes(product.food)
                            ? "15%"
                            : "10%"}
                        </Text>
                      ) : (
                        <Text style={styles.tag} variant={"labelSmall"}>
                          {product.quantity <= 0
                            ? t("TextWithoutStock")
                            : product.quantity <= 3
                              ? t("TextAlmostNoStock")
                              : t("TextInStock")}
                        </Text>
                      )}
                    </View>
                    <View style={styles.actionsContainer}>
                      <IconButton
                        mode="contained"
                        style={[styles.action, styles.decrease]}
                        size={moderateScale(11)}
                        disabled={loading || product.c_quantity === 1}
                        icon={"minus"}
                        onPress={async () => {
                          updateProductQuantity(
                            product._id,
                            product.animals[0],
                            product.c_quantity - 1,
                          );
                          await googleRemoveFromCart({
                            currency: "EUR",
                            value: product.price,
                            items: productToGoogleProduct({
                              product: product,
                              quantity: product.c_quantity,
                            }),
                          });
                        }}
                      />
                      <Text style={styles.actionText} variant="bodySmall">
                        {product.c_quantity}
                      </Text>
                      <IconButton
                        mode="contained"
                        style={[styles.action, styles.increase]}
                        disabled={
                          loading ||
                          product.c_quantity === product.quantity ||
                          testFoods.includes(product.food)
                        }
                        size={moderateScale(11)}
                        icon={"plus"}
                        onPress={async () => {
                          updateProductQuantity(
                            product._id,
                            product.animals[0],
                            product.c_quantity + 1,
                          );
                          await googleAddToCart({
                            currency: "EUR",
                            value: product.price,
                            items: productToGoogleProduct({
                              product: product,
                              quantity: product.c_quantity,
                            }),
                          });
                        }}
                      />
                      {!boxes.some((b) =>
                        b.products.some((bp) => bp.product.hybrid),
                      ) ? (
                        <IconButton
                          style={[styles.action, styles.delete]}
                          size={moderateScale(16)}
                          icon={"delete"}
                          disabled={loading}
                          onPress={async () => {
                            removeProduct(product._id, product.animals[0]);
                            await googleRemoveFromCart({
                              currency: "EUR",
                              value: product.price,
                              items: productToGoogleProduct({
                                product: product,
                                quantity: product.c_quantity,
                              }),
                            });
                          }}
                        />
                      ) : (
                        <View style={{ marginLeft: 5 }} />
                      )}
                    </View>
                  </View>
                </View>
              </Card>
            </View>
          </AnimatedView>
        ))}
      </View>
    </View>
  );
};

export default memo(Products);
