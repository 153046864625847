import axios from "axios";
import { Platform } from "react-native";
import { createAnimalFormData, dataURLtoFile } from "../common/utils";
import { baseAPIURL } from "../config";

export function createAnimal(userToken: string, animal: Animal) {
  let animalFormData: FormData;
  if (Platform.OS == "web") {
    animalFormData = new FormData();
    animalFormData.append("name", animal.name);
    animalFormData.append("weight", animal.weight);
    animalFormData.append("sex", animal.sex);
    animalFormData.append("current_food", JSON.stringify(animal.current_food));
    animalFormData.append("birthday", new Date(animal.birthday).toISOString());
    animalFormData.append("type", animal.type);
    animal.images?.forEach((image, index) => {
      let file = dataURLtoFile(image.uri, animal.name + index);
      animalFormData.append("photo", file);
    });
  } else {
    animalFormData = createAnimalFormData(animal);
  }
  const config = {
    method: "post",
    url: `${baseAPIURL}/animals/`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": userToken,
    },
    data: animalFormData,
  };
  return axios(config);
}

export function getAnimals(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/animals`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getAnimal(userToken: string, id: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/animals/${id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getAnimalTypes(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/animaltypes`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function deleteUserAnimal(userToken: string, id: string) {
  const config = {
    method: "delete",
    url: `${baseAPIURL}/animals/${id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function updateUserAnimal(
  userToken: string,
  animal: Animal,
  id: string
) {
  let animalFormData: FormData;
  if (Platform.OS == "web") {
    animalFormData = new FormData();
    animalFormData.append("name", animal.name);
    animalFormData.append("weight", animal.weight);
    animalFormData.append("sex", animal.sex);
    animalFormData.append("birthday", new Date(animal.birthday).toISOString());
    animalFormData.append("current_food", JSON.stringify(animal.current_food));
    animalFormData.append("type", animal.type);
    animal.images?.forEach((image, index) => {
      let file = dataURLtoFile(image.uri, animal.name + index);
      animalFormData.append("photo", file);
    });
  } else {
    animalFormData = createAnimalFormData(animal);
  }
  const config = {
    method: "put",
    url: `${baseAPIURL}/animals/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": userToken,
    },
    data: animalFormData,
  };

  return axios(config);
}
