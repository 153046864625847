import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
  },
  headerView: {
    width: "100%",
    color: theme.colors.primary,
    backgroundColor: theme.colors.background,
    paddingTop: 15,
  },
  titleContainer: {
    width: "100%",
    padding: 20,
    paddingBottom: 0,
    paddingTop: 50,
    flexDirection: "row",
    backgroundColor: theme.colors.background,
    alignItems: "center",
  },
  bodyView: {
    width: "100%",
    flex: 1,
    paddingHorizontal: 20,
    backgroundColor: theme.colors.background,
  },
  title: { marginVertical: 15, textAlign: "center" },
  headerCardTitleAvatar: {
    backgroundColor: theme.colors.primary,
    alignItems: "center",
    alignSelf: "center",
  },
  avatarView: {
    alignSelf: "center",
    marginTop: 20,
    flexDirection: "column",
  },
  textAvatarView: {
    color: theme.colors.primary,
    alignSelf: "center",
    marginTop: 20,
    flexDirection: "column",
    fontWeight: "700",
  },
  menuContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  formView: {
    color: theme.colors.primary,
    flex: 1,
    width: "100%",
    alignSelf: "flex-start",
  },
  secondBodyView: {
    color: theme.colors.primary,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 10,
    width: "100%",
  },
  friendReferralContainer: {
    width: "100%",
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  friendReferralCard: {
    padding: 10,
    borderRadius: 5,
    maxWidth: 400,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  inviteFriendContainer: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 20,
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: BORDER_RADIUS,
  },
  friendReferralCardMobile: {
    padding: 10,
    borderRadius: 5,
    maxWidth: 400,
    alignItems: "center",
    justifyContent: "center",
  },
  friendConquests: {
    padding: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  friendConquestsCard: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.background,
    padding: 15,
    borderRadius: 5,
    borderWidth: 2,
    marginBottom: 10,
    borderColor: theme.colors.primary,
    width: 250,
  },
  mainActionButton: {
    padding: 5,
    backgroundColor: theme.colors.primary,
    borderRadius: BORDER_RADIUS,
    marginHorizontal: 15,
  },
});
