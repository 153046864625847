import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Image, Platform, ScrollView, View } from "react-native";
import { Text } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";
const Process = () => {
  const { t } = useTranslation();
  const animals = useSelector((state: RootState) => state.animals);
  const getNamesOfExperimentalPets = (type: number) => {
    let experimental_animals = animals;
    let custom_text = experimental_animals.map((animal, index) => {
      if (index != 0 && index === animals.length - 1) {
        return (
          <Text key={"name_of_animal_list" + index}>
            {" "}
            {t("TextAnd")}{" "}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            <Text style={styles.animalName}>{animal.name}</Text>
          </Text>
        );
      } else {
        return (
          <Text key={"name_of_animal_list" + index}>
            {index !== 0 ? ", " : ""}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            {<Text style={styles.animalName}>{animal.name}</Text>}
          </Text>
        );
      }
    });
    return custom_text;
  };

  const renderHorizontalListItem = ({ item }) => (
    <View style={[styles.processHorizontalListItem]}>
      <Image style={styles.horizontalListImage} source={item.image} />
      <Text style={[styles.processHorizontalListItemTitle]}>{item.title}</Text>
      <Text style={[styles.processHorizontalListItemText]}>{item.text}</Text>
    </View>
  );

  return (
    <View style={[styles.processContainer]}>
      <Text variant="titleSmall" style={[styles.processTitle]}>
        {`${t("TextFirstTimeProcessTitle2")} `}
        <Text>{getNamesOfExperimentalPets(1)}</Text>.
      </Text>
      <ScrollView
        contentContainerStyle={{
          marginHorizontal: "auto",
        }}
        horizontal={true}
        showsHorizontalScrollIndicator={Platform.OS === "web"}
        scrollEnabled={true}
      >
        <FlatList
          contentContainerStyle={[styles.processHorizontalList]}
          data={[
            {
              id: "process_list_1",
              image: require("@/assets/images/process_1.png"),
              text: t("TextFirstTimeProcessListItemText"),
            },
            {
              id: "process_list_2",
              image: require("@/assets/images/process_2.png"),
              text: t("TextFirstTimeProcessListItemText2"),
            },
            {
              id: "process_list_3",
              image: require("@/assets/images/process_3.png"),
              text: t("TextFirstTimeProcessListItemText3"),
            },
          ]}
          horizontal={true}
          scrollEnabled={Platform.OS == "web" ? true : false}
          showsHorizontalScrollIndicator={false}
          renderItem={renderHorizontalListItem}
          keyExtractor={(item) => item.id}
        />
      </ScrollView>
    </View>
  );
};

export default memo(Process);
