import axios from "axios";
import { baseAPIURL } from "./../config";
export function validatePromocode(userToken: string, code: string) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/promocodes`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: { code: code },
  };

  return axios(config);
}

export function disablePromocode(userToken: string) {
  const config = {
    method: "delete",
    url: `${baseAPIURL}/promocodes`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}
