import { default_animal_values } from "@/common/helpers/animals";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const animalSlice = createSlice({
  name: "animalForm",
  initialState: {
    open: false,
    type: "",
    selectedAnimal: undefined,
    values: default_animal_values,
    errors: {} as AnimalFormErrors,
  },
  reducers: {
    toggle(state) {
      state.open = !state.open;
    },
    changeType(state, action: PayloadAction<string>) {
      state.type = action.payload;
    },
    changeTypeAndOpen(state, action: PayloadAction<"create" | "edit">) {
      state.type = action.payload;
      state.open = true;
    },
    changeSelectedAnimal(state, action: PayloadAction<Animal>) {
      state.selectedAnimal = action.payload;
    },
    changeValues(
      state,
      action: PayloadAction<{
        values: AnimalFormValues;
        errors: AnimalFormErrors;
      }>
    ) {
      return {
        ...state,
        values: action.payload.values,
        errors: action.payload.errors,
      };
    },
  },
});

export const {
  changeType,
  changeSelectedAnimal,
  changeValues,
  toggle,
  changeTypeAndOpen,
} = animalSlice.actions;
export default animalSlice.reducer;
