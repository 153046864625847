import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    padding: 30,
  },
  faqAccordion: {
    backgroundColor: theme.colors.surfaceVariant,
    marginTop: 20,
    paddingVertical: 10,
    borderRadius: BORDER_RADIUS,
  },
  faqTitle: {
    marginBottom: 10,
    color: theme.colors.onBackground,
    width: "100%",
    textAlign: "center",
  },
  faqSubtitle: {
    width: "100%",
    textAlign: "center",
  },
  faqQuestionIcon: {
    backgroundColor: "transparent",
    borderRadius: BORDER_RADIUS,
  },
  faqQuestionTitle: {
    color: theme.colors.onSurfaceVariant,
    ...theme.fonts.bodySmall,
  },
  faqAnswerTitle: {
    textAlign: "justify",
    paddingLeft: 0,
    color: theme.colors.onBackground,
    ...theme.fonts.labelLarge,
  },
});
