import { toggle } from "@/redux/reducers/Animal";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { View } from "react-native";
import { IconButton } from "react-native-paper";
import { useDispatch } from "react-redux";
import styles from "./styles";
const Header = () => {
  const dispatch = useDispatch();
  return (
    <View style={[styles.container]}>
      <IconButton
        style={styles.backButton}
        icon={"arrow-left"}
        size={moderateScale(20)}
        onPress={() => {
          dispatch(toggle());
        }}
      />
    </View>
  );
};

export default memo(Header);
