import { theme } from "@/theme";
import React, { memo } from "react";
import { Button as PaperButton } from "react-native-paper";
import styles from "./styles";

const Button = ({ ...props }) => (
  <PaperButton
    style={[styles.button, props.style]}
    buttonColor={theme.colors.secondary}
		labelStyle={{width:"100%"}}
    {...props}
  >
    {props.children}
  </PaperButton>
);

export default memo(Button);
