import { capitalizeFirstLetter } from "@/common/utils";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";
import Icon from "../Icon";
import styles from "./styles";

const Toast = ({ ...props }) => {
  const [type] = useState<string>(
    props.toast.type === "danger"
      ? "error"
      : props.toast.type === "info"
      ? "secondary"
      : props.toast.type
  );
  const iconPerType = (type: string) => {
    return type === "info"
      ? "information"
      : type === "success"
      ? "check"
      : type === "error"
      ? "close-octagon"
      : type === "warning"
      ? "alert"
      : "information";
  };
  return (
    <View style={[styles.container, { backgroundColor: theme.colors[type] }]}>
      <Icon
        style={styles.icon}
        name={iconPerType(type)}
        size={moderateScale(18)}
        color={theme.colors.onPrimary}
      />
      <Text
        style={[
          styles.text,
          {
            color:
              theme.colors[
                type ? "on" + capitalizeFirstLetter(type) : "onSecondary"
              ],
          },
        ]}
        variant="labelLarge"
      >
        {props.toast.message}
      </Text>
    </View>
  );
};

export default memo(Toast);
