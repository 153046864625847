import axios from "axios";
import { baseAPIURL } from "./../config";

export function getCountry(userToken: string, country_id: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/countries/${country_id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getCountries(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/countries`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}
