import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const deliveriesSlice = createSlice({
  name: "deliveries",
  initialState: [] as Delivery[],
  reducers: {
    update(state, action: PayloadAction<Delivery[]>) {
      return [...action.payload];
    },
    push(state, action: PayloadAction<Delivery>) {
      state.push(action.payload);
    },
  },
});

export const { update, push } = deliveriesSlice.actions;
export default deliveriesSlice.reducer;
