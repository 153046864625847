import AnimatedView from "@/common/components/AnimatedView";
import StructuredNames from "@/common/components/StructuredNames";
import { globalStyles } from "@/common/styles";
import { goToLink } from "@/common/utils";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Divider, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import ManualLogin from "../../ManualRegister";
import SocialLogin from "../../SocialRegister";
import styles from "./styles";

const Final = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const [manual_login, setManualLogin] = useState<boolean>(false);
  const [state] = useState({
    values: starting_form.values.map((a) => {
      return { ...a };
    }),
    errors: [],
  });

  const toggleManualLogin = () => {
    setManualLogin((state) => !state);
  };

  return (
    <View style={styles.container}>
      <Text variant="bodySmall" style={styles.title}>
        {t("TextGetStartedSubtitleSubtitle10")}{" "}
        <StructuredNames values={state.values} />
      </Text>
      <AnimatedView>
        {manual_login ? (
          <ManualLogin toggleManualLogin={toggleManualLogin} />
        ) : (
          <SocialLogin toggleManualLogin={toggleManualLogin} />
        )}
      </AnimatedView>
      <Divider style={styles.divider} />
      <Text variant="labelSmall">
        {t("TextAgreeingWithTerms1")}{" "}
        <Text
          style={globalStyles.link}
          onPress={async () =>
            goToLink("https://www.purrmi.com/termos-e-condicoes")
          }
        >
          {t("TextTerms")}
        </Text>{" "}
        {t("TextAgreeingWithTerms2")}{" "}
        <Text
          style={globalStyles.link}
          onPress={async () =>
            goToLink("https://www.purrmi.com/politica-de-privacidade")
          }
        >
          {t("TextConditions")}
        </Text>
        {"."}
      </Text>
    </View>
  );
};

export default memo(Final);
