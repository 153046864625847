import { MAX_WEIGHT_OF_ANIMALS } from "@/config";
import validate from "validate.js";

export const validateForm = (values: RegisterFormValues[]) => {
  const schema = {
    name: {
      presence: {
        allowEmpty: false,
        message: `NotificationCannotBeEmpty`,
      },
    },
    sex: {
      presence: {
        allowEmpty: false,
        message: `NotificationCannotBeEmpty`,
      },
    },
    birthday: {
      presence: {
        allowEmpty: false,
        message: `NotificationCannotBeEmpty`,
      },
    },
    weight: {
      presence: {
        allowEmpty: false,
        message: `NotificationCannotBeEmpty`,
      },
      numericality: {
        greaterThan: 0,
        lessThan: MAX_WEIGHT_OF_ANIMALS,
        message: "NotifcationMustBeGreaterThanZeroLessThan15",
      },
    },
    preference: {
      presence: {
        allowEmpty: false,
        message: `NotificationCannotBeEmpty`,
      },
    },
  };
  let all_errors = [];
  values.forEach((form) => {
    let errors = validate(form, schema, { fullMessages: false });
    all_errors.push(errors);
  });
  return all_errors;
};
