import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

const PROGRESS_BAR_DEFAULT_HEIGHT = 5;

export default StyleSheet.create({
  container: {
    width: "100%",
  },
  tickContainer: {
    width: "100%",
    flexDirection: "row",
  },
  progressBarContainer: {
    width: "100%",
    height: PROGRESS_BAR_DEFAULT_HEIGHT,
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.surfaceVariant,
  },
  progressBar: {
    position: "absolute",
    height: PROGRESS_BAR_DEFAULT_HEIGHT,
    bottom: 0,
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.primary,
  },
  sectionIconContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  sectionIcon: {},
});
