import { Button } from "@/common/components/Button";
import { globalStyles } from "@/common/styles";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch } from "react-redux";
import styles from "./styles";
const ProcessingInfo = ({ delivery }: { delivery: Delivery }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const linkToInvoice = () => {
    if (Platform.OS === "web") {
      window.open(delivery.pdf_link, "_blank");
    } else {
      Linking.openURL(delivery.pdf_link);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.invoiceContainer}>
        <Text style={styles.invoiceText} variant="labelLarge">
          <Text style={globalStyles.bold} variant="labelLarge">
            {t("WaitingProcessingInvoice")}
          </Text>
          {"\n"}
          {t("WaitingProcessingInvoice2")}
        </Text>
        <Button
          style={styles.invoiceButton}
          labelStyle={styles.invoiceButtonLabel}
          mode={"contained"}
          onPress={linkToInvoice}
        >
          {t("TextInvoice")}
        </Button>
      </View>
    </View>
  );
};

export default memo(ProcessingInfo);
