import { validate } from "validate.js";
export const getRelativeScale = (selected: number, current: number): number => {
  if (current - selected === 0) return 1;

  return 1 / (Math.abs(current - selected) + 1);
};

export const validateUserForm = (values: { name: string; email: string }) => {
  const schema = {
    name: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    email: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      email: true,
    },
  };
  return validate(values, schema, { fullMessages: false });
};
export const validateAddressForm = (values: {
  address: string;
  phone: string;
  zip_code: string;
  city: string;
  nif: string;
}) => {
  const schema = {
    address: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    phone: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      format: {
        pattern: "(9[1236][0-9]) ?([0-9]{3}) ?([0-9]{3})",
        message: "NotificationMustBeValidPortuguesePhoneNumber",
      },
    },
    zip_code: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      format: {
        pattern: "^\\d{4}-\\d{3}$",
        message: "NotificationMustBeValidPortugueseZipCode",
      },
    },
    city: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    nif: {
      presence: false,
      validatePortugueseVAT: {
        message: "NotificationMustBeValidPortugueseVatNumber",
      },
    },
  };
  return validate(values, schema, { fullMessages: false });
};
export const validatePasswordForm = (values: {
  password: string;
  confirmPassword: string;
}) => {
  const schema = {
    password: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    confirmPassword: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      length: {
        minimum: 6,
        message: "NotificationMustBeSixChatLong",
      },
      equality: {
        attribute: "password",
        message: "NotificationMustBeEqualToTheOtherPassword",
      },
    },
  };
  return validate(values, schema, { fullMessages: false });
};
