import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    backgroundColor: theme.colors.background,
    borderRightColor: theme.colors.primary,
    borderRightWidth: 1,
    flex: 1,
  },
  orderButton: {
    width: "100%",
    height: 65,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.secondary,
  },
  orderButtonDisabled: {
    width: "100%",
    height: 65,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.surfaceDisabled,
  },
  orderButtonLabel: {
    color: theme.colors.onPrimary,
  },
  orderButtonIcon: {
    color: theme.colors.onPrimary,
    marginRight: 10,
  },
  orderButtonContainer: {
    paddingBottom: 25,
    paddingHorizontal: 10,
    width: "100%",
  },
  contentView: {
    justifyContent: "flex-end",
    margin: 0,
  },
  logo: { width: "100%", height: 180, fill: theme.colors.primary },
  bottomTabContainer: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
  },
  intlAndHelpContainer: {},
});
