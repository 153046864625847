import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { Avatar, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";
const Vet = () => {
  const { t, i18n } = useTranslation();
  const animals = useSelector((state: RootState) => state.animals);
  const getNamesOfExperimentalPets = (type: number) => {
    let experimental_animals = animals;
    let custom_text = experimental_animals.map((animal, index) => {
      if (index != 0 && index === animals.length - 1) {
        return (
          <Text key={"name_of_animal_list" + index}>
            {" "}
            {t("TextAnd")}{" "}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            <Text style={styles.animalName}>{animal.name}</Text>
          </Text>
        );
      } else {
        return (
          <Text key={"name_of_animal_list" + index}>
            {index !== 0 ? ", " : ""}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            {<Text style={styles.animalName}>{animal.name}</Text>}
          </Text>
        );
      }
    });
    return custom_text;
  };

  return (
    <View style={[styles.invertedSection]}>
      <View style={styles.vetContainer}>
        <Text variant="titleSmall" style={[styles.vetTextTitle]}>
          {`${t("TextFirstTimeVetTitle")} `}
          <Text>{getNamesOfExperimentalPets(1)}</Text>.
        </Text>
        <View style={[styles.vetRow]}>
          <View style={[styles.vetTextContainer]}>
            <Text style={[styles.vetTextDescription]}>
              {t("TextFirstTimeVetDescriptionText")}
            </Text>
            <View style={styles.vetInfoList}>
              <View style={styles.vetInfoListItem}>
                <Avatar.Icon
                  size={moderateScale(40)}
                  color={theme.colors.secondary}
                  style={styles.vetInfoListItemIcon}
                  icon={"shield-plus"}
                />
                <Text style={[styles.vetInfoListItemText]}>
                  {t("TextFirstTimeVetDescriptionItem")}
                </Text>
              </View>
              <View style={styles.vetInfoListItem}>
                <Avatar.Icon
                  size={moderateScale(40)}
                  color={theme.colors.secondary}
                  style={styles.vetInfoListItemIcon}
                  icon={"shield-plus"}
                />
                <Text style={[styles.vetInfoListItemText]}>
                  {t("TextFirstTimeVetDescriptionItem2")}
                </Text>
              </View>
              <View style={styles.vetInfoListItem}>
                <Avatar.Icon
                  size={moderateScale(40)}
                  color={theme.colors.secondary}
                  style={styles.vetInfoListItemIcon}
                  icon={"shield-plus"}
                />
                <Text style={[styles.vetInfoListItemText]}>
                  {t("TextFirstTimeVetDescriptionItem3")}
                </Text>
              </View>
            </View>
          </View>
          <View style={[styles.vetImageContainer]}>
            <Image
              source={require("@/assets/images/CrisVetCompress.png")}
              style={[styles.vetImage]}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Vet);
