import Background from "@/common/components/Background";
import MoreModule from "@/modules/More";
import React, { memo } from "react";
import { ImageBackground, ScrollView } from "react-native";
import styles from "./styles";

const More = () => {
  return (
    <Background>
      <ImageBackground
        style={styles.imageBackground}
        source={require("@/assets/images/logo_transparent.png")}
        imageStyle={styles.repeatImage}
      >
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollableContainer}
        >
          <MoreModule />
        </ScrollView>
      </ImageBackground>
    </Background>
  );
};

export default memo(More);
