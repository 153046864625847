import AnimatedView from "@/common/components/AnimatedView";
import Background from "@/common/components/Background";
import { LanguageDropdown } from "@/common/components/Dropdown";
import Logo from "@/common/components/Svgs/Logo";
import { ForgotPasswordForm } from "@/modules/Forms";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { ImageBackground, View } from "react-native";
import { useTheme } from "react-native-paper";
import styles from "./styles";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Background>
      <ImageBackground
        style={styles.imageBackground}
        source={require("@/assets/images/logo_transparent.png")}
        imageStyle={styles.repeatImage}
      >
        <View style={styles.header}>
          <Logo style={styles.logo} />
          <View style={styles.iconContainer}>
            <LanguageDropdown />
          </View>
        </View>
        <View style={styles.main}>
          <AnimatedView>
            <ForgotPasswordForm />
          </AnimatedView>
        </View>
      </ImageBackground>
    </Background>
  );
};

export default memo(ForgotPassword);
