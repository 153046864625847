import animalReducer from "./Animal";
import animalsReducer from "./Animals";
import boxesReducer from "./Boxes";
import countriesReducer from "./Countries";
import deliveriesReducer from "./Deliveries";
import languagesReducer from "./Languages";
import orderReducer from "./Order";
import registerReducer from "./Register";
import shippingReducer from "./Shippings";
import subscriptionReducer from "./Subscription";
import userReducer from "./User";

import { combineReducers } from "redux";

const appReducer = combineReducers({
  user: userReducer,
  boxes: boxesReducer,
  animals: animalsReducer,
  registerForm: registerReducer,
  orderForm: orderReducer,
  animalForm: animalReducer,
  subscription: subscriptionReducer,
  shippings: shippingReducer,
  countries: countriesReducer,
  deliveries: deliveriesReducer,
  languages: languagesReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "user/logout") {
    state = undefined;

    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
