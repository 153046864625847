import { theme } from "@/theme";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  forgotPassword: {
    width: "100%",
    alignItems: "flex-end",
    marginBottom: 24,
  },
  input: {
    marginBottom: 10,
  },
  goBackContainer: {
    width: "100%",
    alignItems: "flex-start",
    marginBottom: 10,
  },
  goBackButton: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  goBackButtonIcon: { marginRight: 5 },
  goBackButtonLabel: { color: theme.colors.onSurfaceVariant },
});
