import { Button, ImageButton } from "@/common/components/Button";
import { updateValuesAndGoNext } from "@/redux/reducers/Register";
import moment from "moment";
import React, { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { validateForm } from "../../../helpers";
import styles from "./styles";

const Age = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const [state, setState] = useState({
    values: starting_form.values.map((a) => {
      return { ...a };
    }),
    errors: [],
  });

  const onSelectButton = (index: number, type: number) => {
    setState({
      ...state,
      values: state.values.map((form, jndex) => {
        if (index === jndex) {
          form.birthday =
            type === 0
              ? moment().subtract(6, "months").format()
              : type === 1
              ? moment().subtract(5, "years").format()
              : moment().subtract(10, "years").format();
        }
        return form;
      }),
      errors: validateForm(state.values).map((errors, jndex) => {
        if (state.errors[jndex] && index === jndex) {
          return errors;
        } else if (state.errors[jndex]) {
          return state.errors[jndex];
        }
      }),
    });
  };

  const onUpdateStartingForm = () => {
    let errors = validateForm(state.values);
    if (!errors.some((form_errors) => form_errors.birthday)) {
      dispatch(
        updateValuesAndGoNext({
          slide: starting_form.slide + 1,
          values: state.values,
        })
      );
    } else {
      setState({
        ...state,
        errors: errors,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Text variant="bodySmall" style={styles.title}>
        {state.values.length > 1
          ? t("TextGetStartedSubtitle3P")
          : t("TextGetStartedSubtitle3S")}
      </Text>
      <Text variant="labelMedium" style={styles.subtitle}>
        {t("TextGetStartedSubtitleSubtitle3")}
      </Text>
      {state.values.map((form, index) => (
        <Fragment key={"Form_Age_" + index}>
          <Chip style={styles.animalName} icon="paw">
            {form.name}
          </Chip>
          <View style={styles.buttonContainer}>
            <ImageButton
              mode={
                form.birthday !== "" &&
                moment().diff(moment(form.birthday), "years") === 0
                  ? "contained"
                  : "outlined"
              }
              style={styles.button}
              imageStyle={styles.buttonImage}
              labelStyle={styles.buttonLabel}
              source={require("@/assets/images/icons/kitten.png")}
              onPress={() => onSelectButton(index, 0)}
            >
              {t("TextKittenCat")}
            </ImageButton>
            <ImageButton
              mode={
                form.birthday !== "" &&
                moment().diff(moment(form.birthday), "years") > 0 &&
                moment().diff(moment(form.birthday), "years") < 7
                  ? "contained"
                  : "outlined"
              }
              style={styles.button}
              imageStyle={styles.buttonImage}
              labelStyle={styles.buttonLabel}
              source={require("@/assets/images/icons/normalCat.png")}
              onPress={() => onSelectButton(index, 1)}
            >
              {t("TextAdultCat")}
            </ImageButton>
            <ImageButton
              mode={
                form.birthday !== "" &&
                moment().diff(moment(form.birthday), "years") >= 7
                  ? "contained"
                  : "outlined"
              }
              style={styles.button}
              imageStyle={styles.buttonImage}
              labelStyle={styles.buttonLabel}
              source={require("@/assets/images/icons/senior.png")}
              onPress={() => onSelectButton(index, 2)}
            >
              {t("TextSeniorCat")}
            </ImageButton>
          </View>
        </Fragment>
      ))}
      <Button
        mode="contained"
        style={{ width: "100%" }}
        disabled={state.values.some((a) => a.birthday === "")}
        onPress={onUpdateStartingForm}
      >
        {t("TextContinue")}
      </Button>
    </View>
  );
};

export default memo(Age);
