import React, { memo } from "react";
import { Pressable } from "react-native";
import Svg, { G } from "react-native-svg";
import styles from "./styles";
const Logo = ({ ...props }) => {
  return (
    <Pressable style={styles.container} onPress={props.onPress}>
      <Svg style={props.style} width={256} height={128} viewBox="0 0 1024 512">
        <G>
          <path
            d="M281.5,259.7c-4.6,0-8.2-0.8-10.8-1.8v40.6c0,7,3.5,10.4,12.1,10.4h6.1v2h-68.6v-2h5.1c7.1,0,10.2-4,10.2-11
		v-96.4c0-6.8-4.4-8.9-8.6-8.9h-6.8v-1.8h66.6c25,0,40.2,16.8,40.2,33.1C327.3,245.5,310,259.7,281.5,259.7z M278.7,192.7h-8v63
		c1.8,1.2,4.4,2.1,8.2,2.1c9.6,0,13.5-3.1,13.5-32.4C292.5,193.4,285.9,192.7,278.7,192.7z"
          />
          <path
            d="M429.7,310.7h-39.5v-10.8c-2.9,9.5-12.3,13.4-23.5,13.4c-12.5,0-24.9-7.6-24.9-25.4v-50.7c0-3.9-1-5.7-4.2-5.7
		h-5.7v-1.9h39.8v66.2c0,8.7,3.6,11.2,7.4,11.2c5.9,0,11.2-7.8,11.2-19.2v-51.1c0-4.1-2.7-5.1-4-5.1h-3v-1.9h36.3v72.9
		c0,3.6,1.6,6.5,5.1,6.5h5.1V310.7L429.7,310.7z"
          />
          <path
            d="M495.4,261.1c-6.9,0-14.5-5.7-14.5-13.9c0-9,6.6-15,14-15c5.4,0,7.8,2.2,8.7,2.2c0.3,0,0.8-0.3,0.8-0.8
		c0-2.4-4.4-6.1-10.6-6.1s-16.4,4.9-16.4,21.8v52.9c0,4.8,2,6.8,4.4,6.8h5.7v1.8h-53.9v-1.8h5.1c4.4,0,7.6-2.4,7.6-6.5v-64.1
		c0-4.1-2.1-6.9-5.5-6.9h-5.1v-1.9h40l0.8,9.3c1.8-3.9,6.8-13.1,17.7-13.1c9,0,16.6,7.5,16.6,18.3S503.2,261.1,495.4,261.1
		L495.4,261.1z"
          />
          <path
            d="M576.9,261.1c-6.9,0-14.5-5.7-14.5-13.9c0-9,6.6-15,14-15c5.4,0,7.8,2.2,8.7,2.2c0.3,0,0.8-0.3,0.8-0.8
		c0-2.4-4.3-6.1-10.7-6.1s-16.3,4.9-16.3,21.8v52.9c0,4.8,2,6.8,4.3,6.8h5.7v1.8h-53.8v-1.8h5.1c4.3,0,7.7-2.4,7.7-6.5v-64.1
		c0-4.1-2.1-6.9-5.5-6.9H517v-1.9h40l0.8,9.3c1.8-3.9,6.8-13.1,17.7-13.1c9,0,16.7,7.5,16.7,18.3S584.7,261.1,576.9,261.1
		L576.9,261.1z"
          />
          <path
            d="M742.6,310.7H696v-2h1.7c3.3,0,5.2-2.2,5.2-5.9v-58.5c0-8.6-4.2-11.4-8-11.4c-6.5,0-11.8,9.8-11.8,19.4v50.7
		c0,3.8,1,5.7,4.2,5.7h5.7v2h-46.3v-2h1.8c3.3,0,5.1-2.2,5.1-5.9v-58.5c0-8.6-3.5-11.1-7.2-11.1c-5.8,0-10.2,7.8-10.2,19.2v51.2
		c0,3.9,2.7,5.1,4,5.1h2.8v2h-47.1v-2h4.5c4.5,0,7.2-3.1,7.2-7.4V238c0-3.8-1.7-6.4-5.1-6.4h-5.1v-1.9h38.7v10.4
		c3.3-9.4,12-12.9,22.5-12.9s20.5,5.4,23.5,17.6c3.3-12.6,12.2-17.7,25.7-17.7c12.5,0,25,7.8,25,25.5v50.7c0,3.8,0.9,5.7,4,5.7h5.7
		L742.6,310.7L742.6,310.7z"
          />
          <path
            d="M798.6,310.7h-52.8v-2h4.5c4.3,0,7.2-3,7.2-7v-63.6c0-4.1-2.1-6.6-6.2-6.6h-4v-1.9h41.5v72.2c0,4,1.8,7,5.4,7
		h4.3V310.7z"
          />
        </G>
        <G>
          <path
            d="M763.3,126.9c-8.3,6.4-23,11.9-26.9,13.3c-1.2-0.7-2.8-0.8-4.2-0.9c-7.2-0.9-13.5-6.4-15.4-13.3
		c-0.4-1.5-0.6-3.2,0.2-4.5c0.5-0.9,1.5-1.4,2.3-2.1c3.5-2.6,5.6-6.6,8.4-9.9c2.8-3.3,7.2-6.1,11.3-4.9c2-3.8,4.6-7.3,7.7-10.3
		c1.7,3.9,3.3,7.8,5,11.6c0.8,1.8,1.5,3.6,2.7,5.2c1.4,2,3.4,3.6,4.8,5.7c1.6,2.4,2.3,5.2,3.3,7.8
		C762.7,125.4,763,126.2,763.3,126.9L763.3,126.9z"
          />
          <path
            d="M814.2,201.4c-3.2,1.3-6.9,1.7-9.5,4c-3.4,2.9-4,8-7.1,11.2c-3.9,4.1-10.4,4-16,3.7
		c-14.2-0.6-27.7-0.4-41.7,0.6c0,0,0,0,0-0.3c-0.4-4.6,5.2-8.4,9.4-6.4c0.2-5.4-1-10.8-3.2-15.6c-3.8-8.1-10.6-14.8-12.4-23.5
		c-1.8-9.2,2.4-18.4,4.4-27.6c0.2-0.8,0.3-1.8,0.4-2.6l27.4-12.9c5.4,9.4,13.6,17.1,21.6,24.6c5.3,5,10.7,10.2,13.8,16.8
		c3,6.2,3.7,13.2,3.9,20.1c3.3,0.4,6.7-0.9,8.9-3.3c2.2-2.5,3-6.1,2.1-9.2c-0.8-2.8-2.7-5-4.3-7.4c-6-9-8-20.6-5.3-31.1
		c1.2-4.4,3.5-9,7.7-10.9c4.2-1.9,10.3,0.8,10.3,5.4c0,6-8.8,8-11.1,13.5c-1,2.5-0.7,5.3,0.2,7.8c2.8,8,9.9,14.1,11.7,22.3
		C827.2,188.8,822.2,198.2,814.2,201.4L814.2,201.4z"
          />
        </G>
      </Svg>
    </Pressable>
  );
};

export default memo(Logo);
