import { LanguageDropdown } from "@/common/components/Dropdown";
import Icon from "@/common/components/Icon";
import Logo from "@/common/components/Svgs/Logo";
import { useBreakpoint } from "@/common/hooks";
import { logout } from "@/redux/reducers/User";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { useDispatch } from "react-redux";
import { animated as Animated } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const Header = ({
  section,
  chooseSection,
}: {
  section: string;
  chooseSection: (type: string) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mobile } = useBreakpoint();
  return (
    <View style={styles.container}>
      <View style={styles.topBarContainer}>
        <View style={styles.logoContainer}>
          {mobile && <Logo style={styles.logo} />}
        </View>
        <View style={styles.actionsContainer}>
          <LanguageDropdown />
          <IconButton
            icon={"logout"}
            size={moderateScale(25)}
            onPress={() => dispatch(logout())}
          />
        </View>
      </View>
      <View style={styles.bannerContainer}>
        <View style={[styles.banner, !mobile && { width: "100%" }]}>
          <View style={styles.infoContainer}>
            <Pressable
              style={[
                styles.infoPropertyContainer,
                section === "profile" ? styles.activeBackground : {},
              ]}
              onPress={() => chooseSection("profile")}
            >
              <Icon
                style={[
                  styles.infoPropertyIcon,
                  section === "profile" ? styles.activeText : {},
                ]}
                name={"account"}
                size={25}
              />
              <Text
                style={[
                  styles.infoPropertyText,
                  section === "profile" ? styles.activeText : {},
                ]}
                variant="labelMedium"
              >
                {t("ProfileProfileTitle")}
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.infoPropertyContainer,
                section === "deliveries" ? styles.activeBackground : {},
              ]}
              onPress={() => chooseSection("deliveries")}
            >
              <Icon
                style={[
                  styles.infoPropertyIcon,
                  section === "deliveries" ? styles.activeText : {},
                ]}
                name={"archive"}
                size={25}
              />
              <Text
                style={[
                  styles.infoPropertyText,
                  section === "deliveries" ? styles.activeText : {},
                ]}
                variant="labelMedium"
              >
                {t("TextDeliveries")}
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.infoPropertyContainer,
                section === "faq" ? styles.activeBackground : {},
              ]}
              onPress={() => chooseSection("faq")}
            >
              <Icon
                style={[
                  styles.infoPropertyIcon,
                  section === "faq" ? styles.activeText : {},
                ]}
                name={"frequently-asked-questions"}
                size={25}
              />
              <Text
                style={[
                  styles.infoPropertyText,
                  section === "faq" ? styles.activeText : {},
                ]}
                variant="labelMedium"
              >
                {t("FAQ")}
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.infoPropertyContainer,
                section === "friends" ? styles.activeBackground : {},
              ]}
              onPress={() => chooseSection("friends")}
            >
              <Icon
                style={[
                  styles.infoPropertyIcon,
                  section === "friends" ? styles.activeText : {},
                ]}
                name={"account-multiple"}
                size={25}
              />
              <Text
                style={[
                  styles.infoPropertyText,
                  section === "friends" ? styles.activeText : {},
                ]}
                variant="labelMedium"
              >
                {t("NavbarInviteFriendsTitle")}
              </Text>
            </Pressable>
            <Pressable
              style={[
                styles.infoPropertyContainer,
                section === "terms" ? styles.activeBackground : {},
              ]}
              onPress={() => chooseSection("terms")}
            >
              <Icon
                style={[
                  styles.infoPropertyIcon,
                  section === "terms" ? styles.activeText : {},
                ]}
                name={"file-document-outline"}
                size={25}
              />
              <Text
                style={[
                  styles.infoPropertyText,
                  section === "terms" ? styles.activeText : {},
                ]}
                variant="labelMedium"
              >
                {t("TextTermsTitle")}
              </Text>
            </Pressable>
          </View>
          <View style={styles.mainIconContainer}>
            <IconButton
              style={styles.icon}
              icon="cog"
              size={35}
              iconColor={theme.colors.onPrimary}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Header);
