import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Avatar, List, Text } from "react-native-paper";
import styles from "./styles";
const Section = () => {
  const { t } = useTranslation();
  const [faqArray, setFaqArray] = useState([
    {
      question: t("TextQuestionFAQ"),
      answer: t("TextAnswerFAQ"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ8"),
      answer: t("TextAnswerFAQ8"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ2"),
      answer: t("TextAnswerFAQ2"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ3"),
      answer: t("TextAnswerFAQ3"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ4"),
      answer: t("TextAnswerFAQ4"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ5"),
      answer: t("TextAnswerFAQ5"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ6"),
      answer: t("TextAnswerFAQ6"),
      expanded: false,
    },
    {
      question: t("TextQuestionFAQ7"),
      answer: t("TextAnswerFAQ7"),
      expanded: false,
    },
  ]);
  return (
    <View style={styles.container}>
      <Text variant="titleSmall" style={[styles.faqTitle]}>
        {t("FAQTitleTitle")}
      </Text>
      <Text style={[styles.faqSubtitle]}>{t("FAQSubtitle")}</Text>
      <List.Section>
        {faqArray.map((faq, index) => {
          return (
            <List.Accordion
              key={"faq_question_" + index}
              style={styles.faqAccordion}
              title={faq.question}
              titleNumberOfLines={0}
              titleStyle={[styles.faqQuestionTitle]}
              theme={{ colors: { background: "transparent" } }}
              left={(props) => (
                <Avatar.Icon
                  color={theme.colors.primary}
                  size={moderateScale(60)}
                  style={styles.faqQuestionIcon}
                  icon={"head-question"}
                />
              )}
              expanded={faq.expanded}
              onPress={() => {
                let newFaqArray = [...faqArray];
                newFaqArray[index] = {
                  ...faq,
                  expanded: !faq.expanded,
                };
                setFaqArray(newFaqArray);
              }}
            >
              <List.Item
                style={{ paddingLeft: 0, paddingRight: 16 }}
                title={faq.answer}
                titleNumberOfLines={0}
                titleStyle={[styles.faqAnswerTitle]}
              />
            </List.Accordion>
          );
        })}
      </List.Section>
    </View>
  );
};

export default memo(Section);
