import { StyleSheet } from "react-native";

export default StyleSheet.create({
  fab: {
    position: "absolute",
    margin: 16,
    marginBottom: 10,
    right: 0,
    bottom: 0,
  },
});
