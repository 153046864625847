import Toast from "@/common/components/Toast";
import { useFonts } from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "expo-status-bar";
import { useCallback, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { I18nextProvider } from "react-i18next";
import { Platform, UIManager } from "react-native";
import { Provider as PaperProvider } from "react-native-paper";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { ToastProvider } from "react-native-toast-notifications";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import i18n from "./src/i18n";
import { persistor, store } from "./src/redux/store";
import Navigation from "./src/router/index";
import { theme } from "./src/theme";
import { font_list } from "./src/theme/fonts";
SplashScreen.preventAutoHideAsync();

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export default function App() {
  const [fontsLoaded] = useFonts(font_list);
  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);
  useEffect(() => {
    if (Platform.OS === "web") {
      const options = {
        autoConfig: true,
        debug: true, // enable logs
      };
      ReactPixel.init("914292175896125", undefined, options);

      ReactPixel.pageView(); // For tracking page view
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "azix5p51c7");
    }
  }, []);

  if (!fontsLoaded) {
    return null;
  }
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PaperProvider theme={theme}>
            <ToastProvider
              placement="top"
              renderToast={(toastOptions) => <Toast toast={toastOptions} />}
            >
              <SafeAreaProvider>
                <Navigation />
                <StatusBar />
              </SafeAreaProvider>
            </ToastProvider>
          </PaperProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
}
