import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    alignItems: "flex-start",
    justifyContent: "center",
  },
  image: {
    width: 150,
    height: 150,
    borderRadius: BORDER_RADIUS,
  },
  input: {
    width: 150,
    height: 150,
    backgroundColor: theme.colors.primary,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: BORDER_RADIUS,
  },
  activityIndicator: {
    width: 100,
    height: 100,
    alignItems: "center",
    justifyContent: "center",
  },
  outlineStyle: { borderRadius: BORDER_RADIUS },
  noBorder: { borderWidth: 0 },
  errorText: { marginLeft: 5 },
  icon: { top: 5, right: -10 },
  imageInputLabelImage: {
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    position: "relative",
    zIndex: 10000,
    elevation: 5,
  },
});
