import { useBreakpoint } from "@/common/hooks";
import { getDateISO, isDate } from "@/common/utils/date.utils";
import React, { memo, useEffect, useRef, useState } from "react";
import { Dimensions, StyleProp, TextStyle, View } from "react-native";
import { Modal } from "react-native-paper";
import { animated, useSpring, useTrail } from "react-spring";
import { TextInput } from "..";
import Calendar from "./components/Calendar";
import styles from "./styles";

const AnimatedView = animated(View);

const DateInput = ({
  containerStyle,
  label,
  value,
  errors,
  disabled,
  min,
  max,
  retrictedWeekDays,
  onDateChange,
}: {
  containerStyle?: StyleProp<TextStyle>;
  label: string;
  value: string;
  errors: string[];
  disabled?: boolean;
  min?: string;
  max?: string;
  retrictedWeekDays?: number[];
  onDateChange: (date: string) => void;
}) => {
  const DropdownButton = useRef<any>();
  const { mobile } = useBreakpoint();
  const [visible, setVisible] = useState(false);
  const [dateState, setDateState] = useState(null);
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(0);
  const toggleDropdown = (): void => {
    visible ? closeDropdown() : openDropdown();
  };
  const [spring, springAPI] = useSpring(
    () => ({
      from: { opacity: 0 },
    }),
    [visible]
  );
  const [flagTrail, flagTrailAPI] = useTrail(1, () => ({
    opacity: 0,
  }));

  useEffect(() => {
    const newDate = value && new Date(value);
    isDate(newDate) && setDateState(getDateISO(newDate));
  }, []);

  const openDropdown = () => {
    if (DropdownButton?.current) {
      DropdownButton.current.measure((_fx, _fy, _w, _h, _px, _py) => {
        const windowHeight = Dimensions.get("window").height;
        setDropdownTop(_h);
        setDropdownWidth(_w * (mobile ? 0.95 : 0.45));
        setDropdownMaxHeight(windowHeight - _py - _h - 10);
      });
      setVisible(true);
      flagTrailAPI.start({
        opacity: 1,
      });
      springAPI.start({
        opacity: 1,
      });
    }
  };

  const closeDropdown = () => {
    flagTrailAPI.start({
      opacity: 0,
    });
    springAPI.start({
      opacity: 0,
    });
    setVisible(false);
  };

  const onChangeText = (item: string): void => {
    //onChangeValue(item);
    openDropdown();
  };
  const handleDateChange = (date: Date) => {
    const newDate = date ? getDateISO(date) : null;
    setDateState(newDate);
    flagTrailAPI.start({
      opacity: 0,
    });
    springAPI.start({
      opacity: 0,
    });
    setVisible(false);
    onDateChange(newDate);
  };

  return (
    <View style={[styles.container, containerStyle]} ref={DropdownButton}>
      <TextInput
        label={label}
        value={value}
        error={errors}
        icon={"chevron-down"}
        disabled={disabled}
        onPressIcon={openDropdown}
        onChangeText={(text: string) => onChangeText(text)}
      />
      <Modal
        style={{ backgroundColor: "transparent" }}
        contentContainerStyle={[
          styles.dropdown,
          { top: dropdownTop, width: dropdownWidth },
        ]}
        visible={visible}
        dismissable
        theme={{
          colors: {
            backdrop: "transparent",
          },
        }}
        onDismiss={closeDropdown}
      >
        <AnimatedView style={{ ...spring, maxHeight: dropdownMaxHeight }}>
          {flagTrail.map((style, index) => (
            <AnimatedView key={"LanguageItem" + index} style={style}>
              <Calendar
                onDateChanged={handleDateChange}
                min={min}
                max={max}
                retrictedWeekDays={retrictedWeekDays}
              />
            </AnimatedView>
          ))}
        </AnimatedView>
      </Modal>
    </View>
  );
};

export default memo(DateInput);
