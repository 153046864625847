import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

const CUSTOM_BORDER_RADIUS = 30;

export default StyleSheet.create({
  container: {
    width: "100%",
    backgroundColor: theme.colors.surfaceVariant,
    borderBottomRightRadius: CUSTOM_BORDER_RADIUS,
    borderBottomLeftRadius: CUSTOM_BORDER_RADIUS,
  },
  topBarContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  logoContainer: { alignSelf: "flex-start" },
  logo: {
    width: 105,
    height: 54,
    fill: theme.colors.onSurfaceVariant,
  },
  actionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    top: -5,
    marginRight: 5,
  },
  notificationIcon: {},
  bannerContainer: {
    width: "90%",
    alignItems: "center",
    maxWidth: 1200,
    alignSelf: "center",
  },
  banner: {
    width: "100%",
    backgroundColor: theme.colors.primary,
    borderRadius: CUSTOM_BORDER_RADIUS,
    padding: 25,
    position: "relative",
    top: 30,
  },
  mainIconContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    position: "absolute",
    alignSelf: "center",
    top: -moderateScale(45),
    borderRadius: 100,
    padding: 10,
  },
  icon: { borderRadius: 100, backgroundColor: theme.colors.primary },
  infoContainer: { width: "100%" },
  infoGreetings: {
    color: theme.colors.onPrimary,
    textAlign: "center",
    marginTop: 20,
  },
  searchBar: {
    borderRadius: BORDER_RADIUS,
    ...theme.fonts.labelLarge,
    marginTop: 10,
    height: 40,
  },
  searchBarInput: {
    ...theme.fonts.labelLarge,
    fontWeight: "100",
  },
  infoTitle: { color: theme.colors.onPrimary, textAlign: "right" },
  info: {
    color: theme.colors.onPrimary,
    fontWeight: "bold",
    textAlign: "right",
  },
  infoSubtitle: { color: theme.colors.onPrimary, textAlign: "right" },
});
