import { logCurrentScreen } from "@/common/utils/analytics.utils";
import {
  createNavigationContainerRef,
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
  NavigationContainer,
} from "@react-navigation/native";
import React, { useRef } from "react";
import { adaptNavigationTheme, Portal } from "react-native-paper";
import LinkingConfiguration from "./LinkingConfiguration";
import RootNavigator from "./routes/root";
export const navigationRef = createNavigationContainerRef();
const { LightTheme, DarkTheme } = adaptNavigationTheme({
  reactNavigationLight: NavigationDefaultTheme,
  reactNavigationDark: NavigationDarkTheme,
});
export default function Navigation() {
  const routeNameRef = useRef<string | undefined>();
  // const colorScheme = Appearance.getColorScheme();
  const colorScheme = "light";
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : LightTheme}
      // theme={LightTheme}
      onReady={() =>
        (routeNameRef.current = navigationRef.current?.getCurrentRoute()?.name)
      }
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;

        if (previousRouteName !== currentRouteName) {
          await logCurrentScreen(currentRouteName);
        }

        routeNameRef.current = currentRouteName;
      }}
    >
      <Portal.Host>
        <RootNavigator />
      </Portal.Host>
    </NavigationContainer>
  );
}
