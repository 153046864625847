import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
const CUSTOM_BORDER_RADIUS = 30;
export default StyleSheet.create({
  actionsContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginRight: 5,
    top: -5,
  },
  banner: {
    backgroundColor: theme.colors.primary,
    borderRadius: CUSTOM_BORDER_RADIUS,
    padding: 10,
    // minHeight: 70,
    position: "relative",
    top: 30,
    width: "100%",
  },
  bannerContainer: {
    alignItems: "center",
    alignSelf: "center",
    maxWidth: 1200,
    width: "90%",
  },
  container: {
    backgroundColor: theme.colors.surfaceVariant,
    borderBottomLeftRadius: CUSTOM_BORDER_RADIUS,
    borderBottomRightRadius: CUSTOM_BORDER_RADIUS,
    width: "100%",
  },
  deleteIcon: {
    backgroundColor: theme.colors.errorContainer,
    borderRadius: 100,
    bottom: 7,
    color: theme.colors.onErrorContainer,
    height: 20,
    padding: 0,
    position: "absolute",
    right: 7,
    width: 20,
  },
  editIcon: {
    backgroundColor: theme.colors.secondary,
    borderRadius: 100,
    bottom: 7,
    color: theme.colors.onPrimary,
    left: 7,
    position: "absolute",
  },
  icon: {
    backgroundColor: theme.colors.primary,
    borderRadius: 100,
  },
  info: {
    color: theme.colors.onPrimary,
    fontWeight: "bold",
    textAlign: "right",
  },
  infoContainer: {
    flexDirection: "row",
    paddingTop: 35,
    width: "100%",
  },
  infoFoodContainer: {
    alignSelf: "center",
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    bottom: "-35%",
    padding: 5,
    position: "absolute",
  },
  infoFoodText: {
    textAlign: "center",
  },
  infoGreetings: {
    color: theme.colors.onPrimary,
    textAlign: "center",
    width: `${100 / 5}%`,
  },
  infoPropertyContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: `${100 / 5}%`,
  },
  infoPropertyIcon: {
    color: theme.colors.onPrimary,
  },
  infoPropertyText: {
    color: theme.colors.onPrimary,
    textAlign: "center",
  },
  infoSubtitle: {
    color: theme.colors.onPrimary,
    textAlign: "right",
  },
  infoTitle: {
    color: theme.colors.onPrimary,
    textAlign: "right",
  },
  logo: {
    fill: theme.colors.onSurfaceVariant,
    height: 54,
    width: 105,
  },
  logoContainer: {
    alignSelf: "flex-start",
  },
  mainIconContainer: {
    alignSelf: "center",
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: 100,
    padding: 10,
    position: "absolute",
    top: -moderateScale(45),
  },
  noAnimalsAdded: {
    color: theme.colors.onPrimary,
    marginTop: 35,
    textAlign: "center",
  },
  notificationIcon: {},
  searchBar: {
    borderRadius: BORDER_RADIUS,
    ...theme.fonts.labelLarge,
    height: 40,
    marginTop: 10,
  },
  searchBarInput: { ...theme.fonts.labelLarge, fontWeight: "100" },
  topBarContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
    width: "100%",
  },
});
