import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "user",
  initialState: {} as User,
  reducers: {
    signin(state, action: PayloadAction<User>) {
      return {
        ...state,
        isLoggedOut: false,
        ...action.payload,
      };
    },
    logout(state) {
      state.isLoggedOut = true;
    },
    update(state, action: PayloadAction<User>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { signin, logout, update } = userSlice.actions;
export default userSlice.reducer;
