import { theme } from "@/theme";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: { width: "100%" },
  warningSubscriptionButton: {
    backgroundColor: theme.colors.onSecondaryContainer,
    color: theme.colors.secondaryContainer,
    borderRadius: 100,
    width: 120,
  },
  subscriptionWarningContainer: {
    maxWidth: 1200,
    width: "100%",
    padding: 20,
    backgroundColor: theme.colors.secondaryContainer,
    alignSelf: "center",
    marginTop: 50,
    borderRadius: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  warningSubscriptionText: {
    color: theme.colors.onSecondaryContainer,
  },
  warningSubscriptionButtonText: {
    color: theme.colors.secondaryContainer,
  },
});
