import { theme } from "@/theme";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  content: {
    width: "100%",
    backgroundColor: theme.colors.backdrop2,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    height: "100%",
  },
  contentView: {
    justifyContent: "flex-end",
    margin: 0,
  },
  slideContainer: {
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 5,
  },
  imageContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 100,
  },
  active: {
    borderWidth: 3,
    borderColor: theme.colors.primary,
    borderStyle: "solid",
  },
  listContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 10,
  },
  image: { width: 80, height: 80, borderRadius: 100 },
  title: {
    color: theme.colors.inverseOnSurface,
    marginBottom: 5,
    textAlign: "center",
  },
  subtitle: {
    color: theme.colors.inverseOnSurface,
    marginBottom: 10,
    textAlign: "center",
  },
  animalName: { color: theme.colors.inverseOnSurface },
});
