import { Dropdown } from "@/common/components/Dropdown";
import Icon from "@/common/components/Icon";
import { TextInput } from "@/common/components/Input";
import { getTranslationLanguage, isObjectEmpty } from "@/common/utils";
import { validateForm } from "@/modules/Checkout/helpers";
import { changeValues } from "@/redux/reducers/Order";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const Shipping = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.orderForm);
  const user = useSelector((state: RootState) => state.user);
  const subscription = useSelector((state: RootState) => state.subscription);
  const countries = useSelector((state: RootState) => state.countries);

  useEffect(() => {
    if (!isObjectEmpty(subscription)) {
      dispatch(
        changeValues({
          ...state,
          values: {
            ...state.values,
            country: subscription.country,
            name: subscription.name,
            email: subscription.email || user.email,
            nif: subscription.nif || "",
            phone: subscription.phone,
            address: subscription.address,
            city: subscription.city,
            zip_code: subscription.zip_code,
            delivery_date: "",
          },
          errors: {
            ...state.errors,
          },
        })
      );
    } else {
      dispatch(
        changeValues({
          ...state,
          values: {
            ...state.values,
            email: user.email,
            name: user.name,
          },
          errors: {
            ...state.errors,
          },
        })
      );
    }
  }, []);

  const onChangeText = (type: string, text: string) => {
    let new_state = { ...state, values: { ...state.values, [type]: text } };
    let errors = validateForm(new_state) || {};
    dispatch(
      changeValues({
        ...state,
        values: new_state.values,
        errors: { ...state.errors, [type]: errors[type] },
      })
    );
  };

  const formatZipCode = (value: string) => {
    if (value.length == 5 && value[value.length - 1] === "-") return value;
    const onlyNums = value.replace(/[^\d]/g, "");
    if (onlyNums.length <= 4) return onlyNums;
    if (onlyNums.length == 5 && i18n.language === "es") return onlyNums;
    return `${onlyNums.slice(0, 4)}-${onlyNums.slice(4, 7)}`;
  };

  return (
    <View style={styles.container}>
      {/* <Text style={styles.title} variant="bodySmall">
        {t("TextOrderInfoTitle")}
      </Text> */}
      {/* <DateInput
        label={`${t("TextBillingSectionTitle3")}*`}
        value={state.values.delivery_date}
        errors={state.errors.delivery_date}
        min={addToDate(new Date().toDateString(), waitingDays)}
        retrictedWeekDays={[1, 6, 0]}
        onDateChange={(date: string) => onChangeText("delivery_date", date)}
      />
      <View style={styles.infoContainer}>
        <Icon
          style={styles.checkpointInfoIcon}
          name="information"
          size={moderateScale(12)}
        />
        <Text style={styles.radioButtonTitle} variant="labelMedium">
          {t("TextBillingSectionInfo")}
        </Text>
      </View> */}
      {/* <View style={styles.infoContainer}>
        <Icon
          style={styles.checkpointInfoIconDanger}
          name="information"
          size={moderateScale(12)}
        />
        <Text style={styles.colorDanger} variant="labelMedium">
          {t("TextBillingSectionInfo3")}
        </Text>
      </View> */}
      {subscription && subscription.tier > 1 && (
        <View style={styles.infoContainer}>
          <Icon
            style={styles.checkpointInfoIcon}
            name="information"
            size={moderateScale(12)}
          />
          <Text style={styles.radioButtonTitle} variant="labelMedium">
            {t("TextBillingSectionInfoSubscription")}
          </Text>
        </View>
      )}
      <Text style={styles.title} variant="bodySmall">
        {t("TextOrderInfoTitle2")}
      </Text>
      <TextInput
        containerStyle={styles.inputContainer}
        outlineStyle={styles.inputOutline}
        label={`${t("TextName")}*`}
        value={state.values.name}
        error={state.errors.name}
        onChangeText={(text: string) => onChangeText("name", text)}
      />
      <TextInput
        containerStyle={styles.inputContainer}
        outlineStyle={styles.inputOutline}
        label={`${t("TextEmail")}*`}
        value={state.values.email}
        error={state.errors.email}
        onChangeText={(text: string) => onChangeText("email", text)}
      />
      <View style={styles.row}>
        <TextInput
          containerStyle={[styles.inputContainer, styles.twoSplit]}
          outlineStyle={styles.inputOutline}
          label={t("TextVAT")}
          value={state.values.nif}
          error={state.errors.nif}
          onChangeText={(text: string) => onChangeText("nif", text)}
        />
        <View style={[styles.inputContainer, styles.twoSplit]}>
          <TextInput
            outlineStyle={styles.inputOutline}
            label={`${t("TextPhoneNumber")}*`}
            value={state.values.phone}
            error={state.errors.phone}
            onChangeText={(text: string) => onChangeText("phone", text)}
          />
          <View style={styles.infoContainer}>
            <Icon
              style={styles.checkpointInfoIcon}
              name="information"
              size={moderateScale(12)}
            />
            <Text style={styles.radioButtonTitle} variant="labelMedium">
              {t("TextBillingSectionInfo2")}
            </Text>
          </View>
        </View>
      </View>
      <Text style={styles.title} variant="bodySmall">
        {t("TextOrderInfoTitle3")}
      </Text>
      <Dropdown
        containerStyle={styles.inputContainer}
        data={countries.map((c) => {
          return {
            value: c._id,
            name: c.name[getTranslationLanguage(i18n.language)],
          };
        })}
        label={`${t("TextCountry")}*`}
        value={state.values.country}
        errors={state.errors.country}
        onChangeValue={(text: string) => onChangeText("country", text)}
      />
      <TextInput
        containerStyle={styles.inputContainer}
        outlineStyle={styles.inputOutline}
        label={`${t("TextAddress")}*`}
        value={state.values.address}
        error={state.errors.address}
        onChangeText={(text: string) => onChangeText("address", text)}
      />
      <View style={styles.row}>
        <TextInput
          containerStyle={[styles.inputContainer, styles.twoSplit]}
          outlineStyle={styles.inputOutline}
          label={`${t("TextCity")}*`}
          value={state.values.city}
          error={state.errors.city}
          onChangeText={(text: string) => onChangeText("city", text)}
        />
        <TextInput
          containerStyle={[styles.inputContainer, styles.twoSplit]}
          outlineStyle={styles.inputOutline}
          label={`${t("TextZipCode")}*`}
          value={state.values.zip_code}
          error={state.errors.zip_code}
          onChangeText={(text: string) => onChangeText("zip_code", text)}
        />
      </View>
    </View>
  );
};

export default memo(Shipping);
