import AsyncStorage from "@react-native-async-storage/async-storage";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import rootReducer from "../reducers/index";

const persistConfig = {
  key: "root",
  storage: AsyncStorage,
  whitelist: [
    "animals",
    "boxes",
    "user",
    "subscription",
    "registerForm",
    "orderForm",
    "animalForm",
    "shippings",
    "countries",
    "languages",
    "deliveries",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

const persistor = persistStore(store);

export { store, persistor };
