import { Button } from "@/common/components/Button";
import { TextInput } from "@/common/components/Input";
import { globalStyles } from "@/common/styles";
import { emailValidator } from "@/common/utils";
import { resetPassword, verifyResetCode } from "@/services/user.service";
import { IntroParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useSelector } from "react-redux";
import CodeVerification from "./components/CodeVerification";
import styles from "./styles";

const ForgotPasswordForm = () => {
  const user = useSelector((state: RootState) => state.user);
  const { t } = useTranslation();
  const toast = useToast();
  const navigation = useNavigation<StackNavigationProp<IntroParamList>>();
  const [email, setEmail] = useState({ value: "", error: "" });
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const _onSendPressed = () => {
    const emailError = t("Error" + emailValidator(email.value));

    if (emailError != "Error") {
      setEmail({ ...email, error: emailError });
      return;
    }

    resetPassword(user.accessToken, email.value)
      .then((response: any) => {
        setEmailSent(true);
        toast.show(t("BackendNotification" + response.data.message), {
          type: "success",
        });
      })
      .catch((error: any) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
      });
  };

  const verifyCode = (code: string, password: string) => {
    setLoading(true);
    if (code.split("").length == 4) {
      verifyResetCode(user.accessToken, email.value, code, password)
        .then((response: any) => {
          toast.show(t("BackendNotification" + response.data.message), {
            type: "success",
          });
          navigation.navigate("Login");
          setLoading(false);
        })
        .catch((error: any) => {
          toast.show(t(`BackendNotification${error.response.data.message}`), {
            type: "danger",
          });
          setLoading(false);
        });
    } else {
      toast.show(t("TextYouNeedToFillTheSixBoxes"), { type: "danger" });
    }
  };
  return (
    <Card style={globalStyles.card}>
      <Card.Title
        title={t("TextForgotPassword")}
        titleStyle={styles.title}
        titleNumberOfLines={0}
      />
      <Card.Content>
        <TouchableOpacity
          style={styles.back}
          onPress={() => navigation.navigate("Login")}
        >
          <Text style={styles.label}>← {t("TextBackToLogin")}</Text>
        </TouchableOpacity>
        <View style={{ width: "100%" }}>
          {!emailSent && (
            <>
              <TextInput
                label={t("TextEmail")}
                returnKeyType="done"
                style={{ minWidth: 250 }}
                value={email.value}
                onChangeText={(text: string) =>
                  setEmail({ value: text, error: "" })
                }
                error={!!email.error}
                errorText={email.error}
                autoCapitalize="none"
                autoCompleteType="email"
                textContentType="emailAddress"
                keyboardType="email-address"
              />

              <Button
                mode="contained"
                onPress={_onSendPressed}
                style={styles.button}
              >
                {t("TextSendResetInstructions")}
              </Button>
            </>
          )}
          {emailSent && (
            <CodeVerification verifyCode={verifyCode} loading={loading} />
          )}
        </View>
      </Card.Content>
    </Card>
  );
};

export default memo(ForgotPasswordForm);
