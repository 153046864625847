import React, { memo } from "react";
import { KeyboardAvoidingView, Platform, View } from "react-native";
import styles from "./styles";
const Background = ({ ...props }) => (
  <View style={props.customStyle ? props.customStyle : styles.background}>
    <KeyboardAvoidingView
      style={styles.container}
      behavior={
        props.position
          ? props.position
          : Platform.OS === "ios"
          ? "padding"
          : "height"
      }
    >
      {props.children}
    </KeyboardAvoidingView>
  </View>
);

export default memo(Background);
