import { globalStyles } from "@/common/styles";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text, TextInput } from "react-native-paper";
import styles from "./styles";
const Input = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <View style={[styles.container, props.containerStyle]}>
      <TextInput
        {...props}
        contentStyle={[props.contentStyle]}
        outlineStyle={[
          styles.outlineStyle,
          !props.error ? styles.noBorder : {},
          props.outlineStyle,
        ]}
        right={
          props.icon && (
            <TextInput.Icon
              style={styles.icon}
              icon={props.icon}
              size={moderateScale(15)}
              disabled={!!props.disabled}
              onPress={props.onPressIcon}
            />
          )
        }
        style={[styles.input, props.style]}
        mode="outlined"
      />
      {props.error ? (
        <Text
          variant={"labelMedium"}
          style={[globalStyles.error, styles.errorText, props.errorStyle]}
        >
          {props.error.map((err: string) => t(err) + "\n")}
        </Text>
      ) : null}
    </View>
  );
};

export default memo(Input);
