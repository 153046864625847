import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const registerSlice = createSlice({
  name: "registerForm",
  initialState: { slide: 0, values: Array<RegisterFormValues>(0) },
  reducers: {
    incrementSlide(state) {
      state.slide++;
    },
    decrementSlide(state) {
      state.slide--;
    },
    reset(state) {
      state.slide = 0;
      state.values = [];
    },
    goToSlide(state, action: PayloadAction<number>) {
      state.slide = action.payload;
    },
    changeValues(state, action: PayloadAction<RegisterFormValues[]>) {
      return { ...state, values: action.payload };
    },
    updateValuesAndGoNext(state, action: PayloadAction<RegisterForm>) {
      return {
        ...state,
        slide: action.payload.slide,
        values: action.payload.values,
      };
    },
  },
});

export const {
  incrementSlide,
  decrementSlide,
  goToSlide,
  changeValues,
  reset,
  updateValuesAndGoNext,
} = registerSlice.actions;
export default registerSlice.reducer;
