import { Button } from "@/common/components/Button";
import { TextInput } from "@/common/components/Input";
import { globalStyles } from "@/common/styles";
import { update } from "@/redux/reducers/User";
import { validatePromocode } from "@/services/promocodes.service";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Card, Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, useSpring } from "react-spring";
import styles from "./styles";
const AnimatedView = Animated(View);
const PromocodeValidator = ({ toast }: { toast: ToastType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [promocode, setPromocode] = useState("");
  const card_animated_styles = useSpring({
    from: { opacity: 0 },
    to: async (next) => {
      await next({
        opacity: active ? 1 : 0,
      });
    },
  });
  const onValidatePromocode = () => {
    if (promocode === "") return;

    validatePromocode(user.accessToken, promocode)
      .then((response: any) => {
        let userUpdated = { ...user };
        if (response.data.usercode) {
          userUpdated.friend_discount = response.data.usercode;
        } else {
          userUpdated.promocode = response.data.promocode;
        }
        dispatch(update(userUpdated));
        setLoading(false);
        toast.show(t("BackendNotification" + response.data.message), {
          type: "success",
        });
      })
      .catch((error: any) => {
        console.log(error);
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setLoading(false);
      });
  };

  return (
    <View style={[styles.container]}>
      {!active ? (
        <Text style={styles.couponPrompt} variant="labelLarge">
          {t("TextDoYouWantToApplyACoupon")}{" "}
          <Text
            style={globalStyles.link}
            variant="labelLarge"
            onPress={() => setActive(true)}
          >
            {t("TextAdd")}
          </Text>
        </Text>
      ) : (
        <AnimatedView style={card_animated_styles}>
          <Card style={styles.card}>
            <Card.Content style={styles.content}>
              <TextInput
                style={styles.input}
                outlineStyle={styles.inputOutline}
                containerStyle={styles.inputContainer}
                label={t("TextPromocode")}
                value={promocode}
                onChangeText={(text: string) =>
                  setPromocode(text.toUpperCase())
                }
              />
              <Button
                mode="contained"
                style={styles.button}
                disabled={promocode === "" || loading}
                loading={loading}
                onPress={onValidatePromocode}
              >
                {t("ButtonTextValidatePromocode")}
              </Button>
            </Card.Content>
          </Card>
        </AnimatedView>
      )}
    </View>
  );
};

export default memo(PromocodeValidator);
