import { ImageButton } from "@/common/components/Button";
import { Dropdown } from "@/common/components/Dropdown";
import { ImageInput, TextInput } from "@/common/components/Input";
import MyToast from "@/common/components/Toast";
import { globalStyles } from "@/common/styles";
import { changeValues } from "@/redux/reducers/Animal";
import moment from "moment";
import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import Toast, { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { validateForm } from "../../helpers";
import Footer from "./components/Footer";
import Header from "./components/Header";
import styles from "./styles";
const CreateOrEditForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const toastRef = useRef(null);
  const animalForm = useSelector((state: RootState) => state.animalForm);
  const [toast, setToast] = useState<ToastType>(null);
  const sex_array = [
    { value: "male", name: t("TextMale") },
    { value: "female", name: t("TextFemale") },
  ];

  useEffect(() => {
    if (animalForm.type == "edit") {
      dispatch(
        changeValues({
          values: {
            name: animalForm.selectedAnimal.name,
            images: [],
            weight: animalForm.selectedAnimal.weight,
            sex: animalForm.selectedAnimal.sex,
            birthday: animalForm.selectedAnimal.birthday,
            type: "60b51ded0f4adc3eb88ede08",
          },
          errors: {},
        })
      );
    }
  }, []);
  const onChangeImages = (images) => {
    const currentImages = images || animalForm.values.images;
    let new_state = {
      ...animalForm,
      values: { ...animalForm.values, images: currentImages },
    };
    let errors = validateForm(new_state) || {};
    dispatch(
      changeValues({
        ...animalForm,
        values: new_state.values,
        errors: { ...animalForm.errors, images: errors.images },
      })
    );
  };

  const onSelectButton = (type: number) => {
    let new_state = {
      ...animalForm,
      values: {
        ...animalForm.values,
        birthday:
          type === 0
            ? moment().subtract(6, "months").format()
            : type === 1
            ? moment().subtract(5, "years").format()
            : moment().subtract(10, "years").format(),
      },
    };
    let errors = validateForm(new_state) || {};
    dispatch(
      changeValues({
        ...animalForm,
        values: new_state.values,
        errors: { ...animalForm.errors, birthday: errors.birthday },
      })
    );
  };

  const onChangeText = (type: string, text: string) => {
    let new_state = {
      ...animalForm,
      values: { ...animalForm.values, [type]: text },
    };
    let errors = validateForm(new_state) || {};
    dispatch(
      changeValues({
        ...animalForm,
        values: new_state.values,
        errors: { ...animalForm.errors, [type]: errors[type] },
      })
    );
  };

  useEffect(() => {
    setToast(toastRef.current);
  }, [toastRef]);

  return (
    <View ref={containerRef} style={styles.container}>
      <View style={styles.backgroundEffectContainer}>
        <View style={styles.background}>
          <Header />
          <View style={styles.formContainer}>
            <View style={styles.firstInputContainer}>
              <View style={styles.titleContainer}>
                <Text style={styles.title} variant="titleMedium">
                  {animalForm.values.name !== ""
                    ? animalForm.type === "edit"
                      ? t("TextEdit")
                      : t("TextAdd")
                    : animalForm.type === "edit"
                    ? ""
                    : t("TextAddACat")}
                </Text>
                {animalForm.values.name !== "" && (
                  <Text
                    style={[styles.title, globalStyles.bold]}
                    variant="titleMedium"
                  >
                    {animalForm.values.name}
                  </Text>
                )}
              </View>
              <ImageInput
                containerStyle={styles.imageInputContainer}
                label={t("TextImages")}
                value={animalForm.values.images}
                interaction={onChangeImages}
              />
            </View>
            <TextInput
              containerStyle={styles.inputContainer}
              outlineStyle={styles.inputOutline}
              label={`${t("TextName")}*`}
              value={animalForm.values.name}
              error={animalForm.errors.name}
              onChangeText={(text: string) => onChangeText("name", text)}
            />
            <View style={styles.buttonContainer}>
              <ImageButton
                mode={
                  animalForm.values.birthday !== "" &&
                  moment().diff(moment(animalForm.values.birthday), "years") ===
                    0
                    ? "contained"
                    : "outlined"
                }
                style={styles.button}
                imageStyle={styles.buttonImage}
                labelStyle={styles.buttonLabel}
                source={require("@/assets/images/icons/kitten.png")}
                onPress={() => onSelectButton(0)}
              >
                {t("TextKittenCat")}
              </ImageButton>
              <ImageButton
                mode={
                  animalForm.values.birthday !== "" &&
                  moment().diff(moment(animalForm.values.birthday), "years") >
                    0 &&
                  moment().diff(moment(animalForm.values.birthday), "years") < 7
                    ? "contained"
                    : "outlined"
                }
                style={styles.button}
                imageStyle={styles.buttonImage}
                labelStyle={styles.buttonLabel}
                source={require("@/assets/images/icons/normalCat.png")}
                onPress={() => onSelectButton(1)}
              >
                {t("TextAdultCat")}
              </ImageButton>
              <ImageButton
                mode={
                  animalForm.values.birthday !== "" &&
                  moment().diff(moment(animalForm.values.birthday), "years") >=
                    7
                    ? "contained"
                    : "outlined"
                }
                style={styles.button}
                imageStyle={styles.buttonImage}
                labelStyle={styles.buttonLabel}
                source={require("@/assets/images/icons/senior.png")}
                onPress={() => onSelectButton(2)}
              >
                {t("TextSeniorCat")}
              </ImageButton>
            </View>
            <View style={styles.row}>
              <View style={styles.twoSplit}>
                <TextInput
                  containerStyle={styles.inputContainer}
                  outlineStyle={styles.inputOutline}
                  label={`${t("TextWeight")} (kg)*`}
                  value={animalForm.values.weight}
                  error={animalForm.errors.weight}
                  onChangeText={(text: string) => onChangeText("weight", text)}
                />
              </View>
              <View style={styles.twoSplit}>
                <Dropdown
                  containerStyle={styles.inputContainer}
                  data={sex_array}
                  label={`${t("TextGender")}*`}
                  value={animalForm.values.sex}
                  errors={animalForm.errors.sex}
                  onChangeValue={(text: string) => onChangeText("sex", text)}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
      <Footer toast={toast} />
      <Toast
        placement="top"
        renderToast={(toastOptions) => <MyToast toast={toastOptions} />}
        ref={toastRef}
      />
    </View>
  );
};

export default memo(CreateOrEditForm);
