import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { NumericInput } from "@/common/components/Input";
import Swiper from "@/common/components/Swiper";
import { globalStyles } from "@/common/styles";
import {
  capitalizeFirstLetter,
  discountProducts,
  getTranslationLanguage,
  isArrayEmpty,
} from "@/common/utils";
import {
  googleSelectItem,
  productToGoogleProduct,
} from "@/common/utils/analytics.utils";
import { analytic_components } from "@/config";
import { update } from "@/redux/reducers/Boxes";
import { updateBox } from "@/services/boxes.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import { RootTabParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ScrollView, View } from "react-native";
import Modal from "react-native-modal";
import { IconButton, List, Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, config, useSpring } from "react-spring";
import AnimalPromptModal from "./components/AnimalPromptModal";
import styles from "./styles";
const AnimatedText = Animated(Text);
const ProductView = ({
  product,
  visible,
  toggle,
}: {
  product: Product;
  visible: boolean;
  toggle: () => void;
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toast = useToast();
  const navigation = useNavigation<StackNavigationProp<RootTabParamList>>();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const boxes = useSelector((state: RootState) => state.boxes);
  const [favorite, setFavorite] = useState(false);
  const [choiceModalVisible, setChoiceModalVisible] = useState(false);
  const toggleChoiceModal = (): void => {
    setChoiceModalVisible((state) => !state);
  };
  const [width, setWidth] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const productContainerRef = useRef<any>();

  const animated_price = useSpring({
    immediate: false,
    config: config.gentle,
    number: price,
    from: { number: 0 },
  });

  useEffect(() => {
    if (visible) {
      setWidth(productContainerRef.current.offsetWidth);
    }
  }, [visible]);

  useEffect(() => {
    if (product) {
      setPrice(
        parseFloat(
          (product.price * (1 - product.discount / 100) * quantity).toFixed(2)
        )
      );
    }
  }, [product, quantity]);

  const addOrUpdateProduct = (animalId: string) => {
    setLoading(true);
    let box = { ...boxes.find((b) => b.animal === animalId) };
    let new_box = {
      ...box,
      products: box.products.map((a) => {
        return { ...a };
      }),
    };
    let box_product = new_box.products.find(
      (bp) => bp.product._id === product._id
    );
    if (box_product) {
      box_product.quantity += quantity;
    } else {
      new_box.products.push({ product: product, quantity: quantity });
    }
    updateBox(user.accessToken, animalId, new_box, product._id)
      .then((response) => {
        dispatch(update(response.data.box));
        setChoiceModalVisible(false);
        navigation.navigate("Dashboard");
        toggle();
        setLoading(false);
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setLoading(false);
      });
  };
  const selectAnimals = async () => {
    if (animals.length > 1) {
      setChoiceModalVisible(true);
    } else {
      addOrUpdateProduct(animals[0]._id);
    }
    await googleSelectItem({
      item_list_id: "explorer_products",
      item_list_name: "Explorer Product Cards",
      items: productToGoogleProduct({
        product: product,
        quantity: 1,
      }),
    });
  };

  return (
    <>
      <Modal
        backdropOpacity={0.3}
        isVisible={visible}
        onBackdropPress={toggle}
        style={styles.contentView}
      >
        {product ? (
          <View style={styles.content}>
            <View style={styles.backgroundEffectContainer}>
              <ScrollView style={styles.background}>
                <View style={globalStyles.mobileContainer}>
                  <View style={styles.topBarContainer}>
                    <IconButton
                      style={styles.backButton}
                      icon={"arrow-left"}
                      size={moderateScale(20)}
                      onPress={toggle}
                    />
                    <IconButton
                      style={styles.favoriteButton}
                      icon={favorite ? "heart" : "heart-outline"}
                      size={moderateScale(20)}
                      onPress={() => setFavorite((state) => !state)}
                    />
                  </View>
                  <View
                    ref={productContainerRef}
                    style={styles.productContainer}
                  >
                    <Swiper data={product.images} width={width} height={250} />
                    <View style={styles.productInfoContainer}>
                      <Text
                        style={styles.productTitle}
                        variant={"titleSmall"}
                        numberOfLines={2}
                      >
                        {product.title[getTranslationLanguage(i18n.language)]}
                      </Text>
                      <Text style={styles.productSlogan} variant={"labelLarge"}>
                        {product.slogan[getTranslationLanguage(i18n.language)]}
                      </Text>
                      <View style={styles.priceContainer}>
                        <Text
                          style={styles.productPrice}
                          variant={"displaySmall"}
                        >
                          {(
                            product.price *
                            (1 - product.discount / 100)
                          ).toFixed(2)}
                          €
                        </Text>
                        {discountProducts().includes(product._id) && (
                          <Text
                            style={styles.productPriceStrike}
                            variant={"labelLarge"}
                          >
                            {(
                              (product.price + product.price * 0.25) *
                              (1 - product.discount / 100)
                            ).toFixed(2)}
                            €
                          </Text>
                        )}
                      </View>
                      <View style={styles.tagContainer}>
                        {discountProducts().includes(product._id) ? (
                          <Text
                            style={styles.tagDiscount}
                            variant={"labelLarge"}
                          >
                            <Icon
                              style={styles.tagIcon}
                              name={"tag"}
                              size={moderateScale(15)}
                            />
                            25% {t("TextDiscount")}
                          </Text>
                        ) : (
                          <Text style={styles.tag} variant={"labelSmall"}>
                            {product.quantity <= 0
                              ? t("TextWithoutStock")
                              : product.quantity <= 3
                              ? t("TextAlmostNoStock")
                              : t("TextInStock")}
                          </Text>
                        )}
                      </View>
                      <Text
                        style={styles.productDescription}
                        variant={"labelLarge"}
                      >
                        {
                          product.description[
                            getTranslationLanguage(i18n.language)
                          ]
                        }
                      </Text>
                      <List.Section style={styles.productViewProperties}>
                        {!!product.ingredients &&
                          !isArrayEmpty(product.ingredients) && (
                            <View style={styles.productViewPropertyContainer}>
                              <List.Accordion
                                title={t("ProductViewIngredients")}
                                style={[
                                  styles.accordionButton,
                                  theme.shadows.mainBoxShadow,
                                ]}
                                titleStyle={{ ...theme.fonts.bodyMedium }}
                                left={(props) => (
                                  <List.Icon
                                    {...props}
                                    style={styles.accordionIcon}
                                    icon="format-list-bulleted-type"
                                  />
                                )}
                                expanded={expanded}
                                onPress={() => setExpanded(!expanded)}
                              >
                                <List.Section
                                  style={styles.productPropertyContainer}
                                >
                                  <Text variant="bodySmall">
                                    {product.ingredients.map(
                                      (ingredient, index) => {
                                        return (
                                          <Text
                                            key={ingredient + "_" + index}
                                            variant="bodySmall"
                                          >
                                            {index ===
                                              product.ingredients.length - 1 &&
                                              ` ${t("TextAnd")}`}
                                            {capitalizeFirstLetter(
                                              ingredient[
                                                i18n.language.slice(0, 2) &&
                                                i18n.language.slice(0, 2) !==
                                                  "en"
                                                  ? i18n.language.slice(0, 2)
                                                  : "default"
                                              ]
                                            )}
                                            {index !==
                                              product.ingredients.length - 1 &&
                                              ", "}
                                          </Text>
                                        );
                                      }
                                    )}
                                  </Text>
                                </List.Section>
                              </List.Accordion>
                            </View>
                          )}
                        {!!product.components &&
                          !isArrayEmpty(product.components) && (
                            <View style={styles.productViewPropertyContainer}>
                              <List.Accordion
                                title={t("ProductViewComposition")}
                                style={[
                                  styles.accordionButton,
                                  theme.shadows.mainBoxShadow,
                                ]}
                                left={(props) => (
                                  <List.Icon
                                    {...props}
                                    style={styles.accordionIcon}
                                    icon="semantic-web"
                                  />
                                )}
                                titleStyle={{ ...theme.fonts.bodyMedium }}
                                expanded={expanded2}
                                onPress={() => setExpanded2(!expanded2)}
                              >
                                <List.Section
                                  style={styles.productPropertyContainer}
                                >
                                  <Text style={styles.productDescription}>
                                    {
                                      product.components[
                                        i18n.language.slice(0, 2) &&
                                        i18n.language.slice(0, 2) !== "en"
                                          ? i18n.language.slice(0, 2)
                                          : "default"
                                      ]
                                    }
                                  </Text>
                                </List.Section>
                              </List.Accordion>
                            </View>
                          )}
                        {!!product.assured_analysis && (
                          <View style={styles.productViewPropertyContainer}>
                            <List.Accordion
                              title={t("ProductViewAnalyticComposition")}
                              style={[
                                styles.accordionButton,
                                theme.shadows.mainBoxShadow,
                              ]}
                              left={(props) => (
                                <List.Icon
                                  style={styles.accordionIcon}
                                  {...props}
                                  icon="semantic-web"
                                />
                              )}
                              titleStyle={{ ...theme.fonts.bodyMedium }}
                              expanded={expanded3}
                              onPress={() => setExpanded3(!expanded3)}
                            >
                              <List.Section
                                style={styles.productPropertyContainer}
                              >
                                <Text style={styles.productDescription}>
                                  {product.assured_analysis}
                                </Text>
                              </List.Section>
                            </List.Accordion>
                          </View>
                        )}
                        {!!product.analytic_components &&
                          !isArrayEmpty(product.analytic_components) && (
                            <View style={styles.productViewPropertyContainer}>
                              <List.Accordion
                                title={t("ProductViewAnalyticComposition")}
                                style={[
                                  styles.accordionButton,
                                  theme.shadows.mainBoxShadow,
                                ]}
                                titleStyle={{ ...theme.fonts.bodyMedium }}
                                left={(props) => (
                                  <List.Icon
                                    {...props}
                                    style={styles.accordionIcon}
                                    icon="chart-tree"
                                  />
                                )}
                              >
                                <Text
                                  style={styles.componentsTitle}
                                  variant="bodyMedium"
                                >
                                  {t("TextFor100gOfProduct")}
                                </Text>
                                <List.Section
                                  style={styles.productPropertyContainer}
                                >
                                  {product.analytic_components.map(
                                    (component, index) => {
                                      return (
                                        <View
                                          key={"Product_Component_" + index}
                                          style={{
                                            flexDirection: "row",
                                            width: "100%",
                                            alignItems: "center",
                                          }}
                                        >
                                          <IconButton
                                            icon="circle-small"
                                            size={moderateScale(20)}
                                            style={{ margin: 0 }}
                                          />
                                          <Text
                                            variant="labelLarge"
                                            key={component + "_" + index}
                                          >
                                            <Text>
                                              {t(analytic_components[index])}
                                            </Text>
                                            {" - "}
                                            {component}
                                          </Text>
                                        </View>
                                      );
                                    }
                                  )}
                                </List.Section>
                              </List.Accordion>
                            </View>
                          )}
                      </List.Section>
                    </View>
                  </View>
                </View>
              </ScrollView>
            </View>
            <View style={styles.bottomAreaContainer}>
              <View style={styles.mobileContainer}>
                <View style={styles.productPriceContainer}>
                  <Text
                    style={styles.productFinalPriceTitle}
                    variant={"bodySmall"}
                  >
                    {t("TextFirstTimeCtaText")}
                  </Text>
                  <Text
                    style={styles.productFinalPrice}
                    variant={"displayLarge"}
                  >
                    <AnimatedText
                      style={styles.productFinalPrice}
                      variant={"displayLarge"}
                    >
                      {animated_price.number.to((n) => n.toFixed(2))}
                    </AnimatedText>
                    €
                  </Text>
                  <Text
                    style={styles.productInfoSubtitle}
                    variant={"labelLarge"}
                  >
                    {t("BoxesVATIncluded")}
                  </Text>
                </View>
                <View style={styles.productActions}>
                  <NumericInput
                    mode={"flat"}
                    style={styles.quantityInput}
                    containerStyle={styles.numericInputContainer}
                    // iconStyle={styles.quantityInputButton}
                    iconsize={moderateScale(20)}
                    iconColor={theme.colors.onSurfaceVariant}
                    value={quantity}
                    onChange={(number: number) => {
                      setQuantity(number);
                    }}
                    min={1}
                    step={1}
                  />
                  <Button
                    mode="contained"
                    icon={"package-variant"}
                    disabled={quantity < 1 || quantity >= product.quantity}
                    onPress={selectAnimals}
                  >
                    {t("TextAdd")}
                  </Button>
                </View>
              </View>
            </View>
            <AnimalPromptModal
              visible={choiceModalVisible}
              toggle={toggleChoiceModal}
              addProductToCart={addOrUpdateProduct}
              loading={loading}
            />
          </View>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};

export default memo(ProductView);
