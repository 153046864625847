import MyToast from "@/common/components/Toast";
import React, { memo, useEffect, useRef, useState } from "react";
import { View } from "react-native";
import Toast, { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  animated as Animated,
  useSpringRef,
  useTransition,
} from "react-spring";
import Footer from "./components/Footer";
import Header from "./components/Header";
import {
  PaymentStage,
  ProcessingStage,
  ShippingStage,
  SubscriptionStage,
  SummaryStage,
} from "./components/Stages";
import styles from "./styles";
const AnimatedView = Animated(View);
const CheckoutModule = () => {
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const toastRef = useRef(null);
  const [toast, setToast] = useState<ToastType>(null);
  const transRef = useSpringRef();
  const current = useSelector((state: RootState) => state.orderForm.slide);
  const [oldStage, setOldStage] = useState(current);
  const transitions = useTransition(current, {
    ref: transRef,
    keys: null,
    from: {
      opacity: 0,
      transform: `"translate3d(${current > oldStage ? "100%" : "-100%"},0,0)"`,
    },
    leave: {
      opacity: 0,
      transform: `"translate3d(${current > oldStage ? "-100%" : "100%"},0,0)"`,
    },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
  });
  useEffect(() => {
    transRef.start();
    setOldStage(current);
  }, [current]);
  useEffect(() => {
    setToast(toastRef.current);
  }, [toastRef]);

  const stages = [
    <SummaryStage toast={toast} />,
    <ShippingStage />,
    <PaymentStage toast={toast} />,
    <ProcessingStage toast={toast} />,
    <SubscriptionStage toast={toast} />,
  ];

  return (
    <View ref={containerRef} style={styles.container}>
      <View style={styles.backgroundEffectContainer}>
        <View style={styles.background}>
          <Header />
          <View style={styles.stageContainer}>
            {transitions((style, index) => (
              <AnimatedView style={{ ...style, flex: 1 }}>
                {stages[index]}
              </AnimatedView>
            ))}
          </View>
        </View>
      </View>
      <Footer toast={toast} />
      <Toast
        placement="top"
        renderToast={(toastOptions) => <MyToast toast={toastOptions} />}
        ref={toastRef}
      />
    </View>
  );
};

export default memo(CheckoutModule);
