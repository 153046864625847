import { LanguageDropdown } from "@/common/components/Dropdown";
import Icon from "@/common/components/Icon";
import Logo from "@/common/components/Svgs/Logo";
import { useBreakpoint } from "@/common/hooks";
import {
  getTotal,
  isUserAwaitingDelivery,
  isUserInAnActiveSubscription,
} from "@/common/utils";
import { toggle } from "@/redux/reducers/Order";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, config, useSpring } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mobile } = useBreakpoint();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const [waitingDelivery] = useState(
    isUserAwaitingDelivery(subscription, deliveries),
  );
  const [activeSubscription] = useState(
    isUserInAnActiveSubscription(subscription, deliveries),
  );
  const [price, setPrice] = useState(0);
  const animated_price = useSpring({
    immediate: false,
    config: config.slow,
    number: price,
    from: { number: 0 },
  });

  useEffect(() => {
    setPrice(parseFloat(getTotal(boxes)));
  }, [boxes]);
  useEffect(() => {
    setPrice(parseFloat(getTotal(boxes)));
  }, [boxes]);

  return (
    <View style={styles.container}>
      <View style={styles.topBarContainer}>
        <View style={styles.logoContainer}>
          {mobile && <Logo style={styles.logo} />}
        </View>
        <View style={styles.actionsContainer}>
          <LanguageDropdown />
          <IconButton
            style={styles.notificationIcon}
            icon="bell-outline"
            size={moderateScale(25)}
          />
        </View>
      </View>
      <View style={styles.bannerContainer}>
        <Text style={styles.infoGreetings} variant={"labelLarge"}>
          {t("DashboardTitle").toUpperCase()}, {user.name.toUpperCase()}
        </Text>
        <View style={[styles.banner]}>
          <View style={styles.infoContainer}>
            <Text style={styles.infoTitle} variant={"labelLarge"}>
              {t("DashboardHeaderTitle")}
            </Text>
            <Text style={styles.info} variant={"displaySmall"}>
              <AnimatedText style={styles.info} variant={"displaySmall"}>
                {animated_price.number.to((n) => n.toFixed(2))}
              </AnimatedText>
              €
            </Text>
            <Text style={styles.infoSubtitle} variant={"labelSmall"}>
              {t("BoxesVATIncluded")}
            </Text>
          </View>
          <View style={styles.wrapper}>
            <View style={styles.mainIconContainer}>
              <IconButton
                style={styles.icon}
                icon="package-variant-closed"
                size={moderateScale(35)}
                iconColor={theme.colors.onSecondary}
                onPress={() => activeSubscription && dispatch(toggle())}
              />
            </View>
            {waitingDelivery && (
              <View style={styles.warningLabel}>
                <Icon
                  style={styles.warningLabelIcon}
                  color={theme.colors.onPrimaryContainer}
                  name="update"
                  size={moderateScale(12)}
                />
                <Text style={styles.warningLabelText} variant={"labelSmall"}>
                  {t("TextDeliveryNotFinished")}
                </Text>
              </View>
            )}
            {activeSubscription && (
              <View>
                <TouchableOpacity
                  style={styles.warningLabel}
                  onPress={() => dispatch(toggle())}
                >
                  <Icon
                    style={styles.warningLabelIcon}
                    color={theme.colors.onPrimaryContainer}
                    name="update"
                    size={moderateScale(14)}
                  />
                  <View>
                    <Text
                      style={styles.warningLabelText}
                      variant={"labelSmall"}
                    >
                      {t("TextInAnActiveSubscription")}
                    </Text>
                    <Text style={styles.infoDescription} variant="labelSmall">
                      {t(`WaitingSubscriptionDescription3`)}{" "}
                      {moment(subscription.next_delivery_date).format(
                        "DD/MM/YYYY",
                      )}
                    </Text>
                  </View>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Header);
