import { Button } from "@/common/components/Button";
import { default_animal_values } from "@/common/helpers/animals";
import { globalStyles } from "@/common/styles";
import { validateForm } from "@/modules/Animals/helpers";
import {
  changeSelectedAnimal,
  changeValues,
  toggle,
} from "@/redux/reducers/Animal";
import { add as addAnimal, update } from "@/redux/reducers/Animals";
import { add as addBox } from "@/redux/reducers/Boxes";
import { createAnimal, updateUserAnimal } from "@/services/animals.service";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const Footer = ({ toast }: { toast: ToastType }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const animalForm = useSelector((state: RootState) => state.animalForm);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    let errors = validateForm(animalForm);
    if (errors && Object.keys(errors).length > 0) {
      dispatch(
        changeValues({
          ...animalForm,
          values: { ...animalForm.values },
          errors: errors,
        })
      );
      toast.show(t("ErrorErrorsInForm"), { type: "danger" });
    } else {
      setLoading(true);
      let promise: any;
      if (animalForm.type === "create") {
        promise = createAnimal(user.accessToken, animalForm.values);
      } else {
        promise = updateUserAnimal(
          user.accessToken,
          animalForm.values,
          animalForm.selectedAnimal._id
        );
      }
      promise
        .then((response: any) => {
          if (animalForm.type === "create") {
            dispatch(addAnimal(response.data.animal));
            dispatch(addBox(response.data.box));
          } else {
            dispatch(update(response.data.animal));
            dispatch(changeSelectedAnimal(response.data.animal));
          }
          setLoading(false);
          dispatch(changeValues({ values: default_animal_values, errors: {} }));
          dispatch(toggle());
        })
        .catch((error: any) => {
          console.log(error);
          setLoading(false);
          toast.show(t(error), { type: "danger" });
        });
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.mobileContainer}>
        <View style={styles.productPriceContainer}>
          <Text style={styles.productFinalPriceTitle} variant={"titleSmall"}>
            {animalForm.values.name !== ""
              ? animalForm.type === "edit"
                ? t("TextEdit")
                : t("TextAdd")
              : animalForm.type === "edit"
              ? ""
              : t("TextAddACat")}
          </Text>
          {animalForm.values.name !== "" && (
            <Text
              style={[styles.productFinalPriceTitle, globalStyles.bold]}
              variant="titleMedium"
            >
              {animalForm.values.name}
            </Text>
          )}
        </View>
        <View style={styles.productActions}>
          <Button
            style={styles.actionButton}
            mode={"contained"}
            loading={loading}
            onPress={() => onSubmit()}
          >
            {animalForm.type === "edit" ? t("TextEdit") : t("TextAdd")}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default memo(Footer);
