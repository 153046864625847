import Icon from "@/common/components/Icon";
import { goToLink } from "@/common/utils";
import { moderateScale } from "@/theme/dimensions";
import Slider from "@react-native-community/slider";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform, Pressable, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";
const Sections = () => {
  const { t } = useTranslation();
  const animals = useSelector((state: RootState) => state.animals);
  const animalForm = useSelector((state: RootState) => state.animalForm);
  const [value, setValue] = useState(
    parseFloat(animalForm.selectedAnimal.weight)
  );
  const selectedAnimalIndex = animals.findIndex(
    (a) => animalForm.selectedAnimal._id === a._id
  );
  useEffect(() => {
    setValue(parseFloat(animalForm.selectedAnimal.weight));
  }, [animalForm.selectedAnimal]);
  return (
    <View style={styles.container}>
      <View style={styles.sectionRow}>
        <View style={styles.sectionWrapper}>
          <Card style={styles.sliderContainer}>
            <Text style={styles.sliderTitle} variant="labelLarge">
              {t("TextDailyAmounts")}
            </Text>
            <Text style={styles.sliderSubtitle} variant="labelSmall">
              {t("TextPurrmiSlashWater")}
            </Text>
            <Text style={styles.sliderWeight} variant="headlineSmall">
              {value}kg
            </Text>
            <View style={styles.sectionRow}>
              <View style={styles.sectionColumn}>
                <Icon
                  style={styles.icon}
                  name={"food-drumstick"}
                  size={moderateScale(45)}
                />
                <Text style={styles.sliderDescription} variant="labelLarge">
                  {Math.round((value * 20 + Number.EPSILON) * 100) / 100}g
                </Text>
              </View>
              <View style={styles.sectionColumn}>
                <Icon
                  style={styles.icon}
                  name={"water"}
                  size={moderateScale(45)}
                />
                <Text style={styles.sliderDescription} variant="labelLarge">
                  {Math.round((50 * value + Number.EPSILON) * 100) / 100}ml
                </Text>
              </View>
            </View>
            <Slider
              style={{ width: "100%", height: 30 }}
              value={value}
              minimumValue={1}
              maximumValue={15}
              onValueChange={(value) => setValue(value)}
              step={0.1}
            />
            <View style={styles.infoContainer}>
              <Icon
                style={styles.checkpointInfoIcon}
                name="information"
                size={moderateScale(16)}
              />
              <Text style={styles.radioButtonTitle} variant="labelMedium">
                {t("TextOnlyAnEstimate")}
              </Text>
            </View>
          </Card>
        </View>
      </View>
      <View style={[styles.sectionRow, { justifyContent: "center" }]}>
        <View style={styles.sectionColumn}>
          <View style={styles.sectionWrapper}>
            <Pressable
              style={styles.sectionVetOpinion}
              onPress={
                Platform.OS === "web"
                  ? async () => {
                      // await Analytics.logEvent(`press_FAB_callus_button`);
                      window.open(
                        "https://api.whatsapp.com/send/?phone=%2B351913847159",
                        "_blank"
                      );
                    }
                  : async () => {
                      // await Analytics.logEvent(`press_FAB_callus_button`);
                      goToLink("whatsapp://send?phone=+351913847159");
                    }
              }
            >
              <Icon
                style={styles.sectionVetOpinionIcon}
                name={"shield-plus"}
                size={moderateScale(25)}
              />
              <Text style={styles.sectionVetOpinionText} variant="labelMedium">
                {t("TextGetVetOpinion")}
              </Text>
            </Pressable>
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Sections);
