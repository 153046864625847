import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

const CUSTOM_BORDER_RADIUS = 30;

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  animatedContainer: {
    width: "100%",
  },
  headerInfoListItem: {},
  headerInfoListItemImage: {
    height: moderateScale(30),
    marginRight: 10,
    width: moderateScale(30),
    borderRadius: BORDER_RADIUS,
  },
  headerInfoListItemTitle: { ...theme.fonts.labelSmall },
  noProductsContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  noProductsCard: {
    paddingHorizontal: 25,
    paddingVertical: 15,
  },
  noProductsTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
  },
  imageContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
  },
  image: {
    width: moderateScale(120),
    height: moderateScale(120),
    borderRadius: BORDER_RADIUS,
  },
  cardContainer: {
    width: "100%",
    flexDirection: "row",
    paddingRight: 10,
  },
  card: {
    width: "100%",
    borderRadius: BORDER_RADIUS,
    marginBottom: 20,
  },
  tagContainer: {
    flexDirection: "row",
    marginLeft: 10,
    marginTop: 5,
    marginBottom: 10,
  },
  tag: {
    paddingHorizontal: 5,
    paddingVertical: 1,
    marginRight: 3,
    backgroundColor: theme.colors.surfaceVariant,
    color: theme.colors.onSurfaceVariant,
    borderRadius: BORDER_RADIUS,
  },
  tagDiscount: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: theme.colors.error,
    color: theme.colors.onError,
    borderRadius: BORDER_RADIUS,
  },
  tagIcon: {
    marginRight: 3,
    color: theme.colors.onError,
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  productPriceStrike: {
    marginLeft: 5,
    textDecorationLine: "line-through",
    textAlign: "center",
  },
  infoGreetings: {
    marginVertical: 15,
  },
  productTitle: {
    marginLeft: 10,
    marginTop: 8,
    opacity: 0.6,

    width: "90%",
  },
  productPrice: {
    marginTop: 2,
    marginLeft: 10,
    fontWeight: "bold",
  },
  animalsNamesContainer: {
    position: "absolute",
    opacity: 0.6,
    left: 0,
    top: 0,
    paddingHorizontal: 10,
    paddingVertical: 2,
    borderTopLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.surfaceVariant,
  },
  actionsContainer: {
    position: "absolute",
    right: 0,
    bottom: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 5,
  },
  action: {
    borderRadius: BORDER_RADIUS,
  },
  delete: {
    margin: 0,
  },
  increase: {
    margin: 0,
    marginLeft: 5,
  },
  decrease: {
    margin: 0,
    marginRight: 5,
  },
  actionText: {
    fontWeight: "bold",
  },
});
