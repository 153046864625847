import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const countriesSlice = createSlice({
  name: "countries",
  initialState: [] as Country[],
  reducers: {
    update(state, action: PayloadAction<Country[]>) {
      return [...action.payload];
    },
  },
});

export const { update } = countriesSlice.actions;
export default countriesSlice.reducer;
