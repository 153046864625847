import { theme } from "@/theme";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  actionButton: {
    marginVertical: 5,
    maxWidth: 200,
    width: "100%",
  },
  container: {
    alignItems: "center",
    backgroundColor: theme.colors.surfaceVariant,
    bottom: 0,
    height: "20%",
    justifyContent: "center",
    paddingHorizontal: 30,
    width: "100%",
  },
  mobileContainer: {
    alignItems: "center",
    maxWidth: 1200,
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  paymentSecurityInfo: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 5,
    width: "100%",
  },
  paymentSecurityInfoText: {
    marginLeft: 1,
  },
  productActions: {
    alignItems: "center",
  },
  productDeliveryDate: {
    color: theme.colors.onSurfaceVariant,
    fontWeight: "bold",
  },
  productFinalPrice: {
    color: theme.colors.onSurfaceVariant,
    fontWeight: "bold",
  },
  productFinalPriceTitle: {
    color: theme.colors.onSurfaceVariant,
    opacity: 0.7,
  },
  productInfoSubtitle: {
    color: theme.colors.onSurfaceVariant,
  },
  productPriceContainer: { maxWidth: "50%" },
});
