import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Text, TouchableOpacity, View } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import { RootStackParamList } from "../../types";
import styles from "./styles";

export default function NotFoundScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "NotFound">) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{t("TextScreenDoesNotExist")}</Text>
      <TouchableOpacity
        onPress={() => {
          navigation.navigate("Home");
        }}
        style={styles.link}
      >
        <Text style={styles.linkText}>{t("TextGoToHomeScreen")}</Text>
      </TouchableOpacity>
    </View>
  );
}
