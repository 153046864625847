import Explorer from "@/views/Explorer";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ExplorerParamList } from "../../types";

const Stack = createStackNavigator<ExplorerParamList>();

export default function ExplorerNavigator() {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, title: t("ScreenExplorerTitle") }}
    >
      <Stack.Screen name="Explorer" component={Explorer} />
    </Stack.Navigator>
  );
}
