import { Button } from "@/common/components/Button";
import { TextInput } from "@/common/components/Input";
import { objectsEqual } from "@/common/utils";
import {
  validateAddressForm,
  validatePasswordForm,
  validateUserForm,
} from "@/modules/More/helpers";
import { update } from "@/redux/reducers/User";
import { changeUserPassword, updateUserInfo } from "@/services/user.service";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const Section = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formStateUser, setFormStateUser] = useState({
    isValid: false,
    submitted: false,
    values: {
      name: user.name,
      email: user.email,
    },
    touched: {},
    errors: {} as { name?: String; email?: string },
  });
  const [formStateAddress, setFormStateAddress] = useState({
    isValid: false,
    submitted: false,
    values: {
      address: user.address ? user.address : "",
      zip_code: user.zip_code ? user.zip_code : "",
      city: user.city ? user.city : "",
      phone: user.phone ? user.phone : "",
      nif: user.nif ? user.nif : "",
    },
    touched: {},
    errors: {} as {
      address?: string;
      phone?: string;
      zip_code?: string;
      city?: string;
      nif?: string;
    },
  });
  const [formStatePassword, setFormStatePassword] = useState({
    isValid: false,
    submitted: false,
    values: {
      password: "",
      confirmPassword: "",
    },
    touched: {},
    errors: {} as {
      password?: string;
      confirmPassword?: string;
    },
  });
  const validateFormUser = () => {
    const errors = validateUserForm(formStateUser.values);
    setFormStateUser((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };

  const validateFormAddress = () => {
    const errors = validateAddressForm(formStateAddress.values);
    setFormStateAddress((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };
  const validateFormPassword = () => {
    const errors = validatePasswordForm(formStatePassword.values);
    setFormStatePassword((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  };

  useEffect(() => {
    validateFormUser();
  }, [formStateUser.values]);
  useEffect(() => {
    validateFormAddress();
  }, [formStateAddress.values]);
  useEffect(() => {
    validateFormPassword();
  }, [formStatePassword.values]);

  const onChangeText = (type: string, text: string) => {
    setFormStateAddress((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [type]: text,
      },
      touched: {
        ...formState.touched,
        [type]: true,
      },
    }));
  };
  const onChangeTextPassword = (type: string, text: string) => {
    setFormStatePassword((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [type]: text,
      },
      touched: {
        ...formState.touched,
        [type]: true,
      },
    }));
  };

  const onSubmitUser = () => {
    validateFormUser();
    if (formStateUser.isValid === false) {
      setFormStateUser((formState) => ({
        ...formState,
        submitted: true,
      }));
      return;
    }
    if (
      objectsEqual({ name: user.name, email: user.email }, formStateUser.values)
    ) {
      toast.show(t("NotificationNoChange"), { type: "danger" });
      return;
    }
    setLoading(true);
    let userUpdated = { ...user };
    if (formStateUser.values.name != user.name)
      userUpdated.name = formStateUser.values.name;
    if (formStateUser.values.email != user.email)
      userUpdated.email = formStateUser.values.email;
    updateUserInfo(user.accessToken, userUpdated)
      .then((response: any) => {
        dispatch(update(userUpdated));
        setLoading(false);
        toast.show(t("BackendNotification" + response.data.message), {
          type: "success",
        });
      })
      .catch((error: any) => {
        toast.show(t(error.response.message), { type: "danger" });
        setLoading(false);
      });
  };
  const onSubmitAddress = () => {
    validateFormAddress();
    if (formStateAddress.isValid === false) {
      setFormStateAddress((formState) => ({
        ...formState,
        submitted: true,
      }));
      return;
    }
    if (
      objectsEqual(
        {
          address: user.address,
          zip_code: user.zip_code,
          city: user.city,
          nif: user.nif,
          phone: user.phone,
        },
        formStateAddress.values
      )
    ) {
      toast.show(t("NotificationNoChange"), { type: "danger" });
      return;
    }
    setLoading(true);
    let userUpdated = { ...user };
    userUpdated.address = formStateAddress.values.address;
    userUpdated.zip_code = formStateAddress.values.zip_code;
    userUpdated.city = formStateAddress.values.city;
    userUpdated.nif = formStateAddress.values.nif;
    userUpdated.phone = formStateAddress.values.phone;
    updateUserInfo(user.accessToken, userUpdated)
      .then((response: any) => {
        dispatch(update(userUpdated));
        setLoading(false);
        toast.show(t("BackendNotification" + response.data.message), {
          type: "success",
        });
      })
      .catch((error: any) => {
        toast.show(t(error.response), { type: "danger" });
        setLoading(false);
      });
  };

  const onSubmitPassword = () => {
    validateFormPassword();
    if (formStatePassword.isValid === false) {
      setFormStatePassword((formState) => ({
        ...formState,
        submitted: true,
      }));
      return;
    }
    setLoading(true);
    changeUserPassword(user.accessToken, formStatePassword.values)
      .then((response: any) => {
        toast.show(t("BackendNotification" + response.data.message), {
          type: "success",
        });
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        console.error(error);
      });
  };

  const hasError = (field: string) =>
    (formStateAddress.touched[field] || formStateAddress.submitted) &&
    formStateAddress.errors[field]
      ? true
      : false;
  const hasErrorPassword = (field: string) =>
    (formStatePassword.touched[field] || formStatePassword.submitted) &&
    formStatePassword.errors[field]
      ? true
      : false;

  return (
    <View style={styles.container}>
      <Text variant="titleMedium" style={styles.title}>
        {t("ScreenProfileTitle")}
      </Text>
      <View style={styles.userInfoContainer}>
        <Text style={styles.areaTitle} variant="bodyMedium">
          {t("ProfileRefreshDataTitle")}
        </Text>
        <TextInput
          label={t("TextName")}
          value={formStateUser.values.name}
          error={formStateUser.errors.name}
          onChangeText={(text) => {
            setFormStateUser((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                name: text,
              },
              touched: {
                ...formState.touched,
                name: true,
              },
            }));
          }}
        />
        <TextInput
          label={t("TextEmail")}
          value={formStateUser.values.email}
          error={formStateUser.errors.email}
          onChangeText={(text) => {
            setFormStateUser((formState) => ({
              ...formState,
              values: {
                ...formState.values,
                email: text,
              },
              touched: {
                ...formState.touched,
                email: true,
              },
            }));
          }}
        />
        <Button
          style={{ marginTop: 20 }}
          mode="contained"
          loading={loading}
          disabled={loading}
          onPress={onSubmitUser}
        >
          {t("ButtonTextUpdateUserInfo")}
        </Button>
      </View>
      <View style={styles.addressInfoContainer}>
        <Text style={styles.areaTitle} variant="bodyMedium">
          {t("ProfileAddressTitle")}
        </Text>
        <TextInput
          containerStyle={styles.inputContainer}
          outlineStyle={styles.inputOutline}
          label={`${t("TextAddress")}*`}
          value={formStateAddress.values.address}
          error={hasError("address") ? formStateAddress.errors.address : null}
          onChangeText={(text: string) => onChangeText("address", text)}
        />
        <View style={styles.row}>
          <TextInput
            containerStyle={[styles.inputContainer, styles.twoSplit]}
            outlineStyle={styles.inputOutline}
            label={`${t("TextCity")}*`}
            value={formStateAddress.values.city}
            error={hasError("city") ? formStateAddress.errors.city : null}
            onChangeText={(text: string) => onChangeText("city", text)}
          />
          <TextInput
            containerStyle={[styles.inputContainer, styles.twoSplit]}
            outlineStyle={styles.inputOutline}
            label={`${t("TextZipCode")}*`}
            value={formStateAddress.values.zip_code}
            error={
              hasError("zip_code") ? formStateAddress.errors.zip_code : null
            }
            onChangeText={(text: string) => onChangeText("zip_code", text)}
          />
        </View>
        <View style={styles.row}>
          <TextInput
            containerStyle={[styles.inputContainer, styles.twoSplit]}
            outlineStyle={styles.inputOutline}
            label={`${t("TextPhoneNumber")}*`}
            value={formStateAddress.values.phone}
            error={hasError("phone") ? formStateAddress.errors.phone : null}
            onChangeText={(text: string) => onChangeText("phone", text)}
          />
          <TextInput
            containerStyle={[styles.inputContainer, styles.twoSplit]}
            outlineStyle={styles.inputOutline}
            label={`${t("TextVAT")}*`}
            value={formStateAddress.values.nif}
            error={hasError("nif") ? formStateAddress.errors.nif : null}
            onChangeText={(text: string) => onChangeText("nif", text)}
          />
        </View>
        <Button
          style={{ marginTop: 20 }}
          mode="contained"
          loading={loading}
          disabled={loading}
          onPress={onSubmitAddress}
        >
          {t("ButtonTextUpdateUserInfo")}
        </Button>
      </View>
      <View style={styles.changePasswordContainer}>
        <Text style={styles.areaTitle} variant="bodyMedium">
          {t("ProfileChangePasswordTitle")}
        </Text>
        <TextInput
          textContentType="password"
          secureTextEntry
          containerStyle={styles.inputContainer}
          outlineStyle={styles.inputOutline}
          label={`${t("TextPassword")}*`}
          value={formStatePassword.values.password}
          error={
            hasErrorPassword("address")
              ? formStatePassword.errors.password
              : null
          }
          onChangeText={(text: string) => onChangeText("password", text)}
        />
        <TextInput
          textContentType="password"
          secureTextEntry
          containerStyle={styles.inputContainer}
          outlineStyle={styles.inputOutline}
          label={`${t("TextConfirmPassword")}*`}
          value={formStatePassword.values.confirmPassword}
          error={
            hasErrorPassword("confirmPassword")
              ? formStatePassword.errors.confirmPassword
              : null
          }
          onChangeText={(text: string) => onChangeText("confirmPassword", text)}
        />
        <Button
          style={{ marginTop: 20 }}
          mode="contained"
          loading={loading}
          disabled={loading}
          onPress={onSubmitPassword}
        >
          {t("ButtonTextUpdateUserInfo")}
        </Button>
      </View>
    </View>
  );
};

export default memo(Section);
