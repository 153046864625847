import Icon from "@/common/components/Icon";
import {
  getFinalPrice,
  getShippingCost,
  isObjectEmpty,
  isUserAwaitingDelivery,
  isUserExperimenting,
} from "@/common/utils";
import {
  boxesToGoogleProducts,
  googleBeginCheckout,
} from "@/common/utils/analytics.utils";
import { experimentalPackageID } from "@/config";
import CheckoutModule from "@/modules/Checkout";
import First from "@/modules/First";
import Repurchase from "@/modules/Repurchase";
import { toggle } from "@/redux/reducers/Order";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useEffect, useState } from "react";
import { View } from "react-native";
import Modal from "react-native-modal";
import { IconButton, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const Checkout = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const boxes = useSelector((state: RootState) => state.boxes);
  const orderForm = useSelector((state: RootState) => state.orderForm);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const shippings = useSelector((state: RootState) => state.shippings);
  const order_button_disabled = !boxes.some((b) => b.products.length > 0);
  // const [isFoodUser, setIsFoodUser] = useState(false);
  const isNewUser = isUserExperimenting(boxes, subscription, deliveries);
  const [isComingForFirstRepurchase, setIsComingForFirstRepurchase] =
    useState(false);
  const [waitingDelivery] = useState(
    isUserAwaitingDelivery(subscription, deliveries),
  );
  const [modalVisible, setModalVisible] = useState(
    isNewUser && boxes.some((b) => b.products.length > 0),
  );
  const [repurchaseModalVisible, setRepurchaseModalVisible] = useState(
    isComingForFirstRepurchase,
  );
  const [testFoodModalVisible, setTestFoodModalVisible] = useState(true);
  const toggleDryTest = (): void => {
    setTestFoodModalVisible((state) => !state);
  };
  const toggleFirst = (): void => {
    setModalVisible((state) => !state);
  };
  const toggleRepurchase = (): void => {
    setRepurchaseModalVisible((state) => !state);
  };
  const getShippingValue = () => {
    return getShippingCost(shippings, user, boxes, subscription);
  };
  useEffect(() => {
    // setIsFoodUser(
    //   animals.some(
    //     (a) =>
    //       testFoods.includes(a.current_food?.food?._id) &&
    //       !a.current_food?.product,
    //   ),
    // );
    if (deliveries.length === 0 || isObjectEmpty(subscription)) {
      setIsComingForFirstRepurchase(false);
      return;
    }
    const latestDelivery = deliveries.reduce(function (r, a) {
      return r.delivery_date > a.delivery_date ? r : a;
    });
    const isRepurchase =
      (latestDelivery.status === "delivered" &&
        !(latestDelivery.payment.type === "subscription")) ||
      (!subscription.tier && !subscription.additional_info);
    if (isRepurchase) {
      dispatch(toggle());
      toggleRepurchase();
    }
    setIsComingForFirstRepurchase(isRepurchase);
  }, [deliveries]);

  return (
    <>
      <View style={styles.buttonContainer}>
        <IconButton
          style={styles.buttonStyle}
          icon="truck-fast"
          iconColor={theme.colors.onSecondary}
          disabled={order_button_disabled}
          size={moderateScale(25)}
          onPress={async () => {
            await googleBeginCheckout({
              currency: "EUR",
              value: parseFloat(
                getFinalPrice(user, boxes, parseFloat(getShippingValue())),
              ),
              items: boxesToGoogleProducts(boxes),
            });
            if (isNewUser) {
              toggleFirst();
            } else if (isComingForFirstRepurchase) {
              toggleRepurchase();
            } else {
              dispatch(toggle());
            }
          }}
        />
        {waitingDelivery && (
          <Icon
            style={styles.warningButton}
            color={theme.colors.onPrimaryContainer}
            name="update"
            size={moderateScale(18)}
          />
        )}
      </View>
      <Modal
        backdropOpacity={0.3}
        isVisible={orderForm.open}
        onBackdropPress={() => dispatch(toggle())}
        style={styles.contentView}
      >
        <CheckoutModule />
      </Modal>
      {isNewUser && (
        <Modal
          backdropOpacity={0.3}
          isVisible={modalVisible}
          onBackdropPress={() => setModalVisible(false)}
          style={styles.contentView}
        >
          {boxes.some((b) =>
            b.products.some((bp) => bp.product._id === experimentalPackageID),
          ) && <First toggle={toggleFirst} />}
        </Modal>
      )}
      {isComingForFirstRepurchase && (
        <Modal
          backdropOpacity={0.3}
          isVisible={repurchaseModalVisible}
          onBackdropPress={() => setRepurchaseModalVisible(false)}
          style={styles.contentView}
        >
          <Repurchase toggle={toggleRepurchase} />
        </Modal>
      )}
      {/* {isFoodUser && (
        <Modal
          backdropOpacity={0.3}
          isVisible={testFoodModalVisible}
          onBackdropPress={() => setTestFoodModalVisible(false)}
          style={styles.contentView}
        >
          <DryTest toggle={toggleDryTest} />
        </Modal>
      )} */}
    </>
  );
};

export default memo(Checkout);
