import { StyleSheet } from "react-native";
import { theme } from "../../theme";
export default StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
  },
  header: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  iconContainer: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  logo: { width: 350, height: 180, fill: theme.colors.primary },
  repeatImage: { resizeMode: "repeat", opacity: 0.05 },
  imageBackground: {
    flex: 1,
    width: "100%",
    height: "100%",
  },
  main: { width: "100%", alignItems: "center", flex: 1 },
});
