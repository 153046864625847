import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { TextInput } from "@/common/components/Input";
import { useBreakpoint } from "@/common/hooks";
import { updateValuesAndGoNext } from "@/redux/reducers/Register";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pressable, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { validateForm } from "../../../helpers";
import styles from "./styles";

const Name = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const { mobile } = useBreakpoint();
  const [state, setState] = useState({
    values: starting_form.values.map((a) => {
      return { ...a };
    }),
    errors: [],
  });

  const onChangeText = (index: number, text: string) => {
    setState({
      ...state,
      values: state.values.map((form, jndex) => {
        if (index === jndex) {
          form.name = text;
        }
        return form;
      }),
      errors: validateForm(state.values).map((errors, jndex) => {
        if (state.errors[jndex] && index === jndex) {
          return errors;
        } else if (state.errors[jndex]) {
          return state.errors[jndex];
        }
      }),
    });
  };

  const onSelectButton = (index: number, type: string) => {
    setState({
      ...state,
      values: state.values.map((form, jndex) => {
        if (index === jndex) {
          form.sex = type;
        }
        return form;
      }),
      errors: validateForm(state.values).map((errors, jndex) => {
        if (state.errors[jndex] && index === jndex) {
          return errors;
        } else if (state.errors[jndex]) {
          return state.errors[jndex];
        }
      }),
    });
  };

  const onUpdateStartingForm = () => {
    let errors = validateForm(state.values);
    if (!errors.some((form_errors) => form_errors.name)) {
      dispatch(
        updateValuesAndGoNext({
          slide: starting_form.slide + 1,
          values: state.values,
        })
      );
    } else {
      setState({
        ...state,
        errors: errors,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Text variant="bodySmall" style={styles.title}>
        {state.values.length > 1
          ? t("TextGetStartedSubtitle2P")
          : t("TextGetStartedSubtitle2S")}
      </Text>
      {state.values.map((form, index) => (
        <View key={"Pet_names_" + index} style={styles.wrapper}>
          <TextInput
            containerStyle={styles.inputContainer}
            key={"Form_Name_" + index}
            label={t("TextName")}
            value={form.name}
            error={
              state.errors[index]?.name?.length > 0
                ? state.errors[index].name
                : undefined
            }
            onChangeText={(text: string) => onChangeText(index, text)}
          />
          <View style={styles.buttonContainer}>
            <Pressable
              style={[
                mobile ? styles.genderButtonMobile : styles.genderButton,
                form.sex === "female" ? styles.active : null,
              ]}
              onPress={() => onSelectButton(index, "female")}
            >
              <Icon
                style={[
                  styles.genderButtonIcon,
                  form.sex === "female" ? styles.activeIcon : null,
                ]}
                name={"gender-female"}
                size={moderateScale(12)}
              />
              <Text
                style={[
                  styles.genderButtonText,
                  form.sex === "female" ? styles.activeText : null,
                ]}
                variant="labelSmall"
              >
                {t("TextFemale")}
              </Text>
            </Pressable>
            <Pressable
              style={[
                mobile ? styles.genderButtonMobile : styles.genderButton,
                form.sex === "male" ? styles.active : null,
              ]}
              onPress={() => onSelectButton(index, "male")}
            >
              <Icon
                style={[
                  styles.genderButtonIcon,
                  form.sex === "male" ? styles.activeIcon : null,
                ]}
                name={"gender-male"}
                size={moderateScale(12)}
              />
              <Text
                style={[
                  styles.genderButtonText,
                  form.sex === "male" ? styles.activeText : null,
                ]}
                variant="labelSmall"
              >
                {t("TextMale")}
              </Text>
            </Pressable>
          </View>
        </View>
      ))}
      <Button
        mode="contained"
        disabled={state.values.some((a) => a.name === "" || a.sex === "")}
        style={styles.button}
        onPress={onUpdateStartingForm}
      >
        {t("TextContinue")}
      </Button>
    </View>
  );
};

export default memo(Name);
