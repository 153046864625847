import calendar, {
  CALENDAR_MONTHS,
  getDateISO,
  getNextMonth,
  getPreviousMonth,
  isDate,
  isSameDay,
  isSameMonth,
  PORTUGUESE_HOLIDAYS_2022,
  WEEK_DAYS,
} from "@/common/utils/date.utils";
import { moderateScale } from "@/theme/dimensions";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { FlatList, Pressable, View } from "react-native";
import { IconButton, Text } from "react-native-paper";
import styles from "./styles";
const Calendar = ({ ...props }) => {
  let pressureTimer: any, pressureTimeout: any;
  const [dateState, setDateState] = useState({
    current: undefined,
    month: 0,
    year: 0,
  });
  const [today, setToday] = useState(new Date());
  useEffect(() => {
    addDateToState(props.date);
  }, []);
  const addDateToState = (date) => {
    const isDateObject = isDate(date);
    const _date = isDateObject ? date : new Date();
    setDateState({
      current: isDateObject ? date : null,
      month: +_date.getMonth() + 1,
      year: _date.getFullYear(),
    });
  };
  const getCalendarDates = () => {
    const { current, month, year } = dateState;
    const currentMonth = current
      ? +current.getMonth() + 1
      : +new Date().getMonth() + 1;
    const currentYear = current
      ? +current.getMonth() + 1
      : +new Date().getMonth() + 1;
    const calendarMonth = month || currentMonth;
    const calendarYear = year || currentYear;
    return calendar(calendarMonth, calendarYear);
  };

  const gotoDate = (date) => (evt) => {
    evt && evt.preventDefault();
    const { current } = dateState;
    !(current && isSameDay(date, current)) && addDateToState(date);
    props.onDateChanged && props.onDateChanged(date);
  };
  const gotoPreviousMonth = () => {
    const { month, year } = dateState;
    const previousMonth = getPreviousMonth(month, year);
    setDateState({
      month: previousMonth.month,
      year: previousMonth.year,
      current: dateState.current,
    });
  };
  const gotoNextMonth = () => {
    const { month, year } = dateState;
    const nextMonth = getNextMonth(month, year);
    setDateState({
      month: nextMonth.month,
      year: nextMonth.year,
      current: dateState.current,
    });
  };
  const gotoPreviousYear = () => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year - 1,
      current: dateState.current,
    });
  };
  const gotoNextYear = () => {
    const { year } = dateState;
    setDateState({
      month: dateState.month,
      year: year + 1,
      current: dateState.current,
    });
  };

  const renderMonthAndYear = () => {
    const { month, year } = dateState;
    // Resolve the month name from the CALENDAR_MONTHS object map
    const monthname =
      Object.keys(CALENDAR_MONTHS)[Math.max(0, Math.min(month - 1, 11))];
    return (
      <View style={styles().calendarHeader}>
        <IconButton
          style={styles().calendarArrowLeft}
          size={moderateScale(20)}
          icon={"chevron-left"}
          onPress={gotoPreviousMonth}
          // title="Previous Month"
        />
        <Text style={[styles().calendarMonth]}>
          {monthname} {year}
        </Text>
        <IconButton
          style={styles().calendarArrowRight}
          size={moderateScale(20)}
          icon={"chevron-right"}
          onPress={gotoNextMonth}
        />
      </View>
    );
  };
  // Render the label for day of the week
  // This method is used as a map callback as seen in render()
  const renderDayLabel = ({ item, index }: any) => {
    // Resolve the day of the week label from the WEEK_DAYS object map
    const daylabel = WEEK_DAYS[item].toUpperCase();
    return (
      <Text style={[styles(index).calendarDay]} key={daylabel}>
        {daylabel}
      </Text>
    );
  };

  const isHoliday = (date) => {
    PORTUGUESE_HOLIDAYS_2022.forEach((holiday) => {
      if (moment(date).isSame(holiday)) return true;
    });
    return false;
  };
  const isARestrictedWeekDay = (date, restricted_week_days) => {
    return (
      restricted_week_days &&
      restricted_week_days.some((week_day) => moment(date).day() === week_day)
    );
  };
  const isInAForbiddenRange = (date, min, max) => {
    if (min && moment(date).isBefore(min)) return true;
    if (max && moment(date).isAfter(max)) return true;
    return false;
  };
  // Render a calendar date as returned from the calendar builder function
  // This method is used as a map callback as seen in render()
  const renderCalendarDate = ({ item, index }: any) => {
    const { current, month, year } = dateState;
    const _date = new Date(item.join("-"));
    // Check if calendar date is same day as today
    const isToday = isSameDay(_date, today);
    // Check if calendar date is same day as currently selected date
    const isCurrent = current && isSameDay(_date, current);
    // Check if calendar date is in the same month as the state month and year
    const inMonth =
      month && year && isSameMonth(_date, new Date([year, month, 1].join("-")));
    // The click handler
    const isDisabled =
      isHoliday(_date) ||
      isARestrictedWeekDay(_date, props.retrictedWeekDays) ||
      isInAForbiddenRange(_date, props.min, props.max);
    const onClick = gotoDate(_date);
    const my_props = { index, inMonth, onClick, title: _date.toDateString() };
    // Conditionally render a styled date component
    return (
      <Pressable
        style={[
          styles(my_props).CalendarDate,
          isCurrent
            ? styles(my_props).HighlightedCalendarDate
            : isToday
            ? styles(my_props).TodayCalendarDate
            : isDisabled
            ? styles(my_props).DisabledCalendarDate
            : {},
        ]}
        key={getDateISO(_date)}
        onPress={gotoDate(_date)}
        disabled={isDisabled}
      >
        {_date.getDate()}
      </Pressable>
    );
  };

  return (
    <View style={props.container}>
      {renderMonthAndYear()}
      <View style={props.calendarGrid}>
        <FlatList
          data={Object.keys(WEEK_DAYS)}
          renderItem={renderDayLabel}
          numColumns={7}
          keyExtractor={(item, index) => "WEEK_DAY_" + index}
        />
        <FlatList
          data={getCalendarDates()}
          renderItem={renderCalendarDate}
          numColumns={7}
          keyExtractor={(item, index) => "CALENDAR_DATE_" + index}
        />
      </View>
    </View>
  );
};

export default memo(Calendar);
