import { StyleSheet } from "react-native";
export default StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
  },
  imageBackground: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  repeatImage: { resizeMode: "repeat", opacity: 0.05 },
  scrollView: { width: "100%" },
  scrollableContainer: { width: "100%" },
});
