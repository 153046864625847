import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  ccButton: {
    marginVertical: 3,
    width: moderateScale(100),
  },
  ccButtonLabel: {
    marginVertical: 5,
  },
  actionsContainer: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  actionCancelButton: {
    marginVertical: 3,
    borderColor: theme.colors.error,
    backgroundColor: "transparent",
  },
  actionCancelButtonLabel: {
    marginVertical: 5,
    color: theme.colors.error,
  },
  ccDescription: {
    paddingBottom: 5,
  },
  actionButton: {
    marginVertical: 3,
  },
  actionButtonLabel: {
    marginVertical: 5,
  },
  container: {
    alignItems: "center",
    paddingVertical: 5,
  },
  infoContainer: {
    flex: 1,
    height: "100%",
    paddingHorizontal: 20,
  },
  infoIcon: {
    marginRight: 3,
  },
  infoPaymentMethod: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  infoPaymentMethodContainer: {
    marginVertical: 10,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  infoPaymentMethodImage: {
    height: moderateScale(75),
    marginRight: 10,
    width: moderateScale(75),
  },
  infoPaymentMethodWrapper: {
    alignItems: "center",
  },
});
