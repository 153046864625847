import Animals from "@/views/Animals";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AnimalsParamList } from "../../types";

const Stack = createStackNavigator<AnimalsParamList>();

export default function AnimalsNavigator() {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, title: t("ScreenAnimalsTitle") }}
    >
      <Stack.Screen name="Animals" component={Animals} />
    </Stack.Navigator>
  );
}
