import { Button as DefaultButton } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { googleLogin } from "@/common/utils/analytics.utils";
import { signin } from "@/redux/reducers/User";
import { facebookSignIn } from "@/services/auth.service";
import { moderateScale } from "@/theme/dimensions";
import { ResponseType } from "expo-auth-session";
import * as Facebook from "expo-auth-session/providers/facebook";
import * as WebBrowser from "expo-web-browser";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useTheme } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch } from "react-redux";
import styles from "./styles";
//import { googleLogin } from "@/common/utils/analytics.utils";

WebBrowser.maybeCompleteAuthSession();

const Button = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const toast: any = useToast();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    clientId: "428291428991595",
    responseType: ResponseType.Token,
  });

  React.useEffect(() => {
    if (response?.type === "success") {
      const { access_token } = response.params;
      if (access_token) {
        //MISSING PUSH_TOKEN
        facebookSignIn(access_token, null, Platform.OS)
          .then(async (response: any) => {
            dispatch(signin(response.data));
            await googleLogin({
              method: "Facebook",
            });
            setLoading(false);
          })
          .catch((error: any) => {
            setLoading(false);
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
          });
      } else {
        setLoading(false);
        toast.show(t("ErrorWhileTryingToConnectToFacebookTryAgainLater"), {
          type: "danger",
        });
      }
    } else {
      setLoading(false);
    }
  }, [response]);
  return (
    <DefaultButton
      style={[styles.button, props.style]}
      mode="contained"
      loading={loading}
      disabled={loading}
      onPress={async () => {
        setLoading(true);
        promptAsync();
      }}
    >
      {!loading && (
        <Icon
          style={styles.icon}
          name="facebook"
          size={moderateScale(18)}
          color={theme.colors.onPrimary}
        />
      )}
      {t("TextFacebookSignIn")}
    </DefaultButton>
  );
};

export default memo(Button);
