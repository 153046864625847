import { createNativeStackNavigator } from "@react-navigation/native-stack";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootStackParamList } from "../../types";
import NotFoundScreen from "../../views/NotFound";
import IntroNavigator from "./intro";
import RootTabNavigator from "./rootTab";
const Stack = createNativeStackNavigator<RootStackParamList>();

export default function RootNavigator() {
  const user = useSelector((state: RootState) => state.user);
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      {Object.keys(user).length === 0 || user.isLoggedOut ? (
        <Stack.Screen name="Intro" component={IntroNavigator} />
      ) : (
        <Stack.Screen name="Home" component={RootTabNavigator} />
      )}
      <Stack.Screen name="NotFound" component={NotFoundScreen} />
    </Stack.Navigator>
  );
}
