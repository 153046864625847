import { Button } from "@/common/components/Button";
import { globalStyles } from "@/common/styles";
import {
  getDefaultShippingCost,
  getTotalWithFakeDiscountAndWithShipping,
  getTotalWithoutDiscountButWithShipping,
  getTranslationLanguage,
} from "@/common/utils";
import { update as updateSubscription } from "@/redux/reducers/Subscription";
import { update as updateUser } from "@/redux/reducers/User";
import { updateSubscriptionTier } from "@/services/subscription.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { Avatar, Card, Divider, Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const Main = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const boxes = useSelector((state: RootState) => state.boxes);
  const shippings = useSelector((state: RootState) => state.shippings);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const [toast, setToast] = useState<ToastType>(null);
  const [loading, setLoading] = useState(false);
  const getNamesOfExperimentalPets = (type: number) => {
    let experimental_animals = animals;
    let custom_text = experimental_animals.map((animal, index) => {
      if (index != 0 && index === animals.length - 1) {
        return (
          <Text key={"name_of_animal_list" + index}>
            {" "}
            {t("TextAnd")}{" "}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            <Text style={styles.animalName}>{animal.name}</Text>
          </Text>
        );
      } else {
        return (
          <Text key={"name_of_animal_list" + index}>
            {index !== 0 ? ", " : ""}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            {<Text style={styles.animalName}>{animal.name}</Text>}
          </Text>
        );
      }
    });
    return custom_text;
  };

  const onUpdateSubscription = (tier: number) => {
    setLoading(true);
    const new_subscription = {
      ...subscription,
      tier: tier,
    };
    updateSubscriptionTier(user.accessToken, new_subscription)
      .then((response) => {
        dispatch(updateSubscription(response.data.subscription));
        dispatch(updateUser({ ...user, promocode: response.data.promocode }));
        setLoading(false);
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setLoading(false);
      });
  };

  return (
    <View style={[styles.headerContainer]}>
      <View style={[styles.header]}>
        <View style={[styles.headerInfo]}>
          <Text variant={"titleSmall"} style={[styles.headerInfoTitle]}>
            {`${t("TextRepurchaseHeaderInfoTitle")} `}
            <Text>{getNamesOfExperimentalPets(1)}</Text>
          </Text>
          <Text style={[styles.headerInfoDescription]}>
            {`${t("TextRepurchaseHeaderInfoDescription")} `}
            <Text>{getNamesOfExperimentalPets(1)}</Text>
            {` ${
              animals.length > 1
                ? t("TextRepurchaseHeaderInfoDescription3")
                : t("TextRepurchaseHeaderInfoDescription2")
            }.`}
          </Text>
          <Text style={[styles.headerInfoDescription]}>
            <Text>{getNamesOfExperimentalPets(1)}</Text>{" "}
            {animals.length > 1
              ? t("TextFirstTimeExperimentalHeaderInfoDescription4")
              : t("TextFirstTimeExperimentalHeaderInfoDescription5")}
            :
          </Text>
          <View style={[styles.headerInfoList]}>
            {boxes.map((box) => {
              return box.products.map((bp, index) => {
                return (
                  <View
                    key={"box_product_" + index}
                    style={styles.headerInfoListItem}
                  >
                    <View
                      style={{
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Image
                        source={{ uri: bp.product.images[0] }}
                        style={styles.headerInfoListItemImage}
                      />
                      <View
                        style={{
                          alignItems: "flex-start",
                          justifyContent: "center",
                          maxWidth: "80%",
                        }}
                      >
                        <Text style={[styles.headerInfoListItemTitle]}>
                          {
                            bp.product.title[
                              getTranslationLanguage(i18n.language)
                            ]
                          }
                          {" - "}x{bp.quantity}{" "}
                          <Text
                            style={{
                              fontSize: 14,
                            }}
                          >
                            {(bp.product.price * bp.quantity).toFixed(2)}€
                          </Text>{" "}
                          {bp.product.discount > 0 && (
                            <Text style={styles.discountText}>
                              {`-${bp.product.discount}%`}
                            </Text>
                          )}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              });
            })}
          </View>
        </View>
        <View style={[styles.headerPromoImagesContainer]}>
          <View style={styles.headerPromoImagesContainerWrapper}>
            <View style={globalStyles.wFullCentered}>
              <Text style={[styles.firstTimeInfoTitle]} variant="bodySmall">
                {t("TextRepurchasePromoTitle")}
              </Text>
              <View style={styles.headerPromoBenefits}>
                <Card
                  style={[
                    styles.featuresContainer,
                    subscription.tier === 1 ? styles.successBorder : {},
                  ]}
                  elevation={subscription.tier == 1 ? 4 : 1}
                >
                  <Text style={styles.featuresTitle}>
                    {t("TextRepurchasePriceTime1")}
                  </Text>
                  <Text style={styles.featuresPrice}>
                    {parseFloat(
                      getTotalWithoutDiscountButWithShipping(
                        boxes,
                        parseFloat(
                          getDefaultShippingCost(shippings, user, boxes)
                        )
                      )
                    )}
                    €
                  </Text>
                  <Text style={styles.featuresText}>
                    {t("TextRepurchasePriceSubtitle")}
                  </Text>
                  <Divider style={{ marginVertical: 5 }} />
                  <Card.Content style={styles.featuresAddonsContainer}>
                    <Text style={styles.featuresAddonsTitle}>
                      {t("TextRepurchaseAddonsTitle")}
                    </Text>
                    <View style={{ width: "fit-content", alignSelf: "center" }}>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage1")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextShipping")}{" "}
                          {parseFloat(
                            getDefaultShippingCost(shippings, user, boxes)
                          )}
                          €
                        </Text>
                      </View>
                    </View>
                  </Card.Content>
                  <Button
                    style={[styles.actionButton, { alignSelf: "center" }]}
                    mode={"contained"}
                    loading={loading}
                    disabled={subscription.tier === 1}
                    onPress={async () => onUpdateSubscription(1)}
                  >
                    {t("TextSelect")}
                  </Button>
                </Card>
                <Card
                  style={[
                    styles.featuresContainer,
                    subscription.tier === 2 ? styles.successBorder : {},
                  ]}
                  elevation={subscription.tier == 2 ? 4 : 1}
                >
                  <Text style={styles.discountSticker}>
                    {`-${animals.length > 1 ? 15 : 10}%`}
                  </Text>
                  <Text style={styles.featuresTitle}>
                    {t("TextRepurchasePriceTime2")}
                  </Text>
                  <Text style={styles.featuresPrice}>
                    {parseFloat(
                      getTotalWithFakeDiscountAndWithShipping(
                        boxes,
                        0,
                        animals.length > 1 ? 15 : 10
                      )
                    ).toFixed(2)}
                    €
                  </Text>
                  <Text style={styles.featuresText}>
                    {t("TextRepurchasePriceSubtitle")}
                  </Text>
                  <Divider style={{ marginVertical: 5 }} />
                  <Card.Content style={styles.featuresAddonsContainer}>
                    <Text style={styles.featuresAddonsTitle}>
                      {t("TextRepurchaseAddonsTitle")}
                    </Text>
                    <View style={{ width: "fit-content", alignSelf: "center" }}>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage1")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage2")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage3")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage4")}
                        </Text>
                      </View>
                    </View>
                  </Card.Content>
                  <Button
                    style={[styles.actionButton, { alignSelf: "center" }]}
                    mode={"contained"}
                    loading={loading}
                    disabled={subscription.tier === 2}
                    onPress={async () => onUpdateSubscription(2)}
                  >
                    {t("TextSelect")}
                  </Button>
                </Card>
                <Card
                  style={[
                    styles.featuresContainer,
                    subscription.tier === 3 ? styles.successBorder : {},
                  ]}
                  elevation={subscription.tier == 3 ? 4 : 1}
                >
                  <Text style={styles.discountSticker}>
                    {`-${animals.length > 1 ? 25 : 20}%`}
                  </Text>
                  <Text style={styles.featuresTitle}>
                    {t("TextRepurchasePriceTime3")}
                  </Text>
                  <Text style={styles.featuresPrice}>
                    {parseFloat(
                      getTotalWithFakeDiscountAndWithShipping(
                        boxes,
                        0,
                        animals.length > 1 ? 25 : 20
                      )
                    ).toFixed(2)}
                    €
                  </Text>
                  <Text style={styles.featuresText}>
                    {t("TextRepurchasePriceSubtitle")}
                  </Text>
                  <Divider style={{ marginVertical: 5 }} />
                  <Card.Content style={styles.featuresAddonsContainer}>
                    <Text style={styles.featuresAddonsTitle}>
                      {t("TextRepurchaseAddonsTitle")}
                    </Text>
                    <View style={{ width: "fit-content", alignSelf: "center" }}>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage1")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage2")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage3")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage4")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage5")}
                        </Text>
                      </View>
                      <View style={styles.featuresAddon}>
                        <Avatar.Icon
                          size={moderateScale(20)}
                          color={theme.colors.onSurfaceVariant}
                          style={styles.promoImageInfoListItemIcon}
                          icon={"check"}
                        />
                        <Text style={styles.featuresAddonsText}>
                          {t("TextRepurchasePriceAdvantage6")}
                        </Text>
                      </View>
                    </View>
                  </Card.Content>
                  <Button
                    style={[styles.actionButton, { alignSelf: "center" }]}
                    mode={"contained"}
                    loading={loading}
                    disabled={subscription.tier === 3}
                    onPress={async () => onUpdateSubscription(3)}
                  >
                    {t("TextSelect")}
                  </Button>
                </Card>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Main);
