import Icon from "@/common/components/Icon";
import { useBreakpoint } from "@/common/hooks";
import { updateAll as updateAnimals } from "@/redux/reducers/Animals";
import { updateAll as updateBoxes } from "@/redux/reducers/Boxes";
import { update as updateCountries } from "@/redux/reducers/Countries";
import { update as updateDeliveries } from "@/redux/reducers/Deliveries";
import { update as updateLanguages } from "@/redux/reducers/Languages";
import { goToSlide } from "@/redux/reducers/Order";
import { update as updateShippings } from "@/redux/reducers/Shippings";
import { update as updateSubscription } from "@/redux/reducers/Subscription";
import { logout } from "@/redux/reducers/User";
import { getAnimals } from "@/services/animals.service";
import { getBoxes } from "@/services/boxes.service";
import { getCountries } from "@/services/countries.service";
import { getDeliveries } from "@/services/deliveries.service";
import { getLanguages } from "@/services/languages.service";
import { getShipping } from "@/services/shipping.service";
import { getSubscription } from "@/services/subscription.service";
import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { RootTabParamList } from "@/types";
import Checkout from "@/views/Checkout";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Drawer from "../components/Drawer";
import { getIconBasedOfScreen } from "../helpers";
import AnimalsNavigator from "./animals";
import DashboardNavigator from "./dashboard";
import ExplorerNavigator from "./explorer";
import MoreNavigator from "./more";

const RootBottomTab = createBottomTabNavigator<RootTabParamList>();
const RootSideTab = createDrawerNavigator<RootTabParamList>();
const EmptyComponent = (props) => {
  return null;
};
export default function RootTabNavigator() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mobile } = useBreakpoint();
  const user = useSelector((state: RootState) => state.user);
  const [ready, setReady] = useState(false);
  useEffect(() => {
    Promise.all([
      getAnimals(user.accessToken),
      getBoxes(user.accessToken),
      getSubscription(user.accessToken),
      getShipping(user.accessToken),
      getCountries(user.accessToken),
      getLanguages(user.accessToken),
    ])
      .then((response) => {
        dispatch(updateAnimals(response[0].data));
        dispatch(updateBoxes(response[1].data));
        dispatch(updateSubscription(response[2].data));
        dispatch(updateShippings(response[3].data));
        dispatch(updateCountries(response[4].data));
        dispatch(updateLanguages(response[5].data));
        if (response[2].data) {
          Promise.all([
            getDeliveries(user.accessToken, response[2].data._id),
          ]).then((response2) => {
            dispatch(updateDeliveries(response2[0].data));
            const latestDelivery = response2[0].data.reduce(function (r, a) {
              return r.delivery_date > a.delivery_date ? r : a;
            });
            if (
              response[2].data.active &&
              response[2].data.payment.type === "subscription" &&
              latestDelivery.status === "delivered"
            ) {
              dispatch(goToSlide(4));
            } else if (response[2].data.payment.status === "pending") {
              dispatch(goToSlide(3));
            } else if (
              latestDelivery.status === "delivered" ||
              latestDelivery.status === "failed"
            ) {
              dispatch(goToSlide(0));
              return;
            }
          });
        }
        setReady(true);
      })
      .catch((error) => {
        if (error.response.status !== 200) {
          dispatch(logout());
        }
        console.log(error);
      });
  }, []);

  if (!ready) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator animating={true} size={"large"} />
      </View>
    );
  }
  if (!mobile) {
    return (
      <RootSideTab.Navigator
        screenOptions={({ route }) => {
          return {
            drawerType: "permanent",
            drawerLabel: ({ focused, color }) => {
              return (
                <Text variant="labelLarge" style={{ color: color }}>
                  {t("Navbar" + route.name + "Title")}
                </Text>
              );
            },
            drawerIcon: ({ focused, color }) => {
              return (
                <Icon
                  name={getIconBasedOfScreen(route.name)}
                  color={color}
                  size={moderateScale(25)}
                />
              );
            },
            drawerActiveBackgroundColor: theme.colors.surfaceVariant,
            headerShown: false,
            drawerStyle: {
              backgroundColor: theme.colors.background,
              width: moderateScale(250),
            },
          };
        }}
        drawerContent={(props) => {
          return <Drawer props={props} />;
        }}
      >
        <RootSideTab.Screen name="Dashboard" component={DashboardNavigator} />
        <RootSideTab.Screen name="Explorer" component={ExplorerNavigator} />
        {/* <RootSideTab.Screen
        name="Checkout"
        component={EmptyComponent}
        options={{
          tabBarButton: () => <Checkout />,
        }}
      /> */}
        <RootSideTab.Screen name="Animals" component={AnimalsNavigator} />
        <RootSideTab.Screen name="More" component={MoreNavigator} />
      </RootSideTab.Navigator>
    );
  }

  return (
    <RootBottomTab.Navigator
      screenOptions={({ route }) => {
        return {
          tabBarIcon: ({ focused, color, size }) => {
            return (
              <Icon
                name={getIconBasedOfScreen(route.name)}
                color={color}
                size={moderateScale(25)}
              />
            );
          },
          tabBarStyle: {
            marginBottom: 10,
            marginHorizontal: 10,
            borderRadius: BORDER_RADIUS,
            borderTop: "none",
            ...theme.shadows.mainBoxShadow,
          },
          tabBarShowLabel: false,
          headerShown: false,
        };
      }}
    >
      <RootBottomTab.Screen name="Dashboard" component={DashboardNavigator} />
      <RootBottomTab.Screen name="Explorer" component={ExplorerNavigator} />
      <RootBottomTab.Screen
        name="Checkout"
        component={EmptyComponent}
        options={{
          tabBarButton: () => <Checkout />,
        }}
      />
      <RootBottomTab.Screen name="Animals" component={AnimalsNavigator} />
      <RootBottomTab.Screen name="More" component={MoreNavigator} />
    </RootBottomTab.Navigator>
  );
}
