import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  container: {
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.background,
    height: "100%",
  },
  backgroundEffectContainer: {
    width: "100%",
    height: "80%",
    margin: 0,
    backgroundColor: theme.colors.surfaceVariant,
  },
  inputContainer: { marginBottom: 10 },
  firstInputContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  titleContainer: {
    flex: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  title: {
    textAlign: "center",
  },
  ageTitle: {
    marginBottom: 10,
  },
  imageInputContainer: {},
  inputOutline: { borderRadius: BORDER_RADIUS },
  background: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.background,
    borderBottomRightRadius: 100,
    overflowX: "hidden",
  },
  twoSplit: { width: "48%" },
  formContainer: {
    width: "100%",
    paddingHorizontal: 30,
    maxWidth: 1200,
    alignSelf: "center",
  },
  row: { flexDirection: "row", justifyContent: "space-between" },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 10,
    justifyContent: "space-between",
  },
  button: { width: "30%" },
  animalName: { alignSelf: "flex-start", marginBottom: 5 },
  buttonImage: { width: 50, height: 50 },
  buttonLabel: { ...theme.fonts.labelSmall },
});
