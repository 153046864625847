import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions/index";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
  },
  input: {
    height: moderateScale(35),
    backgroundColor: theme.colors.surfaceVariant,
    color: theme.colors.onSurfaceVariant,
    ...theme.fonts.labelLarge,
  },
  outlineStyle: { borderRadius: BORDER_RADIUS },
  noBorder: { borderWidth: 0 },
  errorText: { marginLeft: 5 },
  icon: { top: 5, right: -10 },
});
