import Background from "@/common/components/Background";
import HelpFAB from "@/common/components/HelpFAB";
import DashboardModule from "@/modules/Dashboard";
import React, { memo } from "react";
import { ImageBackground, ScrollView } from "react-native";
import styles from "./styles";
const Dashboard = () => {
  return (
    <Background>
      <ImageBackground
        style={styles.imageBackground}
        source={require("@/assets/images/logo_transparent.png")}
        imageStyle={styles.repeatImage}
      >
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollableContainer}
        >
          <DashboardModule />
        </ScrollView>
        <HelpFAB />
      </ImageBackground>
    </Background>
  );
};

export default memo(Dashboard);
