import { moderateScale } from "@/theme/dimensions";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Platform, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Avatar, useTheme } from "react-native-paper";
import styles from "./styles";
const ImageInput = ({ ...props }) => {
  const [images, setImages] = useState([]);
  const [loadingImages, setLoadingImages] = useState(false);
  props.imagesLoading = loadingImages;
  const { t } = useTranslation();
  const theme = useTheme();
  let canAccess = false;
  const pickImage = async () => {
    if (Platform.OS !== "web") {
      const { status } =
        await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status !== "granted") {
        props.toast.show(t("NotificationNeedCameraPermissions"), {
          type: "danger",
        });
      } else {
        canAccess = true;
      }
    } else {
      canAccess = true;
    }
    if (canAccess) {
      setLoadingImages(true);
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        base64: false,
        aspect: [4, 3],
        // maxWidth: 200,
        // maxHeight: 200,
        quality: 0.1,
      });
      if (!result.canceled) {
        if (result.assets[0].uri) {
          const manipResult = await ImageManipulator.manipulateAsync(
            result.assets[0].uri,
            [{ resize: { width: 500 } }],
            { compress: 0.5, format: ImageManipulator.SaveFormat.JPEG }
          );
          if (Platform.OS !== "web") {
            const uriParts = manipResult.uri.split(".");
            let my_image = {
              name: new Date().getTime() + "_animal",
              uri: manipResult.uri,
              type: uriParts[uriParts.length - 1],
            };
            props.interaction([my_image]);
            setImages([my_image]);
          } else {
            props.interaction([manipResult]);
            setImages([manipResult]);
          }
        } else {
          props.interaction([result]);
          setImages([result]);
        }
        setLoadingImages(false);
      } else {
        setLoadingImages(false);
      }
    }
  };
  return (
    <View style={[styles.container, props.containerStyle]}>
      {images.length > 0 ? (
        images.map((image, index) => (
          <Image
            key={"image_" + index}
            source={{ uri: image.uri }}
            style={styles.image}
          />
        ))
      ) : (
        <TouchableOpacity onPress={pickImage} style={styles.input}>
          {loadingImages ? (
            <ActivityIndicator
              style={styles.activityIndicator}
              animating={true}
              size="large"
              color={theme.colors.background}
            />
          ) : (
            <Avatar.Icon
              style={[styles.imageInputLabelImage]}
              size={moderateScale(60)}
              color={theme.colors.primary}
              icon="camera"
            />
          )}
        </TouchableOpacity>
      )}
    </View>
  );
};

export default memo(ImageInput);
