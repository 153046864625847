import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Image, Platform, ScrollView, View } from "react-native";
import { Avatar, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";
const How = () => {
  const { t, i18n } = useTranslation();
  const animals = useSelector((state: RootState) => state.animals);
  const getNamesOfExperimentalPets = (type: number) => {
    let experimental_animals = animals;
    let custom_text = experimental_animals.map((animal, index) => {
      if (index != 0 && index === animals.length - 1) {
        return (
          <Text key={"name_of_animal_list" + index}>
            {" "}
            {t("TextAnd")}{" "}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            <Text style={styles.animalName}>{animal.name}</Text>
          </Text>
        );
      } else {
        return (
          <Text key={"name_of_animal_list" + index}>
            {index !== 0 ? ", " : ""}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            {<Text style={styles.animalName}>{animal.name}</Text>}
          </Text>
        );
      }
    });
    return custom_text;
  };

  const renderTrustListItem = ({ item }) => (
    <View style={[styles.trustListItem]}>
      <Text style={[styles.processHorizontalListItemTitle]}>
        {
          item.title[
            i18n.language.slice(0, 2) && i18n.language.slice(0, 2) !== "en"
              ? i18n.language.slice(0, 2)
              : "default"
          ]
        }
      </Text>
      <View
        style={{
          flexDirection: "row",
          marginVertical: 5,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Avatar.Icon
          style={{ backgroundColor: "transparent", marginRight: 2 }}
          icon={"star"}
          size={moderateScale(40)}
          color={"#e0c200"}
        />
        <Avatar.Icon
          style={{ backgroundColor: "transparent", marginRight: 2 }}
          icon={"star"}
          size={moderateScale(40)}
          color={"#e0c200"}
        />
        <Avatar.Icon
          style={{ backgroundColor: "transparent", marginRight: 2 }}
          icon={"star"}
          size={moderateScale(40)}
          color={"#e0c200"}
        />
        <Avatar.Icon
          style={{ backgroundColor: "transparent", marginRight: 2 }}
          icon={"star"}
          size={moderateScale(40)}
          color={"#e0c200"}
        />
        <Avatar.Icon
          style={{ backgroundColor: "transparent", marginRight: 2 }}
          icon={"star"}
          size={moderateScale(40)}
          color={"#e0c200"}
        />
      </View>
      <Text style={[styles.trustListItemText]}>{`"${item.text}"`}</Text>
      <Image style={[styles.trustListImage]} source={item.image} />
    </View>
  );

  return (
    <View style={[styles.processContainer]}>
      <Text variant={"titleSmall"} style={[styles.whyTitle]}>
        {`${t("TextFirstTimeTrustListTitle")} `}
        <Text>{getNamesOfExperimentalPets(1)}</Text>
        {` ${
          animals.length > 1
            ? t("TextFirstTimeTrustListTitle3")
            : t("TextFirstTimeTrustListTitle2")
        }`}
      </Text>
      <Text style={[styles.whySubtitle]}>
        {`${t("TextFirstTimeTrustListSubtitle")}`}
      </Text>
      <ScrollView
        contentContainerStyle={{
          marginHorizontal: "auto",
        }}
        horizontal={true}
        showsHorizontalScrollIndicator={Platform.OS === "web"}
      >
        <FlatList
          style={{ marginHorizontal: "auto" }}
          contentContainerStyle={[styles.trustList]}
          data={[
            {
              id: "trust_list_1",
              image: require("@/assets/images/gatos_purrmi_sofia.png"),
              title: t("TextFirstTimeTrustListItemTitle2"),
              text: t("TextFirstTimeTrustListItemText2"),
            },
            {
              id: "trust_list_2",
              image: require("@/assets/images/gatos_purrmi_vanessa.png"),
              title: t("TextFirstTimeTrustListItemTitle"),
              text: t("TextFirstTimeTrustListItemText"),
            },
            {
              id: "trust_list_3",
              image: require("@/assets/images/gatos_purrmi_veronica.png"),
              title: t("TextFirstTimeTrustListItemTitle3"),
              text: t("TextFirstTimeTrustListItemText3"),
            },
            {
              id: "trust_list_4",
              image: require("@/assets/images/gatos_purrmi_patricia.png"),
              title: t("TextFirstTimeTrustListItemTitle4"),
              text: t("TextFirstTimeTrustListItemText4"),
            },
          ]}
          scrollEnabled={Platform.OS == "web" ? true : false}
          horizontal={true}
          renderItem={renderTrustListItem}
          keyExtractor={(item) => item.id}
        />
      </ScrollView>
    </View>
  );
};

export default memo(How);
