import { Button } from "@/common/components/Button";
import { capitalizeFirstLetter } from "@/common/utils";
import React, { memo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlatList, Image, Pressable, View } from "react-native";
import Modal from "react-native-modal";
import { Text } from "react-native-paper";
import { useSelector } from "react-redux";
import { animated as Animated } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const PromptModal = ({
  visible,
  toggle,
  addProductToCart,
  loading,
}: {
  visible: boolean;
  toggle: () => void;
  addProductToCart: (animal: string) => void;
  loading: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [selectedAnimal, setSelectedAnimal] = useState<string>("");
  const swiperRef = useRef(null);

  const renderItem = ({ item, index }: { item: Animal; index: number }) => (
    <View key={"swiper_animal_choice_" + index} style={[styles.slideContainer]}>
      <Pressable
        style={[
          styles.imageContainer,
          selectedAnimal === item._id ? styles.active : {},
        ]}
        onPress={() => setSelectedAnimal(item._id)}
      >
        <Image
          style={styles.image}
          source={require("@/assets/images/cover.png")}
        />
      </Pressable>
      <Text style={styles.animalName} variant="bodySmall">
        {item.name}
      </Text>
    </View>
  );
  return (
    <>
      <Modal
        backdropOpacity={0.3}
        isVisible={visible}
        onBackdropPress={toggle}
        style={styles.contentView}
      >
        <View style={styles.content}>
          <Text style={styles.title} variant="bodyLarge">
            {capitalizeFirstLetter(t("DialogChooseAnimalTitle"))}
          </Text>
          <Text style={styles.subtitle} variant="labelSmall">
            {t("DialogChooseAnimalSubtitle").toUpperCase()}
          </Text>
          <View style={styles.listContainer}>
            <FlatList
              ref={swiperRef}
              data={animals}
              snapToAlignment={"center"}
              showsHorizontalScrollIndicator={false}
              renderItem={renderItem}
              initialScrollIndex={0}
              decelerationRate={"fast"}
              horizontal
              keyExtractor={(item, index) => "swiper_animals_choice_" + index}
            />
          </View>
          <Button
            mode="contained"
            icon={"package-variant"}
            loading={loading}
            disabled={selectedAnimal === ""}
            onPress={() => addProductToCart(selectedAnimal)}
          >
            {t("TextAdd")}
          </Button>
        </View>
      </Modal>
    </>
  );
};

export default memo(PromptModal);
