import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

const CUSTOM_BORDER_RADIUS = 30;

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 50,
  },
  animatedContainer: {
    width: "100%",
  },
  listContainer: {
    width: "100%",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
  },
  row: {
    flexDirection: "row",
  },
  noProductsContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  noProductsCard: {
    paddingHorizontal: 25,
    paddingVertical: 15,
  },
  noProductsTitle: {
    width: "100%",
    textAlign: "center",
    marginBottom: 10,
  },
  imageContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: moderateScale(150),
    height: moderateScale(150),
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
  },
  cardContainer: {},
  tagContainer: {
    flexDirection: "row",
    marginTop: 5,
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  tag: {
    paddingHorizontal: 5,
    paddingVertical: 1,
    marginRight: 3,
    backgroundColor: theme.colors.surfaceVariant,
    color: theme.colors.onSurfaceVariant,
    borderRadius: BORDER_RADIUS,
  },
  tagDiscount: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: theme.colors.error,
    color: theme.colors.onError,
    borderRadius: BORDER_RADIUS,
  },
  tagIcon: {
    marginRight: 3,
    color: theme.colors.onError,
  },
  infoGreetings: {
    marginVertical: 15,
  },
  productTitle: {
    marginTop: 5,
    textAlign: "center",
  },
  productPriceStrike: {
    marginTop: 5,
    textDecorationLine: "line-through",
    textAlign: "center",
  },
  productPrice: {
    fontWeight: "bold",
    textAlign: "center",
  },
  card: {
    borderRadius: BORDER_RADIUS,
    cursor: "pointer",
    width: moderateScale(150),
    minHeight: moderateScale(280),
  },
});
