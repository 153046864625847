import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  icon: {},
  imageContainer: {
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: moderateScale(250),
    height: moderateScale(250),
    borderRadius: BORDER_RADIUS,
  },
  slideProductContainer: {
    borderRadius: BORDER_RADIUS,
    alignItems: "center",
    justifyContent: "center",
  },
  pagination: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: 20,
  },
  paginationButton: {
    height: 5,
    width: 35,
    marginHorizontal: 2,
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.surfaceVariant,
  },
  activePaginationButton: {
    height: 5,
    width: 50,
    marginHorizontal: 2,
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.onSurfaceVariant,
  },
});
