import { moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    padding: 30,
  },
  termsTitle: { marginVertical: 20 },
  title: { marginVertical: 15, textAlign: "center" },
  termsParagraph: { textAlign: "justify" },
  paymentImage: { width: moderateScale(300), height: moderateScale(97) },
});
