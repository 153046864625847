import { LanguageDropdown } from "@/common/components/Dropdown";
import Icon from "@/common/components/Icon";
import Logo from "@/common/components/Svgs/Logo";
import { useBreakpoint } from "@/common/hooks";
import { capitalizeFirstLetter } from "@/common/utils";
import {
  changeSelectedAnimal,
  changeTypeAndOpen,
} from "@/redux/reducers/Animal";
import { remove } from "@/redux/reducers/Animals";
import { deleteUserAnimal } from "@/services/animals.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import moment from "moment";
import React, { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, Surface, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, config, useSprings } from "react-spring";
import { getRelativeScale } from "../../helpers";
import styles from "./styles";
const AnimatedView = Animated(View);
const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { mobile } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const animalForm = useSelector((state: RootState) => state.animalForm);
  const selectedAnimalIndex =
    animals.length === 0
      ? 0
      : animals.findIndex((a) => animalForm.selectedAnimal._id === a._id);
  const [springs] = useSprings(
    animals.length + 1,
    (i) => ({
      transform: `translate(${
        (i - selectedAnimalIndex) * 120
      }%,0) scale(${getRelativeScale(selectedAnimalIndex, i)})`,
      from: { transform: `translate(500%,0) scale(1)` },
      config: config.stiff,
    }),
    [selectedAnimalIndex]
  );
  const onDelete = () => {
    deleteUserAnimal(user.accessToken, animalForm.selectedAnimal._id)
      .then(() => {
        dispatch(remove(animalForm.selectedAnimal));
        dispatch(changeSelectedAnimal(animals[0]));
        setLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setLoading(false);
      });
  };
  return (
    <View style={styles.container}>
      <View style={styles.topBarContainer}>
        <View style={styles.logoContainer}>
          {mobile && <Logo style={styles.logo} />}
        </View>
        <View style={styles.actionsContainer}>
          <LanguageDropdown />
          <IconButton
            style={styles.notificationIcon}
            icon="bell-outline"
            size={moderateScale(25)}
          />
        </View>
      </View>
      <View style={styles.bannerContainer}>
        <View style={[styles.banner, mobile && { width: "100%" }]}>
          {animals.length === 0 && (
            <Text style={styles.noAnimalsAdded} variant="bodySmall">
              {t("TextAddACat")}
            </Text>
          )}
          {animals.length > 0 && (
            <Fragment>
              <View style={styles.infoContainer}>
                <View style={styles.infoPropertyContainer}>
                  <Icon
                    style={styles.infoPropertyIcon}
                    name={"calendar"}
                    size={moderateScale(20)}
                  />
                  <Text style={styles.infoPropertyText} variant="labelMedium">
                    {moment().diff(
                      moment(animalForm.selectedAnimal.birthday),
                      "years"
                    ) < 1
                      ? t("TextKittenCat")
                      : moment().diff(
                          moment(animalForm.selectedAnimal.birthday),
                          "years"
                        ) >= 7
                      ? t("TextSeniorCat")
                      : t("TextAdultCat")}
                  </Text>
                </View>
                <View style={styles.infoPropertyContainer}>
                  <Icon
                    style={styles.infoPropertyIcon}
                    name={"gender-male-female"}
                    size={moderateScale(20)}
                  />
                  <Text style={styles.infoPropertyText} variant="labelMedium">
                    {t(
                      `Text${capitalizeFirstLetter(
                        animalForm.selectedAnimal.sex
                      )}`
                    )}
                  </Text>
                </View>
                <Text
                  style={styles.infoGreetings}
                  variant={"labelLarge"}
                  numberOfLines={1}
                >
                  {animalForm.selectedAnimal.name}
                </Text>
                <View style={styles.infoPropertyContainer}>
                  <Icon
                    style={styles.infoPropertyIcon}
                    name={"weight-kilogram"}
                    size={moderateScale(20)}
                  />
                  <Text style={styles.infoPropertyText} variant="labelMedium">
                    {`${animalForm.selectedAnimal.weight} kg`}
                  </Text>
                </View>
                <View style={styles.infoPropertyContainer}>
                  <Icon
                    style={styles.infoPropertyIcon}
                    name={"thumb-up"}
                    size={moderateScale(20)}
                  />
                  <Text style={styles.infoPropertyText} variant="labelMedium">
                    {t("TextNone")}
                  </Text>
                </View>
              </View>
              <Surface style={styles.infoFoodContainer}>
                <Text style={styles.infoFoodText} variant="labelMedium">
                  Purrmi {t("TextPerDay")}
                </Text>
                <Text style={styles.infoFoodText} variant="bodySmall">
                  {`${
                    parseFloat(
                      animalForm.selectedAnimal.recommended_food_weight
                    ) * 1000
                  }`}
                  {animalForm.selectedAnimal.recommended_food_weight ==
                  "Conforme o apetite"
                    ? ""
                    : `g`}
                </Text>
              </Surface>
            </Fragment>
          )}
          {springs.map((style, index) => (
            <AnimatedView
              key={"Animal_Buttons_" + index}
              style={{ ...style, ...styles.mainIconContainer }}
            >
              <IconButton
                style={{ ...styles.icon }}
                icon={animals.length === index ? "plus" : "cat"}
                size={moderateScale(35)}
                iconColor={theme.colors.onPrimary}
                onPress={() =>
                  animals.length === index
                    ? dispatch(changeTypeAndOpen("create"))
                    : dispatch(changeSelectedAnimal(animals[index]))
                }
              />
              {animals.length > 0 && index === selectedAnimalIndex && (
                <Fragment>
                  <IconButton
                    style={styles.editIcon}
                    icon={"pencil"}
                    iconColor={theme.colors.onSecondary}
                    size={moderateScale(10)}
                    onPress={() => dispatch(changeTypeAndOpen("edit"))}
                  />
                  <IconButton
                    style={styles.deleteIcon}
                    icon={"delete"}
                    disabled={loading}
                    iconColor={theme.colors.onErrorContainer}
                    size={moderateScale(10)}
                    onPress={() => onDelete()}
                  />
                </Fragment>
              )}
            </AnimatedView>
          ))}
        </View>
      </View>
    </View>
  );
};

export default memo(Header);
