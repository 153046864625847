import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const boxesSlice = createSlice({
  name: "boxes",
  initialState: [] as Box[],
  reducers: {
    updateAll(state, action: PayloadAction<Box[]>) {
      return [...action.payload];
    },
    update(state, action: PayloadAction<Box>) {
      let boxIndex = state.findIndex(
        (box) => box.animal == action.payload.animal
      );
      let new_state = state.slice();
      new_state[boxIndex] = action.payload;
      return new_state;
    },
    remove(
      state,
      action: PayloadAction<{
        animalId: string;
        productId: string;
      }>
    ) {
      let box = state.find((b) => b.animal === action.payload.animalId);
      box.products = box.products.filter(
        (item) => item.product._id !== action.payload.productId
      );
    },
    add(state, action: PayloadAction<Box>) {
      state.push(action.payload);
    },
    updateProductQuantity(
      state,
      action: PayloadAction<{
        animalId: string;
        productId: string;
        quantity: number;
      }>
    ) {
      let box = state.find((b) => b.animal === action.payload.animalId);
      let box_product = box.products.find(
        (bp) => bp.product._id === action.payload.productId
      );
      if (box_product) {
        box_product.quantity = action.payload.quantity;
      } else {
        box.products.push();
      }
    },
  },
});

export const { add, update, updateAll, remove, updateProductQuantity } =
  boxesSlice.actions;
export default boxesSlice.reducer;
