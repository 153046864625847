import * as Localization from "expo-localization";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationDe from "./locales/de.json";
import translationEn from "./locales/en.json";
import translationEs from "./locales/es.json";
import translationPt from "./locales/pt.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: "v3",
    lng: Localization.locale,
    fallbackLng: "en",
    keySeparator: false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      pt: {
        translations: translationPt,
      },
      en: {
        translations: translationEn,
      },
      de: {
        translations: translationDe,
      },
      es: {
        translations: translationEs,
      },
      ca: {
        translations: translationEs,
      },
      gl: {
        translations: translationEs,
      },
      eu: {
        translations: translationEs,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

export default i18n;
