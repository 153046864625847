import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
    marginBottom: 50,
  },
  areaTitle: {
    marginBottom: 10,
  },
  userInfoContainer: {
    width: "100%",
    marginVertical: 10,
  },
  addressInfoContainer: {
    width: "100%",
    marginVertical: 10,
  },
  changePasswordContainer: {
    width: "100%",
    marginVertical: 10,
  },
  inputContainer: { marginBottom: 10 },
  infoContainer: {
    flexDirection: "row",
    paddingHorizontal: 3,
    marginBottom: 10,
  },
  radioButtonContainer: {
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
  },
  title: { marginVertical: 15, textAlign: "center" },
  radioButtonTitle: { color: theme.colors.onSurfaceVariant },
  inputOutline: { borderRadius: BORDER_RADIUS },
  twoSplit: { width: "48%" },
  row: { flexDirection: "row", justifyContent: "space-between" },
  checkpointInfoContainer: { flexDirection: "row", paddingHorizontal: 3 },
  checkpointInfoIcon: { marginTop: 3, marginRight: 3 },
});
