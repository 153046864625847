import { Button, ImageButton } from "@/common/components/Button";
import { updateValuesAndGoNext } from "@/redux/reducers/Register";
import React, { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Chip, Text } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { validateForm } from "../../../helpers";
import styles from "./styles";

const Weight = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const [state, setState] = useState({
    values: starting_form.values.map((a) => {
      return { ...a };
    }),
    errors: [],
  });

  const onSelectButton = (index: number, type: string) => {
    setState({
      ...state,
      values: state.values.map((form, jndex) => {
        if (index === jndex) {
          form.weight = type;
        }
        return form;
      }),
      errors: validateForm(state.values).map((errors, jndex) => {
        if (state.errors[jndex] && index === jndex) {
          return errors;
        } else if (state.errors[jndex]) {
          return state.errors[jndex];
        }
      }),
    });
  };

  const onUpdateStartingForm = () => {
    let errors = validateForm(state.values);
    if (!errors.some((form_errors) => form_errors.weight)) {
      dispatch(
        updateValuesAndGoNext({
          slide: starting_form.slide + 1,
          values: state.values,
        })
      );
    } else {
      setState({
        ...state,
        errors: errors,
      });
    }
  };

  return (
    <View style={styles.container}>
      <Text variant="bodySmall" style={styles.title}>
        {state.values.length > 1
          ? t("TextGetStartedSubtitle9P")
          : t("TextGetStartedSubtitle9S")}
      </Text>
      <Text variant="labelMedium" style={styles.subtitle}>
        {t("TextGetStartedSubtitleSubtitle9")}
      </Text>
      {state.values.map((form, index) => (
        <Fragment key={"Form_Age_" + index}>
          <Chip style={styles.animalName} icon="paw">
            {form.name}
          </Chip>
          <View style={styles.buttonContainer}>
            <ImageButton
              mode={
                form.weight !== "" && form.weight === "2"
                  ? "contained"
                  : "outlined"
              }
              style={styles.button}
              imageStyle={styles.buttonImage}
              labelStyle={styles.buttonLabel}
              source={require("@/assets/images/icons/skinny.png")}
              onPress={() => onSelectButton(index, "2")}
            >
              {t("TextAnimalSlim")}
            </ImageButton>
            <ImageButton
              mode={
                form.weight !== "" && form.weight === "4"
                  ? "contained"
                  : "outlined"
              }
              style={styles.button}
              imageStyle={styles.buttonImage}
              labelStyle={styles.buttonLabel}
              source={require("@/assets/images/icons/normalCat.png")}
              onPress={() => onSelectButton(index, "4")}
            >
              {t("TextNormalCat")}
            </ImageButton>
            <ImageButton
              mode={
                form.weight !== "" && form.weight === "6"
                  ? "contained"
                  : "outlined"
              }
              style={styles.button}
              imageStyle={styles.buttonImage}
              labelStyle={styles.buttonLabel}
              source={require("@/assets/images/icons/chubby.png")}
              onPress={() => onSelectButton(index, "6")}
            >
              {t("TextFatCat")}
            </ImageButton>
          </View>
        </Fragment>
      ))}
      <Button
        mode="contained"
        style={{ width: "100%" }}
        disabled={state.values.some((a) => a.weight === "")}
        onPress={onUpdateStartingForm}
      >
        {t("TextContinue")}
      </Button>
    </View>
  );
};

export default memo(Weight);
