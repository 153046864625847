import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { Image, View } from "react-native";
import { Button as PaperButton, Text } from "react-native-paper";
import Icon from "../../Icon";
import styles from "./styles";

const ImageButton = ({ ...props }) => (
  <PaperButton {...props} compact={true} style={[styles.button, props.style]}>
    <View style={[styles.imageContainer, props.imageContainerStyle]}>
      {(props.url || props.source) && (
        <Image
          style={[styles.image, props.imageStyle]}
          source={props.url ? { uri: props.url } : props.source}
        />
      )}
      {props.name && (
        <Icon
          style={[styles.icon, props.iconStyle]}
          name={props.name}
          size={moderateScale(30)}
          color={props.iconColor || theme.colors.onPrimary}
        />
      )}
      {(props.url || props.source) && (
        <Text
          style={{
            color:
              props.mode === "contained"
                ? theme.colors.onPrimary
                : theme.colors.primary,
          }}
          variant="labelSmall"
        >
          {props.children}
        </Text>
      )}
    </View>
    {props.name && props.children}
  </PaperButton>
);

export default memo(ImageButton);
