import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {},
  toggleButton: {
    backgroundColor: "transparent",
    margin: 0,
  },
  flagContainer: {
    paddingHorizontal: 3,
    paddingVertical: 10,
  },
  flag: {
    borderRadius: 3,
  },
  dropdown: {
    position: "absolute",
    borderRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.background,
    ...theme.shadows.mainBoxShadow,
    zIndex: 10000,
  },
  overlay: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
  },
  option: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.surfaceDisabled,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
});
