import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  animalName: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  horizontalListImage: {
    borderRadius: BORDER_RADIUS,
    height: 200,
    width: 200,
  },
  processContainer: {
    maxWidth: 1200,
    paddingVertical: 60,
    width: "85%",
  },
  processHorizontalList: {
    marginTop: 10,
    width: "100%",
    justifyContent: "center",
  },
  processHorizontalListItem: {
    alignItems: "center",
    marginHorizontal: 15,
    width: 275,
  },
  processHorizontalListItemIcon: {
    backgroundcolor: theme.colors.secondary,
    borderRadius: BORDER_RADIUS,
  },
  processHorizontalListItemText: {
    ...theme.fonts.labelLarge,
    marginTop: 20,
    textAlign: "center",
  },
  processHorizontalListItemTitle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  processTitle: {
    fontWeight: "bold",
    marginBottom: 50,
    textAlign: "center",
    width: "100%",
  },
  whySubtitle: {
    marginBottom: 25,
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "100%",
  },
  whyTitle: {
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    width: "100%",
  },
});
