import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  button: {
    borderRadius: BORDER_RADIUS,
  },
  imageContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  image: { width: 50, height: 50 },
  icon: {},
});
