import { theme } from "@/theme";
import { BORDER_RADIUS, moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  animalName: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  discountText: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    color: theme.colors.onSurfaceVariant,
    fontSize: 14,
    fontWeight: "700",
    paddingHorizontal: 5,
    paddingVertical: 2,
  },
  featuresContainer: {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    padding: 5,
    marginTop: 20,
    width: 300,
  },
  featuresText: { ...theme.fonts.labelSmall, textAlign: "center" },
  featuresTitle: { ...theme.fonts.labelSmall, textAlign: "center" },
  featuresAddonsTitle: {
    ...theme.fonts.labelSmall,
    textAlign: "center",
    marginBottom: 5,
  },
  featuresPrice: {
    ...theme.fonts.displayLarge,
    textAlign: "center",
    paddingHorizontal: 10,
  },
  successBorder: {
    borderColor: theme.colors.secondary,
    borderWidth: 2,
  },
  featuresAddonsContainer: {
    width: 280,
  },
  actionButton: {
    maxWidth: 250,
    marginVertical: 10,
    width: "100%",
  },
  featuresAddon: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 5,
  },
  featuresAddonsText: {
    ...theme.fonts.labelSmall,
    textAlign: "center",
  },
  firstTimeCtaTitle: {
    marginBottom: 15,
  },
  firstTimeInfoTitle: {
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  header: {
    flexDirection: "column",
    maxWidth: 1200,
    paddingHorizontal: 20,
    width: "100%",
  },
  headerContainer: {
    alignItems: "center",
    width: "100%",
  },
  headerInfo: {
    alignItems: "center",
    width: "100%",
  },
  headerInfoDescription: {
    marginTop: 10,
    textAlign: "center",
    ...theme.fonts.labelLarge,
  },
  headerInfoList: {
    marginLeft: 0,
    paddingHorizontal: 20,
    alignSelf: "center",
    width: "100%",
  },
  headerInfoListItem: {
    alignItems: "center",
    marginTop: 10,
  },
  headerInfoListItemImage: {
    height: moderateScale(50),
    marginRight: 10,
    width: moderateScale(50),
  },
  headerInfoListItemTitle: { ...theme.fonts.labelLarge },
  headerInfoTitle: {
    fontWeight: "bold",
    marginTop: 15,
    textAlign: "center",
  },
  headerPromoBenefits: {
    alignItems: "flex-start",
    flexWrap: "wrap",
    flexDirection: "row",
    borderRadius: BORDER_RADIUS,
    justifyContent: "space-evenly",
    paddingVertical: 5,
    width: "100%",
  },
  headerPromoImage: {
    height: 130,
    width: 130,
  },
  headerPromoImages: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  headerPromoImagesContainer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    marginTop: 20,
    width: "100%",
  },
  headerPromoImagesContainerWrapper: {
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  horizontalListImage: {
    borderRadius: BORDER_RADIUS,
    height: 200,
    width: 200,
  },
  invertedSection: {
    alignItems: "center",
    backgroundColor: theme.colors.secondary + "10",
    justifyContent: "center",
    paddingVertical: 60,
    width: "100%",
  },
  invertedSectionFaq: {
    alignItems: "center",
    backgroundColor: theme.colors.secondary + "10",
    justifyContent: "center",
    marginBottom: 50,
    paddingHorizontal: 10,
    paddingVertical: 60,
    width: "100%",
  },
  processContainer: {
    maxWidth: 1200,
    paddingVertical: 60,
    width: "85%",
  },
  processHorizontalList: {
    marginTop: 10,
    width: "100%",
  },
  processHorizontalListItem: {
    alignItems: "center",
    marginHorizontal: 15,
    width: 350,
  },
  processHorizontalListItemIcon: {
    backgroundcolor: theme.colors.secondary,
    borderRadius: BORDER_RADIUS,
  },
  processHorizontalListItemText: {
    ...theme.fonts.labelLarge,
    color: theme.colors.primary,
    marginTop: 20,
    textAlign: "center",
  },
  processHorizontalListItemTitle: {
    color: theme.colors.primary,
    fontWeight: "bold",
    textAlign: "center",
  },
  processTitle: {
    fontWeight: "bold",
    marginBottom: 50,
    textAlign: "center",
    width: "100%",
  },
  productDiscountLabel: {
    fontWeight: "bold",
  },
  productDiscountLabelContainer: {
    backgroundColor: theme.colors.success,
    borderRadius: BORDER_RADIUS,
    marginHorizontal: 5,
    paddingHorizontal: 5,
    paddingVertical: 4,
    position: "absolute",
    right: 0,
    top: 0,
  },
  productDiscountLabelMobile: {
    fontWeight: "bold",
  },
  promoHeaderImage: {
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    height: moderateScale(150),
    minHeight: 150,
    width: moderateScale(150),
  },
  discountSticker: {
    position: "absolute",
    right: -20,
    top: -20,
    paddingVertical: 5,
    paddingHorizontal: 15,
    borderRadius: BORDER_RADIUS,
    color: theme.colors.onError,
    ...theme.fonts.bodyMedium,
    backgroundColor: theme.colors.error,
  },
  promoImageInfoList: {
    alignSelf: "center",
    width: "50%",
  },
  promoImageInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
  },
  promoImageInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  promoImageInfoListItemText: { ...theme.fonts.labelLarge },
  removeTransitionFoodButton: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  removeTransitionFoodButtonLabel: {
    color: theme.colors.primary,
    textDecorationLine: "underline",
    ...theme.fonts.labelLarge,
  },
  sandInput: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  sandInputCheckbox: {},
  sandInputContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    paddingHorizontal: 20,
    width: "100%",
  },
  sandInputText: {
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "80%",
  },
  scrollableViewContainer: {
    width: "100%",
  },
  sectionContainer: {
    justifyContent: "center",
  },
  stageWrapper: {
    height: "100%",
    width: "100%",
  },
  trustList: {
    marginTop: 10,
    width: "100%",
  },
  trustListImage: {
    borderRadius: BORDER_RADIUS,
    height: 320,
    width: 500,
  },
  trustListItem: {
    alignItems: "center",
    marginHorizontal: 15,
    width: 500,
  },
  trustListItemText: {
    ...theme.fonts.labelLarge,
    color: theme.colors.primary,
    marginBottom: 20,
    textAlign: "center",
  },
  vetContainer: {
    maxWidth: 900,
    width: "100%",
  },
  vetImage: {
    borderRadius: BORDER_RADIUS,
    height: 300,
    width: 300,
  },
  vetImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  vetInfoList: {
    marginLeft: 30,
  },
  vetInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 15,
  },
  vetInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  vetInfoListItemText: { ...theme.fonts.labelLarge, width: "80%" },
  vetRow: {
    alignItems: "center",
    flexDirection: "column-reverse",
    justifyContent: "center",
    marginTop: 50,
  },
  vetTextContainer: {
    marginTop: 20,
    width: "80%",
  },
  vetTextDescription: {
    ...theme.fonts.labelLarge,
    color: theme.colors.primary,
  },
  vetTextTitle: {
    fontWeight: "bold",
    marginBottom: 50,
    textAlign: "center",
    width: "100%",
  },
  whySubtitle: {
    marginBottom: 25,
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "100%",
  },
  whyTitle: {
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    width: "100%",
  },
});
