import { StyleSheet } from "react-native";
export default StyleSheet.create({
  container: {
    alignItems: "center",
    paddingVertical: 5,
  },
  invoiceButton: {
    marginVertical: 3,
  },
  invoiceButtonLabel: {
    marginVertical: 5,
  },
  invoiceContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  invoiceText: {
    marginBottom: 5,
    textAlign: "center",
  },
});
