import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { useBreakpoint } from "@/common/hooks";
import { globalStyles } from "@/common/styles";
import { isObjectEmpty } from "@/common/utils";
import { update } from "@/redux/reducers/Subscription";
import {
  cancelSubscription,
  resetSubscription,
} from "@/services/subscription.service";
import { moderateScale } from "@/theme/dimensions";
import React, { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Linking, Platform, View } from "react-native";
import { Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated } from "react-spring";
import styles from "./styles";
const AnimatedView = Animated(View);
const PaymentInfo = ({ toast }: { toast: ToastType }) => {
  const { t, i18n } = useTranslation();
  const { mobile } = useBreakpoint();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.orderForm);
  const user = useSelector((state: RootState) => state.user);
  const subscription = useSelector((state: RootState) => state.subscription);
  const [paymentType] = useState(subscription.payment?.method.type || "");
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingRetry, setLoadingRetry] = useState(false);
  const linkToVisaMcPlatform = () => {
    if (Platform.OS === "web") {
      window.open(subscription.payment.method.url, "_blank");
    } else {
      Linking.openURL(subscription.payment.method.url);
    }
  };

  const onPressToCancelPayment = () => {
    setLoadingCancel(true);
    cancelSubscription(user.accessToken, subscription._id)
      .then((response) => {
        dispatch(update(response.data.subscription));
        setLoadingCancel(false);
      })
      .catch((error) => {
        toast.show(t(`BackendNotification${error.response.data.message}`), {
          type: "danger",
        });
        console.error(error);
        setLoadingCancel(false);
      });
  };
  const onPressToRetryPayment = () => {
    setLoadingRetry(true);
    resetSubscription(user.accessToken, subscription._id, state.values)
      .then((response) => {
        dispatch(update(response.data.subscription));
        setLoadingRetry(false);
        toast.show(t(`NotificationPaymentSuccessfullyRestarted`), {
          type: "success",
        });
      })
      .catch((error) => {
        toast.show(t(`BackendNotification${error.response.data.message}`), {
          type: "danger",
        });
        console.error(error);
        setLoadingRetry(false);
      });
  };

  useEffect(() => {
    if (subscription && subscription.payment.method.url) {
      linkToVisaMcPlatform();
    }
  }, [subscription]);

  if (isObjectEmpty(subscription)) {
    return null;
  }

  return (
    <View style={styles.container}>
      <View style={styles.infoPaymentMethodContainer}>
        <Image
          style={styles.infoPaymentMethodImage}
          source={
            paymentType === "mb"
              ? require("@/assets/images/multibanco_logo.png")
              : paymentType === "mbw"
                ? require("@/assets/images/mbway_logo.png")
                : paymentType === "cc"
                  ? require("@/assets/images/visamc_logo.png")
                  : require("@/assets/images/debito_direto.png")
          }
        />
        <View style={styles.infoPaymentMethod}>
          {paymentType === "mb" ? (
            <View>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("TextEntity")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {subscription.payment.method.entity}
                </Text>
              </Text>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("TextReference")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {subscription.payment.method.reference}
                </Text>
              </Text>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("TextValue")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {subscription.payment.value}€
                </Text>
              </Text>
            </View>
          ) : paymentType === "mbw" ? (
            <View style={styles.infoPaymentMethodWrapper}>
              <Text variant="labelMedium">{t("TextWaitingPaymentFrom")} </Text>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("TextNumber")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {state.values.payment_phone}
                </Text>
              </Text>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("TextValue")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {subscription.payment.value}€
                </Text>
              </Text>
            </View>
          ) : paymentType === "cc" ? (
            <View style={styles.infoPaymentMethodWrapper}>
              <Button
                style={styles.ccButton}
                labelStyle={styles.ccButtonLabel}
                mode={"contained"}
                onPress={linkToVisaMcPlatform}
              >
                {t("TextPayCC")}
              </Button>
            </View>
          ) : paymentType === "dd" ? (
            <View style={styles.infoPaymentMethodWrapper}>
              <Text variant="labelMedium">
                {t("TextWaitingPaymentFromDd")}{" "}
              </Text>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("IBAN")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {state.values.payment_iban}
                </Text>
              </Text>
              <Text style={globalStyles.bold} variant="labelLarge">
                {t("TextValue")}:{" "}
                <Text style={globalStyles.normal} variant="labelLarge">
                  {subscription.payment.value}€
                </Text>
              </Text>
            </View>
          ) : (
            <Fragment />
          )}
        </View>
      </View>
      {paymentType === "cc" && (
        <View style={[globalStyles.row, styles.ccDescription]}>
          <Icon style={styles.infoIcon} name="lock" size={moderateScale(14)} />
          <Text variant="labelMedium">
            {t("TextWaitingPaymentFromCcDescription")}
          </Text>
        </View>
      )}
      {/* <View
        style={[
          styles.actionsContainer,
          mobile
            ? { flexDirection: "row", justifyContent: "space-evenly" }
            : { flex: 1 },
        ]}
      >
        <Button
          style={styles.actionCancelButton}
          labelStyle={styles.actionCancelButtonLabel}
          mode={"outlined"}
          loading={loadingCancel}
          disabled={loadingCancel || loadingRetry}
          onPress={onPressToCancelPayment}
        >
          {t("TextCancel")}
        </Button>
      </View> */}
    </View>
  );
};

export default memo(PaymentInfo);
