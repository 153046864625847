import { globalStyles } from "@/common/styles";
import { changeSelectedAnimal, toggle } from "@/redux/reducers/Animal";
import React, { memo, useEffect } from "react";
import { View } from "react-native";
import Modal from "react-native-modal";
import { Portal } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import Form from "./components/Form";
import Header from "./components/Header";
import Sections from "./components/Sections";
import styles from "./styles";

const AnimalsModule = () => {
  const animals = useSelector((state: RootState) => state.animals);
  const animalForm = useSelector((state: RootState) => state.animalForm);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!animalForm.selectedAnimal) dispatch(changeSelectedAnimal(animals[0]));
  }, []);
  if (!animalForm.selectedAnimal) return null;
  return (
    <View style={styles.container}>
      <Header />
      <View style={globalStyles.mobileContainer}>
        <Sections />
      </View>
      <Portal>
        <Modal
          backdropOpacity={0.3}
          isVisible={animalForm.open}
          onBackdropPress={() => dispatch(toggle())}
          style={styles.contentView}
        >
          {<Form />}
        </Modal>
      </Portal>
    </View>
  );
};

export default memo(AnimalsModule);
