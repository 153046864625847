import axios from "axios";
import i18n from "i18next";
import { baseAPIURL } from "./../config";

export function createSubscription(
  userToken: string,
  boxes: Box[],
  subscription: any
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/subscriptions`,
    data: {
      boxes,
      subscription,
      language: i18n.language.slice(0, 2),
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function updateSubscription(
  userToken: string,
  subscriptionId: string,
  boxes: Box[],
  subscription: any
) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/subscriptions/${subscriptionId}/update`,
    data: {
      boxes,
      subscription,
      language: i18n.language.slice(0, 2),
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}
export function updateSubscriptionTier(userToken: string, subscription: any) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/subscriptions/${subscription._id}/tier`,
    data: {
      subscription,
      language: i18n.language.slice(0, 2),
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function cancelSubscription(userToken: string, subscriptionId: string) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/subscriptions/${subscriptionId}/cancel`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function resetSubscription(
  userToken: string,
  subscriptionId: string,
  subscription: OrderFormValues
) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/subscriptions/${subscriptionId}/reset`,
    data: {
      subscription,
      language: i18n.language.slice(0, 2),
    },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function getSubscription(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/subscriptions`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function createPayment(
  userToken: string,
  subscription_id: string,
  payment_info: Payment
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/subscriptions/${subscription_id}/pay`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: payment_info,
  };

  return axios(config);
}
