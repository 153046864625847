import { Button as DefaultButton } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { googleSignUp as googleAnalyticsSignUp } from "@/common/utils/analytics.utils";
import {
  ANDROID_CLIENT_ID,
  EXPO_CLIENT_ID,
  IOS_CLIENT_ID,
  WEB_CLIENT_ID,
} from "@/config";
import { signin } from "@/redux/reducers/User";
import { googleSignUp } from "@/services/auth.service";
import { moderateScale } from "@/theme/dimensions";

import { RootStackParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import * as Google from "expo-auth-session/providers/google";
import * as WebBrowser from "expo-web-browser";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useTheme } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
WebBrowser.maybeCompleteAuthSession();
const Button = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const toast: any = useToast();
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { t } = useTranslation();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const [request, response, promptAsync] = Google.useAuthRequest({
    responseType: "id_token",
    expoClientId: EXPO_CLIENT_ID,
    iosClientId: IOS_CLIENT_ID,
    androidClientId: ANDROID_CLIENT_ID,
    webClientId: WEB_CLIENT_ID,
    scopes: ["profile", "email"],
  });

  React.useEffect(() => {
    if (response?.type === "success") {
      const { params } = response;
      if (params?.id_token) {
        googleSignUp(params.id_token, null, Platform.OS, starting_form.values)
          .then(async (response: any) => {
            dispatch(signin(response.data));
            await googleAnalyticsSignUp({
              method: "Google",
            });
            setLoading(false);
            navigation.navigate("Home");
          })
          .catch((error: any) => {
            setLoading(false);
            console.log(error);
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [response]);

  return (
    <DefaultButton
      style={[styles.button, props.style]}
      mode="contained"
      loading={loading}
      disabled={loading}
      onPress={async () => {
        setLoading(true);
        promptAsync();
      }}
    >
      {!loading && (
        <Icon
          style={styles.icon}
          name="google"
          size={moderateScale(15)}
          color={theme.colors.onPrimary}
        />
      )}
      {t("TextGoogleSignUp")}
    </DefaultButton>
  );
};

export default memo(Button);
