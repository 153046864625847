import axios from "axios";
import { baseAPIURL } from "./../config";

export function getProducts(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/products`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getHybridProducts(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/products/hybrid`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getProduct(userToken: string, id: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/product/${id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}
