import { Button as DefaultButton } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { googleSignUp } from "@/common/utils/analytics.utils";
import { signin } from "@/redux/reducers/User";
import { facebookSignUp } from "@/services/auth.service";
import { moderateScale } from "@/theme/dimensions";
import { RootStackParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { ResponseType } from "expo-auth-session";
import * as Facebook from "expo-auth-session/providers/facebook";
import * as WebBrowser from "expo-web-browser";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Platform } from "react-native";
import { useTheme } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
//import { googleLogin } from "@/common/utils/analytics.utils";

WebBrowser.maybeCompleteAuthSession();

const Button = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const toast: any = useToast();
  const theme = useTheme();
  const dispatch = useDispatch();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const { t } = useTranslation();

  const [request, response, promptAsync] = Facebook.useAuthRequest({
    clientId: "428291428991595",
    responseType: ResponseType.Token,
  });

  React.useEffect(() => {
    setLoading(true);
    if (response?.type === "success") {
      const { access_token } = response.params;
      if (access_token) {
        //MISSING PUSH_TOKEN
        facebookSignUp(access_token, null, Platform.OS, starting_form.values)
          .then(async (response: any) => {
            dispatch(signin(response.data));
            await googleSignUp({
              method: "Facebook",
            });
            setLoading(false);
            props.navigation.navigate("Home", { screen: "Boxes" });
          })
          .catch((error: any) => {
            setLoading(false);
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
          });
      } else {
        setLoading(false);
        toast.show(t("ErrorWhileTryingToConnectToFacebookTryAgainLater"), {
          type: "danger",
        });
      }
    }
  }, [response]);
  return (
    <DefaultButton
      style={[styles.button, props.style]}
      mode="contained"
      onPress={async () => {
        if (!loading) {
          promptAsync();
        }
      }}
    >
      <Icon
        style={styles.icon}
        name="facebook"
        size={moderateScale(15)}
        color={theme.colors.onPrimary}
      />
      {t("TextFacebookSignUp")}
    </DefaultButton>
  );
};

export default memo(Button);
