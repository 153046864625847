import axios from "axios";
import i18n from "i18next";
import { baseAPIURL } from "./../config";

export function createBox(userToken: string, typeId: string, animalId: string) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/boxes`,
    data: { boxType: typeId, animalId: animalId, language: i18n.language },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}
export function createMonthlyBox(userToken: string, animals: Array<Animal>) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/boxes/create-monthly`,
    data: { animals: animals },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function createDryTestSuggestion(
  userToken: string,
  animals: Array<any>,
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/boxes/create-dry-test`,
    data: { animals: animals },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function updateBox(
  userToken: string,
  animalId: string,
  box: Box,
  productId: string,
) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/animalbox/${animalId}`,
    data: { box: box, product: productId },
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function deleteBox(userToken: string, boxId: string) {
  const config = {
    method: "delete",
    url: `${baseAPIURL}/boxes/${boxId}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}

export function getBox(userToken: string, animalId: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/animalbox/${animalId}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getBoxes(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/boxes`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };
  return axios(config);
}
