export const baseAPIURL = process.env.API_URL || "https://api.purrmi.com/api";
export const baseURL = process.env.APP_URL || "https://app.purrmi.com/";
export const waitingDays = 2;
export const shippingFree1 = 45;
export const shippingFree2 = 55;
export const shippingBoxValue = 0;
export const islandCodes = ["07", "35", "38", "52"];
export const testFoods = [
  "624af58d6a14e861fb9dde83",
  "624af58d6a14e861fb9ddec8",
];
export const experimentalPackageID = "6102cf9f17673393355b5a92";
export const sandCategory = "60cdc55ace1ed926207df90a";
export const freshCategory = "60cdc3f5ce1ed926207df908";
export const humidFoodCategory = "621e076c8644d10712085eb9";
export const sandFor1CatID = "621e02458644d10712085eb6";
export const sandFor2PlusCatsID = "621e02458644d10712085eb6";
export const our_products = [
  "6102cf9f17673393355b5a8d",
  "6102cf9f17673393355b5a8e",
  "6102cf9f17673393355b5a90",
  "61b7470f7dffa40b1df33951",
  "61b745f67dffa40b1df3394e",
  "61b746b47dffa40b1df3394f",
];
export const subscription_products = [
  "6102cf9f17673393355b5a8d",
  "6102cf9f17673393355b5a8e",
  "6102cf9f17673393355b5a90",
  "6102cf9f17673393355b5a92",
];
export const dux_natura_flavours = [
  "DuxRawChicken",
  "DuxRawChickenAndRabbit",
  "DuxRawTurkeyAndChickenLiver",
  "DuxRawDuckAndChickenLiver",
  "DuxRawQuailAndEggs",
];

export const analytic_components = [
  "TextEnergy",
  "TextFat",
  "TextFromWhichFattyAcids",
  "TextCarbohydrates",
  "TextFromWhichSugars",
  "TextProteins",
  "TextSalt",
];

export const free_products = [
  {
    images: [
      "https://duxnatura-storage.s3.eu-west-1.amazonaws.com/purrmi_play_mouse_main.webp",
    ],
    title: {
      default: "Little play mouse for cats with catnip",
      es: "Ratoncito de juego para gatos con hierba gatera",
      de: "Kleine Spielmaus mit catnip",
      pt: "Ratinho de brincar para gatos com erva-de-gato",
    },
  },
  {
    images: [
      "https://duxnatura-storage.s3.eu-west-1.amazonaws.com/topping.png",
    ],
    title: {
      default: "Food topping for fussy eating cats",
      es: "Complemento alimenticio para gatos quisquillosos con la comida",
      de: "Futterbelag für wählerische Fresskatzen",
      pt: "Cobertura alimentar para gatos mais exigentes",
    },
  },
];

export const EXPO_CLIENT_ID =
  "900838385503-2ogffdae8t29lvsi8gjfhmam45d82asj.apps.googleusercontent.com";
export const IOS_CLIENT_ID =
  "900838385503-eocdvj0jgpqlcvgjljlnubpg5go7gctq.apps.googleusercontent.com";
export const ANDROID_CLIENT_ID =
  "900838385503-ha5tuu01cbqheg98dsvle4vphpc6sr8g.apps.googleusercontent.com";
export const WEB_CLIENT_ID =
  "900838385503-2ogffdae8t29lvsi8gjfhmam45d82asj.apps.googleusercontent.com";

export const MIN_NUMBER_OF_ANIMALS = 1;
export const MAX_NUMBER_OF_ANIMALS = 15;
export const MIN_WEIGHT_OF_ANIMALS = 1;
export const MAX_WEIGHT_OF_ANIMALS = 15;
export const LOYALTY_DISCOUNT = 10;
