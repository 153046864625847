import { theme } from "@/theme";
import { useMemo } from "react";
import { useWindowDimensions } from "react-native";

export const useBreakpoint = (): {
  width: number;
  breakpoint: "xs" | "s" | "md" | "lg" | "xl";
  mobile: boolean;
} => {
  const { width } = useWindowDimensions();

  const breakpoint = useMemo(() => {
    const nearestBrekpoint = Object.entries(theme.breakpoints.values)
      .reverse()
      .find(([_, value]) => value < width);
    if (nearestBrekpoint) {
      return nearestBrekpoint[0] as "xs" | "s" | "md" | "lg" | "xl";
    }
    return "xs";
  }, [width, theme]);

  const mobile = ["xs", "s"].includes(breakpoint);

  return {
    width,
    breakpoint,
    mobile,
  };
};
