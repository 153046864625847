import axios from "axios";
import i18n from "i18next";
import { baseAPIURL } from "./../config";
//import { GoogleSignin, GoogleSigninButton, statusCodes } from '@react-native-google-signin/google-signin';
export function login(credentials: any) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/auth/signin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(credentials),
  };

  return axios(config);
}

export function register(
  user: any,
  role: string,
  referral: string,
  animals: RegisterFormValues[]
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/auth/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify({
      email: user.email,
      name: user.name,
      password: user.password,
      role: role,
      friend: referral,
      language: i18n.language,
      animals: animals,
    }),
  };

  return axios(config);
}

export function googleSignIn(
  tokenId: string,
  pushToken: string | undefined,
  platform: string
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/auth/google/signin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      tokenId: tokenId,
      pushToken: pushToken,
      platform: platform,
      language: i18n.language,
    },
  };
  return axios(config);
}

export function googleSignUp(
  tokenId: string,
  pushToken: string | undefined,
  platform: string,
  animals: RegisterFormValues[]
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/auth/google/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      tokenId: tokenId,
      pushToken: pushToken,
      platform: platform,
      language: i18n.language,
      animals: animals,
    },
  };
  return axios(config);
}

export function facebookSignIn(
  token: string,
  pushToken: string | undefined,
  platform: string
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/auth/facebook/signin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      token: token,
      pushToken: pushToken,
      platform: platform,
      language: i18n.language,
    },
  };
  return axios(config);
}

export function facebookSignUp(
  token: string,
  pushToken: string | undefined,
  platform: string,
  animals: RegisterFormValues[]
) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/auth/facebook/signup`,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      token: token,
      pushToken: pushToken,
      platform: platform,
      language: i18n.language,
      animals: animals,
    },
  };
  return axios(config);
}
