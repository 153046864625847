import { Button } from "@/common/components/Button";
import { NumericInput } from "@/common/components/Input";
import { MAX_NUMBER_OF_ANIMALS, MIN_NUMBER_OF_ANIMALS } from "@/config";
import { updateValuesAndGoNext } from "@/redux/reducers/Register";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch } from "react-redux";
import styles from "./styles";

const Count = ({ ...props }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState<number>(1);

  const createStartingForm = () => {
    if (value < 1 || value > 15) return;
    dispatch(
      updateValuesAndGoNext({
        slide: 1,
        values: Array.from({ length: value }, () => ({
          name: "",
          birthday: "",
          sex: "",
          weight: "",
          preference: "",
          type: "60b51ded0f4adc3eb88ede08",
        })),
      })
    );
  };

  return (
    <View style={styles.container}>
      <Text variant="bodySmall" style={styles.title}>
        {t("TextGetStartedSubtitle")}
      </Text>
      <NumericInput
        style={styles.quantityInput}
        containerStyle={styles.numericInputContainer}
        label={t("TextOtherNumberOfAnimals")}
        value={value}
        onChange={(number: number) => {
          setValue(number);
        }}
        min={MIN_NUMBER_OF_ANIMALS}
        max={MAX_NUMBER_OF_ANIMALS}
        step={1}
        maxLength={2}
        error={
          value < MIN_NUMBER_OF_ANIMALS || value > MAX_NUMBER_OF_ANIMALS
            ? t("TextErrorMessageForOtherNumberOfAnimals")
            : undefined
        }
      />
      <Button
        mode="contained"
        style={{ width: "100%" }}
        disabled={
          value < MIN_NUMBER_OF_ANIMALS || value > MAX_NUMBER_OF_ANIMALS
        }
        onPress={createStartingForm}
      >
        {t("TextContinue")}
      </Button>
    </View>
  );
};

export default memo(Count);
