import { globalStyles } from "@/common/styles";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, Text, TextInput } from "react-native-paper";
import styles from "./styles";
const Input = ({ ...props }) => {
  const { t } = useTranslation();
  const [step] = useState<number>(props.step || 1);

  const decreaseValueByStep = () => {
    const value = props.value ? props.value : 0;
    if (props.min === undefined || value > props.min) {
      props.onChange(value - step);
    }
  };
  const increaseValueByStep = () => {
    const value = props.value ? +props.value : 0;
    if (props.max === undefined || value < props.max) {
      props.onChange(value + step);
    }
  };

  const changeValueManually = (text: string) => {
    const value = parseInt(text);
    if (!isNaN(value)) props.onChange(value);
  };

  return (
    <View style={[styles.container, props.containerStyle]}>
      <View
        style={{
          width: "100%",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            style={props.iconStyle || {}}
            iconColor={props.iconColor}
            size={props.iconSize || moderateScale(30)}
            icon={props.iconType === "box" ? "minus-box" : "minus"}
            onPress={decreaseValueByStep}
          />
          <TextInput
            style={[styles.input, props.style]}
            label={props.label}
            value={props.value}
            mode={props.mode || "outlined"}
            onChangeText={(text) => changeValueManually(text)}
            selectTextOnFocus={false}
            keyboardType="numeric"
            maxLength={props.maxLength}
            error={props.error}
          />
          <IconButton
            style={props.iconStyle || {}}
            size={props.iconSize || moderateScale(30)}
            iconColor={props.iconColor}
            icon={props.iconType === "box" ? "plus-box" : "plus"}
            onPress={increaseValueByStep}
          />
        </View>
      </View>
      {props.error ? (
        <Text variant={"labelMedium"} style={[globalStyles.error]}>
          {props.error.map((err: string) => t(err) + "\n")}
        </Text>
      ) : null}
    </View>
  );
};

export default memo(Input);
