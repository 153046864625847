import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

const CUSTOM_BORDER_RADIUS = 30;

export default StyleSheet.create({
  container: {
    width: "100%",
    zIndex: 1,
    backgroundColor: theme.colors.surfaceVariant,
    borderBottomRightRadius: CUSTOM_BORDER_RADIUS,
    borderBottomLeftRadius: CUSTOM_BORDER_RADIUS,
  },
  infoDescription: {
    textAlign: "left",
    color: theme.colors.onPrimaryContainer,
  },
  topBarContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 5,
  },
  logoContainer: { alignSelf: "flex-start" },
  logo: {
    width: 105,
    height: 54,
    fill: theme.colors.onSurfaceVariant,
  },
  notificationIcon: {},
  bannerContainer: { width: "100%", maxWidth: 1200, alignSelf: "center" },
  banner: {
    width: "90%",
    backgroundColor: theme.colors.primary,
    borderRadius: CUSTOM_BORDER_RADIUS,
    paddingHorizontal: 20,
    paddingVertical: 30,
    position: "relative",
    top: 30,
  },
  mainIconContainer: {
    backgroundColor: theme.colors.background,
    borderRadius: 100,
    padding: 10,
    marginRight: 10,
  },
  wrapper: {
    flexDirection: "row",
    alignItems: "center",
    position: "absolute",
    bottom: -15,
    left: -2,
  },
  icon: { borderRadius: 100, backgroundColor: theme.colors.primary },
  infoContainer: { width: "100%" },
  actionsContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    top: -5,
    marginRight: 5,
  },
  infoGreetings: {
    color: theme.colors.onSurfaceVariant,
    marginLeft: CUSTOM_BORDER_RADIUS - 7,
    top: 0,
    position: "absolute",
  },
  infoTitle: { color: theme.colors.onPrimary, textAlign: "right" },
  info: {
    color: theme.colors.onPrimary,
    fontWeight: "bold",
    textAlign: "right",
  },
  infoSubtitle: { color: theme.colors.onPrimary, textAlign: "right" },
  warningLabel: {
    flexDirection: "row",
    backgroundColor: theme.colors.primaryContainer,
    borderRadius: BORDER_RADIUS,
    padding: 3,
    top: 13,
    left: -5,
  },
  warningLabelText: {
    color: theme.colors.onPrimaryContainer,
  },
  warningLabelIcon: {
    color: theme.colors.onPrimaryContainer,
    marginRight: 5,
  },
});
