import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  animalName: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  invertedSection: {
    alignItems: "center",
    backgroundColor: theme.colors.secondaryContainer,
    justifyContent: "center",
    paddingVertical: 60,
    width: "100%",
  },
  vetContainer: {
    maxWidth: 900,
    width: "100%",
  },
  vetImage: {
    borderRadius: BORDER_RADIUS,
    height: 300,
    width: 300,
  },
  vetImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  vetInfoList: {
    marginLeft: 30,
  },
  vetInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 15,
  },
  vetInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  vetInfoListItemText: { ...theme.fonts.labelLarge, width: "80%" },
  vetRow: {
    alignItems: "center",
    flexDirection: "column-reverse",
    justifyContent: "center",
    marginTop: 50,
  },
  vetTextContainer: {
    marginTop: 20,
    width: "80%",
  },
  vetTextDescription: {
    ...theme.fonts.labelLarge,
    color: theme.colors.onSecondaryContainer,
  },
  vetTextTitle: {
    color: theme.colors.onSecondaryContainer,
    paddingHorizontal: 30,
    textAlign: "center",
    width: "100%",
  },
});
