import { Button } from "@/common/components/Button";
import { globalStyles } from "@/common/styles";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform, View } from "react-native";
import { Text } from "react-native-paper";
import { useDispatch } from "react-redux";
import styles from "./styles";
const Shipping = ({ delivery }: { delivery: Delivery }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const linkToInvoice = () => {
    if (Platform.OS === "web") {
      window.open(
        "https://www.mrw.pt/seguimiento_envios/MRW_seguimiento_envios.asp",
        "_blank"
      );
    } else {
      Linking.openURL(
        "https://www.mrw.pt/seguimiento_envios/MRW_seguimiento_envios.asp"
      );
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.invoiceContainer}>
        <Text style={styles.invoiceText} variant="labelLarge">
          <Text style={globalStyles.bold} variant="labelLarge">
            {t("WaitingShippingTracker")}
          </Text>
        </Text>
        <Text style={globalStyles.bold} variant="titleSmall">
          {delivery.service_code}
        </Text>
        <Button
          style={styles.invoiceButton}
          labelStyle={styles.invoiceButtonLabel}
          mode={"contained"}
          onPress={linkToInvoice}
        >
          {t("TextTrackingInfo")}
        </Button>
      </View>
    </View>
  );
};

export default memo(Shipping);
