import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "react-native-paper";
import { GoogleButton } from "../Buttons";
import styles from "./styles";
//import { googleLogin } from "@/common/utils/analytics.utils";
const SocialLogin = ({ ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <>
      <GoogleButton style={styles.button}></GoogleButton>
      {/* <FacebookButton style={styles.button}></FacebookButton> */}
      <Button
        style={styles.button}
        mode="contained"
        onPress={props.toggleManualLogin}
      >
        <Icon
          style={styles.icon}
          name="email"
          size={moderateScale(20)}
          color={theme.colors.onSecondary}
        />
        {t("TextManualSignUp")}
      </Button>
    </>
  );
};

export default memo(SocialLogin);
