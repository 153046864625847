import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const animalsSlice = createSlice({
  name: "animals",
  initialState: [] as Animal[],
  reducers: {
    update(state, action: PayloadAction<Animal>) {
      let boxIndex = state.findIndex(
        (animal) => animal._id == action.payload._id
      );
      let new_state = state.slice();
      new_state[boxIndex] = action.payload;
      return new_state;
    },
    remove(state, action: PayloadAction<Animal>) {
      return state.filter((a) => a._id !== action.payload._id);
    },
    updateAll(state, action: PayloadAction<Animal[]>) {
      return [...action.payload];
    },
    add(state, action: PayloadAction<Animal>) {
      state.push(action.payload);
    },
  },
});

export const { update, updateAll, add, remove } = animalsSlice.actions;
export default animalsSlice.reducer;
