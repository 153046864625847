import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions/index";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 30,
    paddingVertical: 10,
    marginBottom: 20,
  },
  inputContainer: { marginBottom: 10 },
  infoContainer: {
    flexDirection: "row",
    paddingHorizontal: 3,
    marginBottom: 10,
  },
  radioButtonContainer: {
    marginBottom: 10,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
  },
  title: { marginBottom: 5 },
  radioButtonTitle: { color: theme.colors.onSurfaceVariant },
  colorDanger: { color: theme.colors.warning, fontWeight: "bold" },
  inputOutline: { borderRadius: BORDER_RADIUS },
  twoSplit: { width: "48%" },
  row: { flexDirection: "row", justifyContent: "space-between" },
  checkpointInfoContainer: { flexDirection: "row", paddingHorizontal: 3 },
  checkpointInfoIcon: { marginTop: 3, marginRight: 3 },
  checkpointInfoIconDanger: {
    marginTop: 3,
    marginRight: 3,
    color: theme.colors.warning,
  },
});
