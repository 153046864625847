import { globalStyles } from "@/common/styles";
import { getTranslationLanguage } from "@/common/utils";
import {
  googleAddToCart,
  googleRemoveFromCart,
  productToGoogleProduct,
} from "@/common/utils/analytics.utils";
import {
  experimentalPackageID,
  free_products,
  sandCategory,
  sandFor2PlusCatsID,
} from "@/config";
import { update } from "@/redux/reducers/Boxes";
import { getBoxes, updateBox } from "@/services/boxes.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { Avatar, Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const Main = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const boxes = useSelector((state: RootState) => state.boxes);
  const [toast, setToast] = useState<ToastType>(null);
  const [selectedBox, setSelectedBox] = useState<Box | null>(null);
  const [wantsSand, setWantsSand] = useState<boolean>(false);
  const [changingSand, setChangingSand] = useState<boolean>(false);
  const hasExperimentalBox = boxes.find((b: Box) =>
    b.products.some(
      (bp: BoxProduct) => bp.product._id === experimentalPackageID,
    ),
  );
  useEffect(() => {
    let e_box = boxes.find((b: Box) =>
      b.products.some(
        (bp: BoxProduct) => bp.product._id === experimentalPackageID,
      ),
    );
    setSelectedBox(e_box);
    setWantsSand(
      boxes.some((b) =>
        b.products.some((bp) => bp.product?.category?._id === sandCategory),
      ),
    );
  }, [boxes]);

  const getNamesOfExperimentalPets = (type: number) => {
    let experimental_animals = animals;
    let custom_text = experimental_animals.map((animal, index) => {
      if (index != 0 && index === animals.length - 1) {
        return (
          <Text key={"name_of_animal_list" + index}>
            {" "}
            {t("TextAnd")}{" "}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
                ? t("TextA")
                : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            <Text style={styles.animalName}>{animal.name}</Text>
          </Text>
        );
      } else {
        return (
          <Text key={"name_of_animal_list" + index}>
            {index !== 0 ? ", " : ""}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
                ? t("TextA")
                : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            {<Text style={styles.animalName}>{animal.name}</Text>}
          </Text>
        );
      }
    });
    return custom_text;
  };

  const removeSandFromBox = () => {
    setChangingSand(true);
    let new_box = { ...selectedBox };
    let new_products = [];
    if (!new_box.products) return;
    for (let index = 0; index < new_box.products.length; index++) {
      if (new_box.products[index].product.category._id !== sandCategory) {
        new_products.push({ ...new_box.products[index] });
      }
    }
    new_box.products = new_products;
    updateBox(user.accessToken, selectedBox.animal, new_box, sandFor2PlusCatsID)
      .then(async (response) => {
        if (selectedBox) {
          await googleRemoveFromCart({
            currency: "EUR",
            value:
              selectedBox.products[selectedBox.products.length - 1].product
                .price,
            items: productToGoogleProduct(
              selectedBox.products[selectedBox.products.length - 1],
            ),
          });
        }
        dispatch(update(response.data.box));
        getBoxes(user.accessToken)
          .then((response2) => {
            setChangingSand(false);
          })
          .catch((error) => {
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
            setChangingSand(false);
          });
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setChangingSand(false);
      });
  };

  const addSandToBox = () => {
    setChangingSand(true);
    let new_box = { ...selectedBox };
    new_box.products = [...new_box.products];
    new_box.products.push({
      product: sandFor2PlusCatsID as any, // VanCat Carvao Ativado 8.5kg
      quantity: 1,
    });
    updateBox(user.accessToken, selectedBox.animal, new_box, sandFor2PlusCatsID)
      .then((response) => {
        dispatch(update(response.data.box));
        getBoxes(user.accessToken)
          .then(async (response2) => {
            setChangingSand(false);
            await googleAddToCart({
              currency: "EUR",
              value:
                response.data.box.products[
                  response.data.box.products.length - 1
                ].product.price,
              items: productToGoogleProduct(
                response.data.box.products[
                  response.data.box.products.length - 1
                ],
              ),
            });
          })
          .catch((error) => {
            toast.show(t("BackendNotification" + error.response.data.message), {
              type: "danger",
            });
            setChangingSand(false);
          });
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setChangingSand(false);
      });
  };

  const onPressToChangeSand = () => {
    if (wantsSand) {
      removeSandFromBox();
    } else {
      addSandToBox();
    }
    setWantsSand(!wantsSand);
  };

  return (
    <View style={[styles.headerContainer]}>
      <View style={[styles.header]}>
        <View style={[styles.headerInfo]}>
          <Text variant={"titleSmall"} style={[styles.headerInfoTitle]}>
            {`${t("TextFirstTimeHeaderInfoTitle")} `}
            <Text>{getNamesOfExperimentalPets(1)}</Text>
          </Text>
          <Text style={[styles.headerInfoDescription]}>
            {`${t("TextFirstTimeExperimentalHeaderInfoDescription")} `}
            <Text>{getNamesOfExperimentalPets(1)}</Text>
            {` ${
              animals.length > 1
                ? t("TextFirstTimeExperimentalHeaderInfoDescription2")
                : t("TextFirstTimeExperimentalHeaderInfoDescription3")
            }.`}
          </Text>
          <Text style={[styles.headerInfoDescription]}>
            <Text>{getNamesOfExperimentalPets(1)}</Text>{" "}
            {animals.length > 1
              ? t("TextFirstTimeExperimentalHeaderInfoDescription4")
              : t("TextFirstTimeExperimentalHeaderInfoDescription5")}
            :
          </Text>
          <View style={[styles.headerInfoList]}>
            {selectedBox?.products.map((bp, index) => {
              return (
                <View
                  key={"box_product_" + index}
                  style={styles.headerInfoListItem}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={{ uri: bp.product.images[0] }}
                      style={styles.headerInfoListItemImage}
                    />
                    <View
                      style={{
                        alignItems: "flex-start",
                        justifyContent: "center",
                        maxWidth: "80%",
                      }}
                    >
                      <Text style={[styles.headerInfoListItemTitle]}>
                        {
                          bp.product.title[
                            getTranslationLanguage(i18n.language)
                          ]
                        }
                        {" - "}x{bp.quantity}{" "}
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          {(bp.product.price * bp.quantity).toFixed(2)}€
                        </Text>{" "}
                        {bp.product.discount > 0 && (
                          <Text style={styles.discountText}>
                            -{bp.product.discount}%
                          </Text>
                        )}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
            {hasExperimentalBox && (
              <Fragment>
                <View
                  key={"box_product_free_1"}
                  style={styles.headerInfoListItem}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={{ uri: free_products[0].images[0] }}
                      style={styles.headerInfoListItemImage}
                    />
                    <View
                      style={{
                        alignItems: "flex-start",
                        justifyContent: "center",
                        maxWidth: "80%",
                      }}
                    >
                      <Text style={[styles.headerInfoListItemTitle]}>
                        {
                          free_products[0].title[
                            getTranslationLanguage(i18n.language)
                          ]
                        }
                        {" - "}x{1}{" "}
                        <Text
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            color: theme.colors.success,
                          }}
                        >
                          {t("TextFree")}
                        </Text>{" "}
                      </Text>
                    </View>
                  </View>
                </View>
                {/* <View
                  key={"box_product_free_2"}
                  style={styles.headerInfoListItem}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Image
                      source={{ uri: free_products[1].images[0] }}
                      style={styles.headerInfoListItemImage}
                    />
                    <View
                      style={{
                        alignItems: "flex-start",
                        justifyContent: "center",
                        maxWidth: "80%",
                      }}
                    >
                      <Text style={[styles.headerInfoListItemTitle]}>
                        {
                          free_products[1].title[
                            getTranslationLanguage(i18n.language)
                          ]
                        }
                        {" - "}x{1}{" "}
                        <Text
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            color: theme.colors.success,
                          }}
                        >
                          {t("TextFree")}
                        </Text>{" "}
                      </Text>
                    </View>
                  </View>
                </View> */}
              </Fragment>
            )}
          </View>
        </View>
        <View style={[styles.headerPromoImagesContainer]}>
          <View style={styles.headerPromoImagesContainerWrapper}>
            <View style={globalStyles.wFullCentered}>
              <View style={globalStyles.wFullCentered}>
                {selectedBox && (
                  <Image
                    source={{
                      uri: selectedBox.products[0].product.images[0],
                    }}
                    style={styles.promoHeaderImage}
                  />
                )}
              </View>
              <View style={styles.headerPromoBenefits}>
                <Text style={[styles.firstTimeInfoTitle]}>
                  {t("TextFirstTimePromoTitle")}
                </Text>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <View style={styles.featuresContainer}>
                    <Avatar.Icon
                      size={moderateScale(30)}
                      color={theme.colors.onSurfaceVariant}
                      style={styles.promoImageInfoListItemIcon}
                      icon={"truck-fast"}
                    />
                    <Text style={styles.featuresText}>
                      {t("TextFirstTimePromo2")}
                    </Text>
                  </View>
                  <View style={styles.featuresContainer}>
                    <Avatar.Icon
                      size={moderateScale(30)}
                      color={theme.colors.onSurfaceVariant}
                      style={styles.promoImageInfoListItemIcon}
                      icon={"shield-plus"}
                    />
                    <Text style={styles.featuresText}>
                      {t("TextFirstTimePromo3")}
                    </Text>
                  </View>
                  <View style={styles.featuresContainer}>
                    <Avatar.Icon
                      size={moderateScale(30)}
                      color={theme.colors.onSurfaceVariant}
                      style={styles.promoImageInfoListItemIcon}
                      icon={"cat"}
                    />
                    <Text style={styles.featuresText}>
                      {t("TextFirstTimePromo4")}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Main);
