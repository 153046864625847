import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { Text } from "react-native-paper";
import styles from "./styles";
const Section = () => {
  const { t } = useTranslation();
  return (
    <View style={styles.container}>
      <Text variant="titleMedium" style={styles.title}>
        {t("TermsAndConditions")}
      </Text>
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle1")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText1")}
      </Text>
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle2")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText2")}
      </Text>
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle3")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText3")}
      </Text>
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle4")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText4")}
      </Text>
      <Image
        source={require("@/assets/images/easypayblack.png")}
        style={styles.paymentImage}
      />
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle5")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText5")}
      </Text>
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle6")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText6")}
      </Text>
      <Text variant="titleSmall" style={styles.termsTitle}>
        {t("TermsAndConditionsTitle7")}
      </Text>
      <Text variant="labelLarge" style={styles.termsParagraph}>
        {t("TermsAndConditionsText7")}
      </Text>
    </View>
  );
};

export default memo(Section);
