import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 65,
    width: "100%",
  },
  icon: {
    alignSelf: "center",
    color: theme.colors.secondary,
  },
  sectionColumn: {
    width: "50%",
  },
  sectionIcon: {
    color: theme.colors.onSurfaceDisabled,
  },
  sectionRow: {
    flexDirection: "row",
  },
  sectionVetOpinion: {
    alignItems: "center",
    backgroundColor: theme.colors.tertiary,
    borderRadius: BORDER_RADIUS,
    flex: 1,
    justifyContent: "center",
    minHeight: 100,
    padding: 10,
    width: "100%",
  },
  sectionVetOpinionIcon: {
    color: theme.colors.onTertiary,
    marginBottom: 5,
  },
  sectionVetOpinionText: {
    color: theme.colors.onTertiary,
    textAlign: "center",
  },
  sectionWrapper: {
    alignItems: "center",
    borderRadius: BORDER_RADIUS,
    flex: 1,
    justifyContent: "center",
    margin: 20,
  },
  sectionWrapperDisabled: {
    backgroundColor: theme.colors.surfaceDisabled,
  },
  infoContainer: {
    flexDirection: "row",
    paddingHorizontal: 3,
    marginBottom: 10,
  },
  radioButtonTitle: { color: theme.colors.onSurfaceVariant },
  checkpointInfoIcon: { marginTop: 3, marginRight: 3 },
  sliderContainer: {
    padding: 10,
    width: "100%",
  },
  sliderDescription: {
    marginBottom: 5,
    textAlign: "center",
  },
  sliderSubtitle: {
    marginBottom: 5,
    textAlign: "center",
  },
  sliderTitle: {
    textAlign: "center",
  },
  sliderWeight: {
    marginBottom: 5,
    textAlign: "center",
  },
});
