import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Avatar, List, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";
const FAQ = () => {
  const { t } = useTranslation();
  const animals = useSelector((state: RootState) => state.animals);
  const [faqArray, setFaqArray] = useState([
    {
      question: t("TextRepurchaseQuestionFAQ"),
      answer: t("TextRepurchaseAnswerFAQ"),
      expanded: false,
    },
    {
      question: t("TextRepurchaseQuestionFAQ2"),
      answer: t("TextRepurchaseAnswerFAQ2"),
      expanded: false,
    },
    {
      question: t("TextRepurchaseQuestionFAQ3"),
      answer: t("TextRepurchaseAnswerFAQ3"),
      expanded: false,
    },
    {
      question: t("TextRepurchaseQuestionFAQ4"),
      answer: t("TextRepurchaseAnswerFAQ4"),
      expanded: false,
    },
    {
      question: t("TextRepurchaseQuestionFAQ5"),
      answer: t("TextRepurchaseAnswerFAQ5"),
      expanded: false,
    },
    {
      question: t("TextRepurchaseQuestionFAQ6"),
      answer: t("TextRepurchaseAnswerFAQ6"),
      expanded: false,
    },
  ]);
  const getNamesOfExperimentalPets = (type: number) => {
    let experimental_animals = animals;
    let custom_text = experimental_animals.map((animal, index) => {
      if (index != 0 && index === animals.length - 1) {
        return (
          <Text key={"name_of_animal_list" + index}>
            {" "}
            {t("TextAnd")}{" "}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            <Text style={styles.animalName}>{animal.name}</Text>
          </Text>
        );
      } else {
        return (
          <Text key={"name_of_animal_list" + index}>
            {index !== 0 ? ", " : ""}
            {type == 1 && animal.sex == "male"
              ? t("TextO")
              : type == 1 && animal.sex == "female"
              ? t("TextA")
              : ""}
            {t("TextO") == "" || t("TextA") == "" ? "" : " "}
            {<Text style={styles.animalName}>{animal.name}</Text>}
          </Text>
        );
      }
    });
    return custom_text;
  };

  return (
    <View style={[styles.invertedSectionFaq]}>
      <Text variant="titleSmall" style={[styles.faqTitle]}>
        {t("TextFirstTimeFAQTitle")}
      </Text>
      <Text style={[styles.faqSubtitle]}>
        {`${t("TextFirstTimeExpeirmentalFAQSubtitle")} `}
        <Text>{getNamesOfExperimentalPets(1)}</Text>
        {":\n"}
        {t("TextFirstTimeFAQSubtitle2")}
      </Text>
      <View style={[styles.faqContainer]}>
        <List.Section>
          {faqArray.map((faq, index) => {
            return (
              <List.Accordion
                key={"faq_question_" + index}
                style={styles.faqAccordion}
                title={faq.question}
                titleNumberOfLines={0}
                titleStyle={[styles.faqQuestionTitle]}
                theme={{ colors: { background: "transparent" } }}
                left={(props) => (
                  <Avatar.Icon
                    color={theme.colors.secondary}
                    size={moderateScale(60)}
                    style={styles.faqQuestionIcon}
                    icon={"head-question"}
                  />
                )}
                expanded={faq.expanded}
                onPress={() => {
                  let newFaqArray = [...faqArray];
                  newFaqArray[index] = {
                    ...faq,
                    expanded: !faq.expanded,
                  };
                  setFaqArray(newFaqArray);
                }}
              >
                <List.Item
                  style={styles.faqAccordionItem}
                  title={faq.answer}
                  titleNumberOfLines={0}
                  titleStyle={styles.faqAnswerTitle}
                />
              </List.Accordion>
            );
          })}
        </List.Section>
      </View>
    </View>
  );
};

export default memo(FAQ);
