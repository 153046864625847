import { globalStyles } from "@/common/styles";
import { getTranslationLanguage } from "@/common/utils";
import { googleSearch } from "@/common/utils/analytics.utils";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import Header from "./components/Header";
import Products from "./components/Products";
import ProductView from "./components/ProductView";
import styles from "./styles";

const ExplorerModule = ({ products }) => {
  const { i18n } = useTranslation();
  const [searchProducts, setSearchProducts] = useState<Product[]>(
    products.map((p: Product) => {
      return { ...p };
    })
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<Product>(undefined);
  const [visibleProductView, setVisibleProductView] = useState(false);
  const toggleProductView = (): void => {
    setVisibleProductView((state) => !state);
  };
  const openProductView = (product: Product): void => {
    setSelectedProduct(product);
    setVisibleProductView(true);
  };
  const onChangeSearch = async (query) => {
    setSearchProducts(
      products.filter((p: Product) =>
        p.title[getTranslationLanguage(i18n.language)]
          .toLowerCase()
          .includes(query.toLowerCase())
      )
    );
    await googleSearch({
      search_term: query,
    });
    setSearchQuery(query);
  };
  return (
    <View style={styles.container}>
      <Header searchQuery={searchQuery} onChangeSearch={onChangeSearch} />
      <View style={globalStyles.mobileContainer}>
        <Products
          products={searchProducts}
          openProductView={(product: Product) => openProductView(product)}
        />
        <ProductView
          product={selectedProduct}
          visible={visibleProductView}
          toggle={toggleProductView}
        />
      </View>
    </View>
  );
};

export default memo(ExplorerModule);
