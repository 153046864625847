import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  content: {
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.background,
    height: "100%",
  },
  topActionsContainer: { width: "100%" },
  container: { width: "100%" },
  contentTitle: {
    fontSize: 20,
    marginBottom: 12,
  },
  backButton: {
    position: "absolute",
    left: 0,
    top: 0,
  },
  scrollableView: {
    width: "100%",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  contentView: {
    justifyContent: "flex-end",
    margin: 0,
  },
  buttonContainer: {
    position: "relative",
    top: -35,
  },
  buttonStyle: {
    height: 60,
    width: 60,
    backgroundColor: theme.colors.secondary,
    borderRadius: 100,
    ...theme.shadows.mainBoxShadow,
  },
  warningButton: {
    backgroundColor: theme.colors.primaryContainer,
    borderRadius: 100,
    position: "absolute",
    bottom: -10,
    padding: 3,
    right: 0,
  },
});
