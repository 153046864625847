import { isArrayEmpty } from "@/common/utils";
import React, { Fragment, memo, useRef, useState } from "react";
import { FlatList, Image, TouchableOpacity, View } from "react-native";
import styles from "./styles";

const Swiper = ({ data, width, height }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const swiperRef = useRef(null);

  const onViewRef = useRef((viewableItems: { viewableItems: any[] }) => {
    if (viewableItems && !isArrayEmpty(viewableItems.viewableItems)) {
      setActiveIndex(viewableItems.viewableItems[0].index);
    }
  });
  const viewConfigRef = React.useRef({ itemVisiblePercentThreshold: 50 });

  const renderItem = ({ item, index }: { item: string; index: number }) => (
    <View
      key={"swiper_page_" + index}
      style={[styles.slideProductContainer, { width: width }]}
    >
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={{ uri: item }} />
      </View>
    </View>
  );

  const renderSlideButtons = () => {
    return data.map((item: any, index: number) => {
      return (
        <TouchableOpacity
          key={"swiper_pagination" + index}
          style={
            index === activeIndex
              ? styles.activePaginationButton
              : styles.paginationButton
          }
          onPress={() => {
            swiperRef.current.scrollToIndex({
              animated: true,
              index: index,
            });
          }}
        />
      );
    });
  };

  return (
    <Fragment>
      <FlatList
        ref={swiperRef}
        data={data}
        snapToAlignment={"center"}
        showsHorizontalScrollIndicator={false}
        getItemLayout={(_, index) => ({
          length: width,
          offset: width * index,
          index,
        })}
        viewabilityConfig={viewConfigRef.current}
        onViewableItemsChanged={onViewRef.current}
        renderItem={renderItem}
        pagingEnabled={true}
        initialScrollIndex={0}
        decelerationRate={"fast"}
        horizontal
        keyExtractor={(item, index) => "swiper_" + index}
      />
      <View style={styles.pagination}>{renderSlideButtons()}</View>
    </Fragment>
  );
};

export default memo(Swiper);
