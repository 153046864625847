import { globalStyles } from "@/common/styles";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Linking, Platform, View } from "react-native";
import { Button, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import Header from "./components/Header";
import Products from "./components/Products";
import styles from "./styles";

const DashboardModule = () => {
  const { t, i18n } = useTranslation();
  const subscription = useSelector((state: RootState) => state.subscription);
  const linkToStripeBilling = () => {
    const link = `https://billing.stripe.com/p/login/5kA7t7fua3En49i6oo?prefilled_email=${encodeURIComponent(subscription.email)}`;
    if (Platform.OS === "web") {
      window.open(link, "_blank");
    } else {
      Linking.openURL(link);
    }
  };
  return (
    <View style={styles.container}>
      <Header />
      {subscription.stripe_id && (
        <View style={{ paddingHorizontal: 20 }}>
          <View style={styles.subscriptionWarningContainer}>
            <Text
              style={styles.warningSubscriptionText}
              variant={"labelMedium"}
            >
              {t(`WarningYouHaveASubscription`)}
            </Text>
            <Button
              style={styles.warningSubscriptionButton}
              onPress={linkToStripeBilling}
            >
              <Text
                style={styles.warningSubscriptionButtonText}
                variant={"labelMedium"}
              >
                {t(`WarningYouHaveASubscriptionButton`)}
              </Text>
            </Button>
          </View>
        </View>
      )}
      <View style={globalStyles.mobileContainer}>
        <Products />
      </View>
    </View>
  );
};

export default memo(DashboardModule);
