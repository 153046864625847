import MyToast from "@/common/components/Toast";
import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text, View } from "react-native";
import Toast, { ToastType } from "react-native-toast-notifications";
import { useSelector } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import FAQ from "./components/Sections/FAQ";
import Info from "./components/Sections/Info";
import Main from "./components/Sections/Main";
import Process from "./components/Sections/Process";
import Vet from "./components/Sections/Vet";
import Why from "./components/Sections/Why";
import styles from "./styles";
const RepurchaseModule = ({ toggle }: { toggle: () => void }) => {
  const { t, i18n } = useTranslation();
  const subscription = useSelector((state: RootState) => state.subscription);
  const toastRef = useRef(null);
  const [toast, setToast] = useState<ToastType>(null);
  useEffect(() => {
    setToast(toastRef.current);
  }, [toastRef]);
  return (
    <View style={styles.container}>
      <View style={styles.backgroundEffectContainer}>
        <View style={styles.background}>
          <Header toggle={toggle} />
          <View style={styles.sectionContainer}>
            <View style={[styles.containerInner]}>
              {subscription.additional_info ? (
                <>
                  <Main />
                  <Vet />
                  <View style={styles.customDivider}>
                    <Text style={styles.customDividerText}>
                      {t("TextAnd2")}
                    </Text>
                  </View>
                  <Why />
                  <FAQ />
                  <Process />
                </>
              ) : (
                <>
                  <Info />
                </>
              )}
            </View>
          </View>
        </View>
      </View>
      <Footer toggle={toggle} toast={toast} />
      <Toast
        placement="top"
        renderToast={(toastOptions) => <MyToast toast={toastOptions} />}
        ref={toastRef}
      />
    </View>
  );
};

export default memo(RepurchaseModule);
