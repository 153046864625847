import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
  },
  input: {
    height: moderateScale(35),
    ...theme.fonts.labelLarge,
    backgroundColor: theme.colors.surfaceVariant,
    color: theme.colors.onSurfaceVariant,
  },
});
