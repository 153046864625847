import { theme } from "@/theme";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  title: { marginBottom: 10 },
  subtitle: {
    marginBottom: 20,
    ...theme.fonts.labelLarge,
    color: theme.colors.onSurfaceVariant,
  },
  divider: { marginVertical: 10 },
  registerLink: { marginLeft: 2 },
  progressBar: { marginBottom: 20 },
  card: { marginBottom: 20 },
});
