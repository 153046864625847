import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  animalName: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  faqAccordion: {
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginTop: 20,
    paddingVertical: 10,
  },
  faqAccordionItem: {
    backgroundColor: "transparent",
    paddingLeft: 0,
    paddingRight: 16,
  },
  faqAnswerTitle: { ...theme.fonts.labelLarge },
  faqContainer: {
    maxWidth: 1200,
    width: "90%",
  },
  faqQuestionIcon: {
    backgroundColor: "transparent",
    borderRadius: BORDER_RADIUS,
  },
  faqQuestionTitle: {
    ...theme.fonts.bodySmall,
  },
  faqSubtitle: {
    ...theme.fonts.labelLarge,
    paddingHorizontal: 10,
    textAlign: "center",
    width: "100%",
  },
  faqTitle: {
    marginBottom: 10,
    textAlign: "center",
    width: "100%",
  },
  invertedSectionFaq: {
    alignItems: "center",
    backgroundColor: theme.colors.secondaryContainer,
    justifyContent: "center",
    paddingHorizontal: 10,
    paddingVertical: 60,
    width: "100%",
  },
});
