import { StyleSheet } from "react-native";
export default StyleSheet.create({
  image: {
    width: "100%",
    height: "100%",
  },
  contentView: {
    justifyContent: "flex-end",
    margin: 0,
  },
  repeatImage: { resizeMode: "repeat", opacity: 0.05 },
  imageBackground: {
    flex: 1,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  main: { width: "100%", alignItems: "center", flex: 1 },
  scrollView: { width: "100%" },
  scrollableContainer: { width: "100%" },
  fab: {
    position: "absolute",
    margin: 16,
    marginBottom: 15,
    right: 0,
    bottom: 0,
  },
});
