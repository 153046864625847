import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dimensions, Pressable, TouchableOpacity, View } from "react-native";
import CountryFlag from "react-native-country-flag";
import { IconButton, Portal } from "react-native-paper";
import { useSelector } from "react-redux";
import { animated, useSpring, useTrail } from "react-spring";
import styles from "./styles";

const AnimatedView = animated(View);

const LanguageDropdown = ({ ...props }) => {
  const languages = useSelector((state: RootState) => state.languages);
  const DropdownButton = useRef<any>();
  const refMap = useMemo(() => new WeakMap(), []);
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownLeft, setDropdownLeft] = useState(0);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(0);
  const [selected, setSelected] = useState(props.value);
  const toggleDropdown = (): void => {
    visible ? closeDropdown() : openDropdown();
  };
  const [spring, springAPI] = useSpring(
    () => ({
      from: { opacity: 0 },
    }),
    [visible]
  );
  const [flagTrail, flagTrailAPI] = useTrail(languages.length, () => ({
    opacity: 0,
  }));

  const openDropdown = () => {
    if (DropdownButton?.current) {
      DropdownButton.current.measure((_fx, _fy, _w, _h, _px, _py) => {
        const windowHeight = Dimensions.get("window").height;
        setDropdownTop(_py + _h);
        setDropdownLeft(_px);
        setDropdownWidth(_w);
        setDropdownMaxHeight(windowHeight - _py - _h - 10);
      });
      setVisible(true);
      flagTrailAPI.start({
        opacity: 1,
      });
      springAPI.start({
        opacity: 1,
      });
    }
  };

  const closeDropdown = () => {
    flagTrailAPI.start({
      opacity: 0,
    });
    springAPI.start({
      opacity: 0,
    });
    setVisible(false);
  };

  const onItemPress = (item): void => {
    i18n.changeLanguage(item.code);
    flagTrailAPI.start({
      opacity: 0,
    });
    springAPI.start({
      opacity: 0,
    });
    setVisible(false);
  };

  return (
    <View ref={DropdownButton} style={styles.container}>
      <IconButton
        style={styles.toggleButton}
        size={moderateScale(25)}
        iconColor={theme.colors.onSurfaceVariant}
        icon="web"
        onPress={toggleDropdown}
      />
      <Portal>
        {visible && (
          <Pressable style={styles.overlay} onPress={() => setVisible(false)}>
            <View
              style={[
                styles.dropdown,
                { top: dropdownTop, left: dropdownLeft, width: dropdownWidth },
              ]}
            >
              <AnimatedView style={{ ...spring, maxHeight: dropdownMaxHeight }}>
                {flagTrail.map((style, index) => (
                  <AnimatedView key={"LanguageItem" + index} style={style}>
                    <TouchableOpacity
                      style={styles.option}
                      onPress={() => onItemPress(languages[index])}
                    >
                      <View style={styles.flagContainer}>
                        <CountryFlag
                          style={styles.flag}
                          isoCode={
                            languages[index].code === "en"
                              ? "gb"
                              : languages[index].code
                          }
                          size={moderateScale(18)}
                        />
                      </View>
                    </TouchableOpacity>
                  </AnimatedView>
                ))}
              </AnimatedView>
            </View>
          </Pressable>
        )}
      </Portal>
    </View>
  );
};

export default memo(LanguageDropdown);
