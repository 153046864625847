import { moderateScale } from "./../dimensions";
type Font = {
  fontFamily: string;
  fontWeight?:
    | "normal"
    | "bold"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";
};
const title: Font = {
  fontFamily: "MrEaves",
  fontWeight: "bold",
};
const body: Font = {
  fontFamily: "Futura",
  fontWeight: "normal",
};
export const font_list = {
  DM: require("@/assets/fonts/DMSans/DMSans-Regular.ttf"),
  Arvo: require("@/assets/fonts/arvo/Arvo-Regular.ttf"),
  Futura: require("@/assets/fonts/futura/FuturaPTMedium.otf"),
  Futura_Light: require("@/assets/fonts/futura/FuturaPTLight.otf"),
  MrEaves: require("@/assets/fonts/mreaves/MrEavesModOT.ttf"),
};
export const fonts = {
  displayLarge: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(42),
  },
  displayMedium: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(40),
  },
  displaySmall: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(38),
  },
  headlineLarge: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(36),
  },
  headlineMedium: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(34),
  },
  headlineSmall: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(32),
  },
  titleLarge: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(30),
  },
  titleMedium: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(28),
  },
  titleSmall: {
    fontFamily: "MrEaves",

    fontSize: moderateScale(26),
  },
  bodyLarge: {
    fontFamily: "DM",

    fontSize: moderateScale(24),
  },
  bodyMedium: {
    fontFamily: "DM",

    fontSize: moderateScale(22),
  },
  bodySmall: {
    fontFamily: "DM",

    fontSize: moderateScale(20),
  },
  labelLarge: {
    fontFamily: "DM",
    fontSize: moderateScale(16),
  },
  labelMedium: {
    fontFamily: "DM",
    fontSize: moderateScale(14),
  },
  labelSmall: {
    fontFamily: "DM",
    fontSize: moderateScale(12),
  },
};
