import axios from "axios";
import { baseAPIURL } from "./../config";

export function getLanguage(userToken: string, language_id: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/languages/${language_id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getLanguages(userToken: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/languages`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}
