import { Button } from "@/common/components/Button";
import { capitalizeFirstLetter, goToLink } from "@/common/utils";
import { theme } from "@/theme";
import moment from "moment";
import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import { animated as Animated, useTransition } from "react-spring";
import styles from "./styles";
const AnimatedView = Animated(View);
const Section = () => {
  const { t } = useTranslation();
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const refMap = useMemo(() => new WeakMap(), []);
  const transitions = useTransition(deliveries, {
    from: { opacity: 0, height: 0 },
    keys: (item) => item.code,
    enter: (item) => async (next) => {
      await next({ opacity: 1, height: refMap.get(item).offsetHeight });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    trail: 100,
    config: { tension: 125, friction: 20, precision: 0.1 },
  });
  return (
    <View style={styles.container}>
      <Text variant="titleMedium" style={styles.dataTableTitleMobile}>
        {t("TextDeliveries")}
      </Text>
      <View style={[styles.listContainer]}>
        {transitions((style, delivery: Delivery) => (
          <AnimatedView style={{ ...style }}>
            <Surface
              ref={(ref) => ref && refMap.set(delivery, ref)}
              style={styles.dataTableContainerMobile}
              key={delivery._id}
            >
              <Text style={styles.dataTableTextTitleMobile}>
                {t("DeliveryDataTableDelivery") + ": "}
                <Text style={styles.dataTableTextMobile}>{delivery.code}</Text>
              </Text>
              <Text style={styles.dataTableTextTitleMobile}>
                {t("DeliveryDataTableDeliveryDate") + ": "}
                <Text style={styles.dataTableTextMobile}>
                  {moment(delivery.delivery_date).format("L")}
                </Text>
              </Text>
              <Text style={styles.dataTableTextTitleMobile}>
                {t("DeliveryDataTableStatus") + ": "}
                <Text style={styles.dataTableTextMobile}>
                  {t("Text" + capitalizeFirstLetter(delivery.status))}
                </Text>
              </Text>
              <Text style={styles.dataTableTextTitleMobile}>
                {t("DeliveryDataTablePaymentStatus") + ": "}
                <Text style={styles.dataTableTextMobile}>
                  {t("Text" + capitalizeFirstLetter(delivery.payment.status))}
                </Text>
              </Text>
              <Text style={styles.dataTableTextTitleMobile}>
                {t("DeliveryDataTablePaymentValue") + ": "}
                <Text style={styles.dataTableTextMobile}>
                  {delivery.payment.value}€
                </Text>
              </Text>
              <Text style={styles.dataTableTextTitleMobile}>
                {t("DeliveryDataTablePaymentType") + ": "}
                <Text style={styles.dataTableTextMobile}>
                  {t(
                    "DeliveryDataTableType" +
                      capitalizeFirstLetter(delivery.payment.method.type)
                  )}
                </Text>
              </Text>
              <Text style={styles.dataTableTextTitleMobile}>
                {t("TextInvoice") + ": "}
                <Text style={styles.dataTableTextMobile}>
                  {delivery.pdf_link ? (
                    <View style={{ alignSelf: "center" }}>
                      <Button
                        color={theme.colors.background}
                        icon="receipt"
                        onPress={() => goToLink(delivery.pdf_link)}
                      >
                        {t("DeliveryDataTableInvoice")}
                      </Button>
                    </View>
                  ) : (
                    t("DeliveryDataTableNotAvailable")
                  )}
                </Text>
              </Text>
            </Surface>
          </AnimatedView>
        ))}
      </View>
    </View>
  );
};

export default memo(Section);
