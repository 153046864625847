import { LanguageDropdown } from "@/common/components/Dropdown";
import Logo from "@/common/components/Svgs/Logo";
import { useBreakpoint } from "@/common/hooks";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { IconButton, Searchbar, Text } from "react-native-paper";
import { animated as Animated } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const Header = ({ searchQuery, onChangeSearch }) => {
  const { t } = useTranslation();
  const { mobile } = useBreakpoint();
  return (
    <View style={styles.container}>
      <View style={styles.topBarContainer}>
        <View style={styles.logoContainer}>
          {mobile && <Logo style={styles.logo} />}
        </View>
        <View style={styles.actionsContainer}>
          <LanguageDropdown />
          <IconButton
            style={styles.notificationIcon}
            icon="bell-outline"
            size={moderateScale(25)}
          />
        </View>
      </View>
      <View style={styles.bannerContainer}>
        <View style={[styles.banner, mobile && { width: "100%" }]}>
          <View style={styles.infoContainer}>
            <Text style={styles.infoGreetings} variant={"labelMedium"}>
              {t("ExplorerTitle").toUpperCase()}
            </Text>
            <Searchbar
              style={styles.searchBar}
              inputStyle={styles.searchBarInput}
              placeholder={t("ExplorerSearchBarPlaceholder")}
              icon="magnify"
              value={searchQuery}
              onChangeText={onChangeSearch}
            />
          </View>
          <View style={styles.mainIconContainer}>
            <IconButton
              style={styles.icon}
              icon="basket-outline"
              size={moderateScale(35)}
              iconColor={theme.colors.onPrimary}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Header);
