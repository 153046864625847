import {
  MD3DarkTheme as DarkTheme,
  MD3LightTheme as LightTheme,
} from "react-native-paper";
import { dark_colors_2, light_colors_2 } from "./colors";
import { fonts } from "./fonts";
// const colorScheme = Appearance.getColorScheme();
const colorScheme = "light";
const default_theme = colorScheme === "dark" ? DarkTheme : LightTheme;
const default_colors = colorScheme === "dark" ? dark_colors_2 : light_colors_2;
// const default_theme = LightTheme;
// const default_colors = light_colors_3;
export const theme = {
  ...default_theme,
  colors: {
    ...default_colors,
  },
  fonts: fonts,
  shadows: {
    mainBoxShadow: {
      shadowColor: default_colors.shadow,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.23,
      shadowRadius: 2.62,

      elevation: 4,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1536,
    },
  },
};
