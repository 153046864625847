export const default_order_values = {
  country: "",
  name: "",
  email: "",
  nif: "",
  phone: "",
  address: "",
  city: "",
  zip_code: "",
  delivery_date: "",
  payment_type: "mbw",
  payment_phone: "",
  payment_iban: "",
  payment_acc_holder: "",
} as OrderFormValues;
