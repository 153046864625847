import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { TextInput } from "@/common/components/Input/";
import {
  emailValidator,
  nameValidator,
  passwordValidator,
} from "@/common/utils";
import { googleSignUp } from "@/common/utils/analytics.utils";
import { reset } from "@/redux/reducers/Register";
import { signin } from "@/redux/reducers/User";
import { register } from "@/services/auth.service";
import { moderateScale } from "@/theme/dimensions";
import { RootStackParamList } from "@/types";
import { RouteProp, useNavigation, useRoute } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
//import { googleLogin } from "@/common/utils/analytics.utils";
const ManualLogin = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const toast: any = useToast();
  const dispatch = useDispatch();
  const route: RouteProp<{ params: { referral?: string } }, "params"> =
    useRoute();
  const navigation = useNavigation<StackNavigationProp<RootStackParamList>>();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const { t } = useTranslation();
  const [name, setName] = useState({ value: "", error: "" });
  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  //const pushToken = useContext(PushTokenContext);

  const onSignUp = () => {
    const nameError = nameValidator(name.value);
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    const referral_id = route.params?.referral || "";
    if (emailError != "" || passwordError != "" || nameError != "") {
      setName({ ...name, error: nameError });
      setEmail({ ...email, error: emailError });
      setPassword({
        ...password,
        error: passwordError,
      });
      return;
    }
    setLoading(true);
    register(
      {
        name: name.value,
        email: email.value,
        password: password.value,
      },
      "user",
      referral_id,
      starting_form.values
    )
      .then(async (response: any) => {
        await googleSignUp({
          method: "Manual",
        });
        dispatch(signin(response.data));
        dispatch(reset());
        setLoading(false);
      })
      .catch((error: any) => {
        toast.show(
          `${t("BackendNotification" + error.response.data.message)}`,
          {
            type: "danger",
          }
        );
        setLoading(false);
      });
  };

  return (
    <>
      <View style={styles.goBackContainer}>
        <TouchableOpacity
          style={styles.goBackButton}
          onPress={props.toggleManualLogin}
        >
          <Icon name="chevron-left" size={moderateScale(18)} />
          <Text style={[styles.goBackButtonLabel]}>{t("TextGoBack")}</Text>
        </TouchableOpacity>
      </View>
      <TextInput
        containerStyle={[styles.input]}
        label={t("TextName")}
        returnKeyType="next"
        value={name.value}
        onChangeText={(text: string) =>
          setName({ value: text, error: nameValidator(text) })
        }
        error={name.error !== "" && [name.error]}
      />
      <TextInput
        containerStyle={[styles.input]}
        label={t("TextEmail")}
        returnKeyType="next"
        value={email.value}
        onChangeText={(text: string) =>
          setEmail({ value: text, error: emailValidator(text) })
        }
        error={email.error !== "" && [email.error]}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />
      <TextInput
        containerStyle={[styles.input]}
        label={t("TextPassword")}
        returnKeyType="done"
        value={password.value}
        onChangeText={(text: string) =>
          setPassword({ value: text, error: passwordValidator(text) })
        }
        error={password.error !== "" && [password.error]}
        secureTextEntry
      />
      <Button
        mode="contained"
        style={{ width: "100%" }}
        loading={loading}
        disabled={loading}
        onPress={async () => {
          onSignUp();
        }}
      >
        {t("TextSignUp")}
      </Button>
    </>
  );
};

export default memo(ManualLogin);
