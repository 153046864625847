import { globalStyles } from "@/common/styles";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native-paper";
const StructuredNames = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Text>
      {props.values.map((animal: RegisterFormValues, index: number) => {
        if (index !== 0 && index === props.values.length - 1) {
          return animal.sex === "male" ? (
            <Text key={"special_spelling_names_" + index}>
              {t("TextAnd")} {t("TextToM")}{" "}
              <Text style={globalStyles.highlighted}>{animal.name}</Text>
            </Text>
          ) : (
            <Text key={"special_spelling_names_" + index}>
              {t("TextAnd")} {t("TextToF")}{" "}
              <Text style={globalStyles.highlighted}>{animal.name}</Text>
            </Text>
          );
        } else {
          return animal.sex === "male" ? (
            <Text key={"special_spelling_names_" + index}>
              {t("TextToM")}{" "}
              <Text style={globalStyles.highlighted}>{animal.name} </Text>
            </Text>
          ) : (
            <Text key={"special_spelling_names_" + index}>
              {t("TextToF")}{" "}
              <Text style={globalStyles.highlighted}>{animal.name} </Text>
            </Text>
          );
        }
      })}
    </Text>
  );
};

export default memo(StructuredNames);
