import Dashboard from "@/views/Dashboard";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { DashboardParamList } from "../../types";
const Stack = createStackNavigator<DashboardParamList>();

export default function DashboardNavigator() {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, title: t("ScreenDashboardTitle") }}
    >
      <Stack.Screen name="Dashboard" component={Dashboard} />
    </Stack.Navigator>
  );
}
