import Background from "@/common/components/Background";
import ExplorerModule from "@/modules/Explorer";
import { getProducts } from "@/services/products.service";
import React, { memo, useEffect, useState } from "react";
import { ImageBackground, ScrollView } from "react-native";
import { ActivityIndicator } from "react-native-paper";
import { useSelector } from "react-redux";
import styles from "./styles";

const Explorer = () => {
  const [ready, setReady] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const user = useSelector((state: RootState) => state.user);
  useEffect(() => {
    Promise.all([getProducts(user.accessToken)]).then((response) => {
      setProducts(response[0].data);
      setReady(true);
    });
  }, []);
  return (
    <Background>
      <ImageBackground
        style={styles.imageBackground}
        source={require("@/assets/images/logo_transparent.png")}
        imageStyle={styles.repeatImage}
      >
        {ready ? (
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={styles.scrollableContainer}
          >
            <ExplorerModule products={products} />
          </ScrollView>
        ) : (
          <ActivityIndicator animating={true} size={"large"} />
        )}
      </ImageBackground>
    </Background>
  );
};

export default memo(Explorer);
