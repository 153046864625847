import Icon from "@/common/components/Icon";
import {
  getFinalPrice,
  getShippingCost,
  getTotalDiscount,
  getTotalWithoutDiscounts,
} from "@/common/utils";
import { moderateScale } from "@/theme/dimensions";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Card, Divider, Text } from "react-native-paper";
import { useSelector } from "react-redux";
import { animated as Animated } from "react-spring";
import styles from "./styles";
const AnimatedText = Animated(Text);
const Summary = () => {
  const { t } = useTranslation();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const shippings = useSelector((state: RootState) => state.shippings);

  const getShippingValue = () => {
    return getShippingCost(shippings, user, boxes, subscription);
  };
  return (
    <View style={styles.container}>
      <Text style={styles.title} variant="titleSmall">
        {t("TextSummary")}
      </Text>
      <Card style={styles.card}>
        <View style={styles.infoContainer}>
          <Text style={styles.infoTitle} variant="labelLarge">
            {t("TextSubTotal")}
          </Text>
          <View style={styles.infoDots}></View>
          <View style={{ alignSelf: "flex-end" }}>
            <Text style={styles.infoValue} variant="labelLarge">
              {getTotalWithoutDiscounts(boxes)}€
            </Text>
          </View>
        </View>
        {parseFloat(getTotalDiscount(user, boxes)) > 0 && (
          <View style={{ marginBottom: 10 }}>
            <View style={[styles.infoContainer, { marginBottom: 0 }]}>
              <Text style={styles.infoTitle} variant="labelLarge">
                {t("TextDiscounts")}
              </Text>
              <View style={styles.infoDots}></View>
              <Text style={styles.infoValue} variant="labelLarge">
                -{getTotalDiscount(user, boxes)}€
              </Text>
            </View>
            {user.promocode && (
              <Text style={styles.infoAddOn} variant="labelSmall">
                {t("TextPromocode")}{" "}
                <Text style={{ fontWeight: "bold" }} variant="labelSmall">
                  {user.promocode.code}
                </Text>
                {" - "}
                <Text style={styles.infoAddOnGreen} variant="labelSmall">
                  {user.promocode.value}
                  {"%"}
                </Text>
              </Text>
            )}
          </View>
        )}
        <View style={{ marginBottom: 10 }}>
          <View style={[styles.infoContainer, { marginBottom: 0 }]}>
            <Text style={styles.infoTitle} variant="labelLarge">
              {t("TextShipping")}
            </Text>
            <View style={styles.infoDots}></View>
            <Text
              style={[
                styles.infoValue,
                getShippingValue() === "0" ? styles.infoValueSuccess : {},
              ]}
              variant="labelLarge"
            >
              {getShippingValue() === "0"
                ? t("TextFree")
                : getShippingValue() + "€"}
            </Text>
          </View>
          {getShippingValue() !== "0" && (
            <Text style={styles.infoAddOn} variant="labelSmall">
              <Icon
                style={styles.checkpointInfoIcon}
                name="information"
                size={moderateScale(14)}
              />
              {t("TextFirstTimeScreenPromo")}
            </Text>
          )}
        </View>
        <Divider style={styles.divider} />
        <View style={[styles.infoContainer, styles.infoContainerFinal]}>
          <Text style={styles.infoTitle} variant="labelLarge">
            {t("TextTotal")}
          </Text>
          <View style={styles.infoDots}></View>
          <Text style={styles.infoValue} variant="labelLarge">
            {getFinalPrice(user, boxes, parseFloat(getShippingValue()))}€
          </Text>
        </View>
      </Card>
    </View>
  );
};

export default memo(Summary);
