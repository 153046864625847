import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    borderTopRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: BORDER_RADIUS,
    backgroundColor: theme.colors.background,
    height: "100%",
  },
  scrollableViewContainer: {
    width: "100%",
  },
  stageContainer: {
    width: "100%",
    maxWidth: 1200,
    alignSelf: "center",
    justifyContent: "center",
  },
  stageWrapper: {
    width: "100%",
    height: "100%",
  },
  backgroundEffectContainer: {
    width: "100%",
    height: "80%",
    margin: 0,
    backgroundColor: theme.colors.surfaceVariant,
  },
  background: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.colors.background,
    borderBottomRightRadius: 100,
    overflowX: "hidden",
  },
});
