import { moderateScale } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: { width: "100%" },
  numericInputContainer: { marginBottom: 10 },
  quantityInput: {
    maxWidth: moderateScale(150),
  },
  button: {},
  title: { marginBottom: 10 },
});
