import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  accordionButton: {
    borderRadius: BORDER_RADIUS,
    padding: 0,
  },
  accordionIcon: {
    marginLeft: 10,
  },
  backButton: {},
  background: {
    backgroundColor: theme.colors.background,
    borderBottomRightRadius: 100,
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  tagDiscount: {
    paddingHorizontal: 10,
    paddingVertical: 5,
    backgroundColor: theme.colors.error,
    color: theme.colors.onError,
    borderRadius: BORDER_RADIUS,
  },
  tagIcon: {
    marginRight: 3,
    color: theme.colors.onError,
  },
  productPriceStrike: {
    marginTop: 5,
    textDecorationLine: "line-through",
    textAlign: "center",
  },
  backgroundEffectContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    height: "80%",
    justifyContent: "flex-end",
    margin: 0,
    width: "100%",
  },
  bottomAreaContainer: {
    alignItems: "center",
    backgroundColor: theme.colors.surfaceVariant,
    bottom: 0,
    height: "20%",
    justifyContent: "center",
    width: "100%",
  },
  componentsTitle: {
    paddingLeft: 5,
    paddingTop: 20,
    textAlign: "left",
  },
  container: {
    width: "100%",
  },
  mobileContainer: {
    width: "100%",
    flexDirection: "row",
    maxWidth: 1200,
    alignSelf: "center",
    paddingHorizontal: 30,
  },
  content: {
    backgroundColor: theme.colors.background,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    height: "100%",
  },
  contentTitle: {
    fontSize: 20,
    marginBottom: 12,
  },
  contentView: {
    justifyContent: "flex-end",
    margin: 0,
  },
  favoriteButton: {},
  numericInputContainer: {
    marginBottom: 10,
    width: "100%",
  },
  productActions: {
    alignItems: "center",
    width: "50%",
  },
  productContainer: {
    width: "100%",
    maxWidth: 1200,
  },
  productDescription: {
    marginTop: 5,
    opacity: 0.7,
    textAlign: "justify",
  },
  productFinalPrice: {
    color: theme.colors.onSurfaceVariant,
    fontWeight: "bold",
  },
  productFinalPriceTitle: {
    color: theme.colors.onSurfaceVariant,
    opacity: 0.7,
  },
  productInfoContainer: {
    paddingHorizontal: 30,
    paddingVertical: 10,
    width: "100%",
  },
  productInfoSubtitle: {
    color: theme.colors.onSurfaceVariant,
  },
  productPrice: {
    fontWeight: "bold",
    marginTop: 5,
    marginRight: 5,
  },
  priceContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  productPriceContainer: {
    width: "50%",
  },
  productPropertyContainer: {
    alignItems: "center",
    padding: 10,
    paddingLeft: 10,
    width: "100%",
  },
  productSlogan: {
    marginTop: 5,
    opacity: 0.7,
  },
  productTitle: {
    marginTop: 5,
  },
  productViewProperties: {
    marginTop: 20,
    padding: 0,
    width: "100%",
  },
  productViewPropertyContainer: {
    borderRadius: BORDER_RADIUS,
    marginBottom: 10,
    padding: 2,
  },
  quantityInput: {
    textAlign: "center",
    width: 60,
  },
  quantityInputButton: {
    backgroundColor: theme.colors.onSurfaceVariant,
    borderRadius: BORDER_RADIUS,
  },
  scrollableView: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  tag: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    color: theme.colors.onSurfaceVariant,
    marginRight: 3,
    paddingHorizontal: 5,
    paddingVertical: 1,
  },
  tagContainer: {
    flexDirection: "row",
    marginBottom: 10,
    marginTop: 5,
  },
  topActionsContainer: {
    width: "100%",
  },
  topBarContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    width: "100%",
  },
});
