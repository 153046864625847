import Box from "@/common/components/Svgs/Box";
import { useBreakpoint } from "@/common/hooks";
import {
  getTotalWithFakeDiscountAndWithShipping,
  isObjectEmpty,
} from "@/common/utils";
import { goToSlide } from "@/redux/reducers/Order";
import { update } from "@/redux/reducers/Subscription";
import animals from "@/router/routes/animals";
import { cancelSubscription } from "@/services/subscription.service";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import moment from "moment";
import React, { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Avatar, Card, Divider, Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { animated as Animated, config, useSpring } from "react-spring";
import styles from "./styles";
const AnimatedView = Animated(View);
const BOX_VIEWPORT_H = moderateScale(164);
const SubscriptionPhase = ({ toast }: { toast: ToastType }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { mobile } = useBreakpoint();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const [latestDelivery, setLatestDelivery] = useState(undefined);
  const [progress, setProgress] = useState(4);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const animated_height = useSpring({
    from: { height: 0 },
    to: { height: ((progress + 1) / 4) * BOX_VIEWPORT_H },
    config: config.slow,
  });
  useEffect(() => {
    if (
      isObjectEmpty(subscription) ||
      subscription.payment.status === "failed" ||
      !subscription.active
    ) {
      dispatch(goToSlide(0));
      return;
    }
    let latestDelivery = deliveries.reduce(function (r, a) {
      return r.delivery_date > a.delivery_date ? r : a;
    });
    setLatestDelivery(latestDelivery);
  }, [subscription]);

  const onPressToCancelPayment = () => {
    setLoadingCancel(true);
    cancelSubscription(user.accessToken, subscription._id)
      .then((response) => {
        dispatch(update({ ...response.data.subscription, active: false }));
        setLoadingCancel(false);
      })
      .catch((error) => {
        toast.show(t(`BackendNotification${error.response.data.message}`), {
          type: "danger",
        });
        console.error(error);
        setLoadingCancel(false);
      });
  };

  const stages = [
    {
      icon: "repeat",
      stage: <Fragment />,
    },
  ];
  return (
    latestDelivery && (
      <View style={styles.container}>
        <View style={styles.infoContainer}>
          <Text style={styles.infoTitle} variant="titleSmall">
            {t("WaitingSubscriptionTitle")}
          </Text>
          <Text style={styles.infoSubtitle} variant="labelLarge">
            {t(`WaitingSubscriptionSubtitle`)}
          </Text>
          <View style={styles.infoBoxIconContainer}>
            <View>
              <Box style={styles.infoBoxIcon} />
              <AnimatedView
                style={{
                  ...animated_height,
                  ...styles.infoBoxIconWrapper,
                }}
              >
                <Box style={styles.infoBoxIconProgress} />
              </AnimatedView>
            </View>
          </View>
          {stages[0].stage}
          <Text style={styles.infoDescription} variant="labelLarge">
            {t(`WaitingSubscriptionDescription`)}
          </Text>
          <Text style={styles.infoDescription} variant="labelMedium">
            {t(`WaitingSubscriptionDescription2`)}{" "}
            {moment(subscription.next_delivery_date)
              .subtract(2, "days")
              .format("DD/MM/YYYY")}
          </Text>
          <Text style={styles.infoDescription} variant="labelMedium">
            {t(`WaitingSubscriptionDescription3`)}{" "}
            {moment(subscription.next_delivery_date).format("DD/MM/YYYY")}
          </Text>
          <View
            style={{ width: "100%", alignItems: "center", marginBottom: 20 }}
          >
            {subscription.tier === 2 && (
              <Card
                style={[
                  styles.featuresContainer,
                  subscription.tier === 2 ? styles.successBorder : {},
                ]}
                elevation={subscription.tier == 2 ? 4 : 1}
              >
                <Text style={styles.discountSticker}>
                  {`-${animals.length > 1 ? 15 : 10}%`}
                </Text>
                <Text style={styles.featuresTitle}>
                  {t("TextRepurchasePriceTime2")}
                </Text>
                <Text style={styles.featuresPrice}>
                  {parseFloat(
                    getTotalWithFakeDiscountAndWithShipping(
                      boxes,
                      0,
                      animals.length > 1 ? 15 : 10
                    )
                  ).toFixed(2)}
                  €
                </Text>
                <Text style={styles.featuresText}>
                  {t("TextRepurchasePriceSubtitle")}
                </Text>
                <Divider style={{ marginVertical: 5 }} />
                <Card.Content style={styles.featuresAddonsContainer}>
                  <Text style={styles.featuresAddonsTitle}>
                    {t("TextRepurchaseAddonsTitle")}
                  </Text>
                  <View style={{ width: "fit-content", alignSelf: "center" }}>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage1")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage2")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage3")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage4")}
                      </Text>
                    </View>
                  </View>
                </Card.Content>
              </Card>
            )}
            {subscription.tier === 3 && (
              <Card
                style={[
                  styles.featuresContainer,
                  subscription.tier === 3 ? styles.successBorder : {},
                ]}
                elevation={subscription.tier == 3 ? 4 : 1}
              >
                <Text style={styles.discountSticker}>
                  {`-${animals.length > 1 ? 25 : 20}%`}
                </Text>
                <Text style={styles.featuresTitle}>
                  {t("TextRepurchasePriceTime3")}
                </Text>
                <Text style={styles.featuresPrice}>
                  {parseFloat(
                    getTotalWithFakeDiscountAndWithShipping(
                      boxes,
                      0,
                      animals.length > 1 ? 25 : 20
                    )
                  ).toFixed(2)}
                  €
                </Text>
                <Text style={styles.featuresText}>
                  {t("TextRepurchasePriceSubtitle")}
                </Text>
                <Divider style={{ marginVertical: 5 }} />
                <Card.Content style={styles.featuresAddonsContainer}>
                  <Text style={styles.featuresAddonsTitle}>
                    {t("TextRepurchaseAddonsTitle")}
                  </Text>
                  <View style={{ width: "fit-content", alignSelf: "center" }}>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage1")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage2")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage3")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage4")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage5")}
                      </Text>
                    </View>
                    <View style={styles.featuresAddon}>
                      <Avatar.Icon
                        size={moderateScale(20)}
                        color={theme.colors.onSurfaceVariant}
                        style={styles.promoImageInfoListItemIcon}
                        icon={"check"}
                      />
                      <Text style={styles.featuresAddonsText}>
                        {t("TextRepurchasePriceAdvantage6")}
                      </Text>
                    </View>
                  </View>
                </Card.Content>
              </Card>
            )}
          </View>
          <Text style={styles.infoDescription} variant="labelMedium">
            {t(`WaitingSubscriptionDescription4`)}
          </Text>
          <Text style={styles.infoDescription} variant="labelLarge">
            {t(`WaitingSubscriptionDescription5`)}
          </Text>
          <Text
            style={[styles.infoDescription, { fontWeight: "bold" }]}
            variant="labelLarge"
          >
            {t(`WaitingSubscriptionWarning`)}
          </Text>
        </View>
      </View>
    )
  );
};

export default memo(SubscriptionPhase);
