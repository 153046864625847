import moment from "moment";
import { Linking, Platform } from "react-native";
import {
  experimentalPackageID,
  our_products,
  shippingBoxValue,
  shippingFree1,
  shippingFree2,
} from "../../config";
import { theme } from "../../theme";
const BOX_VALUE = shippingBoxValue;
export const emailValidator = (email: string | any) => {
  const re = /\S+@\S+\.\S+/;

  if (!email || email.length <= 0) return "ErrorEmailCannotBeEmpty";
  if (!re.test(email)) return "ErrorEmailMustBeValidEmailAddress";

  return "";
};

export const goToLink = async (link: string) => {
  const supported = await Linking.canOpenURL(link);
  if (supported) {
    await Linking.openURL(link);
  }
};

export const discountProducts = () => {
  const discount_products = our_products.slice(0, 3);
  return [...discount_products, experimentalPackageID];
};

export const isUserAwaitingDelivery = (
  subscription: Subscription,
  deliveries: Delivery[],
): boolean => {
  return (
    !isObjectEmpty(subscription) &&
    deliveries.some((d) => d.status === "processing" || d.status === "shipped")
  );
};
export const isUserInAnActiveSubscription = (
  subscription: Subscription,
  deliveries: Delivery[],
): boolean => {
  return (
    !isObjectEmpty(subscription) &&
    subscription.payment.type === "subscription" &&
    subscription.payment.status === "paid"
  );
};
export const isUserExperimenting = (
  boxes: Box[],
  subscription: Subscription,
  deliveries: Delivery[],
): boolean => {
  return (
    (isObjectEmpty(subscription) ||
      !deliveries.some((d) =>
        ["pending", "paid"].includes(d.payment.status),
      )) &&
    boxes.some((b: Box) =>
      b.products.some(
        (bp: BoxProduct) => bp.product._id === experimentalPackageID,
      ),
    )
  );
};

export const passwordValidator = (password: string | any) => {
  if (!password || password.length <= 0) return "ErrorPasswordCannotBeEmpty";

  return "";
};

export const addToDate = (date: string, days: number) => {
  var date = new Date(date); // Now
  date.setDate(date.getDate() + days); // Set now + days as the new date
  return date;
};

export const isBusinessDay = (date: string) => {
  var dayOfWeek = new Date(date).getDay();
  return !(dayOfWeek === 6 || dayOfWeek === 0 || dayOfWeek === 1);
};

export const objectsEqual = (o1, o2) => {
  return typeof o1 === "object" && Object.keys(o1).length > 0
    ? Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
    : o1 === o2;
};

export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const nameValidator = (name: string | any) => {
  if (!name || name.length <= 0) return "ErrorNameCannotBeEmpty";

  return "";
};
export const isObjectEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export const getTotalWeight = (boxes: Box[]) => {
  let total = 0;
  boxes.forEach((box) => {
    total += getWeightOfTempProducts(box.products);
  });
  return total.toFixed(2);
};

export const getShippingCost = (
  shipping_data: Shipping[],
  user: User,
  boxes: Box[],
  subscription: Subscription,
) => {
  let shipping_cost = "";
  let weight = 0;
  let total = 0;
  let discount = 0;
  if (user.promocode) {
    discount += user.promocode.value;
  } else if (user.friend_discount) {
    discount += user.friend_discount.discount;
  }
  let hasExperimentalBox = false;
  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      if (box_product.product._id === experimentalPackageID) {
        hasExperimentalBox = true;
      }
      total +=
        box_product.product.price *
        box_product.quantity *
        (1 -
          (box_product.product.discount + discount >= 100
            ? 100
            : box_product.product.discount + discount) /
            100);
      weight += box_product.product.weight * box_product.quantity;
    });
  });

  if (user.cash > 0 && user.cash < total) {
    total -= user.cash;
  } else if (user.cash > 0) {
    total = 0;
  }
  if (
    isArrayEmpty(shipping_data) ||
    weight == 0 ||
    subscription.tier > 1 ||
    hasExperimentalBox
  )
    return "0";
  if (weight > 0 && weight <= 2) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T1").price,
    ).toFixed(2);
  } else if (weight > 2 && weight <= 5) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T2").price,
    ).toFixed(2);
  } else if (weight > 5 && weight <= 10) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T3").price,
    ).toFixed(2);
  } else if (weight > 10 && weight <= 20) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T4").price,
    ).toFixed(2);
  } else if (weight > 20 && weight <= 30) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T5").price,
    ).toFixed(2);
  } else if (weight > 30) {
    let added_price = parseFloat(
      shipping_data.find((type) => type.code == "TA").price,
    );
    let extra_weight = parseFloat(Math.floor(weight - 30));
    shipping_cost = (
      parseFloat(shipping_data.find((type) => type.code == "T5").price) +
      added_price * extra_weight
    ).toFixed(2);
  } else {
    throw new Error("Weight calculation not defined!");
  }
  if (total < shippingFree1) {
    shipping_cost = (parseFloat(shipping_cost) + BOX_VALUE).toFixed(2);
  } else if (total > shippingFree2) {
    shipping_cost = "0";
  }
  return shipping_cost;
};
export const getDefaultShippingCost = (
  shipping_data: Shipping[],
  user: User,
  boxes: Box[],
) => {
  let shipping_cost = "";
  let weight = 0;
  let total = 0;
  let discount = 0;
  if (user.promocode) {
    discount += user.promocode.value;
  } else if (user.friend_discount) {
    discount += user.friend_discount.discount;
  }

  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      total +=
        box_product.product.price *
        box_product.quantity *
        (1 -
          (box_product.product.discount + discount >= 100
            ? 100
            : box_product.product.discount + discount) /
            100);
      weight += box_product.product.weight * box_product.quantity;
    });
  });

  if (user.cash > 0 && user.cash < total) {
    total -= user.cash;
  } else if (user.cash > 0) {
    total = 0;
  }
  if (isArrayEmpty(shipping_data) || weight == 0) return "0";
  if (weight > 0 && weight <= 2) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T1").price,
    ).toFixed(2);
  } else if (weight > 2 && weight <= 5) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T2").price,
    ).toFixed(2);
  } else if (weight > 5 && weight <= 10) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T3").price,
    ).toFixed(2);
  } else if (weight > 10 && weight <= 20) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T4").price,
    ).toFixed(2);
  } else if (weight > 20 && weight <= 30) {
    shipping_cost = parseFloat(
      shipping_data.find((type) => type.code == "T5").price,
    ).toFixed(2);
  } else if (weight > 30) {
    let added_price = parseFloat(
      shipping_data.find((type) => type.code == "TA").price,
    );
    let extra_weight = parseFloat(Math.floor(weight - 30));
    shipping_cost = (
      parseFloat(shipping_data.find((type) => type.code == "T5").price) +
      added_price * extra_weight
    ).toFixed(2);
  } else {
    throw new Error("Weight calculation not defined!");
  }
  if (total < shippingFree1) {
    shipping_cost = (parseFloat(shipping_cost) + BOX_VALUE).toFixed(2);
  } else if (total > shippingFree2) {
    shipping_cost = "0";
  }
  return shipping_cost;
};

export const validateNIF = (nif: any) => {
  if (
    !["1", "2", "3", "5", "6", "8"].includes(nif.substr(0, 1)) &&
    !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
      nif.substr(0, 2),
    )
  )
    return false;

  let total =
    nif[0] * 9 +
    nif[1] * 8 +
    nif[2] * 7 +
    nif[3] * 6 +
    nif[4] * 5 +
    nif[5] * 4 +
    nif[6] * 3 +
    nif[7] * 2;

  let modulo11 = total - parseInt(total / 11) * 11;
  let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

  return nif[8] == comparador;
};

export const getAnimalBox = (boxes: Box[], animal_id: string) => {
  return boxes.find((b) => b.animal === animal_id);
};

export const getBestFoodQuantityForAnimal = (animal: Animal) => {
  if (!!!animal) return "Animal não encontrado";
  let age_in_weeks = moment().diff(animal.birthday, "days");
  let weight = parseFloat(animal.weight);
  let lifestyle_value = 0.02;
  let value = "";
  if (age_in_weeks <= 10) {
    value = (weight * 0.1).toFixed(3);
  } else if (age_in_weeks <= 16) {
    value = (weight * 0.08).toFixed(3);
  } else if (age_in_weeks <= 20) {
    value = (weight * 0.07).toFixed(3);
  } else if (age_in_weeks <= 24) {
    value = (weight * 0.06).toFixed(3);
  } else if (age_in_weeks <= 36) {
    value = (weight * 0.05).toFixed(3);
  } else if (age_in_weeks <= 56) {
    value = (weight * 0.04).toFixed(3);
  } else if (age_in_weeks <= 68) {
    value = (weight * 0.035).toFixed(3);
  } else {
    value = (weight * lifestyle_value).toFixed(3);
  }
  return value;
};

export const getFoodQuantityPerMonth = (animal: Animal, foodWeight: number) => {
  if (!animal) return 0;
  let quantity = 1;
  let required_food_per_month = Math.round(
    parseFloat(getBestFoodQuantityForAnimal(animal)) * 30,
  );
  while ((quantity * foodWeight) / required_food_per_month < 1) quantity += 1;
  return quantity;
};

export const getPriceOfTempProducts = (box_products: Array<BoxProduct>) => {
  let sum = 0;
  box_products.forEach((box_product) => {
    sum +=
      box_product.product?.price *
      box_product.quantity *
      (1 - box_product.product?.discount / 100);
  });
  return sum;
};
export const getPriceOfTempProductsWithoutDiscount = (
  box_products: Array<BoxProduct>,
) => {
  let sum = 0;
  box_products.forEach((box_product) => {
    sum += box_product.product.price * box_product.quantity;
  });
  return sum;
};

export const getWeightOfTempProducts = (box_products: Array<BoxProduct>) => {
  let sum = 0;
  box_products.forEach((box_product) => {
    sum += box_product.product.weight * box_product.quantity;
  });
  return sum;
};

export const getTotal = (boxes: Box[]) => {
  let total = 0;
  boxes.forEach((box) => {
    total += getPriceOfTempProducts(box.products);
  });
  return total.toFixed(2);
};
export const getTranslationLanguage = (language: string): string => {
  let language_result = "default";
  const language_code = language.slice(0, 2);
  if (language_code && language_code !== "en") language_result = language_code;
  if (language_code && ["ca", "es", "gl", "eu"].includes(language_code))
    language_result = "es";

  return language_result;
};
export const isLanguageSpanishVariant = (language: string): boolean => {
  const language_code = language.slice(0, 2);

  return !!language_code && ["ca", "es", "gl", "eu"].includes(language_code);
};
export const getBoxesTotal = (boxes: Box[]) => {
  let total = 0;
  boxes.forEach((box) => {
    total += getPriceOfTempProducts(box.products);
  });
  return total;
};
export const getTotalWithoutDiscounts = (boxes: Box[]) => {
  let total = 0;
  boxes.forEach((box) => {
    total += getPriceOfTempProductsWithoutDiscount(box.products);
  });
  return total.toFixed(2);
};
export const getTotalWithoutDiscountsButWithShipping = (
  boxes: Box[],
  shippingValue: number,
) => {
  let total = 0;
  boxes.forEach((box) => {
    total += getPriceOfTempProductsWithoutDiscount(box.products);
  });
  if (total < shippingFree1) {
    total += shippingValue + BOX_VALUE;
  } else if (total < shippingFree2) {
    total += shippingValue;
  }
  return total.toFixed(2);
};
export const getTotalWithoutDiscountButWithShipping = (
  boxes: Box[],
  shippingValue: number,
) => {
  let total = 0;
  boxes.forEach((box) => {
    total += getPriceOfTempProducts(box.products);
  });
  if (total < shippingFree1 && shippingValue > 0) {
    total += shippingValue + BOX_VALUE;
  } else if (total < shippingFree2 && shippingValue > 0) {
    total += shippingValue;
  }
  return total.toFixed(2);
};
export const getTotalWithFakeDiscountAndWithShipping = (
  boxes: Box[],
  shippingValue: number,
  discount: number,
) => {
  let total = 0;
  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      total +=
        box_product.product.price *
        box_product.quantity *
        (1 - (box_product.product.discount + discount) / 100);
    });
  });
  if (total < shippingFree1 && shippingValue > 0) {
    total += shippingValue + BOX_VALUE;
  } else if (total < shippingFree2 && shippingValue > 0) {
    total += shippingValue;
  }
  return total.toFixed(2);
};

export const roundNumber = (num: number, places: number) => {
  return +(Math.round(parseFloat(num + "e+" + places)) + "e-" + places);
};

export const getTotalWithoutShipping = (user: User, boxes: Box[]) => {
  let total = 0;
  let discount = 0;
  if (user.promocode) {
    discount += user.promocode.value;
  } else if (user.friend_discount) {
    discount += user.friend_discount.discount;
  }
  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      total +=
        box_product.product.price *
        box_product.quantity *
        (1 -
          (box_product.product.discount + discount >= 100
            ? 100
            : box_product.product.discount + discount) /
            100);
    });
  });

  if (user.cash > 0 && user.cash < total) {
    total -= user.cash;
  } else if (user.cash > 0) {
    total = 0;
  }
  return total.toFixed(2);
};

export const getTotalWithDiscount = (
  user: User,
  boxes: Box[],
  shippingValue: number,
) => {
  let total = 0;
  let discount = 0;
  if (user.promocode) {
    discount += user.promocode.value;
  } else if (user.friend_discount) {
    discount += user.friend_discount.discount;
  }
  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      total +=
        box_product.product.price *
        box_product.quantity *
        (1 -
          (box_product.product.discount + discount >= 100
            ? 100
            : box_product.product.discount + discount) /
            100);
    });
  });
  if (user.cash > 0 && user.cash < total) {
    total -= user.cash;
  } else if (user.cash > 0) {
    total = 0;
  }
  if (total < shippingFree1) {
    total += shippingValue + BOX_VALUE;
  } else if (total < shippingFree2) {
    total += shippingValue;
  }
  return total.toFixed(2);
};
export const getFinalPrice = (
  user: User,
  boxes: Box[],
  shippingValue: number,
) => {
  let total = 0;
  let discount = 0;
  if (user.promocode) {
    discount += user.promocode.value;
  } else if (user.friend_discount) {
    discount += user.friend_discount.discount;
  }
  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      total +=
        box_product.product.price *
        box_product.quantity *
        (1 -
          (box_product.product.discount + discount >= 100
            ? 100
            : box_product.product.discount + discount) /
            100);
    });
  });
  if (user.cash > 0 && user.cash < total) {
    total -= user.cash;
  } else if (user.cash > 0) {
    total = 0;
  }
  return (total + shippingValue).toFixed(2);
};
export const getTotalDiscount = (user: User, boxes: Box[]) => {
  let total = 0;
  let discount = 0;
  if (user.promocode) {
    discount += user.promocode.value;
  } else if (user.friend_discount) {
    discount += user.friend_discount.discount;
  }
  boxes.forEach((box) => {
    box.products.forEach((box_product) => {
      total +=
        box_product.product.price *
        box_product.quantity *
        ((box_product.product.discount + discount >= 100
          ? 100
          : box_product.product.discount + discount) /
          100);
    });
  });
  if (user.cash > 0 && user.cash < total) {
    total -= user.cash;
  } else if (user.cash > 0) {
    total = 0;
  }
  return total.toFixed(2);
};

export const isProductInStore = (
  animal: Animal,
  boxes: Box[],
  product: Product,
) => {
  if (!!animal && !!boxes) {
    let box = boxes.find((b) => animal._id == b.animal);
    if (!!box) {
      return !!box.products.find((p) => product._id == p.product._id);
    }
  }
  return false;
};

export const isArrayEmpty = (array: Array<any>) => {
  return !!array && array.length === 0;
};

export const isMobileSize = (dimensions: any) => {
  return Platform.OS !== "web" || dimensions.window.width < 642;
};

export function createRows(data: Array<any>, columns: number) {
  let new_data = [...data];
  const rows = Math.floor(new_data.length / columns); // [A]
  let lastRowElements = new_data.length - rows * columns; // [B]
  while (lastRowElements !== columns) {
    // [C]
    new_data.push({
      // [D]
      _id: `empty-${lastRowElements}`,
      name: `empty-${lastRowElements}`,
      empty: true,
    });
    lastRowElements += 1; // [E]
  }
  return new_data; // [F]
}

export const screenSizeType = (dimensions: any) => {
  if (dimensions.window.width < theme.sizes.screen_xs) {
    return "xs";
  } else if (dimensions.window.width < theme.sizes.screen_s) {
    return "s";
  } else if (dimensions.window.width < theme.sizes.screen_m) {
    return "m";
  } else if (dimensions.window.width < theme.sizes.screen_l) {
    return "l";
  } else if (dimensions.window.width < theme.sizes.screen_xl) {
    return "xl";
  } else {
    return "xxl";
  }
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy;
  }
  return new File([u8arr], filename + "." + mime.split("/")[1], { type: mime });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const createAnimalFormData = (animal: Animal) => {
  const data = new FormData();

  Object.keys(animal).forEach((key) => {
    if (key == "images") {
      animal[key].forEach((image) => {
        data.append("photo", {
          uri: image.uri,
          name: image.name,
          type: `image/${image.type}`,
        });
      });
    } else if (key == "birthday") {
      data.append(key, new Date(animal[key]).toISOString());
    } else {
      data.append(key, animal[key]);
    }
  });

  return data;
};

export const handleProductInsertion = (
  products: Array<BoxProduct>,
  product_to_insert: Product,
) => {
  const notfirstInsertion = products.find(
    (p) => product_to_insert._id == p.product._id,
  );
  if (!!notfirstInsertion) {
    if (
      !!notfirstInsertion.quantity &&
      notfirstInsertion.quantity < notfirstInsertion.product.quantity
    ) {
      notfirstInsertion.quantity += 1;
    }
  } else {
    products.push({ product: product_to_insert, quantity: 1 });
  }
  return products;
};

export const handleProductRemoval = (
  products: Array<BoxProduct>,
  product_to_remove: Product,
) => {
  const productFound = products.find(
    (p) => product_to_remove._id == p.product._id,
  );
  if (!!productFound) {
    if (!!productFound.quantity && productFound.quantity > 1) {
      productFound.quantity -= 1;
    }
  }
  return products;
};
