import More from "@/views/More";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { MoreParamList } from "../../types";

const Stack = createStackNavigator<MoreParamList>();

export default function MoreNavigator() {
  const { t } = useTranslation();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        title: t("ScreenMoreTitle"),
      }}
    >
      <Stack.Screen name="More" component={More} />
    </Stack.Navigator>
  );
}
