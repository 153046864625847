import { theme } from "@/theme";
import { StyleSheet } from "react-native";
import { BORDER_RADIUS } from "./../../../../theme/dimensions/index";

export default StyleSheet.create({
  container: {
    width: "100%",
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  backButton: { zIndex: 10 },
  trackerContainer: {
    flexDirection: "row",
    width: "100%",
    position: "absolute",
    justifyContent: "center",
    paddingTop: 15,
  },
  stageButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  stageButton: {
    paddingHorizontal: 5,
    paddingVertical: 3,
    backgroundColor: theme.colors.secondary,
    borderRadius: BORDER_RADIUS,
    width: 30,
  },
  stageButtonDisabled: {
    backgroundColor: theme.colors.surfaceDisabled,
  },
  stageButtonText: {
    color: theme.colors.onBackground,
    textAlign: "center",
    marginTop: 5,
  },
  stageButtonNumber: {
    color: theme.colors.onSecondary,
    textAlign: "center",
  },
  stageButtonNumberDisabled: {
    color: theme.colors.onSurfaceDisabled,
  },
  connectionContainer: { width: 30 },
  connectionDotted: {
    width: "100%",
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.surfaceDisabled,
    borderBottomStyle: "dotted",
    marginTop: 9,
    height: 2,
  },
  connectionAnimated: {
    borderBottomWidth: 2,
    borderBottomColor: theme.colors.secondary,
    marginTop: 9,
    height: 2,
    position: "absolute",
  },
});
