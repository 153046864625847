import { ImageButton } from "@/common/components/Button";
import { updateAll as updateBoxes } from "@/redux/reducers/Boxes";
import { update } from "@/redux/reducers/Subscription";
import { update as updateUser } from "@/redux/reducers/User";
import { createMonthlyBox } from "@/services/boxes.service";
import { theme } from "@/theme";
import React, { Fragment, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Button, Chip, Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const Main = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const animals = useSelector((state: RootState) => state.animals);
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    values: animals.map((a) => {
      return { ...a };
    }),
    errors: [],
  });
  const onSelectButton = (name: string, index: number, type: string) => {
    setState({
      ...state,
      values: state.values.map((form, jndex) => {
        if (index === jndex) {
          form[name] = type;
        }
        return form;
      }),
    });
  };
  const onUpdateButton = () => {
    setLoading(true);
    createMonthlyBox(user.accessToken, state.values)
      .then((response) => {
        dispatch(updateBoxes(response.data.boxes));
        dispatch(updateUser({ ...user, promocode: response.data.promocode }));
        dispatch(update(response.data.subscription));
        setLoading(false);
      })
      .catch((error) => {
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
        setLoading(false);
      });
  };

  return (
    <View style={[styles.headerContainer]}>
      <View style={[styles.header]}>
        <View style={[styles.headerInfo]}>
          <Text variant={"titleSmall"} style={[styles.headerInfoTitle]}>
            {`${t("ScreenRepurchaseQuestionsTitle")}`}
          </Text>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.body}>
          <Text variant="bodySmall" style={styles.title}>
            {state.values.length > 1
              ? t("TextGetStartedSubtitle12P")
              : t("TextGetStartedSubtitle12S")}
          </Text>
          <Text variant="labelMedium" style={styles.subtitle}>
            {t("TextGetStartedSubtitleSubtitle12")}
          </Text>
          {state.values.map((form, index) => (
            <Fragment key={"Form_neutered_" + index}>
              <Chip style={styles.animalName} icon="paw">
                {form.name}
              </Chip>
              <View style={styles.buttonContainer}>
                <ImageButton
                  mode={form.neutered === "yes" ? "contained" : "outlined"}
                  style={styles.button}
                  iconColor={
                    form.neutered === "yes"
                      ? theme.colors.onPrimary
                      : theme.colors.primary
                  }
                  labelStyle={styles.buttonLabel}
                  name={"check"}
                  onPress={() => onSelectButton("neutered", index, "yes")}
                >
                  {t("TextYes")}
                </ImageButton>
                <ImageButton
                  mode={form.neutered === "no" ? "contained" : "outlined"}
                  style={styles.button}
                  labelStyle={styles.buttonLabel}
                  iconColor={
                    form.neutered === "no"
                      ? theme.colors.onPrimary
                      : theme.colors.primary
                  }
                  name={"close"}
                  onPress={() => onSelectButton("neutered", index, "no")}
                >
                  {t("TextNo")}
                </ImageButton>
              </View>
            </Fragment>
          ))}
          <Text variant="bodySmall" style={styles.title}>
            {state.values.length > 1
              ? t("TextRepurchaseTitle2P")
              : t("TextRepurchaseTitle2S")}
          </Text>
          <Text variant="labelMedium" style={styles.subtitle}>
            {t("TextRepurchaseSubtitle2")}
          </Text>
          {state.values.map((form, index) => (
            <Fragment key={"Form_Age_" + index}>
              <Chip style={styles.animalName} icon="paw">
                {form.name}
              </Chip>
              <View style={styles.buttonContainer}>
                <ImageButton
                  mode={
                    form.preference !== "" && form.preference === "pf"
                      ? "contained"
                      : "outlined"
                  }
                  style={styles.button2}
                  imageStyle={styles.buttonImage}
                  labelStyle={styles.buttonLabel}
                  source={require("@/assets/images/purrmi_chicken.png")}
                  onPress={() => onSelectButton("preference", index, "pf")}
                >
                  {t("TextPurrmiRecipe1")}
                </ImageButton>
                <ImageButton
                  mode={
                    form.preference !== "" && form.preference === "pp"
                      ? "contained"
                      : "outlined"
                  }
                  style={styles.button2}
                  imageStyle={styles.buttonImage}
                  labelStyle={styles.buttonLabel}
                  source={require("@/assets/images/purrmi_turkey_sardine.png")}
                  onPress={() => onSelectButton("preference", index, "pp")}
                >
                  {t("TextPurrmiRecipe3")}
                </ImageButton>
                <ImageButton
                  mode={
                    form.preference !== "" && form.preference === "pc"
                      ? "contained"
                      : "outlined"
                  }
                  style={styles.button2}
                  imageStyle={styles.buttonImage}
                  labelStyle={styles.buttonLabel}
                  source={require("@/assets/images/purrmi_quail_salmon.png")}
                  onPress={() => onSelectButton("preference", index, "pc")}
                >
                  {t("TextPurrmiRecipe2")}
                </ImageButton>
              </View>
            </Fragment>
          ))}
          <Text variant="bodySmall" style={styles.title}>
            {t("TextRepurchaseTitle3")}
          </Text>
          <Text variant="labelMedium" style={styles.subtitle}>
            {t("TextRepurchaseSubtitle3")}
          </Text>
          {state.values.map((form, index) => (
            <Fragment key={"Form_routine_" + index}>
              <Chip style={styles.animalName} icon="paw">
                {form.name}
              </Chip>
              <View style={styles.buttonContainer}>
                <ImageButton
                  mode={form.routine === "complete" ? "contained" : "outlined"}
                  style={styles.button}
                  iconColor={
                    form.routine === "complete"
                      ? theme.colors.onPrimary
                      : theme.colors.primary
                  }
                  labelStyle={styles.buttonLabel}
                  name={"check"}
                  onPress={() => onSelectButton("routine", index, "complete")}
                >
                  {t("TextComplete")}
                </ImageButton>
                <ImageButton
                  mode={
                    form.routine === "complementary" ? "contained" : "outlined"
                  }
                  style={styles.button}
                  labelStyle={styles.buttonLabel}
                  iconColor={
                    form.routine === "complementary"
                      ? theme.colors.onPrimary
                      : theme.colors.primary
                  }
                  name={"set-left-center"}
                  onPress={() =>
                    onSelectButton("routine", index, "complementary")
                  }
                >
                  {t("TextComplementary")}
                </ImageButton>
              </View>
            </Fragment>
          ))}
          <Button
            mode="contained"
            style={{ marginBottom: 20 }}
            loading={loading}
            disabled={state.values.some(
              (a) => !a.neutered || !a.preference || !a.routine
            )}
            onPress={onUpdateButton}
          >
            {t("TextGetMonthlySuggestion")}
          </Button>
        </View>
      </View>
    </View>
  );
};

export default memo(Main);
