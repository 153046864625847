/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from "expo-linking";

export default {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Intro: {
        screens: {
          Login: "",
          Register: "register",
          ForgotPassword: "forgotpassword",
        },
      },
      Home: {
        screens: {
          Dashboard: {
            screens: {
              Dashboard: "dashboard",
            },
          },
          Explorer: {
            screens: {
              Explorer: "explorer",
            },
          },
          Boxes: {
            screens: {
              Boxes: "boxes",
            },
          },
          Animals: {
            screens: {
              Animals: "animals",
            },
          },
          More: {
            screens: {
              More: "more",
            },
          },
        },
      },
      Checkout: {
        screens: {
          CheckoutFirstTime: "our-recommendation",
          CheckoutBilling: "billing",
          CheckoutFinal: "payment-confirmation",
        },
      },
      Repurchase: {
        screens: {
          RepurchaseQuestions: "purchase-feedback",
          RepurchaseSuggestion: "repurchase-suggestion",
        },
      },
      NotFound: "*",
    },
  },
};
