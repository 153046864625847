import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "70%",
    alignSelf: "center",
    maxWidth: 600,
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: BORDER_RADIUS,
    marginTop: 10,
  },
  icon: { position: "absolute", left: 0, marginLeft: 10 },
  text: { textAlign: "center" },
});
