import { theme } from "@/theme";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: { width: "100%" },
  buttonContainer: {
    flexDirection: "row",
    marginBottom: 10,
    justifyContent: "space-between",
  },
  button: { width: "30%" },
  animalName: { alignSelf: "flex-start", marginBottom: 5 },
  buttonImage: { width: 50, height: 50 },
  buttonLabel: { ...theme.fonts.labelSmall },
  title: { marginBottom: 5 },
  subtitle: { marginBottom: 10 },
});
