import { roundNumber } from "@/common/utils/index";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import ReactPixel from "react-facebook-pixel";
const firebaseConfig = {
  apiKey: "AIzaSyBruiHkpmj2Zt_UDr3ZSWb6HdjuVUUGofc",
  authDomain: "purrmi-26663.firebaseapp.com",
  projectId: "purrmi-26663",
  storageBucket: "purrmi-26663.appspot.com",
  messagingSenderId: "900838385503",
  appId: "1:900838385503:web:2cc8e26feb535030d0b8ea",
  measurementId: "G-Q4SE4YTTLN",
};

const app = initializeApp(firebaseConfig);

const Analytics = getAnalytics(app);

type GoogleItem = {
  item_id: string;
  item_name: string;
  affiliation?: string;
  coupon?: string;
  currency?: string;
  discount?: number;
  index?: number;
  item_brand?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  price?: number;
  quantity?: number;
};

export const productToGoogleProduct = (bp: BoxProduct): GoogleItem[] => {
  let google_products: GoogleItem[] = [
    {
      item_id: bp.product._id,
      item_name: bp.product.title["default"],
      affiliation: "Purrmi",
      currency: "EUR",
      discount: roundNumber(
        bp.product.price * (1 - bp.product.discount / 100),
        2
      ),
      item_brand: "Purrmi",
      item_category: bp.product.category["default"],
      location_id: "ChIJIVSy21PNHg0Rw3H-0qy5SnQ",
      price: bp.product.price,
      quantity: bp.quantity,
    },
  ];
  return google_products;
};
export const boxesToGoogleProducts = (boxes: Box[]): GoogleItem[] => {
  let google_products: GoogleItem[] = Array(0);
  boxes.forEach((box) => {
    box.products.forEach((bp, index) => {
      google_products.push({
        item_id: bp.product._id,
        item_name: bp.product.title["default"],
        affiliation: "Purrmi",
        currency: "EUR",
        discount: roundNumber(
          bp.product.price * (1 - bp.product.discount / 100),
          2
        ),
        index: index,
        item_brand: "Purrmi",
        item_category: bp.product.category["default"],
        location_id: "ChIJIVSy21PNHg0Rw3H-0qy5SnQ",
        price: bp.product.price,
        quantity: bp.quantity,
      });
    });
  });
  return google_products;
};

export const logCurrentScreen = async (route: string): Promise<void> => {
  return logEvent(Analytics, "screen_view", {
    firebase_screen: route,
    firebase_screen_class: route,
  });
};

export const googleAddPaymentInfo = async (properties: {
  currency: string;
  value: number;
  coupon?: string;
  payment_type?: string;
  items: GoogleItem[];
}): Promise<void> => {
  ReactPixel.track("ADD_PAYMENT_INFO", {
    ...properties,
    content_type: "product",
    contents: [
      properties.items.map((p) => {
        return { id: p.item_id, quantity: p.quantity };
      }),
    ],
  });
  logEvent(Analytics, "add_payment_info", properties);
  return;
};

export const googleAddShippingInfo = async (properties: {
  currency: string;
  value: number;
  coupon?: string;
  shipping_tier?: string;
  items: GoogleItem[];
}): Promise<void> => {
  logEvent(Analytics, "add_shipping_info", properties);
  return;
};

export const googleAddToCart = async (properties: {
  currency: string;
  value: number;
  items: GoogleItem[];
}): Promise<void> => {
  ReactPixel.track("ADD_TO_CART", {
    ...properties,
    content_type: "product",
    contents: [
      properties.items.map((p) => {
        return { id: p.item_id, quantity: p.quantity };
      }),
    ],
  });
  logEvent(Analytics, "add_to_cart", properties);
  return;
};

export const googleBeginCheckout = async (properties: {
  currency: string;
  value: number;
  coupon?: string;
  items: GoogleItem[];
}): Promise<void> => {
  ReactPixel.track("INITIATE_CHECKOUT", {
    ...properties,
    content_type: "product",
    num_items: properties.items.length,
    contents: [
      properties.items.map((p) => {
        return { id: p.item_id, quantity: p.quantity };
      }),
    ],
  });
  logEvent(Analytics, "begin_checkout", properties);
  return;
};

export const googleGenerateLead = async (properties: {
  currency: string;
  value: number;
}): Promise<void> => {
  logEvent(Analytics, "generate_lead", properties);
  return;
};

export const googleLogin = async (properties: {
  method?: string;
}): Promise<void> => {
  logEvent(Analytics, "login", properties);
  return;
};

export const googlePurchase = async (properties: {
  currency: string;
  transaction_id: string;
  value: number;
  affiliation?: string;
  coupon?: string;
  shipping?: number;
  tax?: number;
  items: GoogleItem[];
}): Promise<void> => {
  ReactPixel.track("PURCHASE", {
    ...properties,
    content_type: "product",
    contents: [
      properties.items.map((p) => {
        return { id: p.item_id, quantity: p.quantity };
      }),
    ],
  });
  logEvent(Analytics, "purchase", properties);
  return;
};

export const googleRemoveFromCart = async (properties: {
  currency: string;
  value: number;
  items: GoogleItem[];
}): Promise<void> => {
  logEvent(Analytics, "remove_to_cart", properties);
  return;
};

export const googleSearch = async (properties: {
  search_term: string;
}): Promise<void> => {
  logEvent(Analytics, "search", properties);
  return;
};

export const googleSelectContent = async (properties: {
  content_type?: string;
  item_id?: string;
}): Promise<void> => {
  logEvent(Analytics, "select_content", properties);
  return;
};

export const googleSelectItem = async (properties: {
  item_list_id?: string;
  item_list_name?: string;
  items: GoogleItem[];
}): Promise<void> => {
  logEvent(Analytics, "select_item", properties);
  return;
};

export const googleShare = async (properties: {
  method?: string;
  content_type?: string;
  item_id?: string;
}): Promise<void> => {
  logEvent(Analytics, "share", properties);
  return;
};

export const googleSignUp = async (properties: {
  method?: string;
}): Promise<void> => {
  ReactPixel.track("COMPLETE_REGISTRATION", {
    ...properties,
  });
  logEvent(Analytics, "sign_up", properties);
  return;
};

export const googleViewCart = async (properties: {
  currency: string;
  value: number;
  items: GoogleItem[];
}): Promise<void> => {
  logEvent(Analytics, "view_cart", properties);
  return;
};

export const googleViewItem = async (properties: {
  currency: string;
  value: number;
  items: GoogleItem[];
}): Promise<void> => {
  ReactPixel.track("PURCHASE", {
    ...properties,
    content_type: "product",
    contents: [
      properties.items.map((p) => {
        return { id: p.item_id, quantity: p.quantity };
      }),
    ],
  });
  logEvent(Analytics, "view_item", properties);
  return;
};

export const googleViewItemList = async (properties: {
  item_list_id?: string;
  item_list_name?: string;
  items: GoogleItem[];
}): Promise<void> => {
  logEvent(Analytics, "view_item_list", properties);
  return;
};
export const googleViewSearchResults = async (properties: {
  search_term?: string;
  items: GoogleItem[];
}): Promise<void> => {
  logEvent(Analytics, "view_search_results", properties);
  return;
};
