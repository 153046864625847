import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import TextInput from "@/common/components/Input/Text";
import { globalStyles } from "@/common/styles";
import { emailValidator, passwordValidator } from "@/common/utils";
import { googleLogin } from "@/common/utils/analytics.utils";
import { signin } from "@/redux/reducers/User";
import { login } from "@/services/auth.service";
import { moderateScale } from "@/theme/dimensions";
import { IntroParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { TouchableOpacity, View } from "react-native";
import { Text, useTheme } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useDispatch } from "react-redux";
import styles from "./styles";
//import { googleLogin } from "@/common/utils/analytics.utils";
const ManualLogin = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const toast: any = useToast();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigation = useNavigation<StackNavigationProp<IntroParamList>>();
  const { t } = useTranslation();
  const [email, setEmail] = useState({ value: "", error: "" });
  const [password, setPassword] = useState({ value: "", error: "" });
  //const pushToken = useContext(PushTokenContext);

  const onLogin = () => {
    const emailError = emailValidator(email.value);
    const passwordError = passwordValidator(password.value);
    if (emailError !== "" || passwordError !== "") {
      setEmail({ ...email, error: emailError });
      setPassword({ ...password, error: passwordError });
      return;
    }
    let user = {
      email: email.value,
      password: password.value,
      //MISSING PUSH_TOKEN
      pushToken: null,
    };
    setLoading(true);
    login(user)
      .then(async (response: any) => {
        dispatch(signin(response.data));
        setLoading(false);
        await googleLogin({
          method: "Manual",
        });
        //navigation.navigate("Home");
      })
      .catch((error: any) => {
        setLoading(false);
        toast.show(t("BackendNotification" + error.response.data.message), {
          type: "danger",
        });
      });
  };

  return (
    <>
      <View style={styles.goBackContainer}>
        <TouchableOpacity
          style={styles.goBackButton}
          onPress={props.toggleManualLogin}
        >
          <Icon name="chevron-left" size={moderateScale(18)} />
          <Text style={[styles.goBackButtonLabel]}>{t("TextGoBack")}</Text>
        </TouchableOpacity>
      </View>
      <TextInput
        containerStyle={styles.input}
        label={t("TextEmail")}
        returnKeyType="next"
        value={email.value}
        onChangeText={(text: string) =>
          setEmail({ value: text, error: emailValidator(text) })
        }
        error={email.error !== "" && [email.error]}
        autoCapitalize="none"
        autoCompleteType="email"
        textContentType="emailAddress"
        keyboardType="email-address"
      />

      <TextInput
        containerStyle={styles.input}
        label={t("TextPassword")}
        returnKeyType="done"
        value={password.value}
        onChangeText={(text: string) =>
          setPassword({ value: text, error: passwordValidator(text) })
        }
        error={password.error !== "" && [password.error]}
        secureTextEntry
      />

      <View style={styles.forgotPassword}>
        <TouchableOpacity
          onPress={() => {
            return navigation.navigate("ForgotPassword");
          }}
        >
          <Text style={[globalStyles.label]}>{t("TextForgotPassword")}</Text>
        </TouchableOpacity>
      </View>

      <Button
        mode="contained"
        style={{ width: "100%" }}
        loading={loading}
        disabled={loading}
        onPress={async () => {
          onLogin();
        }}
      >
        {t("TextLogin")}
      </Button>
    </>
  );
};

export default memo(ManualLogin);
