import axios from "axios";
import { baseAPIURL } from "./../config";

export function getDeliveries(userToken: string, subscription_id: string) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/subscriptions/${subscription_id}/deliveries`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}

export function getDelivery(
  userToken: string,
  subscription_id: string,
  delivery_id: string
) {
  const config = {
    method: "get",
    url: `${baseAPIURL}/subscriptions/${subscription_id}/deliveries/${delivery_id}`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
  };

  return axios(config);
}
