import { goToSlide } from "@/redux/reducers/Register";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useState } from "react";
import { View } from "react-native";
import { IconButton, useTheme } from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import {
  animated as Animated,
  config,
  useSpring,
  useSprings,
} from "react-spring";
import { validateForm } from "../../helpers";
import styles from "./styles";

const RealAnimatedView = Animated(View);

const AnimatedProgressBar = ({ ...props }) => {
  const starting_form = useSelector((state: RootState) => state.registerForm);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [pagination_dim, setPaginationDim] = useState({ width: 0, height: 0 });
  const [iconSize, setIconSize] = useState(props.iconSize || moderateScale(16));
  const progress_style = useSpring({
    width: (pagination_dim.width / props.data.length) * starting_form.slide,
  });
  const paginationDimensions = (event: {
    nativeEvent: { layout: { width: any; height: any } };
  }) => {
    const { width, height } = event.nativeEvent.layout;
    setPaginationDim({ width: width, height: height });
  };

  const isSlideButtonActive = (slide: number) => {
    let errors = validateForm(starting_form.values);
    let slideHasErrors =
      slide === props.data.length - 1 ||
      errors.some((errors) => errors[props.data[slide].name]);
    return starting_form.slide === props.data.length - 1 || !slideHasErrors;
  };

  const [springs] = useSprings(
    props.data.length,
    (i) => ({
      opacity: isSlideButtonActive(i) ? 1 : 0.3,
      from: { opacity: 0.3 },
      config: config.stiff,
    }),
    [starting_form.slide]
  );

  return (
    <View
      style={[styles.container, props.style]}
      onLayout={paginationDimensions}
    >
      {props.data && starting_form.slide !== undefined && (
        <View style={props.tickContainer || styles.tickContainer}>
          {springs.map(({ opacity }, index) => (
            <View
              key={"progress_icon" + index}
              style={[
                styles.sectionIconContainer,
                props.iconContainerStyle,
                {
                  width: pagination_dim.width / props.data.length,
                },
              ]}
            >
              <RealAnimatedView style={{ opacity: opacity }}>
                <IconButton
                  style={[styles.sectionIcon, props.iconStyle]}
                  iconColor={theme.colors.primary}
                  icon={props.data[index].icon}
                  size={iconSize}
                  disabled={!isSlideButtonActive(index)}
                  onPress={() => dispatch(goToSlide(index))}
                />
              </RealAnimatedView>
            </View>
          ))}
        </View>
      )}
      <View style={styles.progressBarContainer}>
        <RealAnimatedView
          style={{ ...styles.progressBar, ...progress_style }}
        />
      </View>
    </View>
  );
};

export default memo(AnimatedProgressBar);
