import Background from "@/common/components/Background";
import AnimalsModule from "@/modules/Animals";
import React, { memo } from "react";
import { ImageBackground, ScrollView } from "react-native";
import { useSelector } from "react-redux";
import styles from "./styles";

const Animals = () => {
  const user = useSelector((state: RootState) => state.user);
  return (
    <Background>
      <ImageBackground
        style={styles.imageBackground}
        source={require("@/assets/images/logo_transparent.png")}
        imageStyle={styles.repeatImage}
      >
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollableContainer}
        >
          <AnimalsModule />
        </ScrollView>
      </ImageBackground>
    </Background>
  );
};

export default memo(Animals);
