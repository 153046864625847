import Icon from "@/common/components/Icon";
import Box from "@/common/components/Svgs/Box";
import { default_order_values } from "@/common/helpers/orders";
import { isObjectEmpty } from "@/common/utils";
import { changeValues, goToSlide } from "@/redux/reducers/Order";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { Fragment, memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  animated as Animated,
  config,
  useSpring,
  useSprings,
} from "react-spring";
import Payment from "./components/Payment";
import Processing from "./components/Processing";
import Shipping from "./components/Shipping";
import styles from "./styles";
const AnimatedView = Animated(View);
const BOX_VIEWPORT_H = moderateScale(164);
const ProcessingPhase = ({ toast }: { toast: ToastType }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.orderForm);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const [latestDelivery, setLatestDelivery] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const animated_height = useSpring({
    from: { height: 0 },
    to: { height: ((progress + 1) / 4) * BOX_VIEWPORT_H },
    config: config.slow,
  });
  const [trail] = useSprings(3, (i) => ({
    from: { height: 0, opacity: 0 },
    to: { height: i < progress ? 30 : 0, opacity: i < progress ? 1 : 0 },
    delay: i * 800,
    config: config.slow,
  }));
  useEffect(() => {
    if (
      isObjectEmpty(subscription) ||
      subscription.payment.status === "failed"
    ) {
      dispatch(goToSlide(0));
      return;
    }
    let latestDelivery = deliveries.reduce(function (r, a) {
      return r.delivery_date > a.delivery_date ? r : a;
    });
    if (latestDelivery.status === "delivered") {
      setProgress(3);
      dispatch(changeValues({ values: default_order_values, errors: {} }));
      if (latestDelivery.payment.type === "subscription") {
        dispatch(goToSlide(4));
      } else {
        dispatch(goToSlide(0));
      }
    } else if (latestDelivery.status === "shipped") {
      setProgress(2);
    } else if (
      latestDelivery.status === "processing" &&
      latestDelivery.payment.status === "paid"
    ) {
      setProgress(1);
    } else if (latestDelivery.status === "failed") {
      dispatch(goToSlide(0));
      return;
    }
    setLatestDelivery(latestDelivery);
  }, [subscription, deliveries]);

  const stages = [
    {
      icon: "cash",
      stage: <Payment toast={toast} />,
    },
    {
      icon: "cogs",
      stage: <Processing delivery={latestDelivery} />,
    },
    {
      icon: "truck-check",
      stage: <Shipping delivery={latestDelivery} />,
    },
    {
      icon: "home-plus",
      stage: <Fragment />,
    },
  ];
  return (
    latestDelivery && (
      <View style={styles.container}>
        <View style={styles.infoContainer}>
          <Text style={styles.infoTitle} variant="titleSmall">
            {t("WaitingDeliveryTitle")}
          </Text>
          <Text style={styles.infoSubtitle} variant="labelLarge">
            {t(`WaitingDeliverySubtitle${progress + 1}`)}
          </Text>
          <View style={styles.infoBoxIconContainer}>
            <View>
              <Box style={styles.infoBoxIcon} />
              <AnimatedView
                style={{
                  ...animated_height,
                  ...styles.infoBoxIconWrapper,
                }}
              >
                <Box style={styles.infoBoxIconProgress} />
              </AnimatedView>
            </View>
          </View>
          {stages[progress].stage}
          <Text style={styles.infoDescription} variant="labelLarge">
            {t(`WaitingDeliveryDescription${progress + 1}`)}
          </Text>
        </View>
        <View style={styles.progressContainer}>
          {stages.map((stage, index) => (
            <View key={"Progress_Stage_" + index}>
              <View
                style={[
                  styles.mainIconContainer,
                  index <= progress
                    ? { backgroundColor: theme.colors.secondary }
                    : {},
                ]}
              >
                <View style={styles.mainIconRim}>
                  <Icon
                    style={[
                      styles.icon,
                      ,
                      index <= progress
                        ? {
                            backgroundColor: theme.colors.secondary,
                            color: theme.colors.onSecondary,
                          }
                        : {},
                    ]}
                    name={stage.icon}
                    size={moderateScale(25)}
                  />
                </View>
              </View>
              {index !== stages.length - 1 && (
                <View style={styles.connectionContainer}>
                  <View style={styles.connectionDotted}></View>
                  <AnimatedView
                    style={{
                      ...styles.connectionAnimated,
                      ...trail[index],
                    }}
                  />
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    )
  );
};

export default memo(ProcessingPhase);
