import Icon from "@/common/components/Icon";
import { TextInput } from "@/common/components/Input";
import {
  getFinalPrice,
  getShippingCost,
  getTotalWithoutDiscountButWithShipping,
  isLanguageSpanishVariant,
  isObjectEmpty,
  isUserExperimenting,
} from "@/common/utils";
import { validateForm } from "@/modules/Checkout/helpers";
import { changeValues } from "@/redux/reducers/Order";
import { theme } from "@/theme";
import { moderateScale } from "@/theme/dimensions";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, Pressable, TouchableOpacity, View } from "react-native";
import { ActivityIndicator, Surface, Text } from "react-native-paper";
import { ToastType } from "react-native-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
const PaymentStage = ({ toast }: { toast: ToastType }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const boxes = useSelector((state: RootState) => state.boxes);
  const subscription = useSelector((state: RootState) => state.subscription);
  const deliveries = useSelector((state: RootState) => state.deliveries);
  const shippings = useSelector((state: RootState) => state.shippings);
  const state = useSelector((state: RootState) => state.orderForm);
  const [experimental] = useState(
    isUserExperimenting(boxes, subscription, deliveries),
  );
  const [billingOption, setBillingOption] = useState(
    subscription.tier === 1 ? "2" : "1",
  );
  const [billingOption1Loading, setBillingOption1Loading] = useState(false);
  const [billingOption2Loading, setBillingOption2Loading] = useState(false);

  useEffect(() => {
    if (subscription.tier > 1) {
      dispatch(
        changeValues({
          ...state,
          values: { ...state.values, payment_type: "cc_f" },
        }),
      );
    } else {
      dispatch(
        changeValues({
          ...state,
          values: {
            ...state.values,
            payment_type: !isLanguageSpanishVariant(i18n.language)
              ? "mbw"
              : "cc",
          },
        }),
      );
    }
  }, [subscription]);

  // const applyDefaultPromocode = () => {
  //   if (user.promocode && user.promocode.code === "RECORRENTE15") return;
  //   setBillingOption1Loading(true);
  //   validatePromocode(user.accessToken, "RECORRENTE15")
  //     .then((response: any) => {
  //       let userUpdated = { ...user };
  //       if (response.data.promocode) {
  //         userUpdated.promocode = response.data.promocode;
  //       } else if (response.data.usercode) {
  //         userUpdated.friend_discount = response.data.usercode;
  //       }
  //       dispatch(update(userUpdated));
  //       // dispatch(
  //       //   changeValues({
  //       //     ...state,
  //       //     values: { ...state.values, payment_type: "dd_f" },
  //       //   })
  //       // );
  //       setBillingOption("1");
  //       setBillingOption1Loading(false);
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //       toast.show(t(`BackendNotification${error.response.data.message}`), {
  //         type: "danger",
  //       });
  //       setBillingOption1Loading(false);
  //     });
  // };
  // const disableDefaultPromocode = () => {
  //   if (!user.promocode) return;
  //   setBillingOption2Loading(true);
  //   disablePromocode(user.accessToken)
  //     .then(() => {
  //       let userUpdated = { ...user };
  //       userUpdated.promocode = undefined;
  //       userUpdated.friend_discount = undefined;
  //       dispatch(update(userUpdated));
  //       dispatch(
  //         changeValues({
  //           ...state,
  //           values: { ...state.values, payment_type: "mbw" },
  //         })
  //       );
  //       setBillingOption("2");
  //       setBillingOption2Loading(false);
  //     })
  //     .catch((error: any) => {
  //       console.error(error);
  //       toast.show(t(`BackendNotification${error.response.data.message}`), {
  //         type: "danger",
  //       });
  //       setBillingOption2Loading(false);
  //     });
  // };

  const getShippingValue = () => {
    return getShippingCost(shippings, user, boxes, subscription);
  };

  const onChangeText = (type: string, text: string) => {
    let new_state = { ...state, values: { ...state.values, [type]: text } };
    let errors = validateForm(new_state) || {};
    dispatch(
      changeValues({
        ...state,
        values: new_state.values,
        errors: { ...state.errors, [type]: errors[type] },
      }),
    );
  };

  return (
    <View style={styles.container}>
      <View style={{ width: "100%" }}>
        <Text style={styles.headerInfoTitle}>
          {experimental
            ? t("TextBillingHeaderTitle2")
            : t("TextBillingHeaderTitle")}
        </Text>
        {!isObjectEmpty(subscription) && subscription.tier > 1 && (
          <Surface
            style={[
              billingOption === "1"
                ? styles.billingOptionActive
                : styles.billingOption,
              {
                backgroundColor: billingOption1Loading
                  ? theme.colors.surfaceDisabled
                  : "transparent",
              },
            ]}
          >
            <TouchableOpacity
              style={[
                {
                  padding: 15,
                },
                billingOption1Loading
                  ? {
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {},
              ]}
            >
              {billingOption1Loading ? (
                <ActivityIndicator
                  animating={billingOption1Loading}
                  color={theme.colors.primary}
                />
              ) : (
                <>
                  <View style={styles.billingOptionTitleContainer}>
                    <Text style={styles.billingOptionTitle}>
                      {t("TextBillingOption1Title")}
                    </Text>
                    <Text style={styles.billingOptionPrice}>
                      <Text style={styles.billingOptionPriceBefore}>
                        {`${getTotalWithoutDiscountButWithShipping(
                          boxes,
                          parseFloat(getShippingValue()),
                        )}€`}
                      </Text>
                      {`${getFinalPrice(
                        user,
                        boxes,
                        subscription.tier === 1
                          ? parseFloat(getShippingValue())
                          : 0,
                      )}€`}
                    </Text>
                  </View>
                  <View style={styles.billingOptionSubtitleContainer}>
                    <Text style={styles.billingOptionSubtitle}>
                      {t("TextBillingOption1Subtitle")}
                    </Text>
                    {user.promocode && (
                      <Text style={styles.billingOptionPromo}>
                        {user.promocode.value}%
                      </Text>
                    )}
                  </View>
                  {billingOption === "1" && (
                    <View style={styles.billingInfoList}>
                      <View style={styles.billingInfoListItem}>
                        <Icon
                          size={moderateScale(15)}
                          color={theme.colors.secondary}
                          style={styles.billingInfoListItemIcon}
                          name={"check"}
                        />
                        <Text style={styles.billingInfoListItemText}>
                          {t("TextBillingOption1Text")}
                        </Text>
                      </View>
                      <View style={styles.billingInfoListItem}>
                        <Icon
                          size={moderateScale(15)}
                          color={theme.colors.secondary}
                          style={styles.billingInfoListItemIcon}
                          name={"check"}
                        />
                        <Text style={styles.billingInfoListItemText}>
                          {t("TextBillingOption1Text2")}
                        </Text>
                      </View>
                      <View style={styles.billingInfoListItem}>
                        <Icon
                          size={moderateScale(15)}
                          color={theme.colors.secondary}
                          style={styles.billingInfoListItemIcon}
                          name={"check"}
                        />
                        <Text style={styles.billingInfoListItemText}>
                          {t("TextBillingOption1Text3")}
                        </Text>
                      </View>
                      <View style={styles.billingInfoListItem}>
                        <Icon
                          size={moderateScale(15)}
                          color={theme.colors.secondary}
                          style={styles.billingInfoListItemIcon}
                          name={"check"}
                        />
                        <Text style={styles.billingInfoListItemText}>
                          {t("TextBillingOption1Text5")}
                        </Text>
                      </View>
                      <View style={styles.billingInfoListItem}>
                        <Icon
                          size={moderateScale(15)}
                          color={theme.colors.secondary}
                          style={styles.billingInfoListItemIcon}
                          name={"check"}
                        />
                        <Text style={styles.billingInfoListItemText}>
                          {t("TextBillingOption1Text6")}
                        </Text>
                      </View>
                    </View>
                  )}
                </>
              )}
            </TouchableOpacity>
          </Surface>
        )}
        {(isObjectEmpty(subscription) || subscription.tier === 1) && (
          <Surface
            style={[
              billingOption === "2"
                ? styles.billingOptionActive
                : styles.billingOption,
              {
                backgroundColor: billingOption2Loading
                  ? theme.colors.surfaceDisabled
                  : "transparent",
              },
            ]}
          >
            <TouchableOpacity
              style={[
                {
                  padding: 15,
                },
                billingOption2Loading
                  ? {
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {},
              ]}
            >
              {billingOption2Loading ? (
                <ActivityIndicator
                  animating={billingOption2Loading}
                  color={theme.colors.primary}
                />
              ) : (
                <>
                  <View style={styles.billingOptionTitleContainer}>
                    <Text style={styles.billingOptionTitle}>
                      {t("TextBillingOption2Title")}
                    </Text>
                    <Text style={styles.billingOptionPrice}>
                      {`${getFinalPrice(
                        user,
                        boxes,
                        subscription.tier > 1
                          ? 0
                          : parseFloat(getShippingValue()),
                      )}€`}
                    </Text>
                  </View>
                  <View style={styles.billingOptionSubtitleContainer}>
                    <Text style={styles.billingOptionSubtitle}>
                      {t("TextBillingOption2Subtitle")}
                    </Text>
                    {user.promocode && (
                      <Text style={styles.billingOptionPromo}>
                        {user.promocode.value}%
                      </Text>
                    )}
                  </View>
                  <View style={styles.billingInfoList}>
                    <View style={styles.billingInfoListItem}>
                      <Icon
                        size={moderateScale(15)}
                        color={theme.colors.secondary}
                        style={styles.billingInfoListItemIcon}
                        name={"check"}
                      />
                      <Text style={styles.billingInfoListItemText}>
                        {t("TextBillingOption2Text")}
                      </Text>
                    </View>
                    {!experimental && (
                      <View style={styles.billingInfoListItem}>
                        <Icon
                          size={moderateScale(15)}
                          color={theme.colors.secondary}
                          style={styles.billingInfoListItemIcon}
                          name={"check"}
                        />
                        <Text style={styles.billingInfoListItemText}>
                          {t("TextBillingOption2Text2")} {getShippingValue()}€
                        </Text>
                      </View>
                    )}
                    {experimental && (
                      <>
                        <View style={styles.billingInfoListItem}>
                          <Icon
                            size={moderateScale(15)}
                            color={theme.colors.secondary}
                            style={styles.billingInfoListItemIcon}
                            name={"check"}
                          />
                          <Text style={styles.billingInfoListItemText}>
                            {t("TextBillingOption2Text2")} {getShippingValue()}€
                          </Text>
                        </View>
                        <View style={styles.billingInfoListItem}>
                          <Icon
                            size={moderateScale(15)}
                            color={theme.colors.secondary}
                            style={styles.billingInfoListItemIcon}
                            name={"check"}
                          />
                          <Text style={styles.billingInfoListItemText}>
                            {t("TextBillingOption1Text6")}
                          </Text>
                        </View>
                      </>
                    )}
                  </View>
                </>
              )}
            </TouchableOpacity>
          </Surface>
        )}
        <Text style={styles.paymentInfoTitle}>
          {t("TextBillingSectionTitle5")}
        </Text>
        <Text variant="labelMedium">{t("TextBillingSectionDescription4")}</Text>
        <Text style={styles.paymentInfoTitle}>
          {t("TextBillingSectionTitle4")}
        </Text>
        <View style={styles.paymentChoiceContainer}>
          <View style={styles.paymentOptionsContainer}>
            {!isObjectEmpty(subscription) && subscription.tier > 1 ? (
              <>
                <View style={styles.paymentOptionContainer}>
                  <Pressable
                    style={[
                      styles.paymentOption,
                      state.values.payment_type === "cc_f"
                        ? styles.paymentOptionActive
                        : {},
                    ]}
                    onPress={() => onChangeText("payment_type", "cc_f")}
                  >
                    <Image
                      style={styles.paymentOptionImage}
                      source={require("@/assets/images/visamc_logo.png")}
                    />
                  </Pressable>
                  <Text variant="labelMedium">{t("TextCreditCard")}</Text>
                </View>
              </>
            ) : (
              <>
                {!isLanguageSpanishVariant(i18n.language) && (
                  <View style={styles.paymentOptionContainer}>
                    <Pressable
                      style={[
                        styles.paymentOption,
                        state.values.payment_type === "mbw"
                          ? styles.paymentOptionActive
                          : {},
                      ]}
                      onPress={() => onChangeText("payment_type", "mbw")}
                    >
                      <Image
                        style={styles.paymentOptionImage}
                        source={require("@/assets/images/mbway_logo.png")}
                      />
                    </Pressable>
                    <Text variant="labelMedium">{t("TextMBWay")}</Text>
                  </View>
                )}
                {!isLanguageSpanishVariant(i18n.language) && (
                  <View style={styles.paymentOptionContainer}>
                    <Pressable
                      style={[
                        styles.paymentOption,
                        state.values.payment_type === "mb"
                          ? styles.paymentOptionActive
                          : {},
                      ]}
                      onPress={() => onChangeText("payment_type", "mb")}
                    >
                      <Image
                        style={styles.paymentOptionImage}
                        source={require("@/assets/images/multibanco_logo.png")}
                      />
                    </Pressable>
                    <Text variant="labelMedium">{t("TextMultibank")}</Text>
                  </View>
                )}
                <View style={styles.paymentOptionContainer}>
                  <Pressable
                    style={[
                      styles.paymentOption,
                      state.values.payment_type === "cc"
                        ? styles.paymentOptionActive
                        : {},
                    ]}
                    onPress={() => onChangeText("payment_type", "cc")}
                  >
                    <Image
                      style={styles.paymentOptionImage}
                      source={require("@/assets/images/visamc_logo.png")}
                    />
                  </Pressable>
                  <Text variant="labelMedium">{t("TextCreditCard")}</Text>
                </View>
              </>
            )}
          </View>
          <View style={styles.paymentDescriptionContainer}>
            {state.values.payment_type === "mbw" && (
              <TextInput
                containerStyle={styles.paymentDescriptionInput}
                label={`${t("TextPhoneNumber")}*`}
                value={state.values.payment_phone}
                error={state.errors.payment_phone}
                onChangeText={(text: string) =>
                  onChangeText("payment_phone", text)
                }
              />
            )}
            {state.values.payment_type === "dd_f" && (
              <>
                <TextInput
                  containerStyle={styles.paymentDescriptionInput}
                  label={`${t("IBAN")}*`}
                  value={state.values.payment_iban}
                  error={state.errors.payment_iban}
                  onChangeText={(text: string) =>
                    onChangeText("payment_iban", text)
                  }
                />
                <TextInput
                  containerStyle={styles.paymentDescriptionInput}
                  label={`${t("TextAccountHolder")}*`}
                  value={state.values.payment_acc_holder}
                  error={state.errors.payment_acc_holder}
                  onChangeText={(text: string) =>
                    onChangeText("payment_acc_holder", text)
                  }
                />
              </>
            )}
            <Text style={styles.paymentDescription} variant="labelLarge">
              {state.values.payment_type === "mbw"
                ? t("TextMBWayDescription")
                : state.values.payment_type === "mb"
                  ? t("TextMultibancoDescription")
                  : state.values.payment_type === "dd_f"
                    ? t("TextDirectDebitDescription")
                    : t("TextCCDescription")}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(PaymentStage);
