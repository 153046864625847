import React, { memo, useRef, useState } from "react";
import {
  Dimensions,
  StyleProp,
  TextStyle,
  TouchableOpacity,
  View,
} from "react-native";
import { Modal, Text } from "react-native-paper";
import { animated, useSpring, useTrail } from "react-spring";
import { TextInput } from "../../Input";
import styles from "./styles";

const AnimatedView = animated(View);

type DropdownItem = {
  value: string;
  name: string;
};

const DefaultDropdown = ({
  containerStyle,
  data,
  label,
  value,
  errors,
  disabled,
  onChangeValue,
}: {
  containerStyle?: StyleProp<TextStyle>;
  data: DropdownItem[];
  label: string;
  value: string;
  errors: string[];
  disabled?: boolean;
  onChangeValue: (item: string) => void;
}) => {
  const DropdownButton = useRef<any>();
  const [visible, setVisible] = useState(false);
  const [ownValue, setOwnValue] = useState(
    data.find((el) => el.value === value)?.name || ""
  );
  const [dropdownTop, setDropdownTop] = useState(0);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const [dropdownMaxHeight, setDropdownMaxHeight] = useState(0);
  const [spring, springAPI] = useSpring(
    () => ({
      from: { opacity: 0 },
    }),
    [visible]
  );
  const [flagTrail, flagTrailAPI] = useTrail(data.length, () => ({
    opacity: 0,
  }));

  const openDropdown = () => {
    if (DropdownButton?.current) {
      DropdownButton.current.measure((_fx, _fy, _w, _h, _px, _py) => {
        const windowHeight = Dimensions.get("window").height;
        setDropdownTop(_h);
        setDropdownWidth(_w);
        setDropdownMaxHeight(windowHeight - _py - _h - 10);
      });
      setVisible(true);
      flagTrailAPI.start({
        opacity: 1,
      });
      springAPI.start({
        opacity: 1,
      });
    }
  };

  const closeDropdown = () => {
    flagTrailAPI.start({
      opacity: 0,
    });
    springAPI.start({
      opacity: 0,
    });
    setVisible(false);
  };

  const onChangeText = (item: string): void => {
    openDropdown();
  };
  const onItemPress = (item: DropdownItem): void => {
    setOwnValue(item.name);
    onChangeValue(item.value);
    flagTrailAPI.start({
      opacity: 0,
    });
    springAPI.start({
      opacity: 0,
    });
    setVisible(false);
  };

  return (
    <View style={[styles.container, containerStyle]} ref={DropdownButton}>
      <TextInput
        label={label}
        value={ownValue}
        error={errors}
        icon={"chevron-down"}
        disabled={disabled}
        onPressIcon={openDropdown}
        onChangeText={(text: string) => onChangeText(text)}
      />
      <Modal
        style={{ backgroundColor: "transparent" }}
        contentContainerStyle={[
          styles.dropdown,
          { top: dropdownTop, width: dropdownWidth },
        ]}
        visible={visible}
        dismissable
        theme={{
          colors: {
            backdrop: "transparent",
          },
        }}
        onDismiss={closeDropdown}
      >
        <AnimatedView style={{ ...spring, maxHeight: dropdownMaxHeight }}>
          {flagTrail.map((style, index) => (
            <AnimatedView key={"LanguageItem" + index} style={style}>
              <TouchableOpacity
                style={styles.option}
                onPress={() => onItemPress(data[index])}
              >
                <Text style={styles.optionText} variant="bodySmall">
                  {data[index].name}
                </Text>
              </TouchableOpacity>
            </AnimatedView>
          ))}
        </AnimatedView>
      </Modal>
    </View>
  );
};

export default memo(DefaultDropdown);
