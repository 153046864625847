export const getNumberOfProductUpdates = (boxes: Box[]) => {
  let numberOfProductUpdates = 0;
  boxes.forEach((box) => {
    for (let i = 0; i < box.products.length; i++) {
      numberOfProductUpdates += box.products[i].quantity;
    }
  });
  return numberOfProductUpdates;
};

export const getIconBasedOfScreen = (screen: string) => {
  let iconName = "help";
  if (screen === "Dashboard") {
    iconName = "home-variant";
  } else if (screen === "Boxes") {
    iconName = "cube-outline";
  } else if (screen === "Explorer") {
    iconName = "store";
  } else if (screen === "Animals") {
    iconName = "cat";
  } else if (screen === "Profile") {
    iconName = "account-cog";
  } else if (screen === "InviteFriends") {
    iconName = "account-multiple-plus";
  } else if (screen === "More") {
    iconName = "menu";
  }
  return iconName;
};
