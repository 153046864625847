import { validate } from "validate.js";
export const getRelativeScale = (selected: number, current: number): number => {
  if (current - selected === 0) return 1;

  return 1 / (Math.abs(current - selected) + 1);
};

export const validateForm = (state: AnimalForm): AnimalFormErrors => {
  const schema = {
    name: {
      presence: { allowEmpty: false, message: "NotificationCannotBeEmpty" },
    },
    images: {},
    weight: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
      numericality: {
        greaterThan: 0,
        lessThan: 15,
        message: "NotifcationMustBeGreaterThanZeroLessThan15",
      },
    },
    sex: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    birthday: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
    type: {
      presence: {
        allowEmpty: false,
        message: "NotificationCannotBeEmpty",
      },
    },
  };
  return validate(state.values, schema, { fullMessages: false });
};
