import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const shippingsSlice = createSlice({
  name: "shippings",
  initialState: [] as Shipping[],
  reducers: {
    update(state, action: PayloadAction<Shipping[]>) {
      return [...action.payload];
    },
  },
});

export const { update } = shippingsSlice.actions;
export default shippingsSlice.reducer;
