import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {} as Subscription,
  reducers: {
    update(state, action: PayloadAction<Subscription>) {
      if (action.payload) {
        return { ...state, ...action.payload };
      } else {
        return {} as Subscription;
      }
    },
  },
});

export const { update } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
