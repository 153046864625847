import { default_order_values } from "@/common/helpers/orders";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const orderSlice = createSlice({
  name: "orderForm",
  initialState: {
    open: false,
    slide: 0,
    values: default_order_values,
    errors: {} as OrderFormErrors,
  },
  reducers: {
    toggle(state) {
      state.open = !state.open;
    },
    incrementSlide(state) {
      state.slide++;
    },
    decrementSlide(state) {
      state.slide--;
    },
    goToSlide(state, action: PayloadAction<number>) {
      state.slide = action.payload;
    },
    changeValues(
      state,
      action: PayloadAction<{
        values: OrderFormValues;
        errors: OrderFormErrors;
      }>
    ) {
      return {
        ...state,
        values: action.payload.values,
        errors: action.payload.errors,
      };
    },
    updateValuesAndGoNext(state, action: PayloadAction<OrderForm>) {
      return {
        ...state,
        slide: action.payload.slide,
        values: action.payload.values,
      };
    },
  },
});

export const {
  incrementSlide,
  decrementSlide,
  goToSlide,
  toggle,
  changeValues,
  updateValuesAndGoNext,
} = orderSlice.actions;
export default orderSlice.reducer;
