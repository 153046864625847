import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  animalButton: {
    marginHorizontal: 5,
    width: 100,
  },
  animalButtonActive: {
    borderBottomcolor: theme.colors.secondary,
    borderBottomWidth: 3,
    borderRadius: 0,
    marginHorizontal: 5,
    width: 100,
  },
  animalButtonLabel: {
    color: theme.colors.primary,
  },
  animalButtonLabelActive: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  animalButtonsContainer: {
    alignItems: "center",
    flexDirection: "row",
  },
  animalName: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  background: {
    backgroundColor: theme.colors.background,
    borderBottomRightRadius: 100,
    height: "100%",
    overflowX: "hidden",
    width: "100%",
  },
  backgroundEffectContainer: {
    backgroundColor: theme.colors.surfaceVariant,
    height: "80%",
    margin: 0,
    width: "100%",
  },
  billingInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 10,
  },
  billingInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  billingInfoListItemText: { ...theme.fonts.labelLarge },
  buttonText: {
    color: theme.colors.background,
  },
  container: {
    backgroundColor: theme.colors.background,
    borderTopLeftRadius: BORDER_RADIUS,
    borderTopRightRadius: BORDER_RADIUS,
    height: "100%",
  },
  containerInner: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  ctaContainer: {
    alignItems: "center",
    backgroundColor: theme.colors.secondary + "10",
    justifyContent: "center",
    marginTop: 30,
    minHeight: 150,
    width: "100%",
  },
  ctaText: { ...theme.fonts.labelLarge, color: theme.colors.primary },
  ctaTextPrice: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  customDivider: {
    alignItems: "center",
    alignSelf: "center",
    borderColor: theme.colors.surfaceDisabled,
    borderWidth: 1,
    height: 1,
    justifyContent: "center",
    maxWidth: 1200,
    width: "80%",
  },
  customDividerText: {
    backgroundColor: theme.colors.background,
    color: theme.colors.surfaceDisabled,
    marginBottom: 5,
    padding: 10,
    ...theme.fonts.bodyMedium,
  },
  discountText: {
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    color: theme.colors.onSurfaceVariant,
    fontSize: 14,
    fontWeight: "700",
    paddingHorizontal: 5,
    paddingVertical: 2,
  },
  faqAccordion: {
    backgroundColor: theme.colors.background,
    borderRadius: BORDER_RADIUS,
    marginTop: 20,
    paddingVertical: 10,
  },
  faqAccordionItem: {
    backgroundColor: theme.colors.background,
  },
  faqAnswerTitle: { ...theme.fonts.labelLarge, color: theme.colors.primary },
  faqContainer: {
    maxWidth: 1200,
    width: "90%",
  },
  faqQuestionIcon: {
    backgroundColor: "transparent",
    borderRadius: BORDER_RADIUS,
  },
  faqQuestionTitle: {
    color: theme.colors.primary,
    fontWeight: "bold",
  },
  faqSubtitle: {
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "100%",
  },
  faqTitle: {
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    width: "100%",
  },
  featuresContainer: {
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    width: "30%",
  },
  featuresText: { ...theme.fonts.labelLarge, textAlign: "center" },
  firstTimeCtaTitle: {
    marginBottom: 15,
  },
  firstTimeInfoTitle: {
    fontWeight: "bold",
  },
  header: {
    flexDirection: "column",
    maxWidth: 1200,
    paddingHorizontal: 20,
    width: "100%",
  },
  headerContainer: {
    alignItems: "center",
    width: "100%",
  },
  headerInfo: {
    alignItems: "center",
    width: "100%",
  },
  headerInfoDescription: {
    marginTop: 10,
    textAlign: "center",
    ...theme.fonts.labelLarge,
  },
  headerInfoList: {
    marginLeft: 0,
    paddingHorizontal: 20,
    width: "100%",
  },
  headerInfoListItem: {
    alignItems: "center",
    marginTop: 10,
  },
  headerInfoListItemImage: {
    height: 50,
    marginRight: 10,
    width: 50,
  },
  headerInfoListItemTitle: { ...theme.fonts.labelLarge, width: "80%" },
  headerInfoTitle: {
    fontWeight: "bold",
    marginTop: 15,
  },
  headerPromoBenefits: {
    alignItems: "center",
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    justifyContent: "space-between",
    paddingVertical: 5,
    width: "100%",
  },
  headerPromoImage: {
    height: 130,
    width: 130,
  },
  headerPromoImages: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  headerPromoImagesContainer: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
    marginTop: 20,
    paddingHorizontal: 20,
    width: "100%",
  },
  headerPromoImagesContainerWrapper: {
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  horizontalListImage: {
    borderRadius: BORDER_RADIUS,
    height: 200,
    width: 200,
  },
  invertedSection: {
    alignItems: "center",
    backgroundColor: theme.colors.secondary + "10",
    justifyContent: "center",
    paddingVertical: 60,
    width: "100%",
  },
  invertedSectionFaq: {
    alignItems: "center",
    backgroundColor: theme.colors.secondary + "10",
    justifyContent: "center",
    marginBottom: 50,
    paddingHorizontal: 10,
    paddingVertical: 60,
    width: "100%",
  },
  processContainer: {
    maxWidth: 1200,
    paddingVertical: 60,
    width: "85%",
  },
  processHorizontalList: {
    marginTop: 10,
    width: "100%",
  },
  processHorizontalListItem: {
    alignItems: "center",
    marginHorizontal: 15,
    width: 350,
  },
  processHorizontalListItemIcon: {
    backgroundcolor: theme.colors.secondary,
    borderRadius: BORDER_RADIUS,
  },
  processHorizontalListItemText: {
    ...theme.fonts.labelLarge,
    color: theme.colors.primary,
    marginTop: 20,
    textAlign: "center",
  },
  processHorizontalListItemTitle: {
    color: theme.colors.primary,
    fontWeight: "bold",
    textAlign: "center",
  },
  processTitle: {
    fontWeight: "bold",
    marginBottom: 50,
    textAlign: "center",
    width: "100%",
  },
  productDiscountLabel: {
    fontWeight: "bold",
  },
  productDiscountLabelContainer: {
    backgroundColor: theme.colors.success,
    borderRadius: BORDER_RADIUS,
    marginHorizontal: 5,
    paddingHorizontal: 5,
    paddingVertical: 4,
    position: "absolute",
    right: 0,
    top: 0,
  },
  productDiscountLabelMobile: {
    fontWeight: "bold",
  },
  promoHeaderImage: {
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    height: 150,
    minHeight: 150,
    width: "70%",
  },
  promoImageInfoList: {
    alignSelf: "center",
    width: "50%",
  },
  promoImageInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
  },
  promoImageInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  promoImageInfoListItemText: { ...theme.fonts.labelLarge },
  removeTransitionFoodButton: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  removeTransitionFoodButtonLabel: {
    color: theme.colors.primary,
    textDecorationLine: "underline",
    ...theme.fonts.labelLarge,
  },
  sandInput: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  sandInputCheckbox: {},
  sandInputContainer: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 30,
    paddingHorizontal: 20,
    width: "100%",
  },
  sandInputText: {
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "80%",
  },
  scrollableViewContainer: {
    width: "100%",
  },
  sectionContainer: {
    justifyContent: "center",
  },
  stageWrapper: {
    height: "100%",
    width: "100%",
  },
  trustList: {
    marginTop: 10,
    width: "100%",
  },
  trustListImage: {
    borderRadius: BORDER_RADIUS,
    height: 320,
    width: 500,
  },
  trustListItem: {
    alignItems: "center",
    marginHorizontal: 15,
    width: 500,
  },
  trustListItemText: {
    ...theme.fonts.labelLarge,
    color: theme.colors.primary,
    marginBottom: 20,
    textAlign: "center",
  },
  vetContainer: {
    maxWidth: 900,
    width: "100%",
  },
  vetImage: {
    borderRadius: BORDER_RADIUS,
    height: 300,
    width: 300,
  },
  vetImageContainer: {
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  vetInfoList: {
    marginLeft: 30,
  },
  vetInfoListItem: {
    alignItems: "center",
    flexDirection: "row",
    marginTop: 15,
  },
  vetInfoListItemIcon: {
    backgroundColor: "transparent",
    borderRadius: 0,
  },
  vetInfoListItemText: { ...theme.fonts.labelLarge, width: "80%" },
  vetRow: {
    alignItems: "center",
    flexDirection: "column-reverse",
    justifyContent: "center",
    marginTop: 50,
  },
  vetTextContainer: {
    marginTop: 20,
    width: "80%",
  },
  vetTextDescription: {
    ...theme.fonts.labelLarge,
    color: theme.colors.primary,
  },
  vetTextTitle: {
    fontWeight: "bold",
    marginBottom: 50,
    textAlign: "center",
    width: "100%",
  },
  whySubtitle: {
    marginBottom: 25,
    ...theme.fonts.labelLarge,
    textAlign: "center",
    width: "100%",
  },
  whyTitle: {
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    width: "100%",
  },
});
