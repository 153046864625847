import axios from "axios";
import i18n from "i18next";
import { Platform } from "react-native";
import { dataURLtoFile } from "../common/utils";
import { baseAPIURL } from "./../config";

export function updateUserInfo(userToken: string, user: User) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/users`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: user,
  };

  return axios(config);
}
export function updateShippingInfo(
  userToken: string,
  shippingInfo: OrderFormValues
) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/users/shipping`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: shippingInfo,
  };

  return axios(config);
}

export function updateUserImage(userToken: string, user: User, image: any) {
  let imageFormData: FormData;
  if (Platform.OS == "web") {
    imageFormData = new FormData();
    let file = dataURLtoFile(image.uri, user.name);
    imageFormData.append("photo", file);
  } else {
    imageFormData = new FormData();
    imageFormData.append("photo", {
      uri: image.uri,
      name: image.name,
      type: `image/${image.type}`,
    });
  }
  const config = {
    method: "put",
    url: `${baseAPIURL}/image`,
    headers: {
      "Content-Type": "multipart/form-data",
      "x-access-token": userToken,
    },
    data: imageFormData,
  };
  return axios(config);
}

export function resetPassword(userToken: string, email: string) {
  const config = {
    method: "post",
    url: `${baseAPIURL}/resetpassword`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: { email: email, language: i18n.language },
  };

  return axios(config);
}

export function verifyResetCode(
  userToken: string,
  email: string,
  code: string,
  password: string
) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/changetokenpassword`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: { email: email, code: code, password: password },
  };

  return axios(config);
}

export function changeUserPassword(
  userToken: string,
  user: { password: string; confirmPassword: string }
) {
  const config = {
    method: "put",
    url: `${baseAPIURL}/changepassword`,
    headers: {
      "Content-Type": "application/json",
      "x-access-token": userToken,
    },
    data: user,
  };

  return axios(config);
}
