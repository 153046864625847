import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: { width: "100%" },
  inputContainer: { marginBottom: 10, marginRight: 5, width: "60%" },
  buttonContainer: {
    flexDirection: "row",
    marginTop: 5,
    justifyContent: "space-between",
  },
  imageContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  genderButton: {
    flexDirection: "column",
    marginRight: 5,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 1,
    paddingVertical: 1,
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    height: 48,
    minWidth: 56,
  },
  genderButtonMobile: {
    flexDirection: "column",
    marginRight: 5,
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 1,
    paddingVertical: 1,
    backgroundColor: theme.colors.surfaceVariant,
    borderRadius: BORDER_RADIUS,
    height: 39,
    minWidth: 42,
  },
  genderButtonText: { color: theme.colors.primary, fontSize: 11 },
  genderButtonIcon: { color: theme.colors.primary },
  active: { backgroundColor: theme.colors.primary },
  activeText: { color: theme.colors.onPrimary },
  activeIcon: { color: theme.colors.onPrimary },
  buttonLabel: { ...theme.fonts.labelSmall },
  wrapper: { flexDirection: "row" },
  numericInputContainer: { marginBottom: 10 },
  button: {
    marginTop: 10,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: { marginBottom: 10 },
});
