import { BORDER_RADIUS } from "@/theme/dimensions/index";
import { StyleSheet } from "react-native";

export default StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
  },
  title: { marginBottom: 10 },
  inputContainer: { flex: 1, paddingBottom: 8 },
  inputOutline: { borderRadius: BORDER_RADIUS },
  input: {},
  button: { marginLeft: 5, marginTop: -2 },
  card: {
    width: "100%",
  },
  content: {
    width: "100%",
    paddingHorizontal: 12,
    paddingVertical: 5,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  couponPrompt: {
    textAlign: "center",
  },
});
