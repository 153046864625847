import { Button } from "@/common/components/Button";
import { globalStyles } from "@/common/styles";
import { getUsercode } from "@/services/usercodes.service";
import { theme } from "@/theme";
import * as Clipboard from "expo-clipboard";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ActivityIndicator, View } from "react-native";
import { Surface, Text } from "react-native-paper";
import { useToast } from "react-native-toast-notifications";
import { useSelector } from "react-redux";
import styles from "./styles";
const Section = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const user = useSelector((state: RootState) => state.user);
  const [usercode, setUsercode] = useState(null);

  const copyToClipboard = (value: string) => {
    Clipboard.setStringAsync(value);
    toast.show(t("TextCopiedToClipboard"), { type: "success" });
  };

  useEffect(() => {
    getUsercode(user.accessToken)
      .then((response) => {
        setUsercode(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setUsercode]);

  if (!usercode)
    return (
      <ActivityIndicator
        style={{ flex: 1 }}
        animating={true}
        size="large"
        color={theme.colors.primary}
      />
    );

  return (
    <View style={styles.container}>
      <Text variant="titleMedium" style={styles.title}>
        {t("ScreenInviteFriendsTitle")}
      </Text>
      <View style={styles.friendReferralContainer}>
        <View style={{ flexDirection: "row", width: "100%" }}>
          <View
            style={{
              width: "100%",
            }}
          >
            <Text
              style={[globalStyles.center, { paddingVertical: 10 }]}
              variant="displayLarge"
            >
              {usercode.code}
            </Text>
            <Text style={globalStyles.center} variant="bodyMedium">
              {t("TextInviteFriendSlogan")}
            </Text>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginVertical: 10,
              }}
            >
              <Button
                mode={"contained"}
                icon="clipboard-outline"
                onPress={() => copyToClipboard(usercode?.code)}
              >
                {t("ButtonTextShareCode")}
              </Button>
            </View>
          </View>
        </View>
        <View style={styles.inviteFriendContainer}>
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text variant="bodyLarge">10€</Text>
              <Text
                style={{
                  maxWidth: 125,
                  textAlign: "center",
                }}
              >
                {t("TextFriendDiscountPhraseYourFirstBuy")}
              </Text>
            </View>
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                variant="bodyLarge"
                style={{
                  marginHorizontal: 30,
                }}
              >
                +
              </Text>
            </View>
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <Text variant="bodyLarge">25%</Text>
              <Text
                style={{
                  maxWidth: 125,
                  textAlign: "center",
                }}
              >
                {t("TextFriendDiscountPhraseHisFirstBuy")}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.friendReferralCardMobile}>
          <View style={styles.friendConquests}>
            <Surface style={[styles.friendConquestsCard]}>
              <Text>{t("ProfileFriendConquestsNumber")}</Text>
              <Text variant="bodyLarge">{usercode?.count}</Text>
            </Surface>
            <Surface style={[styles.friendConquestsCard]}>
              <Text>{t("ProfileFriendConquestsCurrentValue")}</Text>
              <Text variant="bodyLarge">{user?.cash}€</Text>
            </Surface>
            <Surface style={[styles.friendConquestsCard]}>
              <Text>{t("ProfileFriendConquestsCredits")}</Text>
              <Text variant="bodyLarge">
                {parseInt(usercode ? usercode?.count : 0) *
                  parseInt(usercode ? usercode?.value : 0)}
                €
              </Text>
            </Surface>
          </View>
        </View>
      </View>
    </View>
  );
};

export default memo(Section);
