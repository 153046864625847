import { theme } from "@/theme";
import { StyleSheet } from "react-native";
export default StyleSheet.create({
  actionButton: {
    maxWidth: 125,
    marginVertical: 5,
    width: "100%",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors.surfaceVariant,
    bottom: 0,
    height: "20%",
    width: "100%",
  },
  mobileContainer: {
    alignItems: "center",
    maxWidth: 1200,
    flexDirection: "row",
    alignSelf: "center",
    paddingHorizontal: 30,
    width: "100%",
  },
  paymentSecurityInfo: {
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    marginTop: 5,
  },
  paymentSecurityInfoText: {
    marginLeft: 1,
  },
  productActions: {
    alignItems: "flex-end",
    justifyContent: "center",
    width: "50%",
  },
  productFinalPrice: {
    color: theme.colors.onSurfaceVariant,
    fontWeight: "bold",
  },
  productDeliveryDate: {
    color: theme.colors.onSurfaceVariant,
    fontWeight: "bold",
  },
  productFinalPriceTitle: {
    color: theme.colors.onSurfaceVariant,
    opacity: 0.7,
  },
  productInfoSubtitle: {
    color: theme.colors.onSurfaceVariant,
  },
  productPriceContainer: {
    width: "50%",
  },
});
