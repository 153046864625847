import { globalStyles } from "@/common/styles";
import React, { Fragment, memo, useState } from "react";
import { View } from "react-native";
import Header from "./components/Header";
import {
  DeliveriesSection,
  FAQSection,
  FriendsSection,
  ProfileSection,
  TermsSection,
} from "./components/Sections";
import styles from "./styles";

const MoreModule = () => {
  const [section, setSection] = useState("profile");
  const getOpenedSection = () => {
    if (section === "profile") return <ProfileSection />;
    if (section === "deliveries") return <DeliveriesSection />;
    if (section === "faq") return <FAQSection />;
    if (section === "terms") return <TermsSection />;
    if (section === "friends") return <FriendsSection />;
    return <Fragment />;
  };

  return (
    <View style={styles.container}>
      <Header section={section} chooseSection={setSection} />
      <View style={globalStyles.mobileContainer}>{getOpenedSection()}</View>
    </View>
  );
};

export default memo(MoreModule);
