import { theme } from "@/theme";
import { BORDER_RADIUS } from "@/theme/dimensions";
import { StyleSheet } from "react-native";

export default (props?: any) =>
  StyleSheet.create({
    container: {
      borderWidth: 2,
      borderColor: theme.colors.primary,
      borderRadius: BORDER_RADIUS,
      overflow: "hidden",
      alignItems: "center",
      justifyContent: "center",
    },
    calendarGrid: { alignItems: "center", justifyContent: "center" },
    calendarHeader: {
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    calendarArrowLeft: {
      position: "relative",
      padding: 0,
      border: "none",
      borderTopWidth: 1,
      borderTopColor: "transparent",
      borderBottomWidth: 1,
      borderBottomColor: "transparent",
      borderRightWidth: 2,
      borderRightColor: theme.colors.secondary,
    },
    calendarMonth: {
      color: theme.colors.primary,
      textAlign: "center",
      paddingVertical: 8,
      paddingHorizontal: 2,
      ...theme.fonts.labelLarge,
    },
    calendarArrowRight: {
      position: "relative",
      padding: 0,
      border: "none",
      borderTopWidth: 1,
      borderTopColor: "transparent",
      borderBottomWidth: 1,
      borderBottomColor: "transparent",
      borderLeftWidth: 2,
      borderLeftColor: theme.colors.primary,
    },
    calendarDay: {
      width: "14.2%",
      textAlign: "center",
      alignSelf: "center",
      letterSpacing: 1,
      paddingHorizontal: 2,
      paddingVertical: 5,
      color: theme.colors.primary,
      borderTopWidth: 2,
      borderTopColor: theme.colors.primary,
      borderBottomWidth: 2,
      borderBottomColor: theme.colors.primary,
      ...theme.fonts.labelSmall,
    },
    CalendarDate: {
      width: "14.2%",
      textAlign: "center",
      alignSelf: "center",
      letterSpacing: 1,
      paddingHorizontal: 2,
      paddingVertical: 5,
      borderColor: theme.colors.background,
      borderWidth: 1,
      backgroundColor: theme.colors.surfaceVariant,
      ...theme.fonts.labelMedium,
      borderRadius: BORDER_RADIUS,
      color: theme.colors.onSurfaceVariant,
    },
    HighlightedCalendarDate: {
      color: theme.colors.background,
      backgroundColor: theme.colors.primary,
      position: "relative",
    },
    TodayCalendarDate: {
      color: theme.colors.primary,
      backgroundColor: "transparent",
    },
    DisabledCalendarDate: {
      color: theme.colors.surfaceDisabled,
      backgroundColor: "transparent",
    },
  });
