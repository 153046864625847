import MyToast from "@/common/components/Toast";
import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { View } from "react-native";
import { Text } from "react-native-paper";
import Toast, { ToastType } from "react-native-toast-notifications";
import { useDispatch } from "react-redux";
import Footer from "./components/Footer";
import Header from "./components/Header";
import FAQ from "./components/Sections/FAQ";
import Main from "./components/Sections/Main";
import Process from "./components/Sections/Process";
import Trust from "./components/Sections/Trust";
import Vet from "./components/Sections/Vet";
import Why from "./components/Sections/Why";
import styles from "./styles";
const FirstModule = ({ toggle }: { toggle: () => void }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const [toast, setToast] = useState<ToastType>(null);
  useEffect(() => {
    setToast(toastRef.current);
  }, [toastRef]);

  return (
    <View style={styles.container}>
      <View style={styles.backgroundEffectContainer}>
        <View style={styles.background}>
          <Header toggle={toggle} />
          <View style={styles.sectionContainer}>
            <View style={[styles.containerInner]}>
              <Main />
              <Why />
              <View style={styles.customDivider}>
                <Text style={styles.customDividerText}>{t("TextAnd2")}</Text>
              </View>
              <Trust />
              <FAQ />
              <Process />
              <Vet />
            </View>
          </View>
        </View>
      </View>
      <Footer toggle={toggle} toast={toast} />
      <Toast
        placement="top"
        renderToast={(toastOptions) => <MyToast toast={toastOptions} />}
        ref={toastRef}
      />
    </View>
  );
};

export default memo(FirstModule);
