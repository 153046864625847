import { Button } from "@/common/components/Button";
import Icon from "@/common/components/Icon";
import { discountProducts, getTranslationLanguage } from "@/common/utils";
import { experimentalPackageID } from "@/config";
import { moderateScale } from "@/theme/dimensions";
import { RootTabParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Image, View } from "react-native";
import { Card, Text } from "react-native-paper";
import { animated as Animated, useTransition } from "react-spring";
import styles from "./styles";

const AnimatedView = Animated(View);

const Products = ({ products, openProductView }) => {
  const { t, i18n } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<RootTabParamList>>();
  const refMap = useMemo(() => new WeakMap(), []);
  const transitions = useTransition(products, {
    from: { opacity: 0, height: 0 },
    keys: (item) => item.title["default"] + "_products",
    enter: (item) => async (next) => {
      await next({ opacity: 1, height: refMap.get(item).offsetHeight });
    },
    leave: [{ opacity: 0 }, { height: 0 }],
    trail: 100,
    config: { tension: 125, friction: 20, precision: 0.1 },
  });

  return (
    <View style={styles.container}>
      {products.length == 0 && (
        <View style={styles.noProductsContainer}>
          <Text style={styles.noProductsTitle} variant={"labelLarge"}>
            {t("TextWithoutProductsInCart")}
          </Text>
          <Button
            mode={"contained"}
            onPress={() => navigation.navigate("Explorer")}
          >
            {t("TextGoToProducts")}
          </Button>
        </View>
      )}
      <View style={[styles.listContainer]}>
        {transitions((style, product: Product) => (
          <AnimatedView style={{ ...style }}>
            <View
              ref={(ref) => ref && refMap.set(product, ref)}
              style={styles.cardContainer}
            >
              <Card
                style={styles.card}
                onPress={() => openProductView(product)}
              >
                <View style={styles.imageContainer}>
                  <Image
                    style={styles.image}
                    source={{ uri: product.images[0] }}
                  />
                </View>
                <View style={{ padding: 10 }}>
                  <Text
                    style={styles.productTitle}
                    variant={"labelMedium"}
                    numberOfLines={2}
                  >
                    {product.title[getTranslationLanguage(i18n.language)]}
                  </Text>
                  {discountProducts().includes(product._id) && (
                    <Text
                      style={styles.productPriceStrike}
                      variant={"labelMedium"}
                    >
                      {(
                        (product.price +
                          product.price *
                            (product._id === experimentalPackageID
                              ? 0.15
                              : 0.1)) *
                        (1 - product.discount / 100)
                      ).toFixed(2)}
                      €
                    </Text>
                  )}
                  <Text style={styles.productPrice} variant={"headlineMedium"}>
                    {(product.price * (1 - product.discount / 100)).toFixed(2)}€
                  </Text>
                  <View style={styles.tagContainer}>
                    {discountProducts().includes(product._id) ? (
                      <Text style={styles.tagDiscount} variant={"labelSmall"}>
                        <Icon
                          style={styles.tagIcon}
                          name={"tag"}
                          size={moderateScale(11)}
                        />
                        {product._id === experimentalPackageID ? "15%" : "10%"}{" "}
                        {t("TextDiscount")}
                      </Text>
                    ) : (
                      <Text style={styles.tag} variant={"labelSmall"}>
                        {product.quantity <= 0
                          ? t("TextWithoutStock")
                          : product.quantity <= 3
                          ? t("TextAlmostNoStock")
                          : t("TextInStock")}
                      </Text>
                    )}
                  </View>
                </View>
              </Card>
            </View>
          </AnimatedView>
        ))}
      </View>
    </View>
  );
};

export default memo(Products);
