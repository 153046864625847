import { goToLink } from "@/common/utils";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Platform, StyleProp, TextStyle } from "react-native";
import { FAB } from "react-native-paper";
import styles from "./styles";

const HelpFAB = ({ style }: { style?: StyleProp<TextStyle> }) => {
  const [state, setState] = React.useState({ open: false });
  const onStateChange = ({ open }) => setState({ open });
  const { open } = state;
  const { t } = useTranslation();
  return (
    <FAB.Group
      open={open}
      visible
      icon={open ? "close" : "account-question-outline"}
      fabStyle={[styles.fab, style]}
      actions={[
        {
          icon: "whatsapp",
          label: t("ButtonTextCallUs"),
          onPress:
            open && Platform.OS === "web"
              ? async () => {
                  // await Analytics.logEvent(`press_FAB_callus_button`);
                  window.open(
                    "https://api.whatsapp.com/send/?phone=%2B351913847159",
                    "_blank",
                  );
                }
              : open
                ? async () => {
                    // await Analytics.logEvent(`press_FAB_callus_button`);
                    goToLink("whatsapp://send?phone=+351913847159");
                  }
                : () => {},
        },
        {
          icon: "email",
          label: t("ButtonTextEmailUs"),
          onPress:
            open && Platform.OS === "web"
              ? async () => {
                  // await Analytics.logEvent(`press_FAB_emailus_button`);
                  window.open("mailto:geral@purrmi.com", "_blank");
                }
              : open
                ? async () => {
                    // await Analytics.logEvent(`press_FAB_emailus_button`);
                    goToLink("mailto:geral@purrmi.com");
                  }
                : () => {},
        },
        {
          icon: "web",
          label: t("ButtonTextWebsite"),
          onPress:
            open && Platform.OS === "web"
              ? async () => {
                  // await Analytics.logEvent(`press_FAB_website_button`);
                  window.open("https://www.purrmi.com/", "_blank");
                }
              : open
                ? async () => {
                    // await Analytics.logEvent(`press_FAB_website_button`);
                    goToLink("https://www.purrmi.com/");
                  }
                : () => {},
        },
        {
          icon: "calculator",
          label: "",
          onPress: () => {},
          // onPress:
          //   Platform.OS === "web"
          //     ? async () => {
          //         // await Analytics.logEvent(`press_FAB_calculator_button`);
          //         window.open(
          //           "https://www.purrmi.com/calculadora-de-doses-de-comida-para-gatos",
          //           "_blank"
          //         );
          //       }
          //     : async () => {
          //         // await Analytics.logEvent(`press_FAB_calculator_button`);
          //         goToLink(
          //           "https://www.purrmi.com/calculadora-de-doses-de-comida-para-gatos"
          //         );
          //       },
        },
      ]}
      onStateChange={onStateChange}
      onPress={async () => {
        // await Analytics.logEvent(`press_FAB_button`);
      }}
    />
  );
};

export default memo(HelpFAB);
