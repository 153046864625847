import AnimatedView from "@/common/components/AnimatedView";
import { LanguageDropdown } from "@/common/components/Dropdown";
import { RegisterForm } from "@/modules/Forms";
import { IntroParamList } from "@/types";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import React, { memo } from "react";
import { ImageBackground, View } from "react-native";
import { useSelector } from "react-redux";
import Background from "../../common/components/Background";
import Logo from "../../common/components/Svgs/Logo";
import styles from "./styles";

const Register = () => {
  const navigation = useNavigation<StackNavigationProp<IntroParamList>>();
  const starting_form = useSelector((state: RootState) => state.registerForm);
  return (
    <Background>
      <ImageBackground
        style={styles.imageBackground}
        source={require("@/assets/images/logo_transparent.png")}
        imageStyle={styles.repeatImage}
      >
        <View style={styles.header}>
          {starting_form.slide === 0 && (
            <Logo
              style={styles.logo}
              onPress={() => navigation.navigate("Login")}
            />
          )}
          <View style={styles.iconContainer}>
            <LanguageDropdown />
          </View>
        </View>
        <View
          style={[
            styles.main,
            { marginTop: starting_form.slide === 0 ? 0 : 100 },
          ]}
        >
          <AnimatedView>
            <RegisterForm />
          </AnimatedView>
        </View>
      </ImageBackground>
    </Background>
  );
};

export default memo(Register);
